import { instance as axios } from '../../axios-api'
import * as actionTypes from './actionTypes'
import { apiPath } from '../../shared'
import { errorHandler } from './errorHandler'

export const fetchCustomers = (token, page, term) => {
  return async (dispatch) => {
    try {
      dispatch(fetchCustomerListStart())
      const response = await axios(token).get(apiPath.customers(page, term))
      dispatch(fetchCustomerListSuccess(response.data))
    } catch (error) {
      errorHandler(dispatch, fetchCustomerListFail, error)
    }
  }
}
export const fetchCustomerListStart = () => ({
  type: actionTypes.FETCH_CUSTOMER_LIST_START
})
export const fetchCustomerListSuccess = (payload) => ({
  type: actionTypes.FETCH_CUSTOMER_LIST_SUCCESS,
  payload
})
export const fetchCustomerListFail = (error) => ({
  type: actionTypes.FETCH_CUSTOMER_LIST_FAIL,
  error: error
})

export const updateCustomerDataProperty = (event) => ({
  type: actionTypes.UPDATE_CUSTOMER_DATA_PROPERTY,
  payload: event
})

export const saveCustomerStart = () => ({
  type: actionTypes.SAVE_CUSTOMER_START
})
export const saveCustomerSuccess = (payload) => ({
  type: actionTypes.SAVE_CUSTOMER_SUCCESS,
  payload
})
export const saveCustomerFail = (error) => ({
  type: actionTypes.SAVE_CUSTOMER_FAIL,
  error: error
})

export const saveCustomer = (token, customer) => {
  return async (dispatch) => {
    try {
      dispatch(saveCustomerStart())
      const options = {
        method: customer.id ? 'PUT' : 'POST',
        url: customer.id ? apiPath.customer(customer.id) : apiPath.customers(),
        data: customer
      }
      const response = await axios(token)(options)
      dispatch(saveCustomerSuccess(response.data))
    } catch (error) {
      errorHandler(dispatch, saveCustomerFail, error)
    }
  }
}
export const fetchCustomer = (token, id) => {
  return async (dispatch) => {
    try {
      dispatch(fetchCustomerStart())
      const response = await axios(token).get(apiPath.customer(id))
      dispatch(fetchCustomerSuccess(response.data))
    } catch (error) {
      errorHandler(dispatch, fetchCustomerFail, error)
    }
  }
}
export const fetchCustomerStart = () => ({
  type: actionTypes.FETCH_CUSTOMER_START
})
export const fetchCustomerSuccess = (payload) => ({
  type: actionTypes.FETCH_CUSTOMER_SUCCESS,
  payload
})
export const fetchCustomerFail = (error) => ({
  type: actionTypes.FETCH_CUSTOMER_FAIL,
  error: error
})

export const fetchCustomerNew = (payload) => ({
  type: actionTypes.FETCH_CUSTOMER_NEW,
  payload
})
export const addProject = (payload) => ({
  type: actionTypes.CUSTOMER_ADD_PROJECT,
  payload
})
export const removeProject = (payload) => ({
  type: actionTypes.CUSTOMER_REMOVE_PROJECT,
  payload
})
