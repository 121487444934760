import React from 'react'
import { NavLink } from 'react-router-dom'
import Spinner from './Spinner'
import { Button } from 'reactstrap'

const UretimSiparisFormFooter = ({ downloading, saving, id, handleDownload }) => {
  return (
    <nav className='navbar fixed-bottom navbar-dark bg-dark'>
      <NavLink className='btn btn-warning m-1' to='/uretim'>
        Vazgec
      </NavLink>
      <div className='form-inline'>
        {(downloading || saving) && <Spinner />}
        <button type='submit' className='btn btn-primary float-end m-1' disabled={downloading || saving}>
          <i className='fas fa-save' /> Kaydet
        </button>
        {id && (
          <Button color='primary float-end m-1' disabled={downloading || saving} onClick={handleDownload}>
            <i className='far fa-file-excel' />
            Rapor Hazirla
          </Button>
        )}
      </div>
    </nav>
  )
}

export default UretimSiparisFormFooter
