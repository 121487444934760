import set from 'lodash/set'
import { initModulModal, initWardrobeModulModalData } from '../../dataModels/ModulModal'
import { initLookup } from '../../dataModels/LookupModals'
import cloneDeep from 'lodash/cloneDeep'
import shortid from 'shortid'
import { toast } from 'react-toastify'
import { toastOptions } from '../../../shared'

export const updateWardrobeModulModalProperty = (state, action) => {
  const { property, value } = action.payload
  const data = { ...state.modulModal.data }
  set(data, property, value)
  if (property === 'kapakrenk') {
    data.kapakmalzemesi = value
  }
  if (property === 'metalCerceveliKapak' && !value) {
    data.metalCerceveliKapakAdet = 0
    data.camModeli = null
    data.camProfili = null
    data.kulpYonu = null
  }
  if (property === 'aynaliKapak' && !value) {
    data.dolapAynaModeli = null
    data.aynaliKapakOlcu = null
    data.aynaliKapakAdet = 0
  }
  return {
    ...state,
    modulModal: {
      ...state.modulModal,
      data
    }
  }
}
export const toggleWardrobeModulModal = (state, action) => {
  const isSurmeOrder = state.wardrobeForm.type === 'surme'
  switch (action.payload.action) {
    case 'cancel':
      return { ...state, ...{ modulModal: initModulModal('wardrobe', state.wardrobeForm.type), lookup: initLookup() } }
    case 'clone':
      const cloneModul = cloneDeep(state.wardrobeForm.moduller[action.payload.index])
      cloneModul.siranumarasi = state.wardrobeForm.moduller.length + 1
      cloneModul.id = shortid.generate()
      toast.success(`Modul kopyalandi!`, toastOptions)
      return {
        ...state,
        ...{
          modulModal: initModulModal('wardrobe'),
          lookup: initLookup(),
          wardrobeForm: { ...state.wardrobeForm, moduller: [...state.wardrobeForm.moduller, cloneModul] }
        }
      }
    case 'save':
      const newModuller = cloneDeep(state.wardrobeForm.moduller)
      if (state.modulModal.index === -1) {
        newModuller.push(state.modulModal.data)
      } else {
        newModuller[state.modulModal.index] = state.modulModal.data
      }
      return {
        ...state,
        ...{
          modulModal: initModulModal('wardrobe', state.wardrobeForm.type),
          lookup: initLookup(),
          wardrobeForm: { ...state.wardrobeForm, moduller: newModuller }
        }
      }
    case 'new':
      const siranumarasi = state.wardrobeForm.moduller.length + 1
      return {
        ...state,
        ...{
          modulModal: {
            ...state.modulModal,
            show: !state.modulModal.show,
            data: {
              ...initWardrobeModulModalData(isSurmeOrder),
              siranumarasi,
              ...(!isSurmeOrder && {
                kulpmodeli: { ...state.wardrobeForm.kulpmodeli },
                kapakmodeli: { ...state.wardrobeForm.kapakmodeli },
                kapakmalzemesi: { ...state.wardrobeForm.kapakmalzemesi },
                kapakrenk: { ...state.wardrobeForm.kapakrenk }
              })
            }
          },
          lookup: initLookup()
        }
      }
    default:
      return {
        ...state,
        ...{
          modulModal: {
            show: !state.modulModal.show,
            data: state.wardrobeForm.moduller[action.payload.index],
            index: action.payload.index
          }
        }
      }
  }
}
export const removeModulOrder = (state, action) => {
  const modullerCloned = cloneDeep(state.wardrobeForm.moduller)
  modullerCloned.splice(action.payload.index, 1)
  const moduller = modullerCloned.map((m, index) => ({ ...m, siranumarasi: index + 1 }))
  return { ...state, wardrobeForm: { ...state.wardrobeForm, moduller } }
}
