import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import get from "lodash/get";
import {toast} from "react-toastify";
import {toasterMessages, toastErrorOptions, toastOptions} from "../../../shared";
import {initLookup} from "../../dataModels/LookupModals";
import {initMobilyaModulModal} from "../../dataModels/ModulModal";
import {initMobilyaData} from "../../dataModels/MobilyaModals";

export const saveMobilyaOrderFail = (state, action) => {
  toast.error(toasterMessages.errorWithMessage(action.error.message), toastErrorOptions)
  return { ...state, ...{ saving: false, error: action.error.message } }
}
export const saveMobilyaOrderSuccess = (state, action) => {
  toast.success(`Siparisiniz kaydedilmistir!`, toastOptions)
  return { ...state, ...{ saving: false, error: null, mobilyaForm: { ...state.mobilyaForm, id: action.data.id } } }
}
export const saveMobilyaOrderStart = (state, action) => {
  return { ...state, ...{ saving: true, error: null } }
}
export const resetMobilyaOrderForm = (state, action) => {
  return { ...state, mobilyaForm: undefined }
}
export const fetchMobilyaOrderStart = (state, action) => {
  return {
    ...state,
    ...{ loading: true, mobilyaForm: initMobilyaData(), modulModal: initMobilyaModulModal(), lookup: initLookup() }
  }
}

export const fetchMobilyaOrderSuccess = (state, action) => {
  let { order } = action.data
  const data = !order ? initMobilyaData() : order.data
  data.id = !order ? null : order.id
  return { ...state, ...{ mobilyaForm: data,loading: false, error: null, wardrobeForm: undefined, kitchenForm:undefined } }
}

export const fetchMobilyaOrderFail = (state, action) => {
  toast.error(toasterMessages.errorWithMessage(action.error.message), toastErrorOptions)
  return { ...state, ...{ loading: false, error: action.error.message } }
}
export const updateMobilyaOrderProperty = (state, action) => {
  const { field, value, updateModuller } = action.payload
  const cloneMobilyaForm = cloneDeep(state.mobilyaForm)
  set(cloneMobilyaForm, field, value)

  // Update values in moduls...
  if (updateModuller) {
    const currentMobilyaFormField = get(state.mobilyaForm, field)
    const updatedModulNames = []
    cloneMobilyaForm.moduller.forEach((modulItem) => {
      const currentModulFormField = get(modulItem, field)
      if (
        currentMobilyaFormField &&
        currentModulFormField &&
        currentMobilyaFormField.aciklama !== currentModulFormField.aciklama
      ) {
        updatedModulNames.push(modulItem.modul.aciklama)
      }

      set(modulItem, field, value)
    })
    if (updatedModulNames.length > 0) {
      toast.warn(`${updatedModulNames.join(', ')} modullerinde farkli degerler mevcut!`, toastErrorOptions)
    }
  }

  return { ...state, mobilyaForm: cloneMobilyaForm, lookup: initLookup() }
}
