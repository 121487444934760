import React, { PureComponent } from 'react'
import Select from '../../../Core/Select'
import { CardHeader, Card, CardBody } from 'reactstrap'
import NumberInput from '../../../Core/NumberInput'
class Baza extends PureComponent {
  render() {
    const { item, koseadeti, adabar, lookup, onInputChange, onSelectMenuClose, onSelectChange, lookupSetting } =
      this.props
    return (
      <Card className='shadow mb-2'>
        <CardHeader>Baza</CardHeader>
        <CardBody>
          <div className='row'>
            <div className='form-group col-lg-4'>
              <Select
                name='baza.item'
                value={item}
                text='Baza'
                onChange={onSelectChange}
                options={lookup.result}
                onSelectMenuClose={onSelectMenuClose}
                onInputChange={(e) => lookupSetting(e, 'baza')}
                isLoading={lookup.isLoading}
              />
            </div>
            <div className='form-group col-lg-2'>
              <NumberInput label='Kose Adeti' name='baza.koseadeti' value={koseadeti} onChange={onInputChange} />
            </div>
            <div className='form-group col-lg-4'>
              <NumberInput
                label='Ada/Bar icin baza ihtiyaci(mm)'
                name='baza.adabar'
                value={adabar}
                onChange={onInputChange}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    )
  }
}
export default Baza
