import * as actionTypes from '../actions/actionTypes';

const initialState = {
  token: null,
  email: '',
  profile: null,
  password: '',
  error: null,
  loading: false
};

const authStart = ( state, action ) => {
  return {...state, ...{ error: null, password: '', profile: null, token: null, loading: true } };
}

const authSuccess = ( state, action ) => {
  const {token,email,profile} = action.payload
  return {...state, ...{
      token,
      email,
      profile,
      error: null,
      password: '',
      loading: false
    }
  }
};

const authFail = ( state, action ) => {
  return {...state, ...{
    error: action.error,
    password: '',
    token: null,
    profile: null,
    loading: false
  }}
};

const authLogout = ( state, action ) => {
  return {...state, ...{ token: null, email: '', password: '' }}
};

const authPropChange = ( state, action ) => {
  return {...state, ...{[action.payload.field]: action.payload.value, error: null}}
}

export const authReducer = ( state = initialState, action ) => {
  switch( action.type ) {
    case actionTypes.SET_AUTH_PROPERTY: return authPropChange( state, action );
    case actionTypes.AUTH_START: return authStart( state, action );
    case actionTypes.AUTH_SUCCESS: return authSuccess( state, action );
    case actionTypes.AUTH_FAIL: return authFail( state, action );
    case actionTypes.AUTH_LOGOUT: return authLogout( state, action );
    default: return state;
  }
};
