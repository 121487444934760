import * as actionTypes from "./actionTypes";
import {instance as axios} from "../../axios-api";
import {apiPath} from "../../shared";
import {errorHandler} from "./errorHandler";
import {
  lookupSelectionStart,
  lookupSelectionSuccess
} from "./kitchenorderActions";
// REPORT DOWNLOAD
export const onDownloadMobilyaReport = (token, reportName, id) => {
  return async (dispatch) => {
    try {
      dispatch(onDownloadReportStart())
      const options = {
        url: apiPath.mobilyaOrderReport(id),
        method: 'GET',
        responseType: 'blob' // important
      }
      const response = await axios(token)(options)
      dispatch(onDownloadReportSuccess(response.data, reportName))
    } catch (error) {
      errorHandler(dispatch, onDownloadReportFail, error)
    }
  }
}
const onDownloadReportStart = () => ({
  type: actionTypes.MOBILYA_ORDER_DOWNLOAD_REPORT
})
const onDownloadReportSuccess = (data, reportName) => ({
  type: actionTypes.MOBILYA_ORDER_DOWNLOAD_REPORT_SUCCESS,
  payload: { data, reportName }
})
const onDownloadReportFail = (error) => ({
  type: actionTypes.MOBILYA_ORDER_DOWNLOAD_REPORT_FAIL,
  payload: error
})
export const saveMobilyaOrder = (token, data) => {
  return async (dispatch) => {
    try {
      dispatch(saveMoilyaOrderStart())
      const resp = await axios(token)({
        method: data.id ? 'PUT' : 'POST',
        url: data.id ? apiPath.mobilyaOrder(data.id) : apiPath.mobilyaOrderList(),
        data
      })
      dispatch(saveMobilyaOrderSuccess(resp.data))
    } catch (error) {
      errorHandler(dispatch, saveMobilyaOrderFail, error)
    }
  }
}

const saveMoilyaOrderStart = () => ({
  type: actionTypes.SAVE_MOBILYA_ORDER_START
})
const saveMobilyaOrderSuccess = (data) => ({
  type: actionTypes.SAVE_MOBILYA_ORDER_SUCCESS,
  data
})

const saveMobilyaOrderFail = (error) => ({
  type: actionTypes.SAVE_MOBILYA_ORDER_FAIL,
  error: error
})
export const fetchMobilyaOrder = (token, id) => {
  return async (dispatch) => {
    try {
      dispatch(fetchMobilyaOrderStart())
      const response = await axios(token).get(apiPath.mobilyaOrder(id))
      dispatch(fetchMobilyaOrderSuccess({ order: response.data }))
    } catch (error) {
      errorHandler(dispatch, fetchMobilyaOrderFail, error)
    }
  }
}
const fetchMobilyaOrderStart = () => ({
  type: actionTypes.FETCH_MOBILYA_ORDER_START
})
const fetchMobilyaOrderSuccess = (data) => ({
  type: actionTypes.FETCH_MOBILYA_ORDER_SUCCESS,
  data
})

const fetchMobilyaOrderFail = (error) => ({
  type: actionTypes.FETCH_MOBILYA_ORDER_FAIL,
  error: error
})
export const updateMobilyaOrderProperty = (event) => {
  const field = event.target.name
  const value = event.target.value
  const target = event.target
  return {
    type: actionTypes.UPDATE_MOBILYA_ORDER_PROPERTY,
    payload: { field, value, target }
  }
}

export const updateMobilyaOrderSelect = (field, value, updateModuller) => ({
  type: actionTypes.UPDATE_MOBILYA_ORDER_SELECT,
  payload: { field, value, updateModuller }
})
// MODUL MODAL
export const toggleMobilyaModulModal = (payload) => ({
  type: actionTypes.TOGGLE_MOBILYA_MODUL_MODAL,
  payload
})
// PARCA MODAL
export const updateMobilyaParcaModal = (action) => ({
  type: actionTypes.UPDATE_MOBILYA_MODUL_PARCA_MODAL,
  payload: action
})
export const updateMobilyaParcaModalNew = (name, value) => ({
  type: actionTypes.UPDATE_MOBILYA_MODUL_PARCA_MODAL_NEW,
  payload: { name, value }
})
export const toggleMobilyaModulParcaModal = (payload) => ({
  type: actionTypes.TOGGLE_MOBILYA_MODUL_PARCA_MODAL,
  payload
})
// AKSAM MODAL
export const toggleMobilyaModulAksamModal = (payload) => ({
  type: actionTypes.TOGGLE_MOBILYA_MODUL_AKSAM_MODAL,
  payload
})
export const updateMobilyaAksamModal = (action) => ({
  type: actionTypes.UPDATE_MOBILYA_MODUL_AKSAM_MODAL,
  payload: action
})
export const updateMobilyaAksamModalNew = (name, value) => ({
  type: actionTypes.UPDATE_MOBILYA_MODUL_AKSAM_MODAL_NEW,
  payload: { name, value }
})
// AKSESUAR MODAL
export const updateMobilyaAksesuarModal = (action) => ({
  type: actionTypes.UPDATE_MOBILYA_MODUL_AKSESUAR_MODAL,
  payload: action
})
export const updateMobilyaAksesuarModalNew = (name, value) => ({
  type: actionTypes.UPDATE_MOBILYA_MODUL_AKSESUAR_MODAL_NEW,
  payload: { name, value }
})
export const toggleMobilyaModulAksesuarModal = (payload) => ({
  type: actionTypes.TOGGLE_MOBILYA_MODUL_AKSESUAR_MODAL,
  payload
})
export const mobilyaSelectSetting = (token, item, name) => {
  return async (dispatch) => {
    try {
      dispatch(lookupSelectionStart())
      const response = await axios(token).get(apiPath.getMobilyaById(item.id))
      dispatch(updateMobilyaModulModalDataProperty(name, response.data))
      dispatch(lookupSelectionSuccess())
    } catch (error) {
      errorHandler(dispatch, lookupFail, error)
    }
  }
}
export const updateMobilyaModulModalDataProperty = (property, value) => ({
  type: actionTypes.UPDATE_MOBILYA_ORDER_MODUL_MODAL_DATA_CHANGE,
  payload: { property, value }
})
export const removeMobilyaModul = (index) => ({
  type: actionTypes.REMOVE_MODUL_MOBILYA_ORDER,
  payload: { index }
})
export const resetMobilyaOrderForm = () => ({
  type: actionTypes.RESET_MOBILYA_ORDER_FORM
})
