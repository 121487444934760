export const initAksamModalNew = () => ({
  aksam: null,
  miktar:0
});
export const initAksamModal = () => ({
  show: false,
  index: 0,
  data: [],
  modulId: '',
  new: initAksamModalNew()
})