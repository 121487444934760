import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Button, Col, Row, Input, InputGroupText, InputGroup } from 'reactstrap'
import SearchInput from '../../Common/SearchInput'
import Loading from '../../Common/Loading'
import Paging from '../../Common/Paging'
import ProductOrderList from './ProductOrderList'
import * as actions from '../../../store/actions'
import {
  getNextStatus,
  PRODUCT_ORDER_STATUS_OLUSTURULAN,
  PRODUCT_ORDER_STATUS_TAMAMLANAN,
  PRODUCT_ORDER_STATUS_TEKLIF_BEKLEYEN,
  PRODUCT_ORDER_STATUS_TESLIM_BEKLEYEN
} from '../../../shared'
import { Link } from 'react-router-dom'

const ProductOrderListContainer = (props) => {
  const dispatch = useDispatch()
  const { loading, list, paging, filters, error } = useSelector((state) => state.productOrders)
  const { token } = useSelector((state) => state.auth)

  const onFetch = (page, status) => dispatch(actions.fetchProductOrders(token, page, status))
  const onSearchClick = (token, query) => dispatch(actions.productOrdersSearchClick(token, query))

  useEffect(() => {
    onFetch(1, filters.status)
  }, [filters.status, token])

  const handleChangeStatus = (orderId, order_fields_id) => {
    const result = list.find((x) => x.id === orderId).order_fields.find((x) => x.id === order_fields_id)
    const currentStatus = result.status
    const nextStatus = getNextStatus(currentStatus)
    let body = { nextStatus }
    if (result.status_log[nextStatus]) {
      const { notification_key, notification_number } = result.status_log[nextStatus]
      body = { notification_key, notification_number, nextStatus }
    }
    dispatch(actions.changeProductOrderStatus(token, orderId, order_fields_id, body))
  }
  const handleChangeNotification = (event, id, orderId) => {
    const nextStatus = getNextStatus(filters.status)
    dispatch(actions.changeProductOrderNotification(event.target.name, event.target.value, nextStatus, id, orderId))
  }
  const handleClearSearchClick = () => {
    onSearchClick(token, '')
  }
  const handleSearchClick = (query) => {
    onSearchClick(token, query)
  }

  const handleStatusChange = (event) => {
    dispatch(actions.changeStatusFilter(event.target.value))
    onFetch(token, 1, event.target.value)
  }
  const handleIlkClick = () => {
    onFetch(token, 1, filters.status)
  }
  const handleGeriClick = () => {
    onFetch(token, ~~paging.currentPage - 1, filters.status)
  }
  const handleSonClick = () => {
    onFetch(token, ~~paging.totalPageCount, filters.status)
  }
  const handleIleriClick = () => {
    onFetch(token, ~~paging.currentPage + 1, filters.status)
  }
  return (
    <>
      <Row>
        <Col sm='2'>
          <h4>Siparişler</h4>
        </Col>
        <Col sm='5'>
          <InputGroup>
            <InputGroupText>Durum:</InputGroupText>
            <Input type='select' name='select' id='exampleSelect' sm={2} onChange={handleStatusChange}>
              <option value={PRODUCT_ORDER_STATUS_OLUSTURULAN}>Olusturulan</option>
              <option value={PRODUCT_ORDER_STATUS_TEKLIF_BEKLEYEN}>Teklif Beklenen</option>
              <option value={PRODUCT_ORDER_STATUS_TESLIM_BEKLEYEN}>Teslim Beklenen</option>
              <option value={PRODUCT_ORDER_STATUS_TAMAMLANAN}>Tamamlanan</option>
            </Input>
          </InputGroup>
        </Col>
        <Col sm='5'>
          <SearchInput title='Siparis ismi' onSearchClick={handleSearchClick}>
            <Link disabled className='btn btn-primary float-end' activeclassname='active' to='/alim/siparisler/yeni'>
              <i className='fa fa-plus' /> Yeni
            </Link>
          </SearchInput>
        </Col>
        {error && (
          <Col sm={12}>
            <Alert color='danger'>Siparişler yuklerken hata olustu — {error}</Alert>
          </Col>
        )}
        {paging.q && (
          <Col sm='12'>
            <Alert color='info'>
              <Button color='danger' onClick={handleClearSearchClick}>
                <i className='fas fa-times' />
              </Button>
              Arama "<strong>{paging.q}</strong>"
            </Alert>
          </Col>
        )}
        {loading ? (
          <Loading />
        ) : (
          <ProductOrderList
            list={list}
            handleChangeStatus={handleChangeStatus}
            handleChangeNotification={handleChangeNotification}
          />
        )}
      </Row>

      <Paging
        {...paging}
        handleIleriClick={handleIleriClick}
        handleSonClick={handleSonClick}
        handleGeriClick={handleGeriClick}
        handleIlkClick={handleIlkClick}
      />
    </>
  )
}

export default ProductOrderListContainer
