import React, { useEffect } from 'react'
import ProductOrderForm from './ProductOrderForm'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../../store/actions'
import Loading from '../../Common/Loading'

const ProductOrderEditContainer = (props) => {
  const dispatch = useDispatch()
  const { productOrderForm } = useSelector((state) => state.productOrders)
  const { token } = useSelector((state) => state.auth)

  useEffect(() => {
    dispatch(actions.fetchProductOrder(token, props.match.params.id))
  }, [props.match.params.id, token, dispatch])
  const handleRemoveProductOrderRow = (id) => dispatch(actions.removeProductOrderRow(id))
  const handleAddToOrder = () => dispatch(actions.addToProductOrder())
  const handleResetOrder = () => dispatch(actions.resetProductOrder())
  const handlePropertyChange = (e) => dispatch(actions.updateProductOrderProperty(e.target.name, e.target.value))
  const handleChangeProductOrderModalProperty = (name, value) =>
    dispatch(actions.updateProductOrderModalProperty(name, value))

  const handleCompaniesForProduct = (productId) => dispatch(actions.getCompaniesForProduct(token, productId))

  const handleEditProductOrderForCompany = (order) => dispatch(actions.editProductOrderForCompany(token, order))

  const handleSave = (e) => {
    e.preventDefault()
    const { id, note, description, orders } = productOrderForm
    dispatch(actions.saveProductOrder(token, { id, note, description, orders }))
  }
  return (
    <>
      {productOrderForm.loading ? (
        <Loading />
      ) : (
        <ProductOrderForm
          productOrderForm={productOrderForm}
          onChange={handlePropertyChange}
          onSave={handleSave}
          getCompaniesForProduct={handleCompaniesForProduct}
          onChangeModalProperty={handleChangeProductOrderModalProperty}
          editProductOrderForCompany={handleEditProductOrderForCompany}
          resetOrder={handleResetOrder}
          addToOrder={handleAddToOrder}
          removeProductOrderRow={handleRemoveProductOrderRow}
        />
      )}
    </>
  )
}

export default ProductOrderEditContainer
