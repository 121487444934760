import { initialPaging, initLookup } from '../dataModels/CommonModals'
import * as actionTypes from '../actions/actionTypes'
import { toast } from 'react-toastify'
import { toasterMessages, toastErrorOptions, toastOptions } from '../../shared'
import set from 'lodash/set'
import cloneDeep from 'lodash/cloneDeep'

const initialProduct = () => ({
  id: '',
  isactive: true,
  fields: { description: '', note: '' },
  productGroup: null,
  companies: []
})
export const initCompanyModalNew = () => ({
  company: null,
  paymentDetails: {
    measure: null,
    minOrderAmount: '',
    paymentType: null,
    deliveryType: null,
    deliveryRate: 0,
    currency: null,
    price: ''
  }
})
export const initCompanyModal = () => ({
  show: false,
  mode: 'create',
  new: initCompanyModalNew()
})

const initialState = {
  error: null,
  search: {
    query: ''
  },
  loading: true,
  paging: initialPaging(),
  productForm: initialProduct(),
  list: [],
  lookup: initLookup(),
  referenceData: {},
  companyModal: initCompanyModal()
}

const fetchProductsSuccess = (state, action) => {
  toast.success(`Urunler yuklendi!`, toastOptions)
  return {
    ...state,
    list: action.payload.result,
    productForm: initialProduct(),
    paging: action.payload.paging,
    loading: false,
    error: null
  }
}

const fetchProductsStart = (state, action) => {
  return { ...state, list: [], loading: true, error: null, paging: initialPaging() }
}

const fetchProductsFail = (state, action) => {
  toast.error(toasterMessages.errorWithMessage(action.error.message), toastErrorOptions)
  return { ...state, ...{ loading: false, error: action.error.message } }
}
const fetchProductSuccess = (state, action) => {
  toast.success(`Urun yuklendi!`, toastOptions)
  const { id, isactive, fields, productGroup, companies } = action.payload
  return {
    ...state,
    ...{
      list: [],
      productForm: { ...state.productForm, id, isactive, fields, productGroup, companies },
      loading: false,
      error: null
    }
  }
}

const fetchProductStart = (state, action) => {
  return { ...state, ...{ list: [], loading: true, error: null, paging: initialPaging() } }
}

const fetchProductFail = (state, action) => {
  toast.error(toasterMessages.errorWithMessage(action.error.message), toastErrorOptions)
  return { ...state, ...{ loading: false, error: action.error.message } }
}
const fetchProductNew = (state, action) => {
  return { ...state, ...{ error: null, loading: false, productForm: initialProduct() } }
}

const saveProductStart = (state, action) => {
  return { ...state, ...{ error: null, loading: true } }
}

const saveProductSuccess = (state, action) => {
  toast.success(`Urun kaydedildi!`, toastOptions)
  return {
    ...state,
    ...{
      error: null,
      loading: false,
      productForm: { ...state.productForm, id: action.payload.id }
    }
  }
}

const saveProductFail = (state, action) => {
  toast.error(toasterMessages.errorWithTitle(`Ürün kaydederken`), toastErrorOptions)
  return {
    ...state,
    ...{
      error: action.error.message,
      loading: false
    }
  }
}
const toggleCompanyModal = (state, action) => {
  switch (action.payload.action) {
    case 'update':
      const paymentDetails = { ...state.companyModal.new.paymentDetails }
      const updatedCompanies = state.productForm.companies.map((x) => {
        if (x.company.id === state.companyModal.new.company.id) {
          if (
            paymentDetails.currency.id !== x.paymentDetails.currency.id ||
            Number(paymentDetails.price) !== x.paymentDetails.price
          ) {
            const date = new Date()
            if (!paymentDetails.priceHistory) {
              paymentDetails.priceHistory = []
            }
            paymentDetails.priceHistory = [
              ...paymentDetails.priceHistory,
              {
                price: x.paymentDetails.price,
                currency: { ...x.paymentDetails.currency },
                date: date.toISOString()
              }
            ]
          }

          return { ...x, paymentDetails }
        }
        return x
      })
      return {
        ...state,
        companyModal: initCompanyModal(),
        productForm: { ...state.productForm, companies: updatedCompanies }
      }
    case 'edit':
      const selectedCompany = state.productForm.companies.find((x) => x.company.id === action.payload.companyId)
      return {
        ...state,
        companyModal: {
          ...state.companyModal,
          new: {
            ...state.companyModal.new,
            company: { ...selectedCompany.company },
            paymentDetails: { ...selectedCompany.paymentDetails }
          },
          mode: 'edit',
          show: true
        }
      }
    case 'create':
      const selectedCompanyId = state.companyModal.new.company.id
      if (state.productForm.companies.some((c) => ~~c.company.id === ~~selectedCompanyId)) {
        toast.error(`Secilen Sirket hala hazirda mevcut!`, toastErrorOptions)
        return state
      }
      const newCompany = { ...state.companyModal.new }
      return {
        ...state,
        companyModal: initCompanyModal(),
        productForm: { ...state.productForm, companies: [...state.productForm.companies, newCompany] }
      }
    case 'cancel':
      return { ...state, ...{ companyModal: initCompanyModal(), lookup: initLookup() } }
    default:
      return { ...state, companyModal: { ...state.companyModal, show: true } }
  }
}
const lookupCompanyStart = (state, action) => {
  return { ...state, lookup: { ...state.lookup, isLoading: true } }
}
const lookupCompanySuccess = (state, action) => {
  return { ...state, lookup: { result: action.payload.result, isLoading: false } }
}
const lookupCompanyFail = (state, action) => {
  return { ...state, lookup: { result: [], isLoading: false } }
}

const removeProductCompany = (state, action) => {
  const companyId = action.payload
  const companies = state.productForm.companies.filter((c) => c.company.id !== companyId)
  return {
    ...state,
    error: null,
    productForm: {
      ...state.productForm,
      companies
    }
  }
}

const updateCompanyModalProperty = (state, action) => {
  const newData = cloneDeep(state.companyModal.new)
  set(newData, action.payload.name, action.payload.value)
  return { ...state, companyModal: { ...state.companyModal, new: newData } }
}
const updateProductProperty = (state, action) => {
  const clonedProductForm = cloneDeep(state.productForm)
  set(clonedProductForm, action.payload.name, action.payload.value)
  return {
    ...state,
    productForm: clonedProductForm
  }
}
const lookupProductGroupStart = (state, action) => {
  return { ...state, referenceData: {}, error: null }
}
const lookupProductGroupSuccess = (state, action) => {
  return { ...state, referenceData: { productGroup: action.payload }, isLoading: false }
}
const lookupProductGroupFail = (state, action) => {
  return { ...state, referenceData: {}, error: action.error.message }
}
const onSearchClickFail = (state, action) => {
  toast.error(toasterMessages.errorWithTitle('Ürün ararken'), toastErrorOptions)
  return {
    ...state,
    loading: false,
    error: action.error.message
  }
}
const onSearchClickStart = (state, action) => {
  return {
    ...state,
    loading: true
  }
}

const onSearchClickSuccess = (state, action) => {
  toast.success(`Arama tamamlandi!`, toastOptions)
  return {
    ...state,
    ...{
      error: null,
      loading: false,
      list: action.payload.result,
      paging: action.payload.paging
    }
  }
}
export const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PRODUCTS_LIST_SUCCESS:
      return fetchProductsSuccess(state, action)

    case actionTypes.FETCH_PRODUCTS_LIST_FAIL:
      return fetchProductsFail(state, action)

    case actionTypes.FETCH_PRODUCTS_LIST_START:
      return fetchProductsStart(state, action)

    case actionTypes.FETCH_PRODUCT_SUCCESS:
      return fetchProductSuccess(state, action)

    case actionTypes.FETCH_PRODUCT_FAIL:
      return fetchProductFail(state, action)

    case actionTypes.FETCH_PRODUCT_START:
      return fetchProductStart(state, action)

    case actionTypes.FETCH_PRODUCT_NEW:
      return fetchProductNew(state, action)

    case actionTypes.SAVE_PRODUCT_START:
      return saveProductStart(state, action)

    case actionTypes.SAVE_PRODUCT_SUCCESS:
      return saveProductSuccess(state, action)

    case actionTypes.SAVE_PRODUCT_FAIL:
      return saveProductFail(state, action)

    case actionTypes.UPDATE_PRODUCT_PROPERTY:
      return updateProductProperty(state, action)

    case actionTypes.PRODUCT_SEARCH_CLICK_START:
      return onSearchClickStart(state, action)
    case actionTypes.PRODUCT_SEARCH_CLICK_SUCCESS:
      return onSearchClickSuccess(state, action)
    case actionTypes.PRODUCT_SEARCH_CLICK_FAIL:
      return onSearchClickFail(state, action)

    case actionTypes.TOGGLE_PRODUCT_COMPANY_MODAL:
      return toggleCompanyModal(state, action)
    case actionTypes.CHANGE_PRODUCT_COMPANY_MODAL_PROPERTY:
      return updateCompanyModalProperty(state, action)
    case actionTypes.REMOVE_PRODUCT_COMPANY:
      return removeProductCompany(state, action)
    case actionTypes.PRODUCT_COMPANY_LOOKUP_START:
      return lookupCompanyStart(state, action)
    case actionTypes.PRODUCT_COMPANY_LOOKUP_SUCCESS:
      return lookupCompanySuccess(state, action)
    case actionTypes.PRODUCT_COMPANY_LOOKUP_FAIL:
      return lookupCompanyFail(state, action)

    case actionTypes.PRODUCT_GROUP_LOOKUP_START:
      return lookupProductGroupStart(state, action)
    case actionTypes.PRODUCT_GROUP_LOOKUP_SUCCESS:
      return lookupProductGroupSuccess(state, action)
    case actionTypes.PRODUCT_GROUP_LOOKUP_FAIL:
      return lookupProductGroupFail(state, action)

    default:
      return state
  }
}
