import React from 'react'
import { Table } from 'reactstrap'
import { Link } from 'react-router-dom'

const ProductList = (props) => {
  return (
    <Table>
      <thead>
        <tr className='d-flex'>
          <th scope='col' className='col-1'>
            #
          </th>
          <th scope='col' className='col-6'>
            Ürün ismi
          </th>
          <th scope='col' className='col-5'>
            Ürün gurubu
          </th>
        </tr>
      </thead>
      <tbody>
        {props.list.map((item) => (
          <tr className='d-flex' key={item.id}>
            <th scope='row' className='col-1'>
              <Link className='btn btn-secondary float-end' to={'/alim/urunler/' + item.id}>
                Degistir
              </Link>
            </th>
            <td className='col-6'>{item.fields.description}</td>
            <td className='col-5'>{item.productGroup ? item.productGroup.aciklama : ''}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default ProductList
