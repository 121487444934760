import { instance as axios } from '../../axios-api'
import * as actionTypes from './actionTypes'
import { apiPath } from '../../shared'
import { errorHandler } from './errorHandler'
import { push } from 'connected-react-router'

export const fetchUretimSettings = (token) => {
  return async (dispatch) => {
    try {
      dispatch(fetchUretimSettingsStart())
      const response = await axios(token).get(apiPath.uretimSettingsList())
      dispatch(fetchUretimSettingsSuccess(response.data))
    } catch (error) {
      errorHandler(dispatch, fetchUretimSettingsFail, error)
    }
  }
}
export const fetchUretimSettingsStart = () => ({
  type: actionTypes.SETTING_URETIM_LIST_START
})
export const fetchUretimSettingsSuccess = (payload) => ({
  type: actionTypes.SETTING_URETIM_LIST_SUCCESS,
  payload
})
export const fetchUretimSettingsFail = (error) => ({
  type: actionTypes.SETTING_URETIM_LIST_FAIL,
  error: error
})

export const fetchUretimSettingV2WithName = (token, selectedSetting, query, page) => {
  return (dispatch) => {
    dispatch(fetchUretimSettingWithNameStart(selectedSetting))
    let url = apiPath.uretimSettingsV2WithPage(selectedSetting, page)
    if (query) {
      url = url + `&q=${query}`
    }
    axios(token)
      .get(url)
      .then((response) => {
        const { paging, result } = response.data
        dispatch(fetchUretimSettingV2WithNameSuccess({ paging, result }))
      })
      .catch((error) => {
        errorHandler(dispatch, fetchUretimSettingWithNameFail, error)
      })
  }
}
export const fetchUretimSettingV2WithId = (token, name, id) => {
  return (dispatch) => {
    dispatch(fetchUretimSettingV2WithIdStart())
    const url = apiPath.uretimSettingsV2GetById(name, id)
    axios(token)
      .get(url)
      .then((response) => {
        dispatch(fetchUretimSettingV2WithIdSuccess(response.data))
      })
      .catch((error) => {
        errorHandler(dispatch, fetchUretimSettingV2WithIdFail, error)
      })
  }
}
const fetchUretimSettingV2WithIdStart = () => ({
  type: actionTypes.URETIM_SETTING_V2_FETCH_BY_ID_START
})
const fetchUretimSettingV2WithIdSuccess = (payload) => {
  return {
    type: actionTypes.URETIM_SETTING_V2_FETCH_BY_ID_SUCCESS,
    payload
  }
}
const fetchUretimSettingV2WithIdFail = (error) => ({
  type: actionTypes.URETIM_SETTING_V2_FETCH_BY_ID_FAIL,
  error: error
})

const fetchUretimSettingV2WithNameSuccess = (payload) => ({
  type: actionTypes.URETIM_SETTING_V2_FETCH_BY_NAME_SUCCESS,
  payload
})

export const fetchUretimSettingWithName = (token, selectedSetting, query, page) => {
  return (dispatch) => {
    dispatch(fetchUretimSettingWithNameStart(selectedSetting))
    let url = apiPath.uretimSettingsWithPage(selectedSetting, page)
    if (query) {
      url = url + `&q=${query}`
    }
    const requests = [
      axios(token)
        .get(url)
        .then((r) => r.data)
    ]
    const extraRequestsForFields = {
      modul: 'parca,aksam,aksesuar',
      aksam: 'aksamgrup',
      aksesuar: 'aksamgrup',
      kapakRenk: 'bandRengi',
      govdeRengi: 'bandRengi',
      parcaTipi: 'bandTipi',
      kapama: 'bandTipi'
    }
    if (extraRequestsForFields[selectedSetting]) {
      requests.push(
        axios(token)
          .get(apiPath.uretimSettingsWithFields(extraRequestsForFields[selectedSetting]))
          .then((r) => r.data)
      )
    }
    Promise.all(requests)
      .then((response) => {
        const [order, referenceData] = response
        const { paging, result } = order
        dispatch(fetchUretimSettingWithNameSuccess({ paging, result, referenceData }))
      })
      .catch((error) => {
        errorHandler(dispatch, fetchUretimSettingWithNameFail, error)
      })
  }
}
const fetchUretimSettingWithNameStart = (payload) => ({
  type: actionTypes.URETIM_SETTING_FETCH_BY_NAME_START,
  payload
})
const fetchUretimSettingWithNameSuccess = (payload) => ({
  type: actionTypes.URETIM_SETTING_FETCH_BY_NAME_SUCCESS,
  payload
})
const fetchUretimSettingWithNameFail = (error) => ({
  type: actionTypes.URETIM_SETTING_FETCH_BY_NAME_FAIL,
  error: error
})

// SEARCH V2 ON URETIM
export const onUretimSearchV2Click = (token, name, inputValue) => {
  return async (dispatch) => {
    try {
      dispatch(uretimSearchV2ClickStart())
      const response = await axios(token).get(apiPath.uretimSettingsV2Search(name, inputValue))
      dispatch(uretimSearchV2ClickSuccess(response.data))
    } catch (error) {
      errorHandler(dispatch, uretimSearchV2ClickFail, error)
    }
  }
}
export const uretimSearchV2ClickStart = () => ({
  type: actionTypes.URETIM_SETTING_V2_SEARCH_CLICK_START
})
export const uretimSearchV2ClickSuccess = (payload) => ({
  type: actionTypes.URETIM_SETTING_V2_SEARCH_CLICK_SUCCESS,
  payload
})
export const uretimSearchV2ClickFail = (err) => ({
  type: actionTypes.URETIM_SETTING_V2_SEARCH_CLICK_FAIL,
  payload: err
})
// SEARCH ON URETIM
export const onUretimSearchClick = (token, name, inputValue) => {
  return async (dispatch) => {
    try {
      dispatch(uretimSearchClickStart())
      const response = await axios(token).get(apiPath.uretimSettingsSearch(name, inputValue))
      dispatch(uretimSearchClickSuccess(response.data))
    } catch (error) {
      errorHandler(dispatch, uretimSearchClickFail, error)
    }
  }
}
export const uretimSearchClickStart = () => ({
  type: actionTypes.URETIM_SETTING_SEARCH_CLICK_START
})
export const uretimSearchClickSuccess = (payload) => ({
  type: actionTypes.URETIM_SETTING_SEARCH_CLICK_SUCCESS,
  payload
})
export const uretimSearchClickFail = (err) => ({
  type: actionTypes.URETIM_SETTING_SEARCH_CLICK_FAIL,
  payload: err
})

export const onSettingInputChangeInArray = (name, selected, index) => ({
  type: actionTypes.SETTING_SELECT_VALUE_CHANGE_IN_ARRAY,
  payload: { name, selected, index }
})
export const onSettingSelectValueChange = (name, selected, index) => ({
  type: actionTypes.SETTING_SELECT_VALUE_CHANGE,
  payload: { name, selected, index }
})
export const onPervazInputChange = (index, pervazName, property, value) => ({
  type: actionTypes.SETTING_PERVAZ_INPUT_CHANGE,
  payload: { index, pervazName, property, value }
})
export const onSettingInputChange = (event) => ({
  type: actionTypes.SETTING_INPUT_CHANGE,
  payload: { event }
})
export const onSettingStateChange = (event) => ({
  type: actionTypes.SETTING_STATE_CHANGE,
  payload: { ...event }
})
export const onSettingSelected = (name, description) => {
  return (dispatch) => {
    dispatch(onSettingSelectedStart(name, description))
    dispatch(push(`/uretim/ayarlar/${name}`))
  }
}
const onSettingSelectedStart = (name, description) => ({
  type: actionTypes.URETIM_SETTING_SELECTED,
  payload: { name, description }
})
export const onSettingV2Delete = (token, selectedSetting, id) => {
  return async (dispatch) => {
    try {
      await axios(token).delete(apiPath.uretimSettingsDataId(selectedSetting, id))
      dispatch(settingV2DeleteSuccess(id))
    } catch (error) {
      errorHandler(dispatch, settingV2DeleteFail, error)
    }
  }
}
export const onSettingV2Clone = (token, selectedSetting, id) => {
  return async (dispatch) => {
    try {
      const result = await axios(token).post(apiPath.uretimSettingsV2CloneId(selectedSetting, id))
      debugger
      dispatch(push(`/uretim/ayarlar/mobilya/${result.data.parent_id}`))
    } catch (error) {
      errorHandler(dispatch, settingV2DeleteFail, error)
    }
  }
}
const settingV2DeleteSuccess = (id) => ({
  type: actionTypes.URETIM_SETTING_V2_DELETE_SUCCESS,
  payload: id
})
const settingV2DeleteFail = (error) => ({
  type: actionTypes.URETIM_SETTING_V2_DELETE_FAIL,
  error: error
})
export const onSettingDataRemoved = (token, selectedSetting, id, index) => {
  return async (dispatch) => {
    if (id) {
      try {
        await axios(token).delete(apiPath.uretimSettingsDataId(selectedSetting, id))
        dispatch(settingRemoveDataItem(id, index))
      } catch (error) {
        errorHandler(dispatch, settingSaveDataItemFail, error)
      }
    } else {
      dispatch(settingRemoveDataItem(id, index))
    }
  }
}
const settingRemoveDataItem = (id, index) => ({
  type: actionTypes.SETTING_DATA_ROW_REMOVED,
  payload: { id, index }
})
export const onSettingDataAdded = () => ({
  type: actionTypes.SETTING_DATA_ROW_ADDED,
  payload: null
})
const settingSaveDataItemSuccess = ({ id, message }) => ({
  type: actionTypes.SETTING_DATA_ROW_SAVE_SUCCESS,
  payload: { id, message }
})
const settingSaveDataItemStart = () => ({
  type: actionTypes.SETTING_DATA_ROW_SAVE_START
})
const settingSaveDataItemFail = (error) => ({
  type: actionTypes.SETTING_DATA_ROW_SAVE_FAIL,
  error: error
})
const uretimSettingV2SaveSuccess = ({ id, message }) => ({
  type: actionTypes.URETIM_SETTING_V2_SAVE_SUCCESS,
  payload: { id, message }
})
const uretimSettingV2SaveStart = () => ({
  type: actionTypes.URETIM_SETTING_V2_SAVE_START
})
const uretimSettingV2SaveFail = (error) => ({
  type: actionTypes.URETIM_SETTING_V2_SAVE_FAIL,
  error: error
})
export const onSettingV2DataSave = (token, selectedSetting, item) => {
  return async (dispatch) => {
    try {
      dispatch(uretimSettingV2SaveStart())
      const method = item.id ? 'PUT' : 'POST'
      const options = {
        method,
        url: item.id
          ? apiPath.uretimSettingsV2DataId(selectedSetting, item.id)
          : apiPath.uretimSettingsV2Data(selectedSetting),
        data: item
      }
      const response = await axios(token)(options)
      dispatch(uretimSettingV2SaveSuccess(response.data))
      dispatch(push(`/uretim/ayarlar/${selectedSetting}`))
    } catch (error) {
      errorHandler(dispatch, uretimSettingV2SaveFail, error)
    }
  }
}
export const onSettingDataSave = (token, selectedSetting, item) => {
  return async (dispatch) => {
    try {
      dispatch(settingSaveDataItemStart())
      const method = item.id ? 'PUT' : 'POST'
      const options = {
        method,
        url: item.id
          ? apiPath.uretimSettingsDataId(selectedSetting, item.id)
          : apiPath.uretimSettingsData(selectedSetting),
        data: item
      }
      const response = await axios(token)(options)
      dispatch(settingSaveDataItemSuccess(response.data))
      dispatch(toggleEditModal(0))
    } catch (error) {
      errorHandler(dispatch, settingSaveDataItemFail, error)
    }
  }
}
export const loadKapakRenkFail = (error) => ({
  type: actionTypes.LOAD_KAPAK_RENK_FAIL
})
export const loadKapakRenkSuccess = (data) => ({
  type: actionTypes.LOAD_KAPAK_RENK_SUCCESS,
  payload: { data }
})

export const loadParcaTipiFail = (error) => ({
  type: actionTypes.LOAD_PARCA_TIPI_FAIL,
  action: error
})
export const loadParcaTipiSuccess = (data) => ({
  type: actionTypes.LOAD_PARCA_TIPI_SUCCESS,
  payload: { data }
})
export const loadKapakRenkStart = (token, inputValue) => {
  return async (dispatch) => {
    try {
      const response = await axios(token).get(apiPath.uretimSettingsLookup('kapakRenk', inputValue))
      dispatch(loadKapakRenkSuccess(response.data))
    } catch (error) {
      errorHandler(dispatch, loadKapakRenkFail, error)
    }
  }
}
export const loadParcaTipiStart = (token, inputValue) => {
  return async (dispatch) => {
    try {
      const response = await axios(token).get(apiPath.uretimSettingsLookup('parcaTipi', inputValue))
      dispatch(loadParcaTipiSuccess(response.data))
    } catch (error) {
      errorHandler(dispatch, loadParcaTipiFail, error)
    }
  }
}

export const loadSetting = (token, name, inputValue) => {
  return async (dispatch) => {
    try {
      dispatch(loadSettingStart())
      const response = await axios(token).get(apiPath.uretimSettingsLookup(name, inputValue))
      dispatch(loadSettingSuccess(response.data))
    } catch (error) {
      errorHandler(dispatch, loadSettingFail, error)
    }
  }
}
export const loadSettingStart = () => ({
  type: actionTypes.SETTINGS_SEARCH_LOOKUP_START
})
export const loadSettingSuccess = (data) => ({
  type: actionTypes.SETTINGS_SEARCH_LOOKUP_SUCCESS,
  payload: { data }
})
export const loadSettingFail = (error) => ({
  type: actionTypes.SETTINGS_SEARCH_LOOKUP_FAIL,
  action: error
})
export const selectMenuClose = () => ({
  type: actionTypes.SETTINGS_SEARCH_LOOKUP_RESET
})
export const toggleEditModal = (index) => ({
  type: actionTypes.TOGGLE_EDIT_MODAL,
  payload: { index }
})
// PARCALAR
export const updateSettingParcaModal = (action) => ({
  type: actionTypes.UPDATE_SETTING_MODUL_PARCA_MODAL,
  payload: { ...action }
})
export const onSettingParcaModalAction = (token, payload) => {
  return async (dispatch) => {
    try {
      if (payload.action && payload.action === 'save') {
        const options = {
          method: 'PUT',
          url: apiPath.uretimSettingsDataIdProp(payload.name, payload.modulId, 'parca'),
          data: payload.data
        }
        await axios(token)(options)
      }
      dispatch(toggleSettingParcaModal(payload))
    } catch (error) {
      errorHandler(dispatch, errorSettingParcaModal, error)
    }
  }
}
export const toggleSettingParcaModal = (payload) => ({
  type: actionTypes.TOGGLE_SETTING_MODUL_PARCA_MODAL,
  payload
})
export const errorSettingParcaModal = (payload) => ({
  type: actionTypes.ERROR_SETTING_MODUL_PARCA_MODAL,
  payload
})
export const updateSettingParcaModalNew = (name, value) => ({
  type: actionTypes.UPDATE_SETTING_MODUL_PARCA_MODAL_NEW,
  payload: { name, value }
})

//AKSAM MODAL
export const onSettingAksamModalAction = (token, payload) => {
  return async (dispatch) => {
    try {
      if (payload.action && payload.action === 'save') {
        const options = {
          method: 'PUT',
          url: apiPath.uretimSettingsDataIdProp(payload.name, payload.modulId, 'aksam'),
          data: payload.data
        }
        await axios(token)(options)
      }
      dispatch(toggleSettingAksamModal(payload))
    } catch (error) {
      errorHandler(dispatch, errorSettingAksamModal, error)
    }
  }
}
export const toggleSettingAksamModal = (payload) => ({
  type: actionTypes.TOGGLE_SETTING_MODUL_AKSAM_MODAL,
  payload
})
export const errorSettingAksamModal = (payload) => ({
  type: actionTypes.ERROR_SETTING_MODUL_AKSAM_MODAL,
  payload
})
export const updateSettingAksamModalNew = (name, value) => ({
  type: actionTypes.UPDATE_SETTING_MODUL_AKSAM_MODAL_NEW,
  payload: { name, value }
})
export const updateSettingAksamModal = (payload) => ({
  type: actionTypes.UPDATE_SETTING_MODUL_AKSAM_MODAL,
  payload
})
// REPORT DOWNLOAD
export const onDownloadReport = (token, reportName) => {
  return async (dispatch) => {
    try {
      dispatch(onDownloadReportStart())
      const options = {
        url: apiPath.uretimSettingsReport(reportName),
        method: 'GET',
        responseType: 'blob' // important
      }
      const response = await axios(token)(options)
      dispatch(onDownloadReportSuccess(response.data, reportName))
    } catch (error) {
      errorHandler(dispatch, onDownloadReportFail, error)
    }
  }
}
export const onDownloadReportStart = () => ({
  type: actionTypes.SETTING_DOWNLOAD_REPORT
})
export const onDownloadReportSuccess = (data, reportName) => ({
  type: actionTypes.SETTING_DOWNLOAD_REPORT_SUCCESS,
  payload: { data, reportName }
})
export const onDownloadReportFail = (error) => ({
  type: actionTypes.SETTING_DOWNLOAD_REPORT_FAIL,
  payload: error
})
//AKSESUAR MODAL
export const onSettingAksesuarModalAction = (token, payload) => {
  return async (dispatch) => {
    try {
      if (payload.action && payload.action === 'save') {
        const options = {
          method: 'PUT',
          url: apiPath.uretimSettingsDataIdProp(payload.name, payload.modulId, 'aksesuar'),
          data: payload.data
        }
        await axios(token)(options)
      }
      dispatch(toggleSettingAksesuarModal(payload))
    } catch (error) {
      errorHandler(dispatch, errorSettingAksesuarModal, error)
    }
  }
}
export const toggleSettingAksesuarModal = (payload) => ({
  type: actionTypes.TOGGLE_SETTING_MODUL_AKSESUAR_MODAL,
  payload
})
export const errorSettingAksesuarModal = (payload) => ({
  type: actionTypes.ERROR_SETTING_MODUL_AKSESUAR_MODAL,
  payload
})
export const updateSettingAksesuarModalNew = (name, value) => ({
  type: actionTypes.UPDATE_SETTING_MODUL_AKSESUAR_MODAL_NEW,
  payload: { name, value }
})
export const updateSettingAksesuarModal = (payload) => ({
  type: actionTypes.UPDATE_SETTING_MODUL_AKSESUAR_MODAL,
  payload
})
