import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'

const ModalContainer = ({modal, cancel, className, size, title, children, save }) => {

  return (
      <Modal isOpen={modal}
             toggle={cancel}
             size={size}
             className={className}
             backdrop={true}>
        <ModalHeader className="bg-light text-white" toggle={cancel}>{title}</ModalHeader>
        <ModalBody>
          {children}
        </ModalBody>
        {save && <ModalFooter className="bg-light">
            <Button color='secondary' className='float-start' onClick={cancel}>
              Vazgec
            </Button>
            <Button color='primary' onClick={save}>
              <i className='fas fa-save' />
              Kaydet
            </Button>
        </ModalFooter>
        }
      </Modal>
  );
}

export default ModalContainer
