import { apiPath } from '../../shared'
import { instance as axios } from '../../axios-api'
import * as actionTypes from './actionTypes'
import { errorHandler } from './errorHandler'

export const saveProductGroup = (token, data) => {
  return async (dispatch) => {
    try {
      dispatch(saveProductGroupStart())
      const options = {
        method: data.id ? 'PUT' : 'POST',
        url: data.id ? apiPath.alimProductGroupWithId(data.id) : apiPath.createProductGroup(),
        data
      }
      const response = await axios(token)(options)
      dispatch(saveProductGroupSuccess(response.data))
    } catch (error) {
      errorHandler(dispatch, saveProductGroupFail, error)
    }
  }
}
const saveProductGroupStart = () => ({
  type: actionTypes.PRODUCT_GROUP_SAVE_START
})
const saveProductGroupSuccess = (payload) => ({
  type: actionTypes.PRODUCT_GROUP_SAVE_SUCCESS,
  payload
})
const saveProductGroupFail = (error) => ({
  type: actionTypes.PRODUCT_GROUP_SAVE_FAIL,
  error
})
export const fetchProductGroup = (token, id) => {
  return async (dispatch) => {
    try {
      dispatch(fetchProductGroupStart())
      if (id !== 'yeni') {
        const response = await axios(token).get(apiPath.alimProductGroupWithId(id))
        dispatch(fetchProductGroupSuccess(response.data))
      } else {
        dispatch(fetchProductGroupSuccess())
      }
    } catch (error) {
      errorHandler(dispatch, fetchProductGroupFail, error)
    }
  }
}
const fetchProductGroupStart = () => ({
  type: actionTypes.PRODUCT_GROUP_GET_START
})
const fetchProductGroupSuccess = (payload) => ({
  type: actionTypes.PRODUCT_GROUP_GET_SUCCESS,
  payload
})
const fetchProductGroupFail = (error) => ({
  type: actionTypes.PRODUCT_GROUP_GET_FAIL,
  error: error
})
export const fetchProductGroups = (token) => {
  return async (dispatch) => {
    try {
      dispatch(fetchProductGroupsStart())
      const response = await axios(token).get(apiPath.listProductGroup())
      dispatch(fetchProductGroupsSuccess(response.data))
    } catch (error) {
      errorHandler(dispatch, fetchProductGroupsFail, error)
    }
  }
}
const fetchProductGroupsStart = () => ({
  type: actionTypes.PRODUCT_GROUP_LIST_START
})
const fetchProductGroupsSuccess = (payload) => ({
  type: actionTypes.PRODUCT_GROUP_LIST_SUCCESS,
  payload
})
const fetchProductGroupsFail = (error) => ({
  type: actionTypes.PRODUCT_GROUP_LIST_FAIL,
  error: error
})
// SEARCH ON ALIM
export const onProductGroupSearchClick = (token, inputValue) => {
  return async (dispatch) => {
    try {
      dispatch(productGroupSearchClickStart())
      const response = await axios(token).get(apiPath.alimProductGroupSearch(inputValue))
      dispatch(productGroupSearchClickSuccess(response.data))
    } catch (error) {
      errorHandler(dispatch, productGroupSearchClickFail, error)
    }
  }
}
const productGroupSearchClickStart = () => ({
  type: actionTypes.PRODUCT_GROUP_SEARCH_CLICK_START
})
const productGroupSearchClickSuccess = (payload) => ({
  type: actionTypes.PRODUCT_GROUP_SEARCH_CLICK_SUCCESS,
  payload
})
const productGroupSearchClickFail = (err) => ({
  type: actionTypes.PRODUCT_GROUP_SEARCH_CLICK_FAIL,
  payload: err
})
export const alimInputChange = (name, value) => ({
  type: actionTypes.PRODUCT_GROUP_INPUT_CHANGE,
  payload: { name, value }
})
