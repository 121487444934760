export const initAksesuarModalNew = () => ({
  aksam: null,
  miktar:0,
  not: ''
});
export const initAksesuarModal = () => ({
  show: false,
  index: 0,
  data: [],
  rafcikar: false,
  mentesecikar: false,
  new: initAksesuarModalNew()
})
