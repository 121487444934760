import cloneDeep from 'lodash/cloneDeep'
import { cloneWithMap } from '../../../shared'
import { initAksesuarModal, initAksesuarModalNew } from '../../dataModels/AksesuarModal'
import { initLookup } from '../../dataModels/LookupModals'
import set from 'lodash/set'

export const updateWardrobeModulAksesuarModalNew = (state, action) => {
  const newData = { ...state.aksesuarModal.new }
  newData[action.payload.name] = action.payload.value
  return { ...state, ...{ aksesuarModal: { ...state.aksesuarModal, new: newData } } }
}
export const updateWardrobeModulAksesuarModal = (state, action) => {
  const cloneAksesuarModal = cloneDeep(state.aksesuarModal)
  set(cloneAksesuarModal, action.payload.name, action.payload.value)
  return { ...state, ...{ aksesuarModal: cloneAksesuarModal } }
}
export const toggleWardrobeModulAksesuarModal = (state, action) => {
  switch (action.payload.action) {
    case 'cancel':
      return { ...state, ...{ aksesuarModal: initAksesuarModal(), lookup: initLookup() } }
    case 'add':
      const { miktar, aksesuar, not } = state.aksesuarModal.new
      const newAksesuar = { ...aksesuar, miktar, not }
      const cloneData = cloneWithMap(state.aksesuarModal.data)
      cloneData.push(newAksesuar)
      return {
        ...state,
        ...{ aksesuarModal: { ...state.aksesuarModal, data: cloneData, new: initAksesuarModalNew() } }
      }
    case 'delete':
      const newData = state.aksesuarModal.data.filter((x) => x.id !== action.payload.id)
      return { ...state, ...{ aksesuarModal: { ...state.aksesuarModal, data: newData } } }
    case 'save':
      const newModuller = cloneDeep(state.wardrobeForm.moduller)
      newModuller[state.aksesuarModal.index].modul.aksesuarlar = cloneDeep(state.aksesuarModal.data)
      return {
        ...state,
        ...{
          aksesuarModal: initAksesuarModal(),
          lookup: initLookup(),
          wardrobeForm: { ...state.wardrobeForm, moduller: newModuller }
        }
      }
    default:
      const aksesuarlar = cloneDeep(state.wardrobeForm.moduller[action.payload.index].modul.aksesuarlar)
      return {
        ...state,
        ...{
          aksesuarModal: {
            ...state.aksesuarModal,
            show: !state.aksesuarModal.show,
            index: action.payload.index,
            data: aksesuarlar
          }
        }
      }
  }
}
