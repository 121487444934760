import React from 'react'
import Select from '../../Core/Select'
import NumberInput from '../../Core/NumberInput'

const ParcaSettings = (props) => {
  function onHandleSelectValueChange(name, value) {
    props.onSettingInputChange({ name, value, row: props.index })
  }
  return (
    <div className='form-group row'>
      <div className='form-group col-md-12'>
        <NumberInput
          label='Uzunluk'
          name='uzunluk'
          dataRow={props.index}
          value={props.uzunluk}
          onChange={props.onChange}
          required
        />
        <NumberInput
          label='Genislik'
          name='genislik'
          dataRow={props.index}
          value={props.genislik}
          onChange={props.onChange}
          required
        />
        <NumberInput
          label='Boyut'
          name='boyut'
          dataRow={props.index}
          value={props.boyut}
          onChange={props.onChange}
          required
        />
        <Select
          name='tip'
          text='Parca Tipi'
          isMulti={false}
          onInputChange={props.searchParcaTipi}
          value={props.tip}
          onChange={onHandleSelectValueChange}
          options={props.lookup.result}
          getOptionValue={(option) => option['id']}
          getOptionLabel={(option) => option['aciklama']}
        />
      </div>
    </div>
  )
}

export default ParcaSettings
