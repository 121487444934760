import React, { useEffect } from 'react'
import { Alert, Col, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import * as actions from '../../store/actions'
import { useDispatch, useSelector } from 'react-redux'
import UserList from './UserList'
import Loading from '../Common/Loading'

const UserListContainer = () => {
  const dispatch = useDispatch()
  const { loading, error, list } = useSelector((state) => state.users)
  const { token } = useSelector((state) => state.auth)
  useEffect(() => {
    dispatch(actions.fetchUsers(token))
  }, [dispatch, token])

  return (
    <Row>
      <Col sm='12'>
        <h3>Kullanıcılar</h3>
      </Col>
      <Col sm='12'>
        <Link className='btn btn-primary float-end' activeclassname='active' to='/kullanicilar/yeni'>
          <i className='fa fa-plus' /> Yeni
        </Link>
      </Col>
      {error && (
        <Col sm={12}>
          <Alert color='danger'>Kullanıcılar yuklerken hata olustu — {error}</Alert>
        </Col>
      )}
      {loading ? <Loading /> : <UserList list={list} />}
    </Row>
  )
}
export default UserListContainer
