import React from 'react'
import { Input, Label } from 'reactstrap'
import RequiredFeedback from '../Common/RequiredFeedback'

const NumberInput = (props) => {
  const {
    valid,
    invalid,
    disabled,
    placeholder,
    hideLabel,
    min,
    optional,
    label,
    name,
    onChange,
    value,
    required,
    dataRow,
    step,
    id
  } = props
  const options = {
    min: min || 0,
    id: id || name,
    step: step || 0.1,
    disabled,
    name,
    value,
    className: 'form-control',
    onChange,
    placeholder: label || placeholder,
    'data-row': dataRow !== undefined ? `${dataRow}` : undefined,
    valid: !optional ? (valid ? valid : value > 0) : undefined,
    invalid: !optional ? (invalid ? invalid : !value) : undefined,
    required: required ? 'required' : undefined
  }
  return (
    <>
      {!hideLabel && <Label htmlFor={name}>{label}</Label>}
      <Input type='number' step={step} {...options} />
      {!optional && <RequiredFeedback />}
    </>
  )
}

export default NumberInput
