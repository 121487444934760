import React, { PureComponent } from 'react'
import { Button, Input, Label } from 'reactstrap'
import Select from '../../Core/Select'
import { Modal } from '../../Uretim/Orders/Kitchen'
import NumberInput from '../../Core/NumberInput'
import { kulpYonuOptions } from '../../../shared'
import ModulFormGolaDetails from "./ModulFormGolaDetails";
class ModulForm extends PureComponent {
  handleSave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.props.save()
  }
  handleInputChange = (event) => {
    let value = event.target.value
    if (event.target.type === 'checkbox') {
      value = event.target.checked
    }
    this.props.onChange(event.target.name, value)
  }
  golaModulVeBDbaslayanModul = (siparis) => {
    return (
      siparis &&
      siparis.modul &&
      siparis.modul.aciklama.startsWith('BD') &&
      siparis.kulpmodeli.aciklama.startsWith('GOLA')
    )
  }
  handleGovdeRengiKeyDown = (e) => {
    const { gorunenyan } = this.props.modulModal.data
    const lookupName = gorunenyan && gorunenyan.aciklama === 'ACIK MODUL' ? 'govdeRengi,kapakRenk' : 'govdeRengi'
    this.props.lookupSetting(e, lookupName)
  }
  render() {
    const modul = this.props.modulModal.data
    return (
      <Modal
        modal={this.props.modulModal.show}
        index={this.props.modulModal.index}
        save={this.handleSave}
        cancel={this.props.cancel}
        size='xl'
        title='Modul Ekle/Degistir'
      >
        <form>
          <div className='mb-3 row'>
            <div className='mb-3 col-sm-3 col-lg-2'>
              <NumberInput
                label='Modul Adet'
                name='adet'
                min={1}
                valid={modul.adet > 0}
                value={modul.adet}
                onChange={this.handleInputChange}
                required
              />
            </div>
            <div className='mb-3 col-sm-6 col-lg-8'>
              <Select
                name='modul'
                value={modul ? modul.modul : null}
                onChange={this.props.selectSetting}
                text='Modul'
                inputValue={this.props.lookup.inputValue}
                options={this.props.lookup.result}
                onInputChange={(e) => this.props.lookupSetting(e, 'modul')}
                onSelectMenuClose={this.props.onSelectMenuClose}
                isLoading={this.props.lookup.isLoading || this.props.lookup.isSelecting}
                isDisabled={this.props.lookup.isSelecting}
              />
            </div>
            <div className='mb-3 col-sm-3 col-lg-2'>
              <NumberInput
                label='Sira'
                name='siranumarasi'
                min={1}
                valid={modul.siranumarasi > 0}
                value={modul.siranumarasi}
                onChange={this.handleInputChange}
                required
              />
            </div>
          </div>
          <div className='mb-3 row'>
            <div className='mb-3 col-lg-4'>
              <Select
                name='gorunenyan'
                value={modul ? modul.gorunenyan : null}
                onChange={this.props.onChange}
                text='Gorunen Yan'
                options={this.props.lookup.result}
                onInputChange={(e) => this.props.lookupSetting(e, 'gorunenYan')}
                onSelectMenuClose={this.props.onSelectMenuClose}
                isLoading={this.props.lookup.isLoading}
              />
            </div>
            <div className='mb-3 col-lg-4'>
              <Select
                name='govderengi'
                value={modul ? modul.govderengi : null}
                onChange={this.props.onChange}
                text='Govde Rengi'
                defaultValue={this.props.govderengi}
                options={this.props.lookup.result}
                onInputChange={this.handleGovdeRengiKeyDown.bind(this)}
                onSelectMenuClose={this.props.onSelectMenuClose}
                isLoading={this.props.lookup.isLoading}
              />
            </div>
            <div className='mb-3 col-lg-4'>
              <Select
                name='govdemalzemesi'
                value={modul ? modul.govdemalzemesi : null}
                onChange={this.props.onChange}
                text='Govde Malzemesi'
                defaultValue={this.props.govdemalzemesi}
                options={this.props.lookup.result}
                onInputChange={(e) => this.props.lookupSetting(e, 'govdeMalzemesi')}
                onSelectMenuClose={this.props.onSelectMenuClose}
                isLoading={this.props.lookup.isLoading}
              />
            </div>
          </div>
          <div className='mb-3 row'>
            <div className='mb-3 col-lg-4'>
              <Select
                name='acilisyonu'
                value={modul ? modul.acilisyonu : null}
                onChange={this.props.onChange}
                text='Acilis Yonu'
                options={this.props.lookup.result}
                onInputChange={(e) => this.props.lookupSetting(e, 'acilisYonu')}
                onSelectMenuClose={this.props.onSelectMenuClose}
                isLoading={this.props.lookup.isLoading}
              />
            </div>
            <div className='mb-3 col-lg-4'>
              <Select
                name='kapakmodeli'
                value={modul ? modul.kapakmodeli : null}
                onChange={this.props.onChange}
                text='Kapak Modeli'
                defaultValue={this.props.kapakmodeli}
                options={this.props.lookup.result}
                onInputChange={(e) => this.props.lookupSetting(e, 'kapakModel')}
                onSelectMenuClose={this.props.onSelectMenuClose}
                isLoading={this.props.lookup.isLoading}
              />
            </div>
            <div className='mb-3 col-lg-4'>
              <Select
                name='kapakdesenyonu'
                value={modul ? modul.kapakdesenyonu : null}
                onChange={this.props.onChange}
                text='Kapak Desen Yonu'
                defaultValue={this.props.kapakdesenyonu}
                options={this.props.lookup.result}
                onInputChange={(e) => this.props.lookupSetting(e, 'kapakDesenYonu')}
                onSelectMenuClose={this.props.onSelectMenuClose}
                isLoading={this.props.lookup.isLoading}
              />
            </div>
          </div>
          <div className='mb-3 row'>
            <div className='mb-3 col-lg-4'>
              <Select
                name='kapakrenk'
                value={modul ? modul.kapakrenk : null}
                onChange={this.props.onChange}
                text='Kapak Renk'
                options={modul && modul.kapakmodeli ? modul.kapakmodeli.kapakRenk : []}
              />
            </div>
            <div className='mb-3 col-lg-4'>
              <Select
                name='kapakmalzemesi'
                value={modul ? modul.kapakmalzemesi : null}
                onChange={this.props.onChange}
                text='Kapak Malzemesi'
                options={modul && modul.kapakmodeli ? modul.kapakmodeli.kapakRenk : []}
              />
            </div>

            <div className='mb-3 col-lg-4'>
              <Select
                name='kapakrenk.bandRengi'
                value={modul && modul.kapakrenk ? modul.kapakrenk.bandRengi : null}
                onChange={this.props.onChange}
                text='Band Renk'
                options={modul && modul.kapakmodeli ? modul.kapakmodeli.kapakRenk : []}
              />
            </div>
            <div className='mb-3 col-lg-4'>
              <Select
                name='kulpmodeli'
                value={modul ? modul.kulpmodeli : null}
                onChange={this.props.onChange}
                text='Kulp Modeli'
                defaultValue={this.props.kulpmodeli}
                options={this.props.lookup.result}
                onInputChange={(e) => this.props.lookupSetting(e, 'kulpModeli')}
                onSelectMenuClose={this.props.onSelectMenuClose}
                isLoading={this.props.lookup.isLoading}
              />
            </div>
            {this.golaModulVeBDbaslayanModul(modul) && (
                <ModulFormGolaDetails modul={modul} handleInputChange={this.handleInputChange}/>
            )}
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <label htmlFor='ozelislem'>Ozel istekler</label>
              <textarea
                className='form-control'
                value={modul ? modul.ozelislem : null}
                onChange={this.handleInputChange}
                name='ozelislem'
                id='ozelislem'
                rows='3'
              />
            </div>
          </div>
          <div className='row p-2'>
            <div className='form-check form-switch'>
              <Input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='metalCerceveliKapak'
                name='metalCerceveliKapak'
                value='true'
                onChange={this.handleInputChange}
                checked={modul.metalCerceveliKapak ? 'checked' : ''}
              />
              <Label className='form-check-label' htmlFor='metalCerceveliKapak'>
                Metal Cerceveli Kapak
              </Label>
            </div>
            {modul.metalCerceveliKapak && (
              <>
                <div className='mb-3 col-lg-3'>
                  <NumberInput
                    min={1}
                    name='metalCerceveliKapakAdet'
                    label='Adet'
                    value={modul.metalCerceveliKapakAdet}
                    onChange={this.handleInputChange}
                    valid={modul.metalCerceveliKapakAdet > 0}
                    required
                  />
                </div>
                <div className='mb-3 col-lg-3'>
                  <Select
                    name='camModeli'
                    value={modul.camModeli || null}
                    onChange={this.props.onChange}
                    text='Cam Modeli'
                    options={this.props.lookup.result}
                    onInputChange={(e) => this.props.lookupSetting(e, 'camModeli')}
                    onSelectMenuClose={this.props.onSelectMenuClose}
                    isLoading={this.props.lookup.isLoading}
                  />
                </div>
                <div className='mb-3 col-lg-3'>
                  <Select
                    name='camProfili'
                    value={modul.camProfili || null}
                    onChange={this.props.onChange}
                    text='Cam Profili'
                    options={this.props.lookup.result}
                    onInputChange={(e) => this.props.lookupSetting(e, 'camProfili')}
                    onSelectMenuClose={this.props.onSelectMenuClose}
                    isLoading={this.props.lookup.isLoading}
                  />
                </div>
                <div className='mb-3 col-lg-3'>
                  <Select
                    name='kulpYonu'
                    value={modul.kulpYonu || null}
                    onChange={this.props.onChange}
                    text='Kulp Yonu'
                    options={kulpYonuOptions}
                  />
                </div>
              </>
            )}
          </div>
        </form>
      </Modal>
    )
  }
}

export default ModulForm
