import { instance as axios } from '../../axios-api'
import * as actionTypes from './actionTypes'
import { apiPath } from '../../shared'
import { errorHandler } from './errorHandler'

export const fetchUsers = (token) => {
  return async (dispatch) => {
    try {
      dispatch(fetchUserListStart())
      const response = await axios(token).get(apiPath.users())
      dispatch(fetchUserListSuccess(response.data))
    } catch (error) {
      errorHandler(dispatch, fetchUserListFail, error)
    }
  }
}
export const fetchUserListStart = () => ({
  type: actionTypes.FETCH_USER_LIST_START
})
export const fetchUserListSuccess = (payload) => ({
  type: actionTypes.FETCH_USER_LIST_SUCCESS,
  payload
})
export const fetchUserListFail = (error) => ({
  type: actionTypes.FETCH_USER_LIST_FAIL,
  error: error
})
export const saveUser = (token, data) => {
  return async (dispatch) => {
    try {
      dispatch(saveUserStart())
      const response = await axios(token)({
        method: data.id !== '' ? 'put' : 'post',
        url: data.id ? apiPath.user(data.id) : apiPath.users(),
        data
      })

      dispatch(saveUserSuccess(response.data))
    } catch (error) {
      errorHandler(dispatch, saveUserFail, error)
    }
  }
}
const saveUserStart = () => ({
  type: actionTypes.SAVE_USER_START
})
const saveUserSuccess = (data) => ({
  type: actionTypes.SAVE_USER_SUCCESS,
  payload: { data }
})
const saveUserFail = (error) => ({
  type: actionTypes.SAVE_USER_FAIL,
  payload: { error }
})
export const updateUserProperty = (name, value) => ({
  type: actionTypes.UPDATE_USER_PROPERTY,
  payload: { name, value }
})
export const updateUserRole = (name, checked) => ({
  type: actionTypes.UPDATE_USER_ROLE,
  payload: { name, checked }
})
export const fetchUser = (token, id) => {
  return async (dispatch) => {
    try {
      dispatch(fetchUserStart())
      const response = await axios(token).get(apiPath.user(id))
      dispatch(fetchUserSuccess(response.data))
    } catch (error) {
      errorHandler(dispatch, fetchUserFail, error)
    }
  }
}
export const fetchUserStart = () => ({
  type: actionTypes.FETCH_USER_START
})
export const fetchUserSuccess = (user) => ({
  type: actionTypes.FETCH_USER_SUCCESS,
  payload: { user }
})
export const fetchUserFail = (error) => ({
  type: actionTypes.FETCH_USER_FAIL,
  payload: { error }
})
