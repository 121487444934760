import {initAksamModal, initAksamModalNew} from "../../dataModels/AksamModal";
import {initLookup} from "../../dataModels/LookupModals";
import {cloneWithMap} from "../../../shared";
import cloneDeep from "lodash/cloneDeep";

export const toggleWardrobeModulAksamModal = (state, action ) => {
  switch(action.payload.action) {
    case 'cancel':
      return { ...state, ...{ aksamModal: initAksamModal(),lookup: initLookup() } }
    case 'add':
      const cloneData = cloneWithMap(state.aksamModal.data);
      const newAksam = {...state.aksamModal.new.aksam,miktar: state.aksamModal.new.miktar};
      cloneData.push(newAksam);
      return { ...state, ...{ aksamModal: {...state.aksamModal, data: cloneData, new: initAksamModalNew()} } }
    case 'delete':
      const newData = state.aksamModal.data.filter(x =>x.id !== action.payload.id);
      return { ...state, ...{ aksamModal: {...state.aksamModal, data: newData} } }
    case 'save':
      const newModuller = cloneDeep(state.wardrobeForm.moduller);
      newModuller[state.aksamModal.index].modul.aksamlar = cloneDeep(state.aksamModal.data);
      return {
        ...state, ...{
          aksamModal: initAksamModal(),
          lookup: initLookup(),
          wardrobeForm: {...state.wardrobeForm, moduller: newModuller}
        }
      }
    default:
      const aksamlar = cloneWithMap(state.wardrobeForm.moduller[action.payload.index].modul.aksamlar);
      return {
        ...state, ...{
          aksamModal: {
            ...state.aksamModal,
            show: !state.aksamModal.show,
            index: action.payload.index,
            data: aksamlar
          }
        }
      }
  }
}
export const updateWardrobeModulAksamModalNew = ( state, action ) => {
  const newData = {...state.aksamModal.new,[action.payload.name]: action.payload.value};
  return { ...state, ...{ aksamModal: {...state.aksamModal, new: newData} } }
}
export const updateWardrobeModulAksamModal = ( state, action ) => {
  const resultedData =  cloneWithMap(state.aksamModal.data);
  resultedData[action.payload.row][action.payload.name] = action.payload.value;
  return { ...state, ...{ aksamModal: {...state.aksamModal, data: resultedData} } }
}
