import { Button } from 'reactstrap'
import React from 'react'

const Paging = (props) => {
  const { totalPageCount, currentPage, handleIleriClick, handleIlkClick, handleGeriClick, handleSonClick } = props
  return (
    totalPageCount > 1 && (
      <nav className='navbar sticky-bottom navbar-dark bg-dark'>
        <div className='float-start'>
          <Button color='primary' disabled={currentPage <= 1} className='m-1' onClick={handleGeriClick}>
            <i className='fa fa-fast-backward' />
            Geri
          </Button>
          <Button color='primary' disabled={currentPage <= 1} className='m-1' onClick={handleIlkClick}>
            <i className='fa fa-step-backward' />
            Ilk
          </Button>
        </div>
        <div className='d-flex justify-content-center'>
          <strong className='d-flex justify-content-center text-white'>
            Sayfa {currentPage} / {totalPageCount}
          </strong>
        </div>
        <div className='float-end'>
          <Button
            type='button'
            disabled={currentPage === totalPageCount}
            className='m-1'
            color='primary'
            onClick={handleSonClick}
          >
            <i className='fa fa-fast-forward' />
            Son
          </Button>
          <Button
            type='button'
            disabled={currentPage === totalPageCount}
            className='m-1'
            color='primary'
            onClick={handleIleriClick}
          >
            <i className='fa fa-step-forward' />
            Ileri
          </Button>
        </div>
      </nav>
    )
  )
}

export default Paging
