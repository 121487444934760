import React from 'react'
import { Button, Input, Label } from 'reactstrap'
import RequiredFeedback from '../../Common/RequiredFeedback'

import EditEkstra from './EditEkstra'
import IsActiveBadge from '../../Common/IsActiveBadge'
const SettingEdit = (props) => {
  const {
    aynaRengi,
    aynaKenari,
    aciklama,
    isactive,
    boy,
    en,
    surmeKulp,
    mekanizma,
    surmeKapakCalismaPayi,
    kapakKesimOlcusu,
    aynaKesimOlcusu,
    pervazlar,
    kapakRenk,
    genislik,
    uzunluk,
    boyut,
    kapakBoyuKucuk,
    ebatliKulp,
    kapakBoyuBuyuk,
    grup,
    bandRengi,
    fiyat,
    bandTipi,
    cekmeceArkasiEnCikar,
    cekmeceArkasiBoyCikar,
    cekmeceArkasiBoyCikarBordursuz,
    cekmeceTabaniEnCikar,
    cekmeceTabaniBoyCikar,
    tabanMelamiKalinligi
  } = props.data[props.index]
  const handleSave = (e) => {
    e.preventDefault()
    props.onSave(props.index)
  }

  return (
    <form onSubmit={handleSave}>
      <div className='form-group row'>
        <div className='form-group col-md-6'>
          <Label>Aciklama</Label>
          <Input
            type='text'
            name='aciklama'
            onChange={props.onChange}
            data-row={props.index}
            value={aciklama}
            valid={aciklama.length > 0}
            invalid={!aciklama}
            required
          />
          <RequiredFeedback />
        </div>
        <div className='form-group col-md-6'>
          <Label htmlFor='isactive'>Aktif/Pasif</Label>
          <IsActiveBadge dataRow={props.index} value={isactive} onChange={props.onChange} />
        </div>
      </div>
      <EditEkstra
        {...props}
        settingName={props.settingName}
        aynaRengi={aynaRengi}
        aynaKenari={aynaKenari}
        boy={boy}
        en={en}
        surmeKulp={surmeKulp}
        mekanizma={mekanizma}
        surmeKapakCalismaPayi={surmeKapakCalismaPayi}
        kapakKesimOlcusu={kapakKesimOlcusu}
        aynaKesimOlcusu={aynaKesimOlcusu}
        pervazlar={pervazlar}
        kapakRenk={kapakRenk}
        bandRengi={bandRengi}
        fiyat={fiyat}
        bandTipi={bandTipi}
        boyut={boyut}
        genislik={genislik}
        uzunluk={uzunluk}
        ebatliKulp={ebatliKulp}
        kapakBoyuBuyuk={kapakBoyuBuyuk}
        kapakBoyuKucuk={kapakBoyuKucuk}
        cekmeceTabaniEnCikar={cekmeceTabaniEnCikar}
        cekmeceTabaniBoyCikar={cekmeceTabaniBoyCikar}
        tabanMelamiKalinligi={tabanMelamiKalinligi}
        cekmeceArkasiEnCikar={cekmeceArkasiEnCikar}
        cekmeceArkasiBoyCikar={cekmeceArkasiBoyCikar}
        cekmeceArkasiBoyCikarBordursuz={cekmeceArkasiBoyCikarBordursuz}
        grup={grup}
        onSettingInputChange={props.onSettingInputChange}
        handleSelectValueChangeInArray={props.handleSelectValueChangeInArray}
      />

      <div className='form-group row'>
        <div className='form-group col-md-12'>
          <Button color='secondary' className='float-start' onClick={props.onCancel}>
            Vazgec
          </Button>
          <button type='submit' className='btn btn-primary float-end' data-row={props.index}>
            <i className='fas fa-save' />
            Kaydet
          </button>
        </div>
      </div>
    </form>
  )
}

export default SettingEdit
