import * as actionTypes from './actionTypes'
import { instance as axios } from '../../axios-api'
import { push } from 'connected-react-router'
import { apiPath } from '../../shared'
import get from 'lodash/get'

export const authStart = () => ({
  type: actionTypes.AUTH_START
})

export const authSuccess = (token, email, profile) => ({
  type: actionTypes.AUTH_SUCCESS,
  payload: { token, email, profile }
})

export const authFailure = (error) => ({
  type: actionTypes.AUTH_FAIL,
  error: error
})

export const checkAuthTimeout = (expirationTime) => {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(logout())
    }, expirationTime * 60 * 60 * 1000)
  }
}

export const logout = () => {
  localStorage.removeItem('token')
  return {
    type: actionTypes.AUTH_LOGOUT
  }
}

export const authenticate = (email, password) => {
  return async (dispatch) => {
    try {
      dispatch(authStart())
      const response = await axios().post(apiPath.authLogin(), { email, password })
      const { expiresIn, token, user } = response.data
      localStorage.setItem('token', token)
      dispatch(authSuccess(token, user.email, user.profile))
      dispatch(checkAuthTimeout(expiresIn))
      dispatch(push('/uretim/siparisler'))
    } catch (error) {
      let message = 'Kullanıcı ismi yada şifre yanlış. Lütfen tekrar deneyin.'
      if (get(error, 'response.status', 500) === 500) {
        message = 'Sitede bir hata oluştu. Lütfen tekrar deneyin.'
      }
      dispatch(authFailure(message))
    }
  }
}

export const authPropertyChange = (event) => {
  const field = event.target.name
  const value = event.target.value
  return {
    type: actionTypes.SET_AUTH_PROPERTY,
    payload: { field, value }
  }
}

export const authCheckState = (path) => {
  return async (dispatch) => {
    try {
      const authToken = localStorage.getItem('token')
      if (!authToken) {
        dispatch(logout())
      } else {
        const authResp = await axios(authToken).post(apiPath.authVerify())
        const { user, token, expiresIn } = authResp.data
        dispatch(authSuccess(token, user.email, user.profile))
        dispatch(checkAuthTimeout(expiresIn))
        dispatch(push(path === '/' ? '/uretim' : path))
      }
    } catch (err) {
      dispatch(logout())
    }
  }
}
