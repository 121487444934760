import React, {useEffect} from 'react'
import * as actions from '../../../store/actions'
import {useDispatch, useSelector} from 'react-redux'
import Setting from './Setting'
import Paging from '../../Common/Paging'
import { Button, Alert } from 'reactstrap'
import Loading from '../../Common/Loading'
import Spinner from '../../Common/Spinner'
import SearchInput from '../../Common/SearchInput'

const UretimSettingEditContainer = (props) => {
  const dispatch = useDispatch()
  const {
    setting,
    paging,
    loading,
    lookup,
    editModal,
    referenceData,
    parcaModal,
    aksamModal,
    aksesuarModal,
    downloading
  } = useSelector((state) => state.uretimSettings)
  const { token } = useSelector((state) => state.auth)

  const onFetch = (token, name, query, page) => dispatch(actions.fetchUretimSettingWithName(token, name, query, page))

  const onDownload = (token, settingName) => dispatch(actions.onDownloadReport(token, settingName))

  const onLoadSetting= (token, name, inputValue) => dispatch(actions.loadSetting(token, name, inputValue))
  const onSelectMenuClose = () => dispatch(actions.selectMenuClose())

  const loadKapakRenk = (token, inputValue) => dispatch(actions.loadKapakRenkStart(token, inputValue))
  const loadParcaTipi = (token, inputValue) => dispatch(actions.loadParcaTipiStart(token, inputValue))
  const toggleEditModal = (index) => dispatch(actions.toggleEditModal(index))

  const onModulParcaClick = (token, payload) => dispatch(actions.onSettingParcaModalAction(token, payload)) // payload = { action: 'save',name, data: props.parcaModal.data, modulId: props.parcaModal.modulId }


  const parcaModalNewOnChange = (name, value) => dispatch(actions.updateSettingParcaModalNew(name, value))
  const parcaModalOnChange = (action) => dispatch(actions.updateSettingParcaModal(action))

  const aksamModalOnChange = (action) => dispatch(actions.updateSettingAksamModal(action))
  const onModulAksamClick = (token, payload) => dispatch(actions.onSettingAksamModalAction(token, payload))
  const aksamModalNewOnChange = (name, value) => dispatch(actions.updateSettingAksamModalNew(name, value))

  const aksesuarModalOnChange = (action) => dispatch(actions.updateSettingAksesuarModal(action))
  const onModulAksesuarClick = (token, payload) => dispatch(actions.onSettingAksesuarModalAction(token, payload))
  const aksesuarModalNewOnChange = (name, value) => dispatch(actions.updateSettingAksesuarModalNew(name, value))

  const onSearchClick = (token, name, query) => dispatch(actions.onUretimSearchClick(token, name, query))

  const onPervazInputChange = (index, pervazName, property, value) =>
    dispatch(actions.onPervazInputChange(index, pervazName, property, value))
  const onSettingInputChange = (event) => dispatch(actions.onSettingInputChange(event))
  const onSettingDataRemoved = (token, selectedSetting, id, index) =>
    dispatch(actions.onSettingDataRemoved(token, selectedSetting, id, index))
  const onSettingDataAdded = () => dispatch(actions.onSettingDataAdded())
  const onSettingDataSave = (token, selectedSetting, item) =>
    dispatch(actions.onSettingDataSave(token, selectedSetting, item))

  const onSelectValueChangeInArray = (name, selected, index) =>
    dispatch(actions.onSettingInputChangeInArray(name, selected, index))
  const onSettingSelectValueChange = (name, selected, index) =>
    dispatch(actions.onSettingSelectValueChange(name, selected, index))
  const  handleAksesuarModalOnChange = (e) => {
    e.stopPropagation()
    const target = e.target
    aksesuarModalOnChange({ name: target.name, value: target.value, row: target.dataset.row })
  }
  const handleAksamModalOnChange = (e) => {
    e.stopPropagation()
    const target = e.target
    aksamModalOnChange({ name: target.name, value: target.value, row: target.dataset.row })
  }
  const handleSaveRow = (index) => {
    onSettingDataSave(token, setting.name, setting.data[index])
  }
  const handleAddRow = (e) => {
    e.preventDefault()
    onSettingDataAdded()
  }
  const handleLoadKapakRenk = (inputValue) => {
    loadKapakRenk(token, inputValue)
  }
  const handleLoadParcaTipi = (inputValue) => {
    loadParcaTipi(token, inputValue)
  }
  const handleRemoveRow = (id, index) => {
    onSettingDataRemoved(token, setting.name, id, index)
  }
  const handleSelectValueChangeInArray = (nameWitID, selected, action) => {
    const [name, index] = nameWitID.split('.')
    onSelectValueChangeInArray(name, selected, index)
  }
  const handleSelectValueChangeSingle = (nameWitID, selected) => {
    const [name, index] = nameWitID.split('_')
    onSettingSelectValueChange(name, selected ? selected.aciklama : '', index)
  }
  const handleSelectValueChange = (nameWitID, selected) => {
    const [name, index] = nameWitID.split('_')
    onSettingSelectValueChange(name, selected, index)
  }
  const handleInputChange = (e) => {
    e.stopPropagation()
    const target = e.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    const row = target.dataset.row
    onSettingInputChange({ name, value, row })
  }
  const handleCancel = () => {
    toggleEditModal(0)
  }
  const handleIlkClick = () => {
    onFetch(token, setting.name, paging.q || '', 1)
  }
  const handleGeriClick = () => {
    onFetch(token, setting.name, paging.q || '', ~~paging.currentPage - 1)
  }
  const handleIleriClick = () => {
    onFetch(token, setting.name, paging.q || '', ~~paging.currentPage + 1)
  }
  const handleSonClick = () => {
    onFetch(token, setting.name, paging.q || '', ~~paging.totalPageCount)
  }
  const handleModulParcaClick = (payload) => {
    //    payload = { action: 'save',name, data: props.parcaModal.data, modulId: props.parcaModal.modulId }
    onModulParcaClick(token, payload)
  }
  const handleModulAksamClick = (payload) => {
    onModulAksamClick(token, payload)
  }
  const handleModulAksesuarClick = (payload) => {
    onModulAksesuarClick(token, payload)
  }
  const handleClearSearchClick = () => {
    onFetch(token, setting.name, '', 1)
  }
  const handleSearchClick = (query) => {
    onSearchClick(token, setting.name, query)
  }
  const handleDownload = () => {
    onDownload(token, setting.name)
  }
  const handleLookupSetting = (e, name) => {
    if (e !== '') {
      onLoadSetting(token, name, e)
    }
  }

  useEffect(() => {
    if (props.match.params.name) {
      onFetch(token, props.match.params.name, '', paging.currentPage)
    }
  }, [])
    const enableDownloadLink =
      setting && (setting.name === 'aksam' || setting.name === 'aksesuar' || setting.name === 'modul')

  return (
          <>
        <div className='container-fluid'>
          <div className='row'>
            <SearchInput title={setting && setting.description} onSearchClick={handleSearchClick}>
              <Button color='primary' className='ml-sm-2' onClick={handleAddRow}>
                <i className='fa fa-plus' /> Yeni
              </Button>
              {enableDownloadLink && !downloading && (
                <Button
                  color='primary'
                  onClick={handleDownload}
                  placeholder='Rapor Hazirla'
                  aria-label='Rapor Hazirla'
                  aria-describedby='enableDownloadLink'
                >
                  <i className='far fa-file-excel' />
                  Rapor Hazirla
                </Button>
              )}
              {enableDownloadLink && downloading && <Spinner />}
            </SearchInput>
          </div>
          {paging.q && (
            <div className='row'>
              <div className='col'>
                <Alert color='info'>
                  <Button color='danger' onClick={handleClearSearchClick}>
                    <i className='fas fa-times' />
                  </Button>
                  Arama "<strong>{paging.q}</strong>"
                </Alert>
              </div>
            </div>
          )}
        </div>
        {loading && <Loading />}
        {!loading && setting && (
          <Setting
            item={setting}
            onSettingInputChange={onSettingInputChange}
            handleSelectValueChangeInArray={handleSelectValueChangeInArray}
            handleSelectValueChangeSingle={handleSelectValueChangeSingle}
            handleSelectValueChange={handleSelectValueChange}
            onChange={handleInputChange}
            onRemove={handleRemoveRow}
            onSave={handleSaveRow}
            onCancel={handleCancel}
            editModal={editModal}
            parcaModal={parcaModal}
            aksamModal={aksamModal}
            aksesuarModal={aksesuarModal}
            lookup={lookup}
            referenceData={referenceData}
            toggleEditModal={toggleEditModal}
            onPervazInputChange={onPervazInputChange}
            onLoadSetting={handleLookupSetting}
            onSelectMenuClose={onSelectMenuClose}
            loadKapakRenk={handleLoadKapakRenk}
            loadParcaTipi={handleLoadParcaTipi}
            onModulParcaClick={handleModulParcaClick}
            parcaModalNewOnChange={parcaModalNewOnChange}
            parcaModalOnChange={parcaModalOnChange}
            onModulAksamClick={handleModulAksamClick}
            aksamModalOnChange={handleAksamModalOnChange}
            aksamModalNewOnChange={aksamModalNewOnChange}
            onModulAksesuarClick={handleModulAksesuarClick}
            aksesuarModalOnChange={handleAksesuarModalOnChange}
            aksesuarModalNewOnChange={aksesuarModalNewOnChange}
          />
        )}
        {paging.totalPageCount > 1 && (
          <Paging
            {...paging}
            handleIleriClick={handleIleriClick}
            handleSonClick={handleSonClick}
            handleGeriClick={handleGeriClick}
            handleIlkClick={handleIlkClick}
          />
        )}
      </>
  )
}

export default UretimSettingEditContainer
