import * as actionTypes from '../actions/actionTypes'
import { set, cloneDeep } from 'lodash'
import { initialPaging } from '../dataModels/CommonModals'
import { toast } from 'react-toastify'
import { toasterMessages, toastErrorOptions, toastOptions } from '../../shared'
const initProductGroupForm = () => ({ fields: { description: '' } })
const initialState = {
  error: null,
  productGroupForm: initProductGroupForm(),
  loading: true,
  paging: initialPaging(),
  list: []
}
const productGroupListStart = (state, action) => {
  return {
    ...state,
    error: null,
    loading: true,
    productGroupForm: initProductGroupForm(),
    list: [],
    paging: initialPaging()
  }
}

const productGroupListSuccess = (state, action) => {
  toast.success(`Urun gruplari yuklendi!`, toastOptions)
  return {
    ...state,
    ...{
      error: null,
      loading: false,
      list: action.payload.result,
      paging: action.payload.paging
    }
  }
}

const productGroupListFail = (state, action) => {
  toast.error(toasterMessages.errorWithTitle(`Ürün grupları yüklenirken`), toastErrorOptions)
  return {
    ...state,
    ...{
      error: action.error,
      loading: false
    }
  }
}

const getProductGroupStart = (state, action) => {
  return {
    ...state,
    ...{ error: null, loading: true, productGroupForm: initProductGroupForm(), list: [], paging: initialPaging() }
  }
}

const getProductGroupSuccess = (state, action) => {
  toast.success(`Urun grubu yuklendi!`, toastOptions)
  return {
    ...state,
    ...{
      error: null,
      loading: false,
      productGroupForm: action.payload ? action.payload : state.productGroupForm
    }
  }
}

const getProductGroupFail = (state, action) => {
  toast.error(toasterMessages.errorWithTitle(`Ürün grubu yüklenirken`), toastErrorOptions)
  return {
    ...state,
    ...{
      error: action.error,
      loading: false
    }
  }
}
const onSearchClickFail = (state, action) => {
  toast.error(toasterMessages.errorWithTitle('Ürün grubu ararken'), toastErrorOptions)
  return {
    ...state,
    error: action.error,
    loading: false
  }
}
const onSearchClickStart = (state, action) => {
  return {
    ...state,
    loading: true
  }
}

const onSearchClickSuccess = (state, action) => {
  toast.success(`Arama tamamlandi!`, toastOptions)
  return {
    ...state,
    ...{
      error: null,
      loading: false,
      list: action.payload.result,
      paging: action.payload.paging
    }
  }
}

const updateInput = (state, action) => {
  const { name, value } = action.payload
  const clonedProductGroupForm = cloneDeep(state.productGroupForm)
  set(clonedProductGroupForm, name, value)
  return {
    ...state,
    productGroupForm: clonedProductGroupForm
  }
}
const alimSettingSaveProductGroupStart = (state, action) => {
  return { ...state, ...{ error: null, loading: true } }
}

const alimSettingSaveProductGroupSuccess = (state, action) => {
  toast.success(`Urun grubu kaydedildi!`, toastOptions)
  return {
    ...state,
    ...{
      error: null,
      loading: false,
      productGroupForm: {
        ...state.productGroupForm,
        id: action.payload.id
      }
    }
  }
}

const alimSettingSaveProductGroupFail = (state, action) => {
  toast.error(toasterMessages.errorWithTitle(`Kaydetme esnasinda`), toastErrorOptions)
  return {
    ...state,
    ...{
      error: action.error,
      loading: false
    }
  }
}
export const productGroupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PRODUCT_GROUP_LIST_START:
      return productGroupListStart(state, action)
    case actionTypes.PRODUCT_GROUP_LIST_SUCCESS:
      return productGroupListSuccess(state, action)
    case actionTypes.PRODUCT_GROUP_LIST_FAIL:
      return productGroupListFail(state, action)

    case actionTypes.PRODUCT_GROUP_GET_START:
      return getProductGroupStart(state, action)
    case actionTypes.PRODUCT_GROUP_GET_SUCCESS:
      return getProductGroupSuccess(state, action)
    case actionTypes.PRODUCT_GROUP_GET_FAIL:
      return getProductGroupFail(state, action)

    case actionTypes.PRODUCT_GROUP_SAVE_START:
      return alimSettingSaveProductGroupStart(state, action)
    case actionTypes.PRODUCT_GROUP_SAVE_SUCCESS:
      return alimSettingSaveProductGroupSuccess(state, action)
    case actionTypes.PRODUCT_GROUP_SAVE_FAIL:
      return alimSettingSaveProductGroupFail(state, action)

    case actionTypes.PRODUCT_GROUP_INPUT_CHANGE:
      return updateInput(state, action)
    case actionTypes.PRODUCT_GROUP_SEARCH_CLICK_START:
      return onSearchClickStart(state, action)
    case actionTypes.PRODUCT_GROUP_SEARCH_CLICK_SUCCESS:
      return onSearchClickSuccess(state, action)
    case actionTypes.PRODUCT_GROUP_SEARCH_CLICK_FAIL:
      return onSearchClickFail(state, action)
    default:
      return state
  }
}
