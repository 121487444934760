import React from 'react'
import { Label } from 'reactstrap'
import RequiredFeedback from '../../../Common/RequiredFeedback'
import NumberInput from '../../../Core/NumberInput'

const PervazItem = (props) => {
  return (
    <div className='form-group col-md-3'>
      <h4>
        {props.name}
        {props.showExtraProps && (
          <input
            type='checkbox'
            data-row={props.index}
            onChange={(event) => props.onChange(event, props.name)}
            name='isActive'
            checked={props.isActive}
          />
        )}
      </h4>
      {props.isActive && (
        <>
          {!props.showExtraProps  && (
            <div>
              <NumberInput
                label='Adet'
                name='adet'
                dataRow={props.index}
                onChange={(event) => props.onChange(event, props.name)}
                value={props.adet}
                required
              />
            </div>
          )}
          <div>
            <NumberInput
              label='En(cm)'
              name='en'
              dataRow={props.index}
              onChange={(event) => props.onChange(event, props.name)}
              value={props.en}
              required
            />
          </div>
          {!props.showExtraProps && (
            <>
              <div>
                <NumberInput
                  label='Boy(cm)'
                  name='boy'
                  dataRow={props.index}
                  onChange={(event) => props.onChange(event, props.name)}
                  value={props.boy}
                  required
                />
              </div>
              {props.baza && (
                <>
                  <div className='form-check-inline form-check'>
                    <input
                      type='checkbox'
                      className='form-check-input'
                      id='baza.uzun1'
                      name='baza.uzun1'
                      value='true'
                      data-row={props.index}
                      onChange={(event) => props.onChange(event, props.name)}
                      checked={props.baza.uzun1 ? 'checked' : ''}
                    />
                    <label className='form-check-label' htmlFor='baza.uzun1'>
                      Uzun
                    </label>
                  </div>
                  <div className='form-check form-check-inline'>
                    <input
                      type='checkbox'
                      className='form-check-input'
                      id='baza.uzun2'
                      name='baza.uzun2'
                      value='true'
                      data-row={props.index}
                      onChange={(event) => props.onChange(event, props.name)}
                      checked={props.baza.uzun2 ? 'checked' : ''}
                    />
                    <label className='form-check-label' htmlFor='baza.uzun2'>
                      Uzun
                    </label>
                  </div>
                  <div className='form-check-inline form-check'>
                    <input
                      type='checkbox'
                      className='form-check-input'
                      id='baza.kisa1'
                      name='baza.kisa1'
                      value='true'
                      data-row={props.index}
                      onChange={(event) => props.onChange(event, props.name)}
                      checked={props.baza.kisa1 ? 'checked' : ''}
                    />
                    <label className='form-check-label' htmlFor='baza.kisa1'>
                      Kisa
                    </label>
                  </div>
                  <div className='form-check-inline form-check'>
                    <input
                      type='checkbox'
                      className='form-check-input'
                      id='baza.kisa2'
                      name='baza.kisa2'
                      value='true'
                      data-row={props.index}
                      onChange={(event) => props.onChange(event, props.name)}
                      checked={props.baza.kisa2 ? 'checked' : ''}
                    />
                    <label className='form-check-label' htmlFor='baza.kisa2'>
                      Kisa
                    </label>
                  </div>
                </>
              )}
            </>
          )}
          <div className='form-group'>
            <Label>Ozellik</Label>
            <textarea
              className='form-control'
              onChange={(event) => props.onChange(event, props.name)}
              name='ozellik'
              data-row={props.index}
              value={props.ozellik}
              valid={props.ozellik}
              invalid={props.ozellik}
              required
            />
            <RequiredFeedback />
          </div>
        </>
      )}
    </div>
  )
}

export default PervazItem
