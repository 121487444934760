import set from 'lodash/set'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import { toast } from 'react-toastify'
import {
  ALT_CERCEVE,
  ALT_PERVAZ,
  initPervazBaza,
  SAG_CERCEVE,
  SAG_GORUNEN_YAN,
  SAG_PERVAZ,
  SOL_CERCEVE,
  SOL_GORUNEN_YAN,
  SOL_PERVAZ,
  toasterMessages,
  toastErrorOptions,
  toastOptions,
  UST_CERCEVE,
  UST_PERVAZ
} from '../../../shared'
import { initLookup } from '../../dataModels/LookupModals'

export const saveWardrobeOrderSuccess = (state, action) => {
  toast.success(`Siparisiniz kaydedilmistir!`, toastOptions)
  return { ...state, ...{ saving: false, wardrobeForm: { ...state.wardrobeForm, id: action.data.id } } }
}
export const saveWardrobeOrderFail = (state, action) => {
  toast.error(toasterMessages.errorWithMessage(action.error.message), toastErrorOptions)
  return { ...state, ...{ saving: false, error: action.error.message } }
}
export const updateWardrobeOrderArrayProperty = (state, action) => {
  const { name, value } = action.payload
  const arrayItem = cloneDeep(state.wardrobeForm[name])
  arrayItem[action.payload.index][action.payload.property] = value
  return {
    ...state,
    wardrobeForm: {
      ...state.wardrobeForm,
      [name]: arrayItem
    },
    lookup: initLookup()
  }
}

export const saveWardrobeOrderStart = (state, action) => {
  return { ...state, ...{ saving: true } }
}

export const resetWardrobeOrderForm = (state, action) => {
  return { ...state, wardrobeForm: undefined }
}
export const updateWardrobeOrderProperty = (state, action) => {
  const { value, field, updateModuller } = action.payload
  const cloneData = cloneDeep(state.wardrobeForm)
  set(cloneData, field, value)
  switch (field) {
    case 'camKapakAdet':
      if (!(Number(value) > 0)) {
        cloneData.dolapAynaModeli = null
      }
      break
    case 'tipi':
      const tipi = cloneDeep(value)
      const sagGorunenPervaz = tipi.pervazlar.find((x) => x.name === SAG_GORUNEN_YAN)
      if (sagGorunenPervaz) {
        sagGorunenPervaz.adet = 1
        sagGorunenPervaz.en = get(cloneData, 'boslukOlcusu.derinlik')
        sagGorunenPervaz.boy = get(cloneData, 'boslukOlcusu.boy')
        if (!sagGorunenPervaz.baza) {
          sagGorunenPervaz.baza = { ...initPervazBaza() }
        }
      }
      const solGorunenePervaz = tipi.pervazlar.find((x) => x.name === SOL_GORUNEN_YAN)
      if (solGorunenePervaz) {
        solGorunenePervaz.adet = 1
        solGorunenePervaz.en = get(cloneData, 'boslukOlcusu.derinlik')
        solGorunenePervaz.boy = get(cloneData, 'boslukOlcusu.boy')
        if (!solGorunenePervaz.baza) {
          solGorunenePervaz.baza = { ...initPervazBaza() }
        }
      }
      const sagPervaz = tipi.pervazlar.find((x) => x.name === SAG_PERVAZ)
      if (sagPervaz) {
        sagPervaz.adet = 1
        sagPervaz.boy = get(cloneData, 'boslukOlcusu.boy')
        if (!sagPervaz.baza) {
          sagPervaz.baza = { ...initPervazBaza() }
        }
      }
      const solPervaz = tipi.pervazlar.find((x) => x.name === SOL_PERVAZ)
      if (solPervaz) {
        solPervaz.adet = 1
        solPervaz.boy = get(cloneData, 'boslukOlcusu.boy')
        if (!solPervaz.baza) {
          solPervaz.baza = { ...initPervazBaza() }
        }
      }
      const altPervaz = tipi.pervazlar.find((x) => x.name === ALT_PERVAZ)
      if (altPervaz) {
        altPervaz.adet = 1
        altPervaz.boy = get(cloneData, 'yapilacakOlan.en')
        if (!altPervaz.baza) {
          altPervaz.baza = { ...initPervazBaza() }
        }
      }
      const ustPervaz = tipi.pervazlar.find((x) => x.name === UST_PERVAZ)
      if (ustPervaz) {
        ustPervaz.adet = 1
        ustPervaz.boy = get(cloneData, 'yapilacakOlan.en')
        if (!ustPervaz.baza) {
          ustPervaz.baza = { ...initPervazBaza() }
        }
      }
      const altCerceve = tipi.pervazlar.find((x) => x.name === ALT_CERCEVE)
      if (altCerceve) {
        altCerceve.boy = get(cloneData, 'yapilacakOlan.en')
      }
      const ustCerceve = tipi.pervazlar.find((x) => x.name === UST_CERCEVE)
      if (ustCerceve) {
        ustCerceve.boy = get(cloneData, 'yapilacakOlan.en')
      }
      const sagCerceve = tipi.pervazlar.find((x) => x.name === SAG_CERCEVE)
      if (sagCerceve) {
        sagCerceve.boy = get(cloneData, 'yapilacakOlan.boy')
      }
      const solCerceve = tipi.pervazlar.find((x) => x.name === SOL_CERCEVE)
      if (solCerceve) {
        solCerceve.boy = get(cloneData, 'yapilacakOlan.boy')
      }
      cloneData.tipi = tipi
      break
    case 'kapakrenk':
      cloneData.pervazrenk = value
      break
    case 'boslukOlcusu.derinlik':
      if (cloneData.tipi) {
        const solGorunenePervaz = cloneData.tipi.pervazlar.find((x) => x.name === SOL_GORUNEN_YAN)
        const sagGorunenPervaz = cloneData.tipi.pervazlar.find((x) => x.name === SAG_GORUNEN_YAN)
        if (sagGorunenPervaz) {
          sagGorunenPervaz.en = value
        }
        if (solGorunenePervaz) {
          solGorunenePervaz.en = value
        }
      }
      break
    case 'boslukOlcusu.boy':
      if (cloneData.tipi) {
        const sagPervaz = cloneData.tipi.pervazlar.find((x) => x.name === SAG_PERVAZ)
        const solPervaz = cloneData.tipi.pervazlar.find((x) => x.name === SOL_PERVAZ)
        const sagGorunenPervaz = cloneData.tipi.pervazlar.find((x) => x.name === SAG_GORUNEN_YAN)
        const solGorunenPervaz = cloneData.tipi.pervazlar.find((x) => x.name === SOL_GORUNEN_YAN)

        if (sagPervaz) {
          sagPervaz.boy = value
        }
        if (solPervaz) {
          solPervaz.boy = value
        }
        if (sagGorunenPervaz) {
          sagGorunenPervaz.boy = value
        }
        if (solGorunenPervaz) {
          solGorunenPervaz.boy = value
        }
      }
      break
    case 'yapilacakOlan.en':
      if (cloneData.tipi) {
        const altPervaz = cloneData.tipi.pervazlar.find((x) => x.name === ALT_PERVAZ)
        const ustPervaz = cloneData.tipi.pervazlar.find((x) => x.name === UST_PERVAZ)
        const ustCerceve = cloneData.tipi.pervazlar.find((x) => x.name === UST_CERCEVE)
        const altCerceve = cloneData.tipi.pervazlar.find((x) => x.name === ALT_CERCEVE)

        if (altPervaz) {
          altPervaz.boy = value
        }
        if (ustPervaz) {
          ustPervaz.boy = value
        }
        if (altCerceve) {
          altCerceve.boy = value
        }
        if (ustCerceve) {
          ustCerceve.boy = value
        }
      }
      break
    case 'yapilacakOlan.boy':
      if (cloneData.tipi) {
        const sagCerceve = cloneData.tipi.pervazlar.find((x) => x.name === SAG_CERCEVE)
        const solCerceve = cloneData.tipi.pervazlar.find((x) => x.name === SOL_CERCEVE)

        if (sagCerceve) {
          sagCerceve.boy = value
        }
        if (solCerceve) {
          solCerceve.boy = value
        }
      }
      break
    default:
      break
  }
  if (updateModuller) {
    const currentWardrobeFormField = get(state.wardrobeForm, field)
    const updatedModulNames = []
    cloneData.moduller.forEach((modulItem) => {
      const currentModulFormField = get(modulItem, field)
      if (
        currentWardrobeFormField &&
        currentModulFormField &&
        currentWardrobeFormField.aciklama !== currentModulFormField.aciklama
      ) {
        updatedModulNames.push(modulItem.modul.aciklama)
      }

      set(modulItem, field, value)
    })
    if (updatedModulNames.length > 0) {
      toast.warn(`${updatedModulNames.join(', ')} modullerinde farkli degerler mevcut!`, toastErrorOptions)
    }
  }
  return { ...state, wardrobeForm: cloneData, lookup: initLookup() }
}
