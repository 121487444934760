import {initSurmeData} from "../../dataModels/AcilanAndSurmeModal";
import {toast} from "react-toastify";
import {toasterMessages, toastErrorOptions} from "../../../shared";
import {initModulModal} from "../../dataModels/ModulModal";
import {initLookup} from "../../dataModels/LookupModals";


export const fetchSurmeWardrobeOrderSuccess = ( state, action ) => {
  let {order } = action.data;
  const wardrobeForm = !order ? initSurmeData() : order.data;
  wardrobeForm.id = !order ? null : order.id
  return {...state, ...{ wardrobeForm, loading: false } }
}

export const fetchSurmeWardrobeOrderFail = ( state, action ) => {
  toast.error(toasterMessages.errorWithMessage(action.error.message),toastErrorOptions);
  return {...state, ...{ loading: false, error: action.error.message } }
}
export const fetchSurmeWardrobeOrderStart = ( state, action ) => {
  return {...state, ...{ loading: true, kitchenForm: undefined, wardrobeForm: initSurmeData(), modulModal: initModulModal('wardrobe','surme'), lookup:initLookup() } }
}
