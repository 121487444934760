export const initParcaModalNew = () => ({
  parca: null,
  miktar: 0,
  uzunluk: 0,
  genislik: 0,
  isactive: true,
  boyut: 0
})

export const initParcaModal = () => ({
  show: false,
  showBand: false,
  index: 0,
  data: [],
  modulId: '',
  notes: '',
  new: initParcaModalNew()
})
