import axios from 'axios';
export const instance = (token) => {
  const headers = {
    'Content-Type': 'application/json'
  };
  if (token){
    headers['Authorization'] = 'JWT ' + token
  }
  return axios.create({
    timeout: 30*60*1000,
    headers
  })
}
