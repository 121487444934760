import React from 'react'
import { Input, Label } from 'reactstrap'
import PropTypes from 'prop-types'

const Radio = (props) => {
  const { name, id, value, onChange, index, text, checked, ...others } = props
  return (
    <Label className={checked ? 'btn btn-success' : 'btn btn-secondary'}>
      <Input
        className='form-check-input'
        type='radio'
        name={name}
        id={id}
        value={value}
        checked={checked}
        onChange={onChange}
        data-row={index}
        {...others}
      />{' '}
      {text}
    </Label>
  )
}
Radio.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  index: PropTypes.number,
  value: PropTypes.string,
  text: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string
}
export default Radio
