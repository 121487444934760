import React, { PureComponent } from 'react'
import { Badge, Table } from 'reactstrap'
import { Link } from 'react-router-dom'
import { localDateStringOptions } from '../../../shared'

class WeeklyList extends PureComponent {
  render() {
    const getTypeDescription = (item) => (item.fields.type ? 'Toplu Dolap' : 'Haftalik')
    const toLocaleDate = (teslimtarihi) => {
      return new Date(teslimtarihi).toLocaleDateString('tr', localDateStringOptions)
    }
    return (
      <Table>
        <thead>
          <tr className='d-flex'>
            <th scope='col' className='col-4'>
              Aciklama
            </th>
            <th scope='col' className='col-2'>
              Yaratilma tarihi
            </th>
            <th scope='col' className='col-6'>
              #
            </th>
          </tr>
        </thead>
        <tbody>
          {this.props.list.map((item) => (
            <tr className='d-flex' key={item.id}>
              <td className={`col-4 text-white ${item.fields.type ? 'bg-success' : 'bg-primary'}`}>
                {item.fields.name}
                <div>
                  <Badge className='float-start' pill>
                    {getTypeDescription(item)}
                  </Badge>
                </div>
              </td>
              <td className='col-2'>{toLocaleDate(item.created)}</td>
              <td className='col-6'>
                <Link
                  className='btn btn-link float-end'
                  to={`/uretim/raporlar/${item.fields.type ? item.fields.type : 'haftalik'}/${item.id}`}
                >
                  Degistir
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }
}

export default WeeklyList
