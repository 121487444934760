import * as actionTypes from '../actions/actionTypes'
import { toast } from 'react-toastify'
import { toasterMessages, toastErrorOptions, toastOptions } from '../../shared'
import { initialPaging } from '../dataModels/CommonModals'

const initialUser = () => ({
  error: null,
  loading: false,
  saving: false,
  id: '',
  password: '',
  email: '',
  isactive: false,
  displayName: '',
  roles: []
})

const initialState = {
  error: null,
  loading: true,
  list: [],
  user: initialUser(),
  paging: initialPaging()
}

const fetchUserListStart = (state, action) => {
  return {
    ...state,
    ...{
      error: '',
      loading: true,
      paging: initialPaging(),
      user: initialUser(),
      list: []
    }
  }
}

const fetchUserListSuccess = (state, action) => {
  toast.success(`Kullanıcılar yuklendi!`, toastOptions)
  return {
    ...state,
    ...{
      error: '',
      loading: false,
      list: action.payload.result,
      paging: action.payload.paging
    }
  }
}

const fetchUserListFail = (state, action) => {
  toast.error(toasterMessages.errorWithTitle(`Kullanıcılar yuklerken`), toastErrorOptions)
  return {
    ...state,
    ...{
      error: action.error,
      loading: false
    }
  }
}
const fetchUserStart = (state, action) => {
  return {
    ...state,
    ...{
      user: {
        ...initialUser(),
        loading: true
      }
    }
  }
}
const fetchUserSuccess = (state, action) => {
  toast.success(`Kullanici yuklendi!`, toastOptions)
  return {
    ...state,
    ...{
      user: {
        ...state.user,
        loading: false,
        ...action.payload.user
      }
    }
  }
}
const fetchUserFail = (state, action) => {
  toast.error(toasterMessages.errorWithTitle(`Kullanici yuklerken`), toastErrorOptions)
  return {
    ...state,
    ...{
      user: {
        ...state.user,
        error: action.payload.error,
        loading: false
      }
    }
  }
}
const updateUserRole = (state, action) => {
  const { name, checked } = action.payload
  let roles = []
  if (checked) {
    roles = state.user.roles.indexOf(name) === -1 ? [...state.user.roles, name] : [...state.user.roles]
  } else {
    roles = state.user.roles.filter((e) => e !== name)
  }
  return {
    ...state,
    ...{
      user: {
        ...state.user,
        roles
      }
    }
  }
}
const updateUserProperty = (state, action) => {
  return {
    ...state,
    ...{
      user: {
        ...state.user,
        [action.payload.name]: action.payload.value
      }
    }
  }
}
const saveUserStart = (state, action) => {
  return {
    ...state,
    ...{
      user: {
        ...state.user,
        saving: true
      }
    }
  }
}
const saveUserSuccess = (state, action) => {
  toast.success(`Kullanici kaydedildi!`, toastOptions)
  return {
    ...state,
    ...{
      user: {
        ...state.user,
        saving: false
      }
    }
  }
}
const saveUserFail = (state, action) => {
  toast.error(toasterMessages.errorWithTitle(`Kullanici kaydedilirken`), toastErrorOptions)
  return {
    ...state,
    ...{
      user: {
        ...state.user,
        error: action.error,
        saving: false
      }
    }
  }
}
export const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_LIST_START:
      return fetchUserListStart(state, action)
    case actionTypes.FETCH_USER_LIST_SUCCESS:
      return fetchUserListSuccess(state, action)
    case actionTypes.FETCH_USER_LIST_FAIL:
      return fetchUserListFail(state, action)

    case actionTypes.FETCH_USER_START:
      return fetchUserStart(state, action)
    case actionTypes.FETCH_USER_SUCCESS:
      return fetchUserSuccess(state, action)
    case actionTypes.FETCH_USER_FAIL:
      return fetchUserFail(state, action)

    case actionTypes.UPDATE_USER_ROLE:
      return updateUserRole(state, action)
    case actionTypes.UPDATE_USER_PROPERTY:
      return updateUserProperty(state, action)
    case actionTypes.SAVE_USER_START:
      return saveUserStart(state, action)
    case actionTypes.SAVE_USER_SUCCESS:
      return saveUserSuccess(state, action)
    case actionTypes.SAVE_USER_FAIL:
      return saveUserFail(state, action)
    default:
      return state
  }
}
