import React from 'react'
import { Col, Input, Label } from 'reactstrap'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../store/actions'
import { URETIM_SETTINGS_ADMIN_ROLE, USER_ADMIN_ROLE } from '../../shared'

const UserEdit = () => {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.users)
  const { token } = useSelector((state) => state.auth)
  const handleRolesChange = (event) => {
    const checked = event.target.checked
    const name = event.target.name
    dispatch(actions.updateUserRole(name, checked))
  }
  const handleOnPropertyChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
    const name = event.target.name
    dispatch(actions.updateUserProperty(name, value))
  }
  const handleSave = (e) => {
    e.preventDefault()
    dispatch(actions.saveUser(token, user))
  }
  return (
    <Col sm='12'>
      <h3>Kullani Degistir</h3>
      <form onSubmit={handleSave}>
        <div className='form-group col-md-12'>
          <Label htmlFor='displayName'>Isim</Label>
          <Input
            type='text'
            className='form-control'
            onChange={handleOnPropertyChange}
            name='displayName'
            placeholder='Isim'
            valid={!!user.displayName}
            value={user.displayName}
            invalid={!user.displayName}
            required
          />
        </div>
        <div className='form-group col-md-12'>
          <Label htmlFor='email'>Email</Label>
          <Input
            type='text'
            className='form-control'
            onChange={handleOnPropertyChange}
            name='email'
            placeholder='email'
            valid={!!user.email}
            value={user.email}
            invalid={!user.email}
            required
          />
        </div>
        <div className='form-group col-md-12'>
          <Label htmlFor='password'>Password</Label>
          {user.id !== '' ? (
            <Input
              type='password'
              className='form-control'
              onChange={handleOnPropertyChange}
              name='password'
              placeholder='password'
              valid={!!user.password}
              value={user.password}
              invalid={!user.password}
            />
          ) : (
            <Input
              type='password'
              className='form-control'
              onChange={handleOnPropertyChange}
              name='password'
              placeholder='password'
              valid={!!user.password}
              value={user.password}
              invalid={!user.password}
              required
            />
          )}
        </div>
        <div className='form-group row'>
          <div className='checkbox col-md-4'>
            <label
              htmlFor='user-admin'
              className={user.roles.indexOf(USER_ADMIN_ROLE) > -1 ? 'btn btn-success' : 'btn btn-danger'}
            >
              <span>
                {user.roles.indexOf(USER_ADMIN_ROLE) > -1
                  ? 'Kullanici Yoneticisi Aktif'
                  : ' Kullanici Yoneticisi Pasif'}
              </span>

              <input
                type='checkbox'
                checked={user.roles.indexOf(USER_ADMIN_ROLE) > -1}
                onChange={handleRolesChange}
                placeholder='Kullanici Yoneticisi'
                id='user-admin'
                className='badgebox'
                name='user-admin'
              />
              <span className='badge'>
                <i className='fas fa-check' />
              </span>
            </label>
          </div>
          <div className='checkbox col-md-4'>
            <label
              htmlFor='uretim-settings-admin'
              className={user.roles.indexOf(URETIM_SETTINGS_ADMIN_ROLE) > -1 ? 'btn btn-success' : 'btn btn-danger'}
            >
              <span>
                {user.roles.indexOf(URETIM_SETTINGS_ADMIN_ROLE) > -1
                  ? 'Uretim Ayarlar Yoneticisi Aktif'
                  : 'Uretim Ayarlar Yoneticisi Pasif'}
              </span>

              <input
                type='checkbox'
                checked={user.roles.indexOf(URETIM_SETTINGS_ADMIN_ROLE) > -1}
                onChange={handleRolesChange}
                placeholder='Uretim Ayarlar Yoneticisi'
                id='uretim-settings-admin'
                className='badgebox'
                name='uretim-settings-admin'
              />
              <span className='badge'>
                <i className='fas fa-check' />
              </span>
            </label>
          </div>
        </div>
        <div className='form-group col-md-12'>
          <div className='checkbox'>
            <label htmlFor='isactive' className={user.isactive ? 'btn btn-success' : 'btn btn-danger'}>
              <span>{user.isactive ? 'Aktif' : 'Pasif'}</span>

              <input
                type='checkbox'
                checked={user.isactive}
                onChange={handleOnPropertyChange}
                placeholder='Aktif/Pasif'
                id='isactive'
                className='badgebox'
                name='isactive'
              />
              <span className='badge'>
                <i className='fas fa-check' />
              </span>
            </label>
          </div>
        </div>

        <nav className='navbar fixed-bottom navbar-dark bg-dark'>
          <NavLink className='btn btn-warning  m-1' to='/kullanicilar'>
            Vazgec
          </NavLink>
          {!user.loading && (
            <button type='submit' className='btn btn-primary float-end m-1'>
              <i className='fas fa-save' />
              Kaydet
            </button>
          )}
        </nav>
      </form>
    </Col>
  )
}

export default UserEdit
