import React, { PureComponent } from 'react'
import { Card, CardHeader, CardBody, Button, Row } from 'reactstrap'
import KapamaItem from './KapamaItem'

class Kapama extends PureComponent {
  render() {
    const {
      kapamalar,
      lookup,
      lookupSetting,
      onKapamaRemoveClick,
      onInputChange,
      onSelectChange,
      onSelectMenuClose,
      onKapamaAddClick
    } = this.props
    return (
      <Card className='shadow mb-2'>
        <CardHeader>
          Kapama/Lambri
          <Button outline color='primary' className='float-end' onClick={onKapamaAddClick}>
            <i className='fas fa-plus' />
            Kapama Ekle
          </Button>
        </CardHeader>
        <CardBody>
          <Row>
            {kapamalar.map((item, index) => {
              return (
                <KapamaItem
                  key={item.id}
                  index={index}
                  item={item}
                  lookup={lookup}
                  lookupSetting={lookupSetting}
                  onKapamaRemoveClick={onKapamaRemoveClick}
                  onInputChange={onInputChange}
                  onSelectChange={onSelectChange}
                  onSelectMenuClose={onSelectMenuClose}
                />
              )
            })}
          </Row>
        </CardBody>
      </Card>
    )
  }
}
export default Kapama
