import set from 'lodash/set'

const golaDegisikligindeBDModullerdeYanTablaParcalariOrijinalHalineGetir = (modulItem) => {
  const updatedParcalar = modulItem.modul.parcalar.filter((parca) => {
    return !parcaTipiHerhangiBirYanTabla(parca) || (parcaTipiHerhangiBirYanTabla(parca) && !parca.parcaYon)
  })
  updatedParcalar.forEach((parca) => {
    if (parcaTipiHerhangiBirYanTabla(parca)) {
      parca.miktar = 2
    }
  })
  return updatedParcalar
}
export const kulpModeliGolaSecildi = (currentKulpModeli) => currentKulpModeli && currentKulpModeli.aciklama.startsWith('GOLA')
const parcaTipiHerhangiBirYanTabla = (parca) => parca.tip.aciklama.includes('YAN TABLA')
const parcaTipiYanTablaveyaArkalik = (parca) => parca.tip.aciklama.includes('YAN TABLA') || parca.tip.aciklama.includes('ARKALIK')
const parcaTipiAltTabla = (parca) => parca.tip.aciklama.includes('ALT TABLA')
const parcaTipiCekmeceKapak = (parca) => parca.tip.aciklama === 'CEKMECE KAPAK'
const golaTipiGolaSagYadaGolaSolSecildi = (golatip) => golatip && (golatip === 'golaSag' || golatip === 'golaSol')
const golaTipiGolaSagSolSecildi = (golatip) => golatip && golatip === 'golaSagSol'

const handleGolaKulpModeliParcaUpdate = ({ newKulpModeli, currentKulpModeli, modulItem, golaKulpModeliModulleri }) => {
  if (kulpModeliGolaSecildi(newKulpModeli) && kulpModeliGolaSecildi(currentKulpModeli)) {
    return
  }
  if (kulpModeliGolaSecildi(newKulpModeli)) {
    if (modulItem.modul.aciklama.startsWith('BD')) {
      if (!modulItem.golatip) {
        golaKulpModeliModulleri.push(`${modulItem.modul.aciklama} icin Gola tipi secin!`)
      } else if (golaTipiGolaSagYadaGolaSolSecildi(modulItem.golatip)) {
        modulItem.modul.parcalar.forEach((parca, index) => {
          if (parcaTipiHerhangiBirYanTabla(parca) && Number(parca.miktar) === 2) {
            const clonedAltTabla = { ...modulItem.modul.parcalar[index] }
            clonedAltTabla.miktar = 1
            clonedAltTabla.genislik = Number(parca.genislik) - 12
            clonedAltTabla.parcaYon = modulItem.golatip.replace('gola', '')
            modulItem.modul.parcalar.push(clonedAltTabla)
            set(modulItem, `modul.parcalar[${index}].miktar`, 1)
            golaKulpModeliModulleri.push(`${modulItem.modul.aciklama}-${parca.tip.aciklama} genislik 12 cikarildi`)
          }
        })
      } else if (golaTipiGolaSagSolSecildi(modulItem.golatip)) {
        modulItem.modul.parcalar.forEach((parca, index) => {
          if (parcaTipiHerhangiBirYanTabla(parca)) {
            set(modulItem, `modul.parcalar[${index}].genislik`, Number(parca.genislik) - 12)
            golaKulpModeliModulleri.push(`${modulItem.modul.aciklama}-${parca.tip.aciklama} genislik 12 cikarildi`)
          }
        })
      }
    } else {
      set(modulItem, 'golatip', undefined)
      set(modulItem, 'golaTekliCiftli', undefined)
      set(modulItem, 'golaAdet', undefined)
      set(modulItem, 'golaParcaUzunlugu', undefined)
    }
    if (modulItem.modul.aciklama.startsWith('UD')) {
      modulItem.modul.parcalar.forEach((parca, index) => {
        if (parcaTipiAltTabla(parca)) {
          set(modulItem, `modul.parcalar[${index}].genislik`, Number(parca.genislik) - 24)
          set(modulItem, 'modul.aksesuarlar', [...modulItem.modul.aksesuarlar, {
            aciklama:'FITILSIZ GOLA',
            tip: 'Imalat',
            fiyat: 0,
            not:'',
            grup:{
              aciklama: 'Aksesuarlar',
              id: 456,
              isactive: true
            },
            id: Date.now(),
            miktar: Number(parca.uzunluk)*Number(parca.miktar)*Number(modulItem.adet)
          }])
        }
        golaKulpModeliModulleri.push(`${modulItem.modul.aciklama}-${parca.tip.aciklama} genislik 24 cikarildi, FITILSIZ GOLA aksesuari eklendi`)
      })
    }
    if (modulItem.modul.aciklama.startsWith('YD')) {
      let toplamAltTablaParcaUzunluk = 0
      modulItem.modul.parcalar.forEach((parca, index) => {
          if (parcaTipiAltTabla(parca)) {
            toplamAltTablaParcaUzunluk += Number(modulItem.modul.parcalar[index].uzunluk)
          }
          if (parcaTipiYanTablaveyaArkalik(parca)) {
          set(modulItem, `modul.parcalar[${index}].uzunluk`, Number(parca.uzunluk) + 36)
          golaKulpModeliModulleri.push(`${modulItem.modul.aciklama}-YAN TABLA uzunluk 36 eklendi`)
        }
      })
      if (modulItem.modul.aciklama.startsWith('YDC2')) {
        modulItem.modul.parcalar.forEach((parca, index) => {
          if (parcaTipiCekmeceKapak(parca)) {
            set(modulItem, `modul.parcalar[${index}].uzunluk`, Number(parca.uzunluk) - 15)
            golaKulpModeliModulleri.push(`${modulItem.modul.aciklama}-CEKMECE KAPAK uzunluk 15 cikarildi`)
          }
        })
      }
      if (modulItem.modul.aciklama.startsWith('YDC3')) {
        modulItem.modul.parcalar.forEach((parca, index) => {
          if (parcaTipiCekmeceKapak(parca) && Number(parca.miktar) === 1) {
            set(modulItem, `modul.parcalar[${index}].uzunluk`, Number(parca.uzunluk) - 15)
            golaKulpModeliModulleri.push(`${modulItem.modul.aciklama}-CEKMECE KAPAK uzunluk 15 cikarildi`)
          }
          if (parcaTipiCekmeceKapak(parca) && Number(parca.miktar) === 2) {
            set(modulItem, `modul.parcalar[${index}].uzunluk`, Number(parca.uzunluk) - 8)
            golaKulpModeliModulleri.push(`${modulItem.modul.aciklama}-CEKMECE KAPAK uzunluk 8 cikarildi`)
          }
        })
      }
      if (modulItem.modul.aciklama.startsWith('YDC')) {
        set(modulItem, 'modul.aksesuarlar',[...modulItem.modul.aksesuarlar,{
          aciklama:'CIFTLI YATAY GOLA',
          tip: 'Montaj',
          fiyat: 0,
          not:'',
          grup:{
            aciklama: 'Aksesuarlar',
            id: 456,
            isactive: true
          },
          id: Date.now(),
          miktar: toplamAltTablaParcaUzunluk
        }])
      }
      set(modulItem, 'modul.aksesuarlar',[...modulItem.modul.aksesuarlar,{
        aciklama:'YATAY TEKLI GOLA',
        tip: 'Montaj',
        fiyat: 0,
        not:'',
        grup:{
          aciklama: 'Aksesuarlar',
          id: 456,
          isactive: true
        },
        id: Date.now(),
        miktar: toplamAltTablaParcaUzunluk
      }])

    }
  } else if (kulpModeliGolaSecildi(currentKulpModeli)) {
    if (modulItem.modul.aciklama.startsWith('BD')) {
      if (golaTipiGolaSagYadaGolaSolSecildi(modulItem.golatip)) {
        const updatedParcalar = golaDegisikligindeBDModullerdeYanTablaParcalariOrijinalHalineGetir(modulItem)
        modulItem.modul.parcalar = [...updatedParcalar]
      } else if (golaTipiGolaSagSolSecildi(modulItem.golatip)) {
        modulItem.modul.parcalar.forEach((parca, index) => {
          if (parcaTipiHerhangiBirYanTabla(parca)) {
            set(modulItem, `modul.parcalar[${index}].genislik`, Number(parca.genislik) + 12)
            golaKulpModeliModulleri.push(`${modulItem.modul.aciklama}-${parca.tip.aciklama} genislik 12 eklendi`)
          }
        })
      }
    }
    if (modulItem.modul.aciklama.startsWith('UD')) {
      modulItem.modul.parcalar.forEach((parca, index) => {
        if (parcaTipiAltTabla(parca)) {
          set(modulItem, `modul.parcalar[${index}].genislik`, Number(parca.genislik) + 24)
          set(modulItem, 'modul.aksesuarlar',modulItem.modul.aksesuarlar.filter(aks=>aks.aciklama!=='FITILSIZ GOLA'))
          golaKulpModeliModulleri.push(`${modulItem.modul.aciklama}-${parca.tip.aciklama} uzunluk 24 eklendi, FITILSIZ GOLA aksesuari cikarildi`)
        }
      })
    }
    if (modulItem.modul.aciklama.startsWith('YD')) {
      modulItem.modul.parcalar.forEach((parca, index) => {
        if (parcaTipiYanTablaveyaArkalik(parca)) {
          set(modulItem, `modul.parcalar[${index}].uzunluk`, Number(parca.uzunluk) - 36)
          golaKulpModeliModulleri.push(`${modulItem.modul.aciklama}-YAN TABLA uzunluk 36 cikarildi`)
        }
      })
      if (modulItem.modul.aciklama.startsWith('YDC2')) {
        modulItem.modul.parcalar.forEach((parca, index) => {
          if (parcaTipiCekmeceKapak(parca)) {
            set(modulItem, `modul.parcalar[${index}].uzunluk`, Number(parca.uzunluk) + 15)
            golaKulpModeliModulleri.push(`${modulItem.modul.aciklama}-CEKMECE KAPAK uzunluk 15 eklendi`)
          }
        })
      }
      if (modulItem.modul.aciklama.startsWith('YDC3')) {
        modulItem.modul.parcalar.forEach((parca, index) => {
          if (parcaTipiCekmeceKapak(parca) && Number(parca.miktar) === 1) {
            set(modulItem, `modul.parcalar[${index}].uzunluk`, Number(parca.uzunluk) + 15)
            golaKulpModeliModulleri.push(`${modulItem.modul.aciklama}-1 adet CEKMECE KAPAK uzunluk 15 eklendi`)
          }
          if (parcaTipiCekmeceKapak(parca) && Number(parca.miktar) === 2) {
            set(modulItem, `modul.parcalar[${index}].uzunluk`, Number(parca.uzunluk) + 8)
            golaKulpModeliModulleri.push(`${modulItem.modul.aciklama}-2 adet CEKMECE KAPAK uzunluk 8 eklendi`)
          }
        })
      }
    }
    set(modulItem, 'golatip', undefined)
    set(modulItem, 'golaTekliCiftli', undefined)
    set(modulItem, 'golaAdet', undefined)
    set(modulItem, 'golaParcaUzunlugu', undefined)
  }
}

export {
  handleGolaKulpModeliParcaUpdate,
  golaDegisikligindeBDModullerdeYanTablaParcalariOrijinalHalineGetir,
  parcaTipiHerhangiBirYanTabla,
  golaTipiGolaSagYadaGolaSolSecildi,
  golaTipiGolaSagSolSecildi
}
