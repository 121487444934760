import React from 'react'
import { Modal } from '../Uretim/Orders/Kitchen'
import { Button, Label } from 'reactstrap'
import NumberInput from '../Core/NumberInput'
import Select from '../Core/Select'

const CompanyModal = (props) => {
  const handleSave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const action = props.companyModal.mode === 'edit' ? 'update' : 'create'
    props.onCompanyModalClick({ action })
  }
  const {
    new: { company, paymentDetails },
    mode,
    show
  } = props.companyModal
  return (
    <Modal modal={show} size='xl' title='Firma Ekle/Degistir'>
      <form onSubmit={handleSave}>
        <div className='row'>
          <div className='form-group col-lg-6'>
            <Select
              name='company'
              value={company}
              onChange={props.onSelectChange}
              isDisabled={mode === 'edit'}
              text='Firma'
              inputValue={props.lookup.inputValue}
              options={props.lookup.result}
              onInputChange={props.lookupCompany}
              isLoading={props.lookup.isLoading}
              getOptionValue={(option) => option['id']}
              getOptionLabel={(option) => option['name']}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-3'>
            <Select
              name='paymentDetails.measure'
              text='Olcu Birim'
              value={paymentDetails.measure}
              onChange={props.onSelectChange}
              options={props.measures}
            />
          </div>
          <div className='col-md-3'>
            <Label htmlFor='isim'>Min Siparis Adeti</Label>
            <NumberInput
              name='paymentDetails.minOrderAmount'
              label='Min Siparis Adeti'
              onChange={props.onChange}
              value={paymentDetails.minOrderAmount}
              valid={!!paymentDetails.minOrderAmount}
              invalid={!paymentDetails.minOrderAmount}
              required
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-3'>
            <Select
              name='paymentDetails.deliveryType'
              text='Termin Tarih Birim'
              value={paymentDetails.deliveryType}
              onChange={props.onSelectChange}
              options={props.deliveryTypes}
            />
          </div>
          <div className='col-md-3'>
            <NumberInput
              label='Termin Tarih'
              name='paymentDetails.deliveryRate'
              value={paymentDetails.deliveryRate}
              valid={!!paymentDetails.deliveryRate}
              invalid={!paymentDetails.deliveryRate}
              onChange={props.onChange}
              required
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-2'>
            <Select
              name='paymentDetails.paymentType'
              text='Odeme Sekli'
              value={paymentDetails.paymentType}
              onChange={props.onSelectChange}
              options={props.paymentTypes}
            />
          </div>
          <div className='col-md-2'>
            <Select
              name='paymentDetails.currency'
              text='Para birimi'
              value={paymentDetails.currency}
              onChange={props.onSelectChange}
              options={props.currencies}
            />
          </div>
          <div className='col-md-2'>
            <NumberInput
              label='Fiyat'
              name='paymentDetails.price'
              value={paymentDetails.price}
              onChange={props.onChange}
              step='0.001'
              valid={paymentDetails.price >= 0}
              invalid={!paymentDetails.price}
            />
          </div>
          <div className='col-md-12 pt-md-4'>
            <Button
              color='secondary'
              name='cancel-button'
              className='float-start'
              onClick={() => props.onCompanyModalClick({ action: 'cancel' })}
            >
              Vazgec
            </Button>
            <button type='submit' className='btn btn-primary'>
              <i className='fas fa-save' />
              Kaydet
            </button>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default CompanyModal
