import React from 'react'
import KapamaSettings from './KapamaSettings'
import ParcaSettings from './ParcaSettings'
import Select from '../../Core/Select'
import PervazSettings from './PervazSettings'
import AksamSettings from './AksamSettings'
import AksesuarSettings from './AksesuarSettings'
import KulpModeliSettings from './KulpModeliSettings'
import CekmceSistemiSettings from './CekmceSistemiSettings'
import KapakRenk from './KapakRenk'
import GovdeRenk from './GovdeRenk'
import ParcaTipi from './ParcaTipi'
import DolapAynaModeli from './DolapAynaModeli'
import SurmeKulpVeMekanizma from './SurmeKulpVeMekanizma'
import BandTipi from './BandTipi'
import KapakModel from './kapakModel'

const EditEkstra = (props) => {
  const searchKapakRenk = (e) => {
    if (e !== '') {
      props.loadKapakRenk(e)
    }
  }

  const searchParcaTipi = (e) => {
    if (e !== '') {
      props.loadParcaTipi(e)
    }
  }
  let result = null

  switch (props.settingName) {
    case 'surmeKulpVeMekanizma':
      result = (
        <SurmeKulpVeMekanizma
          onChange={props.onChange}
          index={props.index}
          surmeKulp={props.surmeKulp}
          fiyat={props.fiyat}
          mekanizma={props.mekanizma}
          surmeKapakCalismaPayi={props.surmeKapakCalismaPayi}
          kapakKesimOlcusu={props.kapakKesimOlcusu}
          aynaKesimOlcusu={props.aynaKesimOlcusu}
        />
      )
      break
    case 'dolapAynaModeli':
      result = (
        <DolapAynaModeli
          onChange={props.onChange}
          onSelectChange={props.handleSelectValueChange}
          index={props.index}
          aynaRengi={props.aynaRengi}
          aynaKenari={props.aynaKenari}
          fiyat={props.fiyat}
        />
      )
      break
    case 'duvarDolabiTipi':
      result = (
        <PervazSettings
          onChange={props.onChange}
          onPervazInputChange={props.onPervazInputChange}
          index={props.index}
          pervazlar={props.pervazlar}
        />
      )
      break
    case 'cekmeceSistemi':
      result = (
        <CekmceSistemiSettings
          data={props.data}
          index={props.index}
          onChange={props.onChange}
          fiyat={props.fiyat}
          cekmeceArkasiEnCikar={props.cekmeceArkasiEnCikar}
          cekmeceArkasiBoyCikarBordursuz={props.cekmeceArkasiBoyCikarBordursuz}
          cekmeceArkasiBoyCikar={props.cekmeceArkasiBoyCikar}
          cekmeceTabaniEnCikar={props.cekmeceTabaniEnCikar}
          cekmeceTabaniBoyCikar={props.cekmeceTabaniBoyCikar}
          tabanMelamiKalinligi={props.tabanMelamiKalinligi}
        />
      )
      break
    case 'kulpModeli':
      result = (
        <KulpModeliSettings
          data={props.data}
          index={props.index}
          onChange={props.onChange}
          ebatliKulp={props.ebatliKulp}
          fiyat={props.fiyat}
          kapakBoyuBuyuk={props.kapakBoyuBuyuk}
          kapakBoyuKucuk={props.kapakBoyuKucuk}
        />
      )
      break
    case 'kapama':
      result = (
        <KapamaSettings
          onChange={props.onChange}
          handleSelectValueChange={props.handleSelectValueChange}
          index={props.index}
          boy={props.boy}
          en={props.en}
          bandTipi={props.bandTipi}
          referenceData={props.referenceData}
        />
      )
      break
    case 'parca':
      result = (
        <ParcaSettings
          onChange={props.onChange}
          onSettingInputChange={props.onSettingInputChange}
          index={props.index}
          boyut={props.boyut}
          uzunluk={props.uzunluk}
          genislik={props.genislik}
          tip={props.data[props.index].tip}
          searchParcaTipi={searchParcaTipi}
          lookup={props.lookup}
        />
      )
      break
    case 'kapakRenk':
      result = (
        <KapakRenk
          onChange={props.handleSelectValueChange}
          index={props.index}
          referenceData={props.referenceData}
          bandRengi={props.bandRengi}
        />
      )
      break
    case 'govdeRengi':
      result = (
        <GovdeRenk
          onChange={props.handleSelectValueChange}
          index={props.index}
          referenceData={props.referenceData}
          bandRengi={props.bandRengi}
        />
      )
      break
    case 'bandTipi':
      result = <BandTipi onChange={props.onChange} index={props.index} fiyat={props.fiyat} />
      break
    case 'parcaTipi':
      result = (
        <ParcaTipi
          fiyat={props.fiyat}
          onChange={props.onChange}
          index={props.index}
          grup={props.grup}
          bandTipi={props.bandTipi}
          handleSelectValueChangeSingle={props.handleSelectValueChangeSingle}
          handleSelectValueChange={props.handleSelectValueChange}
          searchKapakRenk={props.searchKapakRenk}
          referenceData={props.referenceData}
        />
      )

      break
    case 'kapakModel':
      result = (
        <KapakModel
          index={props.index}
          searchKapakRenk={searchKapakRenk}
          onInputChange={props.onChange}
          handleSelectValueChange={props.handleSelectValueChangeInArray}
          kapakRenk={props.kapakRenk}
          fiyat={props.fiyat}
          options={props.lookup.result}
        />
      )
      break
    case 'aksam':
      result = (
        <AksamSettings
          data={props.data}
          index={props.index}
          onChange={props.onChange}
          grup={props.grup}
          aksamgrup={props.referenceData.aksamgrup}
          handleSelectValueChange={props.handleSelectValueChange}
        />
      )
      break
    case 'aksesuar':
      result = (
        <AksesuarSettings
          data={props.data}
          index={props.index}
          onChange={props.onChange}
          grup={props.grup}
          aksamgrup={props.referenceData.aksamgrup}
          handleSelectValueChange={props.handleSelectValueChange}
        />
      )
      break
    default:
      result = null
  }

  return result
}

export default EditEkstra
