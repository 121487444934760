import React, { PureComponent } from 'react'
import { Table } from 'reactstrap'
import OrderListItem from './OrderListItem'

class OrderList extends PureComponent {
  render() {
    return (
      <Table>
        <thead>
          <tr className='d-flex'>
            <th scope='col' className='col-sm-4 col-md-4'>
              Müşteri/Müteahhit
            </th>
            <th scope='col' className='col-sm-2 col-md-2'>
              Raporlar
            </th>
            <th scope='col' className='d-none d-sm-none d-md-block col-1'>
              Konut No
            </th>
            <th scope='col' className='col-md-1 col-lg-1'>
              Sicil No
            </th>
            <th scope='col' className='d-none d-sm-none d-md-block col-2'>
              Teslim Tarihi
            </th>
            <th scope='col' className='col-sm-4 col-md-2'>
              #
            </th>
          </tr>
        </thead>
        <tbody>
          {this.props.orders.map((order) => (
            <OrderListItem order={order} key={order.id} onDuplicateOrder={this.props.onDuplicateOrder} onClickCopy={this.props.toggleCloneOrderModal} />
          ))}
        </tbody>
      </Table>
    )
  }
}

export default OrderList
