import React from 'react'
import { Modal } from '../Uretim/Orders/Kitchen'
import Select from '../Core/Select'
import { Button } from 'reactstrap'
import NumberInput from '../Core/NumberInput'
import CheckboxTree from 'react-checkbox-tree'

const ProductModal = (props) => {
  const handleSave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const action = props.productModal.mode === 'edit' ? 'update' : 'create'
    props.onProductModalClick({ action })
  }
  const {
    new: { paymentDetails, checked, expanded, products },
    mode,
    show
  } = props.productModal
  return (
    <Modal modal={show} size='xl' title='Firma Ekle/Degistir'>
      <form onSubmit={handleSave}>
        <div className='row'>
          <div className='form-group col-md-12 col-lg-4'>
            {mode === 'create' && (
              <CheckboxTree
                nodes={products}
                checked={checked}
                expanded={expanded}
                onCheck={(checked) => props.onChange({ target: { name: 'checked', value: checked } })}
                onExpand={(expanded) => props.onChange({ target: { name: 'expanded', value: expanded } })}
                iconsClass='fa5'
              />
            )}
            {mode === 'edit' &&
              products.map((p) => {
                return <strong>{p.description}</strong>
              })}
          </div>
          <div className='form-group col-md-12 col-lg-8'>
            <div className='row'>
              <div className='col-md-6'>
                <Select
                  name='paymentDetails.measure'
                  text='Olcu Birim'
                  value={paymentDetails.measure}
                  onChange={props.onSelectChange}
                  options={props.measures}
                />
              </div>

              <div className='col-md-6'>
                <NumberInput
                  name='paymentDetails.minOrderAmount'
                  label='Min Siparis Adeti'
                  onChange={props.onChange}
                  value={paymentDetails.minOrderAmount}
                  valid={!!paymentDetails.minOrderAmount}
                  invalid={!paymentDetails.minOrderAmount}
                  required
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <Select
                  name='paymentDetails.deliveryType'
                  text='Termin Tarih Birim'
                  value={paymentDetails.deliveryType}
                  onChange={props.onSelectChange}
                  options={props.deliveryTypes}
                />
              </div>
              <div className='col-md-6'>
                <NumberInput
                  name='paymentDetails.deliveryRate'
                  label='Termin Tarihi'
                  value={paymentDetails.deliveryRate}
                  valid={!!paymentDetails.deliveryRate}
                  invalid={!paymentDetails.deliveryRate}
                  onChange={props.onChange}
                  required
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-md-4'>
                <Select
                  name='paymentDetails.paymentType'
                  text='Odeme Sekli'
                  value={paymentDetails.paymentType}
                  onChange={props.onSelectChange}
                  options={props.paymentTypes}
                />
              </div>
              <div className='col-md-2'>
                <Select
                  name='paymentDetails.currency'
                  text='Para birimi'
                  value={paymentDetails.currency}
                  onChange={props.onSelectChange}
                  options={props.currencies}
                />
              </div>
              <div className='col-md-4'>
                <NumberInput
                  label='Fiyat'
                  step='0.001'
                  name='paymentDetails.price'
                  value={paymentDetails.price}
                  onChange={props.onChange}
                  valid={paymentDetails.price >= 0}
                  invalid={!paymentDetails.price}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-12 pt-md-4'>
            <Button
              color='secondary'
              name='cancel-button'
              className='float-start'
              onClick={() => props.onProductModalClick({ action: 'cancel' })}
            >
              Vazgec
            </Button>
            <button type='submit' className='btn btn-primary'>
              <i className='fas fa-save' />
              Kaydet
            </button>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default ProductModal
