import Select from '../../Core/Select'
import React from 'react'
import { parcaTipiGrupOptions } from '../../../shared'
import NumberInput from "../../Core/NumberInput";
const ParcaTipi = (props) => {
  return (
    <>
      <div className='row'>
        <div className='col-md-5 col-sm-12'>
          <Select
            name={'grup_' + props.index}
            text='Parca Grup'
            isMulti={false}
            value={{ id: props.grup, aciklama: props.grup }}
            onChange={props.handleSelectValueChangeSingle}
            options={parcaTipiGrupOptions}
            getOptionValue={(option) => option['id']}
            getOptionLabel={(option) => option['aciklama']}
          />
        </div>
      </div>
      <div className='row mt-3 mb-3'>
        <div className='col-md-3 col-sm-12'>
          <Select
            name={`bandTipi.uzunUst_${props.index}`}
            text='Band Tipi Uzun'
            value={props.bandTipi.uzunUst}
            onChange={props.handleSelectValueChange}
            options={props.referenceData.bandTipi}
            disableRequired={true}
          />
        </div>
        <div className='col-md-3 col-sm-12'>
          <Select
            name={`bandTipi.uzunAlt_${props.index}`}
            text='Band Tipi Uzun'
            value={props.bandTipi.uzunAlt}
            onChange={props.handleSelectValueChange}
            options={props.referenceData.bandTipi}
            disableRequired={true}
          />
        </div>
        <div className='col-md-3 col-sm-12'>
          <Select
            name={`bandTipi.kisaSag_${props.index}`}
            text='Band Tipi Kisa'
            value={props.bandTipi.kisaSag}
            onChange={props.handleSelectValueChange}
            options={props.referenceData.bandTipi}
            disableRequired={true}
          />
        </div>
        <div className='col-md-3 col-sm-12'>
          <Select
            name={`bandTipi.kisaSol_${props.index}`}
            text='Band Tipi Kisa'
            value={props.bandTipi.kisaSol}
            onChange={props.handleSelectValueChange}
            options={props.referenceData.bandTipi}
            disableRequired={true}
          />
        </div>
        {props.grup.startsWith('Arkaliklar') &&
          <NumberInput
            label='Fiyat €'
            step='0.001'
            name='fiyat'
            dataRow={props.index}
            value={props.fiyat}
            onChange={props.onChange}
            required
          />
        }
      </div>
    </>
  )
}

export default ParcaTipi
