export const initMobilyaData = () => ({
  id: null,
  muteahhit: null,
  musteri: null,
  teslimtarihi: '',
  konutno: '',
  sicilno: '',
  ozelistekler: '',
  moduller: []
})
