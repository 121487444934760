import { instance as axios } from '../../axios-api'
import * as actionTypes from './actionTypes'
import { apiPath } from '../../shared'
import { errorHandler } from './errorHandler'

export const fetchCompanies = (token, query = '') => {
  return async (dispatch) => {
    try {
      dispatch(fetchCompaniesStart())
      const response = await axios(token).get(apiPath.companyList(query))
      dispatch(fetchCompaniesSuccess(response.data))
    } catch (error) {
      errorHandler(dispatch, fetchCompaniesFail, error)
    }
  }
}
export const fetchCompaniesStart = () => ({
  type: actionTypes.FETCH_COMPANIES_LIST_START
})
export const fetchCompaniesSuccess = (payload) => ({
  type: actionTypes.FETCH_COMPANIES_LIST_SUCCESS,
  payload
})
export const fetchCompaniesFail = (error) => ({
  type: actionTypes.FETCH_COMPANIES_LIST_FAIL,
  error: error
})

export const getProductTree = (token) => {
  return async (dispatch) => {
    try {
      dispatch(getProductTreeStart())
      const response = await axios(token).get(apiPath.productTree())
      dispatch(getProductTreeSuccess(response.data))
    } catch (error) {
      errorHandler(dispatch, getProductTreeFail, error)
    }
  }
}

export const getProductTreeStart = () => ({
  type: actionTypes.GET_PRODUCT_TREE_START
})
export const getProductTreeSuccess = (payload) => ({
  type: actionTypes.GET_PRODUCT_TREE_SUCCESS,
  payload
})
export const getProductTreeFail = (error) => ({
  type: actionTypes.GET_PRODUCT_TREE_FAIL,
  error: error
})
export const updateProductModalProperty = (payload) => ({
  type: actionTypes.CHANGE_COMPANY_PRODUCT_MODAL_PROPERTY,
  payload
})
export const initProductModal = (token) => {
  return async (dispatch) => {
    try {
      dispatch(actionProductModal({ action: 'new' }))
      dispatch(getProductTreeStart())
      const response = await axios(token).get(apiPath.productTree())
      dispatch(getProductTreeSuccess(response.data))
    } catch (error) {
      errorHandler(dispatch, getProductTreeFail, error)
    }
  }
}
export const actionProductModal = (payload) => ({
  type: actionTypes.TOGGLE_COMPANY_PRODUCT_MODAL,
  payload
})
export const fetchCompany = (token, id) => {
  return async (dispatch) => {
    try {
      dispatch(fetchCompanyStart())
      const response = await axios(token).get(apiPath.company(id))
      dispatch(fetchCompanySuccess(response.data))
    } catch (error) {
      errorHandler(dispatch, fetchCompanyFail, error)
    }
  }
}
export const fetchCompanyStart = () => ({
  type: actionTypes.FETCH_COMPANY_START
})
export const fetchCompanySuccess = (payload) => ({
  type: actionTypes.FETCH_COMPANY_SUCCESS,
  payload
})
export const fetchCompanyFail = (error) => ({
  type: actionTypes.FETCH_COMPANY_FAIL,
  error: error
})

export const fetchCompanyNew = () => ({
  type: actionTypes.FETCH_COMPANY_NEW
})

export const saveCompanyStart = () => ({
  type: actionTypes.SAVE_COMPANY_START
})
export const saveCompanySuccess = (payload) => ({
  type: actionTypes.SAVE_COMPANY_SUCCESS,
  payload
})
export const saveCompanyFail = (error) => ({
  type: actionTypes.SAVE_COMPANY_FAIL,
  error: error
})

export const saveCompany = (token, companyForm) => {
  return async (dispatch) => {
    try {
      dispatch(saveCompanyStart())
      const options = {
        method: companyForm.id ? 'PUT' : 'POST',
        url: companyForm.id ? apiPath.company(companyForm.id) : apiPath.companies(),
        data: companyForm
      }
      const response = await axios(token)(options)
      dispatch(saveCompanySuccess(response.data))
    } catch (error) {
      errorHandler(dispatch, saveCompanyFail, error)
    }
  }
}
export const removeProductFromCompany = (productId) => {
  return {
    type: actionTypes.REMOVE_COMPANY_PRODUCT,
    payload: productId
  }
}

export const updateCompanyProperty = (event) => {
  const { name, value } = event
  return {
    type: actionTypes.UPDATE_COMPANY_PROPERTY,
    payload: { name, value }
  }
}
