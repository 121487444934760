import React, { PureComponent } from 'react'
import Select from '../../../Core/Select'
import { Card, CardHeader, CardBody } from 'reactstrap'
import NumberInput from '../../../Core/NumberInput'
import * as actions from "../../../../store/actions";
import {useDispatch, useSelector} from "react-redux";
const Suluk = (props) => {
    const dispatch = useDispatch()
    const {
      kitchenForm: {
        suluk: {
          item,
          adet,
          kapakadet,
          ickoseadet,
          diskoseadet
        }
      },
      lookup
    } = useSelector((state) => state.orderForm)
  const { token } = useSelector((state) => state.auth)
  const updateMutfakState = (event) => dispatch(actions.updateKitchenOrderProperty(event))
    const updateSelect = (name, value, action) => dispatch(actions.updateMutfakOrderSelect(name, value, false))
    const onLookupSetting = (name, value) => dispatch(actions.searchSetting(token, name, value))
    const onSelectMenuClose = () => dispatch(actions.onSelectMenuClose())
    const handleLookupSetting = (e, name) => {
      if (e !== '') {
        onLookupSetting(name, e)
      }
    }
    return (
      <Card className='shadow mb-2'>
        <CardHeader>Suluk</CardHeader>
        <CardBody>
          <div className='row'>
            <div className='col-lg-6 col-xl-3'>
              <Select
                name='suluk.item'
                text='Suluk'
                value={item}
                onChange={updateSelect}
                options={lookup.result}
                onSelectMenuClose={onSelectMenuClose}
                onInputChange={(e) => handleLookupSetting(e, 'suluk')}
                isLoading={lookup.isLoading}
              />
            </div>
            <div className='col-lg-2'>
              <NumberInput label='Adet' name='suluk.adet' value={adet} onChange={updateMutfakState} />
            </div>
            <div className='col-lg-2'>
              <NumberInput label='Kapak Adet' name='suluk.kapakadet' value={kapakadet} onChange={updateMutfakState} />
            </div>

            <div className='col-lg-2'>
              <NumberInput label='Ic Kose Adet' name='suluk.ickoseadet' value={ickoseadet} onChange={updateMutfakState} />
            </div>
            <div className='col-lg-2'>
              <NumberInput
                label='Dis Kose Adet'
                name='suluk.diskoseadet'
                value={diskoseadet}
                onChange={updateMutfakState}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    )

}

export default Suluk
