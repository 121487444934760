// Kapama
import shortid from "shortid";
import {toast} from "react-toastify";
import {toastOptions,cloneWithMap} from "../../../shared";
import { initLookup } from '../../dataModels/LookupModals'

export const updateKapamaProperty = (state, action ) => {
  const kapamalar = cloneWithMap(state.kitchenForm.kapamalar);
  const kapama = kapamalar.find(x=>x.id === action.payload.id)
  kapama[action.payload.name] = action.payload.value;
  return {
    ...state,
    kitchenForm: {
      ...state.kitchenForm,
      kapamalar
    },
    lookup: initLookup()
  }
}

export const removeKapamaKitchenOrder = ( state, action ) => {
  const kapamalar = state.kitchenForm.kapamalar.filter(k=> k.id !== action.payload.id);
  return {...state, kitchenForm: {...state.kitchenForm, kapamalar} }
}

export const addKapamaKitchenOrder = ( state, action ) => {
  const kapama = {
    id:shortid.generate(),
    kapama: null,
    adet: '',
  };
  toast.success(`Kapama eklendi!`, toastOptions);
  return {...state, kitchenForm: {...state.kitchenForm, kapamalar:[...state.kitchenForm.kapamalar,kapama]} }
}
