import {toast} from "react-toastify";
import {toasterMessages, toastErrorOptions} from "../../../shared";

export const onDownloadReportStart = ( state, action ) => {
  return {...state, ...{
      downloading: true,
      error: null,
    }
  }
}
export const onDownloadReportSuccess = ( state, action ) => {
  const {data, reportName} = action.payload
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${reportName}.xlsx`); //or any other extension
  document.body.appendChild(link);
  link.click();
  return {...state, ...{
      downloading: false,
      error: null,
    }
  }
}
export const onDownloadReportFail = ( state, action ) => {
  toast.error(toasterMessages.errorWithTitle(`Rapor indirilirken`), toastErrorOptions);
  return {...state, ...{
      downloading: false,
      error: action.payload,
    }
  }
}
