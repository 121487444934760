import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Col, Row } from 'reactstrap'
import * as actions from '../../../store/actions'
import CustomerForm from './CustomerForm'
import Loading from '../../Common/Loading'

const CustomerEditContainer = (props) => {
  const dispatch = useDispatch()
  const { loading, error } = useSelector((state) => state.customers)
  const { token } = useSelector((state) => state.auth)

  useEffect(() => {
    props.match.params.id
      ? dispatch(actions.fetchCustomer(token, props.match.params.id))
      : dispatch(actions.fetchCustomerNew({ has_projects: props.has_projects }))
  }, [props.match.params.id, props.has_projects, token, dispatch])

  return (
    <Row>
      {error && (
        <Col sm={12}>
          <Alert color='danger'>Musteri yuklerken hata oluştu — {error}</Alert>
        </Col>
      )}
      {loading ? <Loading /> : <CustomerForm />}
    </Row>
  )
}
export default CustomerEditContainer
