import React from "react";
import { Label,CardHeader,CardBody,Card} from "reactstrap";
import Radio from "../../Core/Radio";
import NumberInput from "../../Core/NumberInput";

const ModulFormGolaDetails = (props) => {
    const {handleInputChange,modul}  = props
    return (<Card className='shadow mb-2'>
                <CardHeader>Gola Detaylari</CardHeader>
                <CardBody>
                    <div className='row'>
                        <div className='mb-3 col-lg-4'>
                            <Label htmlFor='golatip-container'>Gola Tip</Label>
                            <div id='golatip-container' className='btn-group btn-group-toggle d-flex' data-toggle='buttons'>
                                <Radio
                                    checked={modul && modul.golatip === 'golaSag'}
                                    name='golatip'
                                    id='golasag'
                                    value='golaSag'
                                    onChange={handleInputChange}
                                    text='Gola Sag'
                                />
                                <Radio
                                    checked={modul && modul.golatip === 'golaSol'}
                                    name='golatip'
                                    id='golasol'
                                    value='golaSol'
                                    onChange={handleInputChange}
                                    text='Gola Sol'
                                />
                                <Radio
                                    checked={modul && modul.golatip === 'golaSagSol'}
                                    name='golatip'
                                    id='golasagsol'
                                    value='golaSagSol'
                                    onChange={handleInputChange}
                                    text='Gola Sag Sol'
                                />
                            </div>
                        </div>
                        <div className='mb-2 col-lg-2'>
                            <Label htmlFor='golaTekliCiftli-container'>Gola Tekli/Ciftli</Label>
                            <div id='golaTekliCiftli-container' className='btn-group btn-group-toggle d-flex' data-toggle='buttons'>
                                <Radio
                                    checked={modul && modul.golaTekliCiftli === 'golaTekli'}
                                    name='golaTekliCiftli'
                                    id='golaTekli'
                                    value='golaTekli'
                                    onChange={handleInputChange}
                                    text='Gola Tekli'
                                />
                                <Radio
                                    checked={modul && modul.golaTekliCiftli === 'golaCiftli'}
                                    name='golaTekliCiftli'
                                    id='golaCiftli'
                                    value='golaCiftli'
                                    onChange={handleInputChange}
                                    text='Gola Ciftli'
                                />
                            </div>
                        </div>
                        <div className='mb-2 col-lg-2'>
                            <NumberInput
                                min={0}
                                name='golaAdet'
                                label='Gola Adet'
                                value={modul?.golaAdet || 0}
                                onChange={handleInputChange}
                                valid={modul?.golaAdet > 0}
                                required
                            />
                        </div>
                        <div className='mb-2 col-lg-2'>
                            <NumberInput
                                min={0}
                                name='golaParcaUzunlugu'
                                label='Gola Parca Uzunlugu'
                                value={modul?.golaParcaUzunlugu || 204}
                                onChange={handleInputChange}
                                valid={modul?.golaParcaUzunlugu > 0}
                                required
                            />
                        </div>
                    </div>
                </CardBody>
    </Card>)
}
export default ModulFormGolaDetails
