import React, { PureComponent } from 'react'
import TezgahItem from './TezgahItem'
import { Button, Card, CardHeader, CardBody } from 'reactstrap'
import {useDispatch, useSelector} from "react-redux";
import * as actions from "../../../../../store/actions";

const Tezgah = (props) => {
    const dispatch = useDispatch()
    const {
      kitchenForm: { tezgahlar },
      lookup
    } = useSelector((state) => state.orderForm)
    const { token } = useSelector((state) => state.auth)
    const onTezgahAdd = () => dispatch(actions.addTezgah())
    const onTezgahRemove = (id) => dispatch(actions.removeTezgah(id))
    const updateTezgahState = (id, name, value) => dispatch(actions.updateTezgahProperty(id, name, value))
    const onLookupSetting = (name, value) => dispatch(actions.searchSetting(token, name, value))
    const onSelectMenuClose = () => dispatch(actions.onSelectMenuClose())
    const handleInputChangeTezgahState = (event) => {
      const [name, id] = event.currentTarget.name.split('.')
      const value = event.currentTarget.value
      updateTezgahState(id, name, value)
    }
    const handleSelectChangeTezgahState = (nameAndId, selected, event) => {
      const [name, id] = nameAndId.split('.')
      updateTezgahState(id, name, selected)
    }
    const handleLookupSetting = (e, name) => {
      if (e !== '') {
        onLookupSetting(name, e)
      }
    }

    return (
      <Card className='shadow mb-2'>
        <CardHeader>
          Tezgah
          <Button outline color='primary' className='float-end' onClick={onTezgahAdd}>
            <i className='fas fa-plus' />
            Tezgah Ekle
          </Button>
        </CardHeader>
        <CardBody>
          {tezgahlar.map((item, index) => {
            return (
              <TezgahItem
                {...item}
                key={item.id}
                index={index}
                lookupSetting={handleLookupSetting}
                onSelectMenuClose={onSelectMenuClose}
                lookup={lookup}
                onSelectChange={handleSelectChangeTezgahState}
                onInputChange={handleInputChangeTezgahState}
                onTezgahRemoveClick={onTezgahRemove}
              />
            )
          })}
        </CardBody>
      </Card>
    )

}

export default Tezgah
