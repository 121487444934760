import React from 'react'
import * as ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { compose, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import { applyMiddleware } from 'redux'
import { createBrowserHistory } from 'history'
import { routerMiddleware, connectRouter, ConnectedRouter } from 'connected-react-router'
import 'bootstrap/dist/css/bootstrap.css'
import './index.css'
import App from './App'
import {
  authReducer,
  orderReducer,
  orderFormReducer,
  uretimSettingsReducer,
  productGroupsReducer,
  customersReducer,
  usersReducer,
  groupsReducer,
  companiesReducer,
  productsReducer,
  productOrdersReducer
} from './store/reducers'
import * as registerServiceWorker from './registerServiceWorker'
// import DevTools from './DevTools/DevTools'
import {configureStore} from "@reduxjs/toolkit";

const history = createBrowserHistory()

let enhancer = [compose(applyMiddleware(routerMiddleware(history), thunk))]
const isDev = process.env.NODE_ENV === 'development'
if (isDev) {
  enhancer = [
    // Middleware you want to use in development:
    //  require('redux-immutable-state-invariant').default(),
    applyMiddleware(routerMiddleware(history), thunk, logger),
    // Required! Enable Redux DevTools with the monitors you chose
    // DevTools.instrument()
  ]
}

//const rootReducers = combineReducers( {
const rootReducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    order: orderReducer,
    orderForm: orderFormReducer,
    uretimSettings: uretimSettingsReducer,
    customers: customersReducer,
    users: usersReducer,
    groups: groupsReducer,
    companies: companiesReducer,
    productGroups: productGroupsReducer,
    products: productsReducer,
    productOrders: productOrdersReducer
  })

const store = configureStore({
    reducer:rootReducers(history),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
            immutableCheck: false,
        }),
    enhancers: enhancer
})

const root = ReactDOM.createRoot(
    document.getElementById("root")
);
root.render(<Provider store={store}>
    <ConnectedRouter history={history}>
        <App />
    </ConnectedRouter>
</Provider>);

registerServiceWorker.unregister()
