import {toast} from "react-toastify";
import {toasterMessages, toastErrorOptions} from "../../../shared";
import {initLookup} from "../../dataModels/LookupModals";

export const onLookupReset = ( state, action ) => {
  return {...state, lookup: {result: [], isLoading:false}}
}
export const onLookupStart = ( state, action ) => {
  return {...state, lookup: {result: [], isLoading:true}}
}
export const onLookupSuccess = ( state, action ) => {
  return {...state, lookup: {result: action.payload.data, isLoading:false}}
}

export const onLookupFail = ( state, action ) => {
  toast.error(toasterMessages.errorWithMessage(action.payload.error || 'Sistemde bir hata ulustu,lutfen sayfayi yenileyip tekrar deneyin'), toastErrorOptions);
  return {...state, lookup: initLookup()}
}

export const onLookupSelectionStart = ( state, action ) => {
  return {...state, lookup: {...state.lookup, isSelecting:true}}
}
export const onLookupSelectionSuccess = ( state, action ) => {
  return {...state, lookup: {...state.lookup, isSelecting:false}}
}
