import {
  cloneWithMap,
  parcaTipiToUpdateWhenUzunlukChanged,
  groupsToUpdateWhenUzunlukChanged,
  parcaTipiGrupGovdeYan,
  parcaTipiGrupKapaklar,
  parcaTipiGrupVasistasYanTabla
} from '../../../shared'
import { initParcaModal, initParcaModalNew } from '../../dataModels/ParcaModals'
import { initLookup } from '../../dataModels/LookupModals'
import cloneDeep from 'lodash/cloneDeep'
import set from 'lodash/set'

export const updateModulParcaModalNew = (state, action) => {
  const { name, value } = action.payload
  let newData = { ...state.parcaModal.new, [name]: value }
  if (name === 'parca') {
    const { genislik, uzunluk, boyut } = action.payload.value
    newData = { ...newData, genislik, uzunluk, boyut: boyut || 18 }
  }

  return { ...state, ...{ parcaModal: { ...state.parcaModal, new: newData } } }
}
export const updateModulParcaModal = (state, action) => {
  const { index, value, name } = action.payload
  const parcalar = cloneDeep(state.parcaModal.data)
  let notes = ''
  const parca = parcalar[index]
  //TODO: This logic is repeated multiple times
  if (
    name === 'uzunluk' &&
    (parca.tip.grup === parcaTipiGrupVasistasYanTabla || parca.tip.grup === parcaTipiGrupGovdeYan)
  ) {
    const hasVasistasYanTabla = parcalar.some((p) => p.tip.grup === parcaTipiGrupVasistasYanTabla)
    const currentValue = Number(parca[name])
    const nextValue = Number(value)
    const difference = nextValue - currentValue
    notes = `uzunluk olcusu ${value} olarak degistirildi`
    parca[name] = value
    parcalar.forEach((parcaToUpdate, arrayIndex) => {
      if (
        (groupsToUpdateWhenUzunlukChanged.indexOf(parcaToUpdate.tip.grup) > -1 ||
          parcaTipiToUpdateWhenUzunlukChanged.indexOf(parcaToUpdate.tip.aciklama) > -1) &&
        arrayIndex !== index
      ) {
        if (
          hasVasistasYanTabla &&
          parcaTipiGrupKapaklar === parcaToUpdate.tip.grup &&
          Number(parcaToUpdate.miktar) > 1
        ) {
          parcaToUpdate[name] = Number(parcaToUpdate[name]) + difference / Number(parcaToUpdate.miktar)
        } else {
          parcaToUpdate[name] = Number(parcaToUpdate[name]) + difference
        }
      }
    })
  } else {
    set(parca, name, value)
  }
  return { ...state, ...{ parcaModal: { ...state.parcaModal, data: parcalar, notes } } }
}
export const toggleModulParcaModal = (state, action) => {
  switch (action.payload.action) {
    case 'toggleBandlar':
      return { ...state, parcaModal: { ...state.parcaModal, showBand: !state.parcaModal.showBand } }
    case 'cancel':
      return { ...state, ...{ parcaModal: initParcaModal(), lookup: initLookup() } }
    case 'add':
      const cloneData = cloneWithMap(state.parcaModal.data)
      const { parca, genislik, uzunluk, miktar, boyut, isactive } = state.parcaModal.new
      const newParca = { ...parca, genislik, uzunluk, miktar, boyut, isactive }
      cloneData.push(newParca)
      return { ...state, ...{ parcaModal: { ...state.parcaModal, data: cloneData, new: initParcaModalNew() } } }
    case 'delete':
      const newData = cloneWithMap(state.parcaModal.data)
      newData.splice(action.payload.index, 1)
      return { ...state, ...{ parcaModal: { ...state.parcaModal, data: newData } } }
    case 'save':
      const newModuller = cloneDeep(state.kitchenForm.moduller)
      newModuller[state.parcaModal.index].modul.parcalar = cloneWithMap(state.parcaModal.data)
      newModuller[state.parcaModal.index].ozelislem =
        newModuller[state.parcaModal.index].ozelislem + ' ' + state.parcaModal.notes
      return {
        ...state,
        ...{
          parcaModal: initParcaModal(),
          lookup: initLookup(),
          kitchenForm: { ...state.kitchenForm, moduller: newModuller }
        }
      }
    default:
      const parcalar = cloneWithMap(state.kitchenForm.moduller[action.payload.index].modul.parcalar)
      return {
        ...state,
        ...{
          parcaModal: {
            ...state.parcaModal,
            show: !state.parcaModal.show,
            index: action.payload.index,
            data: parcalar
          }
        }
      }
  }
}
