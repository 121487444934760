import React, { useEffect } from 'react'
import { Alert, Col } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../../store/actions'
import Loading from '../../Common/Loading'
import ProductForm from './ProductForm'

const ProductEditContainer = (props) => {
  const dispatch = useDispatch()
  const { loading, error, productForm, lookup, companyModal, referenceData } = useSelector((state) => state.products)
  const { token } = useSelector((state) => state.auth)

  useEffect(() => {
    props.match.params.id
      ? dispatch(actions.fetchProduct(token, props.match.params.id))
      : dispatch(actions.fetchProductNew())
    dispatch(actions.lookupProductGroups(token))
  }, [token, props.match.params.id, dispatch])
  const handleCompanyModalClick = (payload) => dispatch(actions.toggleCompanyModal(payload))
  const handleSave = (e) => {
    e.preventDefault()
    dispatch(actions.saveProduct(token, productForm))
  }
  const handleLookupCompany = (e) => {
    if (e !== '') {
      dispatch(actions.lookupCompany(token, e))
    }
  }
  const handleOnChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
    const name = event.target.name
    dispatch(actions.updateProductProperty({ name, value }))
  }
  const handleSelectChange = (name, selected, event) => {
    dispatch(actions.updateProductProperty({ name, value: selected }))
  }
  const handleCompanyModalSelectChange = (name, selected, event) => {
    dispatch(actions.updateCompanyModalProperty({ name, value: selected }))
  }
  const handleRemoveCompanyFromProduct = (companyId) => {
    dispatch(actions.removeCompanyFromProduct(companyId))
  }
  const handleCompanyModalPropChange = (event) => {
    event.stopPropagation()
    const value = event.currentTarget.value
    const name = event.currentTarget.name
    dispatch(actions.updateCompanyModalProperty({ name, value }))
  }
  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-12'>
          {error && (
            <Col sm={12}>
              <Alert color='danger'>Urun yuklerken hata olustu — {error}</Alert>
            </Col>
          )}
          {loading ? (
            <Loading />
          ) : (
            <ProductForm
              productForm={productForm}
              lookup={lookup}
              lookupCompany={handleLookupCompany}
              companyModal={companyModal}
              referenceData={referenceData}
              onCompanyModalClick={handleCompanyModalClick}
              onRemoveCompany={handleRemoveCompanyFromProduct}
              onCompanyModalPropertyChange={handleCompanyModalPropChange}
              onSave={handleSave}
              onChange={handleOnChange}
              onSelectChange={handleSelectChange}
              onCompanyModalSelectChange={handleCompanyModalSelectChange}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default ProductEditContainer
