import * as actionTypes from './actionTypes'
import { instance as axios } from '../../axios-api'
import { apiPath } from '../../shared'
import { errorHandler } from './errorHandler'

export const fetchAcilanWardrobeOrder = (token, id) => {
  return async (dispatch) => {
    try {
      dispatch(fetchAcilanWardrobeOrderStart())
      const response = await axios(token).get(apiPath.wardrobeOrder(id))
      dispatch(fetchAcilanWardrobeOrderSuccess({ order: response.data }))
    } catch (error) {
      errorHandler(dispatch, fetchAcilanWardrobeOrderFail, error)
    }
  }
}
export const resetWardrobeOrderForm = () => ({
  type: actionTypes.RESET_WARDROBE_ORDER_FORM
})
export const fetchSurmeWardrobeOrder = (token, id) => {
  return async (dispatch) => {
    try {
      dispatch(fetchSurmeWardrobeOrderStart())
      const response = await axios(token).get(apiPath.wardrobeOrder(id))
      dispatch(fetchSurmeWardrobeOrderSuccess({ order: response.data }))
    } catch (error) {
      errorHandler(dispatch, fetchSurmeWardrobeOrderFail, error)
    }
  }
}
export const fetchAcilanWardrobeOrderFail = (error) => {
  if (error.response && error.response.status && error.response.status === 401) {
    return {
      type: actionTypes.AUTH_LOGOUT
    }
  }
  return {
    type: actionTypes.FETCH_ACILAN_WARDROBE_ORDER_FAIL,
    error: error
  }
}
export const fetchAcilanWardrobeOrderStart = () => ({
  type: actionTypes.FETCH_ACILAN_WARDROBE_ORDER_START
})
export const fetchAcilanWardrobeOrderSuccess = (data) => ({
  type: actionTypes.FETCH_ACILAN_WARDROBE_ORDER_SUCCESS,
  data
})
export const fetchSurmeWardrobeOrderFail = (error) => ({
  type: actionTypes.FETCH_SURME_WARDROBE_ORDER_FAIL,
  error: error
})
export const fetchSurmeWardrobeOrderStart = () => ({
  type: actionTypes.FETCH_SURME_WARDROBE_ORDER_START
})
export const fetchSurmeWardrobeOrderSuccess = (data) => ({
  type: actionTypes.FETCH_SURME_WARDROBE_ORDER_SUCCESS,
  data
})
export const saveOrder = (token, data) => {
  return async (dispatch) => {
    try {
      dispatch(saveOrderStart())
      const resp = await axios(token)({
        method: data.id ? 'put' : 'post',
        url: data.id ? apiPath.wardrobeOrder(data.id) : apiPath.wardrobeOrders(),
        data
      })
      dispatch(saveOrderSuccess(resp.data))
    } catch (error) {
      errorHandler(dispatch, saveOrderFail, error)
    }
  }
}
export const saveOrderStart = () => ({
  type: actionTypes.SAVE_WARDROBE_ORDER_START
})
export const saveOrderSuccess = (data) => ({
  type: actionTypes.SAVE_WARDROBE_ORDER_SUCCESS,
  data
})

export const saveOrderFail = (error) => ({
  type: actionTypes.SAVE_WARDROBE_ORDER_FAIL,
  error: error
})

export const updatePervazInputChange = (name, property, value) => ({
  type: actionTypes.UPDATE_WARDROBE_PERVAZ_INPUT,
  payload: { name, property, value }
})
export const updateWardrobeOrderSelect = (field, value, updateModuller) => ({
  type: actionTypes.UPDATE_WARDROBE_ORDER_SELECT,
  payload: { field, value, updateModuller }
})
export const updateWardrobeOrderProperty = ({ field, value }) => ({
  type: actionTypes.UPDATE_WARDROBE_ORDER_PROPERTY,
  payload: { field, value }
})

export const toggleWardrobeModulModal = (payload) => ({
  type: actionTypes.TOGGLE_WARDROBE_MODUL_MODAL,
  payload
})

export const modulModalStateChange = (property, value) => ({
  type: actionTypes.UPDATE_WARDROBE_MODUL_MODAL_PROPERTY,
  payload: { property, value }
})
//AKSESUAR
export const toggleAcilanWardrobeModulAksesuarModal = (payload) => ({
  type: actionTypes.TOGGLE_ACILAN_WARDROBE_MODUL_AKSESUAR_MODAL,
  payload
})
export const updateAcilanWardrobeAksesuarModal = (action) => ({
  type: actionTypes.UPDATE_ACILAN_WARDROBE_MODUL_AKSESUAR_MODAL,
  payload: action
})
export const updateAcilanWardrobeAksesuarModalNew = (name, value) => ({
  type: actionTypes.UPDATE_ACILAN_WARDROBE_MODUL_AKSESUAR_MODAL_NEW,
  payload: { name, value }
})
//AKSAM
export const toggleAcilanWardrobeModulAksamModal = (payload) => ({
  type: actionTypes.TOGGLE_ACILAN_WARDROBE_MODUL_AKSAM_MODAL,
  payload
})
export const updateWardrobeAksamModalNew = (name, value) => ({
  type: actionTypes.UPDATE_WARDROBE_MODUL_AKSAM_MODAL_NEW,
  payload: { name, value }
})
export const updateWardrobeAksamModal = (action) => ({
  type: actionTypes.UPDATE_WARDROBE_MODUL_AKSAM_MODAL,
  payload: action
})

export const updateAcilanWardrobeParcaModal = (action) => ({
  type: actionTypes.UPDATE_ACILAN_WARDROBE_MODUL_PARCA_MODAL,
  payload: action
})

export const updateAcilanWardrobeParcaModalNew = (name, value) => ({
  type: actionTypes.UPDATE_ACILAN_WARDROBE_MODUL_PARCA_MODAL_NEW,
  payload: { name, value }
})
export const toggleAcilanWardrobeModulParcaModal = (payload) => ({
  type: actionTypes.TOGGLE_ACILAN_WARDROBE_MODUL_PARCA_MODAL,
  payload
})

export const removeAcilanWardrobeModul = (index) => ({
  type: actionTypes.REMOVE_MODUL_ACILAN_WARDROBE_ORDER,
  payload: { index }
})

// REPORT DOWNLOAD
export const onDownloadSurmeReport = (token, reportName, id) => {
  return async (dispatch) => {
    try {
      dispatch(onDownloadReportStart())
      const options = {
        url: apiPath.surmeOrderReport(id),
        method: 'GET',
        responseType: 'blob' // important
      }
      const response = await axios(token)(options)
      dispatch(onDownloadReportSuccess(response.data, reportName))
    } catch (error) {
      errorHandler(dispatch, onDownloadReportFail, error)
    }
  }
}

export const onDownloadAcilanReport = (token, reportName, id) => {
  return async (dispatch) => {
    try {
      dispatch(onDownloadReportStart())
      const options = {
        url: apiPath.acilanOrderReport(id),
        method: 'GET',
        responseType: 'blob' // important
      }
      const response = await axios(token)(options)
      dispatch(onDownloadReportSuccess(response.data, reportName))
    } catch (error) {
      errorHandler(dispatch, onDownloadReportFail, error)
    }
  }
}
const onDownloadReportStart = () => ({
  type: actionTypes.ACILAN_WARDROBE_ORDER_DOWNLOAD_REPORT
})
const onDownloadReportSuccess = (data, reportName) => ({
  type: actionTypes.ACILAN_WARDROBE_ORDER_DOWNLOAD_REPORT_SUCCESS,
  payload: { data, reportName }
})
const onDownloadReportFail = (error) => ({
  type: actionTypes.ACILAN_WARDROBE_ORDER_DOWNLOAD_REPORT_FAIL,
  payload: error
})

export const addWardrobeDekorasyon = (order_type) => ({
  type: actionTypes.ADD_WARDROBE_ORDER_DEKORASYON
})

export const removeWardrobeDekorasyon = (id, order_type) => ({
  type: actionTypes.REMOVE_WARDROBE_ORDER_DEKORASYON,
  payload: { id }
})
export const updateWardrobeDekorasyonProperty = (id, name, value) => ({
  type: actionTypes.UPDATE_WARDROBE_ORDER_DEKORASYON_PROPERTY,
  payload: { id, name, value }
})
