import React from 'react'
import { Card, CardBody, CardHeader, Col } from 'reactstrap'
import DeleteConfirmAlert from '../../../../Common/DeleteConfirmAlert'
import NumberInput from '../../../../Core/NumberInput'
import Select from '../../../../Core/Select'

const KapamaItem = (props) => {
  const { item, index, lookup, lookupSetting, onKapamaRemoveClick, onInputChange, onSelectChange, onSelectMenuClose } =
    props
  return (
    <Col lg='12' key={item.id}>
      <Card className='shadow mb-2'>
        <CardHeader>
          Kapama/Lambri {index + 1}{' '}
          <DeleteConfirmAlert
            deleteButtonStyle='btn btn-danger float-end'
            className='p-2'
            onRemove={() => onKapamaRemoveClick(item.id)}
          />
        </CardHeader>
        <CardBody>
          <div className='row'>
            <div className='form-group col-sm-12 col-md-2  col-lg-2'>
              <NumberInput label='Adet' name={`adet.${item.id}`} value={item.adet} onChange={onInputChange} />
            </div>
            <div className='form-group col-sm-12 col-md-5  col-lg-4'>
              <Select
                name={`kapama.${item.id}`}
                value={item.kapama}
                onChange={onSelectChange}
                text='Kapama/Lambri'
                options={lookup.result}
                onInputChange={(e) => lookupSetting(e, 'kapama')}
                onSelectMenuClose={onSelectMenuClose}
                isLoading={lookup.isLoading}
              />
            </div>
            <div className='form-group col-sm-12 col-md-5 col-lg-4'>
              <Select
                name={`kapamaRenk.${item.id}`}
                value={item.kapamaRenk}
                onChange={onSelectChange}
                text='Kapama Renk'
                options={lookup.result}
                onSelectMenuClose={onSelectMenuClose}
                onInputChange={(e) => lookupSetting(e, 'kapakRenk')}
                isLoading={lookup.isLoading}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  )
}

export default KapamaItem
