import cloneDeep from 'lodash/cloneDeep'
import {initMobilyaModulModal, initMobilyaModulModalData} from '../../dataModels/ModulModal'
import { initLookup } from '../../dataModels/LookupModals'
import shortid from 'shortid'
import { toast } from 'react-toastify'
import { toastOptions } from '../../../shared'
import clone from 'lodash/clone'
import set from 'lodash/set'


export const removeModulMobilyaOrder = (state, action) => {
  const modullerCloned = cloneDeep(state.mobilyaForm.moduller)
  modullerCloned.splice(action.payload.index, 1)
  const moduller = modullerCloned.map((m, index) => ({ ...m, siranumarasi: index + 1 }))
  return { ...state, mobilyaForm: { ...state.mobilyaForm, moduller } }
}
export const toggleMobilyaModulModal = (state, action) => {
  switch (action.payload.action) {
    case 'cancel':
      return { ...state, ...{ modulModal: initMobilyaModulModal(), lookup: initLookup() } }
    case 'clone':
      const cloneModul = cloneDeep(state.mobilyaForm.moduller[action.payload.index])
      cloneModul.siranumarasi = Math.max(...state.mobilyaForm.moduller.map((m) => Number(m.siranumarasi))) + 1
      cloneModul.id = shortid.generate()
      toast.success(`Modul kopyalandi!`, toastOptions)
      return {
        ...state,
        ...{
          modulModal: initMobilyaModulModal(),
          lookup: initLookup(),
          mobilyaForm: { ...state.mobilyaForm, moduller: [...state.mobilyaForm.moduller, cloneModul] }
        }
      }
    case 'save':
      const newModuller = cloneDeep(state.mobilyaForm.moduller)
      if (state.modulModal.index === -1) {
        newModuller.push(state.modulModal.data)
      } else {
        newModuller[state.modulModal.index] = state.modulModal.data
      }
      newModuller
        .sort((a, b) => (Number(a.siranumarasi) > Number(b.siranumarasi) ? 1 : -1))
        .forEach((m, index) => {
          if (Number(m.siranumarasi) !== index + 1) {
            m.siranumarasi = index + 1
          }
        })
      return {
        ...state,
        ...{
          modulModal: initMobilyaModulModal(),
          lookup: initLookup(),
          mobilyaForm: { ...state.mobilyaForm, moduller: newModuller }
        }
      }
    case 'new':
      const siranumarasi =state.mobilyaForm.moduller.length + 1
      return {
        ...state,
        ...{
          modulModal: {
            ...state.modulModal,
            show: !state.modulModal.show,
            data: {
              ...initMobilyaModulModalData(),
              siranumarasi,
            }
          }
        }
      }
    default:
      return {
        ...state,
        ...{
          modulModal: {
            show: !state.modulModal.show,
            data: state.mobilyaForm.moduller[action.payload.index],
            index: action.payload.index
          }
        }
      }
  }
}
export const updateMobilyaModulModalDataProperty = (state, action) => {
  const { value, property } = action.payload
  const modulItem = cloneDeep(state.modulModal.data)

  set(modulItem, property, value)
  return {
    ...state,
    modulModal: {
      ...state.modulModal,
      data: modulItem
    }
  }
}
