import React from 'react'
import { Button } from 'reactstrap'
import { Modal } from '../../Uretim/Orders/Kitchen'
import Select from '../../Core/Select'
import NumberInput from '../../Core/NumberInput'

const ModulAksamForm = (props) => {
  const handleSave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    props.save()
  }

  const handleChange = (action) => {
    action.preventDefault()
    action.stopPropagation()
    props.newOnChange(action.target.name, action.target.value)
  }
  const {
    new: { miktar, aksam },
    show,
    index,
    data
  } = props.aksamModal

  return (
    <Modal modal={show} index={index} size='xl' title='Aksam Ekle/Degistir'>
      <div className='row'>
        <div className='form-group col-sm-4 col-md-2'>
          <NumberInput label='Miktar' name='miktar' value={miktar} onChange={handleChange} />
        </div>

        <div className='form-group col-sm-5'>
          <Select
            name='aksam'
            value={aksam}
            onChange={props.newOnChange}
            text='Aksam'
            options={props.lookup.result}
            onInputChange={(e) => props.lookupSetting(e, 'aksam')}
            onSelectMenuClose={props.onSelectMenuClose}
            isLoading={props.lookup.isLoading}
          />
        </div>
        <div className='form-group col-sm-1'>
          <label htmlFor='btn_ekle_aksam_modal sr-only'>Ekle</label>
          <Button color='success' disabled={!(aksam && miktar > 0)} name='btn_ekle_aksam_modal' onClick={props.onAdd}>
            Ekle
          </Button>
        </div>
      </div>
      <form onSubmit={handleSave}>
        <h5>Aksamlar:</h5>
        <div className='container'>
          <div className='row'>
            <div className='col-3'>
              <strong>Miktar</strong>
            </div>
            <div className='col-6'>
              <strong>Aksam</strong>
            </div>
            <div className='col-3'>
              <strong>#</strong>
            </div>
          </div>
          {data.map((item, index) => {
            return (
              <div className='row' key={`${item.id}-${index}`}>
                <div className='form-group col-sm-3'>
                  <NumberInput
                    hideLabel={true}
                    name='miktar'
                    id={`miktar.${index}`}
                    dataRow={index}
                    value={item.miktar}
                    valid={item.miktar > 0}
                    invalid={item.miktar <= 0}
                    min={0.1}
                    required
                    onChange={props.onChange}
                  />
                </div>
                <div className='form-group col-sm-6'>
                  <div name='aksam'>{item.aciklama}</div>
                  <small className='text-muted'>
                    {item.grup.description} - {item.tip}
                  </small>
                </div>
                <div className='form-group col-sm-3'>
                  <Button color='danger' id={index} onClick={() => props.onRemove({ action: 'delete', id: item.id })}>
                    Sil
                  </Button>
                </div>
              </div>
            )
          })}
        </div>
        <Button color='secondary' className='float-start' onClick={props.cancel}>
          Vazgec
        </Button>
        <button type='submit' className='btn btn-primary'>
          <i className='fas fa-save' />
          Kaydet
        </button>{' '}
      </form>
    </Modal>
  )
}

export default ModulAksamForm
