import React from 'react'
import { Card, CardBody, CardHeader } from 'reactstrap'
import Select from '../../../Core/Select'
import PervazItem from './PervazItem'
import {
  ALT_CERCEVE,
  UST_CERCEVE,
  SAG_CERCEVE,
  SOL_CERCEVE,
  ALT_PERVAZ,
  UST_PERVAZ,
  SAG_PERVAZ,
  SOL_PERVAZ,
  SAG_GORUNEN_YAN,
  SOL_GORUNEN_YAN
} from '../../../../shared'

const getPervaz = (listOfComponents) =>
  listOfComponents.filter(
    (i) =>
      i.isActive &&
      (i.name === ALT_PERVAZ ||
        i.name === UST_PERVAZ ||
        i.name === SAG_GORUNEN_YAN ||
        i.name === SOL_GORUNEN_YAN ||
        i.name === SOL_PERVAZ ||
        i.name === SAG_PERVAZ)
  )
const getCerceve = (listOfComponents) =>
  listOfComponents.filter(
    (i) =>
      i.isActive &&
      (i.name === ALT_CERCEVE || i.name === UST_CERCEVE || i.name === SAG_CERCEVE || i.name === SOL_CERCEVE)
  )

const DolapTipi = (props) => {
  return (
    <Card className='shadow mb-2'>
      <CardHeader>Dolap Tipi</CardHeader>
      <CardBody>
        <div className='form-group row'>
          <div className='form-group col-sm-12 col-md-6 col-lg-4'>
            <Select
              name='tipi'
              value={props.tipi}
              onChange={props.updateSelect}
              text='Tipi'
              options={props.lookup.result}
              onInputChange={(e) => props.lookupSetting(e, 'duvarDolabiTipi')}
              onSelectMenuClose={props.onSelectMenuClose}
              isLoading={props.lookup.isLoading}
            />
          </div>
        </div>
        {props.tipi && (
          <>
            <div className='form-group row'>
              {getPervaz(props.tipi.pervazlar).map((p) => (
                <PervazItem key={p.name} {...p} showExtraProps={false} onChange={props.handlePervazInputChange} />
              ))}
            </div>
            <div className='form-group row'>
              {getCerceve(props.tipi.pervazlar).map((p) => (
                <PervazItem key={p.name} {...p} showExtraProps={false} onChange={props.handlePervazInputChange} />
              ))}
            </div>
          </>
        )}
      </CardBody>
    </Card>
  )
}

export default DolapTipi
