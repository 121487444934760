import {cloneWithMap} from "../../../shared";
import {initAksamModal, initAksamModalNew} from "../../dataModels/AksamModal";
import {initLookup} from "../../dataModels/LookupModals";
import cloneDeep from "lodash/cloneDeep";

export const updateMobilyaModulAksamModalNew = (state, action ) => {
  const {value,name} = action.payload
  const newData =  {...state.aksamModal.new, [name]: value};
  newData[action.payload.name] = action.payload.value;
  return { ...state, ...{ aksamModal: {...state.aksamModal, new: newData} } }
}
export const updateMobilyaModulAksamModal = ( state, action ) => {
  const {value,name,row} = action.payload
  const resultedData =  cloneWithMap(state.aksamModal.data);
  resultedData[row][name] = value;
  return { ...state, ...{ aksamModal: {...state.aksamModal, data: resultedData} } }
}

export const toggleMobilyaModulAksamModal = ( state, action ) => {
  switch(action.payload.action) {
    case 'cancel':
      return { ...state, ...{ aksamModal: initAksamModal(),lookup: initLookup() } }
    case 'add':
      const cloneData = cloneWithMap(state.aksamModal.data);
      const newAksam = {...state.aksamModal.new.aksam, miktar:state.aksamModal.new.miktar};
      cloneData.push(newAksam);
      return { ...state, ...{ aksamModal: {...state.aksamModal, data: cloneData, new: initAksamModalNew()} } }
    case 'delete':
      const newData = state.aksamModal.data.filter((x) => x.id !== action.payload.id);
      return { ...state, ...{ aksamModal: {...state.aksamModal, data: newData} } }
    case 'save':
      const newModuller = cloneDeep(state.mobilyaForm.moduller);
      newModuller[state.aksamModal.index].modul.aksamlar = cloneWithMap(state.aksamModal.data);
      return {
        ...state, ...{
          aksamModal: initAksamModal(),
          lookup: initLookup(),
          mobilyaForm: {...state.mobilyaForm, moduller: newModuller}
        }
      }
    default:
      const aksamlar = cloneWithMap(state.mobilyaForm.moduller[action.payload.index].modul.aksamlar);
      return {
        ...state, ...{
          aksamModal: {
            ...state.aksamModal,
            show: !state.aksamModal.show,
            index: action.payload.index,
            data: aksamlar
          }
        }
      }
  }
}
