import React from 'react'
import { Button } from 'reactstrap'
import Modal from '../../Common/Modal'
import DeleteConfirmAlert from '../../Common/DeleteConfirmAlert'
import Loading from '../../Common/Loading'
import SettingEdit from './SettingEdit'
import ModulParcaForm from '../../Common/Modul/ModulParcaForm'
import { ModulAksamForm, ModulAksesuarForm } from '../Orders/Kitchen'
import Aux from '../../hoc/Aux'

const Setting = (props) => {
  const { id, name, data } = props.item
  const showParcaAksamAksesuar = name === 'modul' || name === 'mobilya'
  const showRenk = name === 'mobilya'

  const handleAddParca = (e) => {
    props.onModulParcaClick({ action: 'add' })
  }
  const handleSaveParca = (e) => {
    props.onModulParcaClick({ action: 'save',name, data: props.parcaModal.data, modulId: props.parcaModal.modulId })
  }
  const handleCancelParca = (e) => {
    props.onModulParcaClick({ action: 'cancel' })
  }
  const handleDeleteParca = (params) => {
    props.onModulParcaClick(params)
  }

  const handleAddAksam = (e) => {
    props.onModulAksamClick({ action: 'add' })
  }
  const handleSaveAksam = (e) => {
    props.onModulAksamClick({ action: 'save', name, data: props.aksamModal.data, modulId: props.aksamModal.modulId })
  }
  const handleCancelAksam = (e) => {
    props.onModulAksamClick({ action: 'cancel' })
  }
  const handleCancelAksesuar = (e) => {
    props.onModulAksesuarClick({ action: 'cancel' })
  }
  const handleSaveAksesuar = (e) => {
    props.onModulAksesuarClick({ action: 'save', name, data: props.aksesuarModal.data, modulId: props.aksesuarModal.modulId })
  }
  const handleAddAksesuar = (e) => {
    props.onModulAksesuarClick({ action: 'add' })
  }
  const handleParcaModalOnChange = (index) => (name, value) => {
    props.parcaModalOnChange({ name, index: Number(index), value })
  }
  return (
    <div key={id} className='container-fluid'>
      {showParcaAksamAksesuar && (
        <>
          <ModulParcaForm
            parcaModal={props.parcaModal}
            onChange={handleParcaModalOnChange}
            onRemove={handleDeleteParca}
            onAdd={handleAddParca}
            save={handleSaveParca}
            cancel={handleCancelParca}
            lookupSetting={props.onLoadSetting}
            onSelectMenuClose={props.onSelectMenuClose}
            lookup={props.lookup}
            newOnChange={props.parcaModalNewOnChange}
            enableEditParcaBandlar={false}
            showRenk={showRenk}
          />
          <ModulAksamForm
            aksamModal={props.aksamModal}
            onChange={props.aksamModalOnChange}
            onRemove={props.onModulAksamClick}
            onAdd={handleAddAksam}
            save={handleSaveAksam}
            cancel={handleCancelAksam}
            lookupSetting={props.onLoadSetting}
            onSelectMenuClose={props.onSelectMenuClose}
            lookup={props.lookup}
            newOnChange={props.aksamModalNewOnChange}
          />
          <ModulAksesuarForm
            aksesuarModal={props.aksesuarModal}
            onChange={props.aksesuarModalOnChange}
            onRemove={props.onModulAksesuarClick}
            onAdd={handleAddAksesuar}
            save={handleSaveAksesuar}
            cancel={handleCancelAksesuar}
            lookupSetting={props.onLoadSetting}
            onSelectMenuClose={props.onSelectMenuClose}
            lookup={props.lookup}
            newOnChange={props.aksesuarModalNewOnChange}
          />
        </>
      )}
      <div className='row'>
        <div className='col-6 py-1 px-lg-3 border bg-light'>
          <strong>Aciklama</strong>
        </div>
        <div className='col-1 py-1 px-lg-3 border bg-light'>
          <strong>Aktif</strong>
        </div>
        <div className='col-5 py-1 px-lg-3 border bg-light'>
          <strong>#</strong>
        </div>

        {data &&
          data.map((item_data, index) => {
            return (
              <Aux key={item_data.id}>
                <div className='col-6 py-1 px-lg-3 border'>{item_data.aciklama}</div>
                <div className='col-1 py-1 px-lg-3 border'>{item_data.isactive ? 'Aktif' : 'Pasif'}</div>
                <div className='col-5 py-1 px-lg-3 border'>
                  {name !== 'aksamgrup' && (
                    <>
                      <DeleteConfirmAlert
                        deleteButtonStyle='btn btn-danger float-end'
                        onRemove={() => props.onRemove(item_data.id, index)}
                      />
                      <Button
                        outline
                        className='float-end'
                        color='primary'
                        onClick={() => {
                          props.toggleEditModal(index)
                        }}
                      >
                        Degistir
                      </Button>
                    </>
                  )}
                  {(showParcaAksamAksesuar) && (
                    <>
                      <Button
                        outline
                        className='float-end'
                        color='primary'
                        onClick={props.onModulParcaClick.bind(this, { index, name })}
                      >
                        Parcalar
                      </Button>
                      <Button
                        outline
                        className='float-end'
                        color='primary'
                        onClick={props.onModulAksamClick.bind(this, { index, name })}
                      >
                        Aksamlar
                      </Button>
                      <Button
                        outline
                        className='float-end'
                        color='primary'
                        onClick={props.onModulAksesuarClick.bind(this, { index, name })}
                      >
                        Aksesuarlar
                      </Button>
                    </>
                  )}
                </div>
              </Aux>
            )
          })}
      </div>
      <Modal modal={props.editModal.show} index={props.editModal.index} size='xl'>
        {props.editModal.loading ? (
          <Loading />
        ) : (
          <SettingEdit
            {...props}
            data={props.item.data}
            index={props.editModal.index}
            settingName={props.item.name}
            loadKapakRenk={props.loadKapakRenk}
          />
        )}
      </Modal>
    </div>
  )
}

export default Setting
