import React from 'react'
import { Button, Card, CardBody, CardHeader, ListGroup, ListGroupItem, Table, UncontrolledCollapse } from 'reactstrap'
import DeleteConfirmAlert from '../../Common/DeleteConfirmAlert'

const CompanyFormProducts = (props) => {
  const { products } = props.companyForm
  return (
    <Card className='shadow mb-2'>
      <CardHeader>
        <strong>Tedarik edilen Urunler</strong>
        <Button className='float-end' outline color='primary' onClick={() => props.initProductModal('create')}>
          <i className='fas fa-plus' />
          Urun Ekle
        </Button>
      </CardHeader>
      <CardBody>
        <div className='form-group row'>
          <Table size='sm'>
            <thead>
              <tr>
                <th scope='col'>Urun</th>
                <th scope='col'>Min Siparis Adeti</th>
                <th scope='col'>Odeme Sekli</th>
                <th scope='col'>Termin Tarihi</th>
                <th scope='col' className='w-25'>
                  Fiyat
                </th>
                <th scope='col'>#</th>
              </tr>
            </thead>
            <tbody>
              {products.map((p) => {
                const showPriceHistory = p.paymentDetails.priceHistory && p.paymentDetails.priceHistory.length > 0
                return (
                  <tr key={p.id}>
                    <td>{p.description}</td>
                    <td>
                      {p.paymentDetails.minOrderAmount} {p.paymentDetails.measure.aciklama}
                    </td>
                    <td>{p.paymentDetails.paymentType.aciklama}</td>
                    <td>
                      {p.paymentDetails.deliveryRate} {p.paymentDetails.deliveryType.aciklama}{' '}
                    </td>
                    <td>
                      {showPriceHistory && (
                        <Button color='secondary' size='sm' id={`toggler${p.id}`}>
                          <i className='fas fa-caret-down' />
                        </Button>
                      )}
                      {p.paymentDetails.currency.aciklama} {p.paymentDetails.price}
                      {showPriceHistory && (
                        <UncontrolledCollapse toggler={`#toggler${p.id}`}>
                          <ListGroup>
                            {p.paymentDetails.priceHistory.map((ph) => {
                              return (
                                <ListGroupItem key={ph.date}>
                                  <strong>{ph.date.split('T')[0]}</strong>: {ph.currency.aciklama} {ph.price}
                                </ListGroupItem>
                              )
                            })}
                          </ListGroup>
                        </UncontrolledCollapse>
                      )}
                    </td>
                    <td>
                      <Button
                        color='primary'
                        onClick={() => props.onProductModalClick({ action: 'edit', productId: p.id })}
                      >
                        Degistir
                      </Button>
                      <DeleteConfirmAlert onRemove={() => props.onRemoveProduct(p.id)} />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  )
}

export default CompanyFormProducts
