import * as actionTypes from '../actions/actionTypes';
import { initParcaModal } from '../dataModels/ParcaModals'
import { initAksesuarModal } from '../dataModels/AksesuarModal'
import { initAksamModal } from '../dataModels/AksamModal'
import { initLookup } from '../dataModels/LookupModals'
import { initKitchenData } from '../dataModels/KitchenModals'
import { initMobilyaData } from '../dataModels/MobilyaModals'
import { initAcilanData } from '../dataModels/AcilanAndSurmeModal'
import {initModulModal} from '../dataModels/ModulModal'
import {addKapamaKitchenOrder,updateKapamaProperty,removeKapamaKitchenOrder} from './orderFormReducerFunctions/kapamaReducers'
import {addTezgahKitchenOrder,removeTezgahKitchenOrder,updateTezgahProperty} from './orderFormReducerFunctions/tezgahReducers'
import {toggleModulAksesuarModal,updateModulAksesuarModal,updateModulAksesuarModalNew} from './orderFormReducerFunctions/kitchenOrderAksesuarModalReducers'
import {addDekorasyonWardrobeOrder,removeDekorasyonWardrobeOrder,updateDekorasyonWardrobeProperty} from './orderFormReducerFunctions/wardrobeOrderDekorasyonReducers'
import {addDekorasyonKitchenOrder,removeDekorasyonKitchenOrder,updateDekorasyonKitchenProperty} from './orderFormReducerFunctions/kitchenOrderDekorasyonReducers'
import {onLookupFail,onLookupReset,onLookupSelectionStart,onLookupSelectionSuccess,onLookupStart,onLookupSuccess} from './orderFormReducerFunctions/lookupReducers'
import {onDownloadReportFail,onDownloadReportStart,onDownloadReportSuccess} from './orderFormReducerFunctions/reportReducers'
import {toggleModulAksamModal,updateKitchenModulAksamModalNew,updateModulAksamModal} from './orderFormReducerFunctions/kitchenOrderAksamModalReducers'
import {toggleModulParcaModal,updateModulParcaModal,updateModulParcaModalNew} from './orderFormReducerFunctions/kitchenOrderParcaModalReducers'
import {toggleWardrobeModulAksamModal,updateWardrobeModulAksamModal,updateWardrobeModulAksamModalNew} from './orderFormReducerFunctions/wardrobeOrderAksamModalReducers'
import {toggleWardrobeModulAksesuarModal,updateWardrobeModulAksesuarModal,updateWardrobeModulAksesuarModalNew} from './orderFormReducerFunctions/wardrobeOrderAksesuarModalReducers'
import {updateKitchenOrderProperty,resetKitchenOrderForm,fetchKitchenOrderFail,fetchKitchenOrderStart,fetchKitchenOrderSuccess,saveKitchenOrderFail,saveKitchenOrderStart,saveKitchenOrderSuccess} from './orderFormReducerFunctions/kitchenOrderReducers'
import {fetchAcilanWardrobeOrderFail,fetchAcilanWardrobeOrderStart,fetchAcilanWardrobeOrderSuccess} from './orderFormReducerFunctions/acilanWardrobeOrderReducers'
import {fetchSurmeWardrobeOrderFail,fetchSurmeWardrobeOrderStart,fetchSurmeWardrobeOrderSuccess} from './orderFormReducerFunctions/surmeWardrobeOrderReducers'
import {resetWardrobeOrderForm,saveWardrobeOrderFail,saveWardrobeOrderStart,saveWardrobeOrderSuccess,updateWardrobeOrderArrayProperty,updateWardrobeOrderProperty} from './orderFormReducerFunctions/wardrobeOrderReducers'
import {removeModulKitchenOrder,toggleKitchenModulModal,updateModulModalDataProperty} from './orderFormReducerFunctions/kitchenOrderModulModalReducers'
import {removeModulOrder,toggleWardrobeModulModal,updateWardrobeModulModalProperty} from './orderFormReducerFunctions/wardrobeOrderModulModalReducers'
import {toggleWardrobeModulParcaModal,updateWardrobeModulParcaModalNew} from './orderFormReducerFunctions/wardrobeOrderParcaModalReducers'
import {updatePervazInputChange} from './orderFormReducerFunctions/pervazReducers'
import {
  updateMobilyaOrderProperty,
  resetMobilyaOrderForm,
  fetchMobilyaOrderSuccess,
  fetchMobilyaOrderFail,
  fetchMobilyaOrderStart,
  saveMobilyaOrderStart,
  saveMobilyaOrderFail,
  saveMobilyaOrderSuccess
} from "./orderFormReducerFunctions/mobilyaOrderReducers";
import {updateMobilyaModulParcaModalNew, toggleMobilyaModulParcaModal, updateMobilyaModulParcaModal} from "./orderFormReducerFunctions/mobilyaOrderParcaModalReducers";
import {toggleMobilyaModulAksamModal, updateMobilyaModulAksamModalNew, updateMobilyaModulAksamModal} from "./orderFormReducerFunctions/mobilyaOrderAksamModalReducers";
import {toggleMobilyaModulAksesuarModal, updateMobilyaModulAksesuarModalNew, updateMobilyaModulAksesuarModal} from "./orderFormReducerFunctions/mobilyaOrderAksesuarModalReducers";
import {updateMobilyaModulModalDataProperty, removeModulMobilyaOrder, toggleMobilyaModulModal} from "./orderFormReducerFunctions/mobilyaOrderModulModalReducers";

const initialState = {
  modulModal: initModulModal('kitchen'),
  parcaModal: initParcaModal(),
  aksamModal: initAksamModal(),
  aksesuarModal: initAksesuarModal(),
  error:null,
  lookup:initLookup(),
  loading:false,
  saving:false,
  mobilyaForm: initMobilyaData(),
  kitchenForm: initKitchenData(),
  wardrobeForm:  {...initAcilanData(),type:''},
  downloading: false
};


export const orderFormReducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    case actionTypes.RESET_KITCHEN_ORDER_FORM: return resetKitchenOrderForm( state, action);
    case actionTypes.RESET_WARDROBE_ORDER_FORM: return resetWardrobeOrderForm( state, action);
    case actionTypes.RESET_MOBILYA_ORDER_FORM: return resetMobilyaOrderForm( state, action);


    case actionTypes.ADD_KITCHEN_ORDER_DEKORASYON:   return addDekorasyonKitchenOrder( state, action);
    case actionTypes.REMOVE_KITCHEN_ORDER_DEKORASYON:   return removeDekorasyonKitchenOrder( state, action);
    case actionTypes.UPDATE_KITCHEN_ORDER_DEKORASYON_PROPERTY:   return updateDekorasyonKitchenProperty( state, action);

    case actionTypes.ADD_WARDROBE_ORDER_DEKORASYON:   return addDekorasyonWardrobeOrder( state, action);
    case actionTypes.REMOVE_WARDROBE_ORDER_DEKORASYON:   return removeDekorasyonWardrobeOrder( state, action);
    case actionTypes.UPDATE_WARDROBE_ORDER_DEKORASYON_PROPERTY:   return updateDekorasyonWardrobeProperty( state, action);

    case actionTypes.ADD_TEZGAH_KITCHEN_ORDER:   return addTezgahKitchenOrder( state, action);
    case actionTypes.REMOVE_TEZGAH_KITCHEN_ORDER:   return removeTezgahKitchenOrder( state, action);
    case actionTypes.UPDATE_KITCHEN_ORDER_TEZGAH_PROPERTY:   return updateTezgahProperty( state, action);

    case actionTypes.UPDATE_KITCHEN_ORDER_SELECT:   return updateKitchenOrderProperty( state, action);
    case actionTypes.UPDATE_KITCHEN_ORDER_MODUL_MODAL_DATA_CHANGE:   return updateModulModalDataProperty( state, action);

    case actionTypes.ADD_KAPAMA_KITCHEN_ORDER:   return addKapamaKitchenOrder( state, action);
    case actionTypes.REMOVE_KAPAMA_KITCHEN_ORDER:   return removeKapamaKitchenOrder( state, action);
    case actionTypes.UPDATE_KITCHEN_ORDER_KAPAMA_PROPERTY:   return updateKapamaProperty( state, action);

    case actionTypes.UPDATE_KITCHEN_ORDER_PROPERTY:   return updateKitchenOrderProperty( state, action);

    case actionTypes.FETCH_KITCHEN_ORDER_SUCCESS:   return fetchKitchenOrderSuccess( state, action);
    case actionTypes.FETCH_KITCHEN_ORDER_FAIL:   return fetchKitchenOrderFail( state, action);
    case actionTypes.FETCH_KITCHEN_ORDER_START:   return fetchKitchenOrderStart( state, action);

    case actionTypes.FETCH_MOBILYA_ORDER_SUCCESS:   return fetchMobilyaOrderSuccess( state, action);
    case actionTypes.FETCH_MOBILYA_ORDER_FAIL:   return fetchMobilyaOrderFail( state, action);
    case actionTypes.FETCH_MOBILYA_ORDER_START:   return fetchMobilyaOrderStart( state, action);

    case actionTypes.SAVE_MOBILYA_ORDER_SUCCESS:   return saveMobilyaOrderSuccess( state, action);
    case actionTypes.SAVE_MOBILYA_ORDER_FAIL:   return saveMobilyaOrderFail( state, action);
    case actionTypes.SAVE_MOBILYA_ORDER_START:   return saveMobilyaOrderStart( state, action);

    case actionTypes.SAVE_KITCHEN_ORDER_START:   return saveKitchenOrderStart(state, action);
    case actionTypes.SAVE_KITCHEN_ORDER_SUCCESS:   return saveKitchenOrderSuccess( state, action);
    case actionTypes.SAVE_KITCHEN_ORDER_FAIL:   return saveKitchenOrderFail( state, action);

    case actionTypes.TOGGLE_KITCHEN_MODUL_AKSESUAR_MODAL:   return toggleModulAksesuarModal( state, action);
    case actionTypes.UPDATE_KITCHEN_MODUL_AKSESUAR_MODAL:   return updateModulAksesuarModal( state, action);
    case actionTypes.UPDATE_KITCHEN_MODUL_AKSESUAR_MODAL_NEW:   return updateModulAksesuarModalNew( state, action);

    case actionTypes.TOGGLE_KITCHEN_MODUL_AKSAM_MODAL:   return toggleModulAksamModal( state, action);
    case actionTypes.UPDATE_KITCHEN_MODUL_AKSAM_MODAL:   return updateModulAksamModal( state, action);
    case actionTypes.UPDATE_KITCHEN_MODUL_AKSAM_MODAL_NEW:   return updateKitchenModulAksamModalNew( state, action);

    case actionTypes.TOGGLE_KITCHEN_MODUL_PARCA_MODAL:   return toggleModulParcaModal( state, action);
    case actionTypes.UPDATE_KITCHEN_MODUL_PARCA_MODAL:   return updateModulParcaModal( state, action);
    case actionTypes.UPDATE_KITCHEN_MODUL_PARCA_MODAL_NEW:   return updateModulParcaModalNew( state, action);

    case actionTypes.TOGGLE_KITCHEN_MODUL_MODAL:   return toggleKitchenModulModal( state, action);
    case actionTypes.REMOVE_MODUL_KITCHEN_ORDER: return removeModulKitchenOrder( state, action);

    case actionTypes.SEARCH_LOOKUP_RESET: return onLookupReset(state, action);
    case actionTypes.SEARCH_LOOKUP_START:   return onLookupStart(state, action);
    case actionTypes.SEARCH_LOOKUP_SUCCESS:   return onLookupSuccess(state, action);
    case actionTypes.SEARCH_LOOKUP_FAIL:   return onLookupFail(state, action);
    case actionTypes.SEARCH_LOOKUP_SELECTION_SUCCESS:   return onLookupSelectionSuccess(state, action);
    case actionTypes.SEARCH_LOOKUP_SELECTION_START:   return onLookupSelectionStart(state, action);

    case actionTypes.ACILAN_WARDROBE_ORDER_DOWNLOAD_REPORT:
    case actionTypes.KITCHEN_ORDER_DOWNLOAD_REPORT:
    case actionTypes.MOBILYA_ORDER_DOWNLOAD_REPORT:
      return onDownloadReportStart(state, action);
    case actionTypes.ACILAN_WARDROBE_ORDER_DOWNLOAD_REPORT_SUCCESS:
    case actionTypes.KITCHEN_ORDER_DOWNLOAD_REPORT_SUCCESS:
    case actionTypes.MOBILYA_ORDER_DOWNLOAD_REPORT_SUCCESS:
      return onDownloadReportSuccess(state, action);
    case actionTypes.KITCHEN_ORDER_DOWNLOAD_REPORT_FAIL:
    case actionTypes.ACILAN_WARDROBE_ORDER_DOWNLOAD_REPORT_FAIL:
    case actionTypes.MOBILYA_ORDER_DOWNLOAD_REPORT_FAIL:
      return onDownloadReportFail(state, action);

    case actionTypes.FETCH_ACILAN_WARDROBE_ORDER_SUCCESS: return fetchAcilanWardrobeOrderSuccess(state, action);
    case actionTypes.FETCH_ACILAN_WARDROBE_ORDER_FAIL: return fetchAcilanWardrobeOrderFail(state, action);
    case actionTypes.FETCH_ACILAN_WARDROBE_ORDER_START: return fetchAcilanWardrobeOrderStart(state, action);

    case actionTypes.FETCH_SURME_WARDROBE_ORDER_SUCCESS: return fetchSurmeWardrobeOrderSuccess(state, action);
    case actionTypes.FETCH_SURME_WARDROBE_ORDER_FAIL: return fetchSurmeWardrobeOrderFail(state, action);
    case actionTypes.FETCH_SURME_WARDROBE_ORDER_START: return fetchSurmeWardrobeOrderStart(state, action);
    case actionTypes.SAVE_WARDROBE_ORDER_START: return saveWardrobeOrderStart(state, action);
    case actionTypes.SAVE_WARDROBE_ORDER_SUCCESS: return saveWardrobeOrderSuccess(state, action);
    case actionTypes.SAVE_WARDROBE_ORDER_FAIL: return saveWardrobeOrderFail(state, action);

    case actionTypes.UPDATE_WARDROBE_PERVAZ_INPUT: return updatePervazInputChange(state, action);
    case actionTypes.UPDATE_WARDROBE_ORDER_SELECT: return updateWardrobeOrderProperty(state, action);
    case actionTypes.UPDATE_WARDROBE_ORDER_PROPERTY: return updateWardrobeOrderProperty(state, action);
    case actionTypes.TOGGLE_WARDROBE_MODUL_MODAL: return toggleWardrobeModulModal(state, action);
    case actionTypes.UPDATE_WARDROBE_MODUL_MODAL_PROPERTY: return updateWardrobeModulModalProperty(state, action);
    case actionTypes.UPDATE_WARDROBE_ORDER_ARRAY_PROPERTY: return updateWardrobeOrderArrayProperty(state, action);

    case actionTypes.TOGGLE_ACILAN_WARDROBE_MODUL_AKSAM_MODAL: return toggleWardrobeModulAksamModal(state, action);
    case actionTypes.TOGGLE_ACILAN_WARDROBE_MODUL_AKSESUAR_MODAL: return toggleWardrobeModulAksesuarModal(state, action);
    case actionTypes.UPDATE_ACILAN_WARDROBE_MODUL_AKSESUAR_MODAL: return updateWardrobeModulAksesuarModal(state, action);
    case actionTypes.UPDATE_ACILAN_WARDROBE_MODUL_AKSESUAR_MODAL_NEW: return updateWardrobeModulAksesuarModalNew(state, action);

    case actionTypes.UPDATE_WARDROBE_MODUL_AKSAM_MODAL_NEW: return updateWardrobeModulAksamModalNew(state, action);
    case actionTypes.UPDATE_WARDROBE_MODUL_AKSAM_MODAL: return updateWardrobeModulAksamModal(state, action);

    case actionTypes.TOGGLE_ACILAN_WARDROBE_MODUL_PARCA_MODAL: return toggleWardrobeModulParcaModal(state, action);
    case actionTypes.UPDATE_ACILAN_WARDROBE_MODUL_PARCA_MODAL: return updateModulParcaModal(state, action);
    case actionTypes.UPDATE_ACILAN_WARDROBE_MODUL_PARCA_MODAL_NEW: return updateWardrobeModulParcaModalNew(state, action);

    case actionTypes.REMOVE_MODUL_ACILAN_WARDROBE_ORDER: return removeModulOrder(state, action);


    case actionTypes.TOGGLE_MOBILYA_MODUL_AKSESUAR_MODAL:   return toggleMobilyaModulAksesuarModal( state, action);
    case actionTypes.UPDATE_MOBILYA_MODUL_AKSESUAR_MODAL:   return updateMobilyaModulAksesuarModal( state, action);
    case actionTypes.UPDATE_MOBILYA_MODUL_AKSESUAR_MODAL_NEW:   return updateMobilyaModulAksesuarModalNew( state, action);


    case actionTypes.TOGGLE_MOBILYA_MODUL_MODAL:   return toggleMobilyaModulModal( state, action);

    case actionTypes.UPDATE_MOBILYA_ORDER_PROPERTY:   return updateMobilyaOrderProperty( state, action);
    case actionTypes.UPDATE_MOBILYA_ORDER_SELECT:   return updateMobilyaOrderProperty( state, action);
    case actionTypes.TOGGLE_MOBILYA_MODUL_PARCA_MODAL: return toggleMobilyaModulParcaModal(state, action);

    case actionTypes.UPDATE_MOBILYA_MODUL_PARCA_MODAL: return updateMobilyaModulParcaModal(state, action);
    case actionTypes.UPDATE_MOBILYA_MODUL_PARCA_MODAL_NEW: return updateMobilyaModulParcaModalNew(state, action);

    case actionTypes.TOGGLE_MOBILYA_MODUL_AKSAM_MODAL:   return toggleMobilyaModulAksamModal( state, action);
    case actionTypes.UPDATE_MOBILYA_MODUL_AKSAM_MODAL:   return updateMobilyaModulAksamModal( state, action);
    case actionTypes.UPDATE_MOBILYA_MODUL_AKSAM_MODAL_NEW:   return updateMobilyaModulAksamModalNew( state, action);

    case actionTypes.UPDATE_MOBILYA_ORDER_MODUL_MODAL_DATA_CHANGE:   return updateMobilyaModulModalDataProperty( state, action);
    case actionTypes.REMOVE_MODUL_MOBILYA_ORDER: return removeModulMobilyaOrder( state, action);

    default: return state;
  }
};
