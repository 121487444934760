import React, { useEffect } from 'react'
import * as actions from '../../../store/actions'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Col } from 'reactstrap'
import Loading from '../../Common/Loading'
import CompanyForm from './CompanyForm'
import 'react-checkbox-tree/lib/react-checkbox-tree.css'

const CompanyEditContainer = (props) => {
  const dispatch = useDispatch()
  const { loading, error, companyForm, productModal } = useSelector((state) => state.companies)
  const { token } = useSelector((state) => state.auth)

  useEffect(
    () => {
      props.match.params.id
        ? dispatch(actions.fetchCompany(token, props.match.params.id))
        : dispatch(actions.fetchCompanyNew())
    },
    [props.match.params.id, dispatch, token],
    token
  )

  const handleProductModalClick = (payload) => dispatch(actions.actionProductModal(payload))
  const handleRemoveCompanyFromProduct = (productId) => dispatch(actions.removeProductFromCompany(productId))

  const handleInitProductModal = (mode) => dispatch(actions.initProductModal(token, mode))

  const handleSave = (e) => {
    e.preventDefault()
    dispatch(actions.saveCompany(token, companyForm))
  }
  const handleOnSelectChange = (name, selected, event) =>
    dispatch(actions.updateCompanyProperty({ name, value: selected }))

  const handleProductModalSelectProperty = (name, value) =>
    dispatch(actions.updateProductModalProperty({ name, value }))

  const handleProductModalProperty = (event) => {
    const { name, value } = event.target
    dispatch(actions.updateProductModalProperty({ name, value }))
  }
  const handleOnChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
    const name = event.target.name
    dispatch(actions.updateCompanyProperty({ name, value }))
  }
  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-12'>
          {error && (
            <Col sm={12}>
              <Alert color='danger'>Sirket yuklerken hata oluştu — {error}</Alert>
            </Col>
          )}
          {loading ? (
            <Loading />
          ) : (
            <CompanyForm
              productModal={productModal}
              companyForm={companyForm}
              onRemoveProduct={handleRemoveCompanyFromProduct}
              onProductModalClick={handleProductModalClick}
              initProductModal={handleInitProductModal}
              onSave={handleSave}
              onChange={handleOnChange}
              onSelectChange={handleOnSelectChange}
              updateProductModalProperty={handleProductModalProperty}
              updateProductModalSelectProperty={handleProductModalSelectProperty}
            />
          )}
        </div>
      </div>
    </div>
  )
}
export default CompanyEditContainer
