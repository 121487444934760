import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../../../../store/actions'
import '../../Orders.css'
import AcilanForm from './AcilanForm'
import Loading from '../../../../Common/Loading'

const AcilanWardrobeOrderContainer = (props) => {
  const dispatch = useDispatch()
  const {
    downloading,
    wardrobeForm,
    saving,
    loading,
    lookup,
    aksesuarModal,
    aksamModal,
    parcaModal,
    modulModal
  } = useSelector((state) => state.orderForm)
  const { token } = useSelector((state) => state.auth)

  const onSaveOrder = (data) => dispatch(actions.saveOrder(token, data))
  const onSearchMusteri = (value) => dispatch(actions.searchMusteri(token, value))
  const onSearchMuteahhit = (value) => dispatch(actions.searchMuteahhit(token, value))
  const onSelectSetting = (name, id) => dispatch(actions.selectSetting(token, name, id))
  const onLookupSetting = (name, value) => dispatch(actions.searchSetting(token, name, value))
  const onSelectMenuClose = () => dispatch(actions.onSelectMenuClose())

  const updateWardrobeState = (event) => dispatch(actions.updateWardrobeOrderProperty(event))
  const addNewModulModal = () => dispatch(actions.toggleWardrobeModulModal({ action: 'new' }))
  const cancelModulModal = () => dispatch(actions.toggleWardrobeModulModal({ action: 'cancel' }))
  const saveModulModal = () => dispatch(actions.toggleWardrobeModulModal({ action: 'save' }))
  const onModulModalStateChange = (name, value) => dispatch(actions.modulModalStateChange(name, value))
  const updateSiparisOzellikleri = (name, value) => dispatch(actions.updateWardrobeOrderSelect(name, value, true))
  const updateSelect = (name, value) => dispatch(actions.updateWardrobeOrderSelect(name, value))
  const onPervazInputChange = (pervazName, property, value) =>
    dispatch(actions.updatePervazInputChange(pervazName, property, value))

  const aksamModalOnChange = (action) => dispatch(actions.updateWardrobeAksamModal(action))
  const aksamModalNewOnChange = (name, value) => dispatch(actions.updateWardrobeAksamModalNew(name, value))
  const onModulAksamClick = ({ action, index, id }) =>
    dispatch(actions.toggleAcilanWardrobeModulAksamModal({ action, index, id }))

  const onModulAksesuarClick = ({ action, index, id }) =>
    dispatch(actions.toggleAcilanWardrobeModulAksesuarModal({ action, index, id }))
  const aksesuarModalOnChange = (action) => dispatch(actions.updateAcilanWardrobeAksesuarModal(action))
  const aksesuarModalNewOnChange = (name, value) => dispatch(actions.updateAcilanWardrobeAksesuarModalNew(name, value))

  const parcaModalOnChange = (action) => dispatch(actions.updateAcilanWardrobeParcaModal(action))
  const parcaModalNewOnChange = (name, value) => dispatch(actions.updateAcilanWardrobeParcaModalNew(name, value))
  const onModulParcaClick = ({ index, action }) =>
    dispatch(actions.toggleAcilanWardrobeModulParcaModal({ index, action }))

  const onModulModalClick = (action, index) => dispatch(actions.toggleWardrobeModulModal({ action, index }))
  const onModulRemoveClick = (index) => dispatch(actions.removeAcilanWardrobeModul(index))

  const onDekorasyonAdd = () => dispatch(actions.addWardrobeDekorasyon())
  const onDekorasyonRemove = (id) => dispatch(actions.removeWardrobeDekorasyon(id))
  const updateDekorasyonState = (id, name, value) => dispatch(actions.updateWardrobeDekorasyonProperty(id, name, value))

  const onDownloadAcilanReport = (reportName, id) => dispatch(actions.onDownloadAcilanReport(token, reportName, id))

  useEffect(() => {
    const onExiting = () => dispatch(actions.resetWardrobeOrderForm())
    const onFetchOrder = (id) => dispatch(actions.fetchAcilanWardrobeOrder(token, id))
    onFetchOrder(props.match.params.id)
    return () => {
      onExiting()
    }
  }, [token, dispatch, props.match.params.id])

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-12'>
          <h3>Acilan Duvar Dolabi Siparis</h3>
          {loading || wardrobeForm === undefined ? (
            <Loading />
          ) : (
            <AcilanForm
              onModulParcaClick={onModulParcaClick}
              onModulAksesuarClick={onModulAksesuarClick}
              onModulAksamClick={onModulAksamClick}
              aksamModalOnChange={aksamModalOnChange}
              parcaModalOnChange={parcaModalOnChange}
              aksesuarModalOnChange={aksesuarModalOnChange}
              updateWardrobeState={updateWardrobeState}
              onDownloadAcilanReport={onDownloadAcilanReport}
              updateDekorasyonState={updateDekorasyonState}
              onPervazInputChange={onPervazInputChange}
              onSearchMusteri={onSearchMusteri}
              onSearchMuteahhit={onSearchMuteahhit}
              onLookupSetting={onLookupSetting}
              onSelectSetting={onSelectSetting}
              onSelectMenuClose={onSelectMenuClose}
              updateSelect={updateSelect}
              updateSiparisOzellikleri={updateSiparisOzellikleri}
              onDekorasyonAdd={onDekorasyonAdd}
              onDekorasyonRemove={onDekorasyonRemove}
              onSaveOrder={onSaveOrder}
              wardrobeForm={wardrobeForm}
              lookup={lookup}
              addNewModulModal={addNewModulModal}
              modulModal={modulModal}
              parcaModal={parcaModal}
              parcaModalNewOnChange={parcaModalNewOnChange}
              onModulModalClick={onModulModalClick}
              onModulRemoveClick={onModulRemoveClick}
              aksesuarModalNewOnChange={aksesuarModalNewOnChange}
              aksesuarModal={aksesuarModal}
              aksamModal={aksamModal}
              aksamModalNewOnChange={aksamModalNewOnChange}
              downloading={downloading}
              saving={saving}
              cancelModulModal={cancelModulModal}
              saveModulModal={saveModulModal}
              onModulModalStateChange={onModulModalStateChange}
            />
          )}
        </div>
      </div>
    </div>
  )
}
export default AcilanWardrobeOrderContainer
