import React, { useState } from 'react'
import { Button, Input, InputGroup, InputGroupText } from 'reactstrap'

const SearchInput = (props) => {
  const [term, setTerm] = useState('')

  const handleSearchKeyDown = (e) => {
    if (e.key === 'Enter' && term !== '') {
      props.onSearchClick(term)
      setTerm('')
      return
    }
  }
  const handleSearchClick = () => {
    if (term !== '') {
      props.onSearchClick(term)
      setTerm('')
    }
  }
  const handleSearchChange = (e) => {
    const target = e.target
    setTerm(target.value)
  }
  return (
    <>
      <InputGroup>
        <InputGroupText>{props.title}</InputGroupText>
        <Input onKeyDown={handleSearchKeyDown} value={term} name='query' onChange={handleSearchChange} />

        <Button color='secondary' onClick={handleSearchClick}>
          <i className='fas fa-search' />
          Ara
        </Button>
        {props.children}
      </InputGroup>
    </>
  )
}

export default SearchInput
