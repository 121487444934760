import get from "lodash/get";
import {logout} from "./authActions";

export const errorHandler = (dispatch,funcToExec,error) => {
  if (get(error, 'response.status', 500) === 401) {
    dispatch(logout())
  } else {
    const message = error.response ? error.response.data : error.message
    dispatch(funcToExec(message))
  }
}

export const errorHandlerWithProps = (dispatch,funcToExec,error,props) => {
  if (get(error, 'response.status', 500) === 401) {
    dispatch(logout())
  } else {
    dispatch(funcToExec(error.response.data,props))
  }
}
