import React from 'react'
import { NavLink, Switch } from 'react-router-dom'
import ProtectedRoute from '../hoc/ProtectedRoute'
import Orders from '../Uretim/Orders/Orders'
import KitchenOrderContainer from '../Uretim/Orders/Kitchen/KitchenOrderContainer'
import AcilanWardrobeOrderContainer from '../Uretim/Orders/Wardrobe/Acilan/AcilanWardrobeOrderContainer'
import SurmeWardrobeOrderContainer from '../Uretim/Orders/Wardrobe/Surme/SurmeWardrobeOrderContainer'
import DefaultSettingsForm from '../Uretim/Settings/Uretim/DefaultSettingsForm'
import DefaultSettingsListContainer from '../Uretim/Settings/Uretim/DefaultSettingsListContainer'
import UretimSettingContainer from '../Uretim/Settings/UretimSettingEditContainer'
import UretimSettingListContainer from '../Uretim/Settings/UretimSettingListContainer'
import CustomerListContainer from '../Uretim/Customer/CustomerListContainer'
import CustomerEditContainer from '../Uretim/Customer/CustomerEditContainer'
import WeeklyListContainer from '../Uretim/Weekly/WeeklyListContainer'
import WeeklyEditContainer from '../Uretim/Weekly/WeeklyEditContainer'
import TopluAcilanContainer from '../Uretim/Weekly/TopluAcilanContainer'
import { URETIM_SETTINGS_ADMIN_ROLE } from '../../shared'
import MobilyaOrderContainer from "../Uretim/Orders/Mobilya/MobilyaOrderContainer"
import ModulParcalarForm from "../Uretim/Settings/Uretim/ModulParcalarForm";
import ModulAksamlarForm from "../Uretim/Settings/Uretim/ModulAksamlarForm";
import ModulAksesuarlarForm from "../Uretim/Settings/Uretim/ModulAksesuarlarForm";

const UretimSubMenu = ({ isAuthenticated, profile }) => {
  return (
    <>
      <div className='row'>
        <div className='col-12 p-0'>
          <ul className='nav nav-pills nav-fill navbar-dark bg-dark green-active'>
            <li className='nav-item bg-Ciddi m-1'>
              <NavLink className='nav-link' to='/uretim/siparisler'>
                Siparişler
              </NavLink>
            </li>
            <li className='nav-item bg-Ciddi m-1'>
              <NavLink className='nav-link' to='/uretim/raporlar'>
                Raporlar
              </NavLink>
            </li>
            <li className='nav-item bg-Ciddi m-1'>
              <NavLink className='nav-link' to='/uretim/musteriler'>
                Müşteriler
              </NavLink>
            </li>
            {profile && profile.roles && profile.roles.indexOf(URETIM_SETTINGS_ADMIN_ROLE) > -1 && (
              <li className='nav-item bg-Ciddi m-1'>
                <NavLink className='nav-link' to='/uretim/ayarlar'>
                  Ayarlar
                </NavLink>
              </li>
            )}
          </ul>
        </div>
      </div>
      <Switch>
        <ProtectedRoute path='/uretim/siparisler' exact={true} component={Orders} isAuthenticated={isAuthenticated} />
        <ProtectedRoute
          path={['/uretim/siparisler/mobilya/:id', '/uretim/siparisler/mobilya']}
          exact={true}
          component={MobilyaOrderContainer}
          isAuthenticated={isAuthenticated}
        />
        <ProtectedRoute
          path={['/uretim/siparisler/mutfak/:id', '/uretim/siparisler/mutfak']}
          exact={true}
          component={KitchenOrderContainer}
          isAuthenticated={isAuthenticated}
        />
        <ProtectedRoute
          path={['/uretim/siparisler/duvarDolabi/acilan/:id', '/uretim/siparisler/duvarDolabi/acilan']}
          exact={true}
          component={AcilanWardrobeOrderContainer}
          isAuthenticated={isAuthenticated}
        />
        <ProtectedRoute
          path={['/uretim/siparisler/duvarDolabi/surme/:id', '/uretim/siparisler/duvarDolabi/surme']}
          exact={true}
          component={SurmeWardrobeOrderContainer}
          isAuthenticated={isAuthenticated}
        />
        <ProtectedRoute
          path={[
            '/uretim/ayarlar/:entity/:id/parcalar'
          ]}
          exact={true}
          component={ModulParcalarForm}
          isAuthenticated={isAuthenticated}
        />
        <ProtectedRoute
          path={[
            '/uretim/ayarlar/:entity/:id/aksamlar'
          ]}
          exact={true}
          component={ModulAksamlarForm}
          isAuthenticated={isAuthenticated}
        />
        <ProtectedRoute
          path={[
            '/uretim/ayarlar/:entity/:id/aksesuarlar'
          ]}
          exact={true}
          component={ModulAksesuarlarForm}
          isAuthenticated={isAuthenticated}
        />
        <ProtectedRoute
          path={[
            '/uretim/ayarlar/tezgahYon/yeni',
            '/uretim/ayarlar/tezgahYon/:id',
            '/uretim/ayarlar/tezgah/yeni',
            '/uretim/ayarlar/tezgah/:id',
            '/uretim/ayarlar/acilisYonu/yeni',
            '/uretim/ayarlar/acilisYonu/:id',
            '/uretim/ayarlar/kapakYon/yeni',
            '/uretim/ayarlar/kapakYon/:id',
            '/uretim/ayarlar/kapakDesenYonu/yeni',
            '/uretim/ayarlar/kapakDesenYonu/:id',
            '/uretim/ayarlar/govdeMalzemesi/yeni',
            '/uretim/ayarlar/govdeMalzemesi/:id',
            '/uretim/ayarlar/camModeli/yeni',
            '/uretim/ayarlar/camModeli/:id',
            '/uretim/ayarlar/suluk/yeni',
            '/uretim/ayarlar/suluk/:id',
            '/uretim/ayarlar/bandRengi/yeni',
            '/uretim/ayarlar/bandRengi/:id',
            '/uretim/ayarlar/baza/yeni',
            '/uretim/ayarlar/baza/:id',
            '/uretim/ayarlar/gorunenYan/yeni',
            '/uretim/ayarlar/gorunenYan/:id',
            '/uretim/ayarlar/dekorasyon/yeni',
            '/uretim/ayarlar/dekorasyon/:id',
            '/uretim/ayarlar/camProfili/yeni',
            '/uretim/ayarlar/camProfili/:id',
            '/uretim/ayarlar/modul/yeni',
            '/uretim/ayarlar/modul/:id',
            '/uretim/ayarlar/mobilya/yeni',
            '/uretim/ayarlar/mobilya/:id'
          ]}
          exact={true}
          component={DefaultSettingsForm}
          isAuthenticated={isAuthenticated}
        />
        <ProtectedRoute
          path={[
            '/uretim/ayarlar/tezgahYon',
            '/uretim/ayarlar/tezgah',
            '/uretim/ayarlar/acilisYonu',
            '/uretim/ayarlar/dekorasyon',
            '/uretim/ayarlar/kapakYon',
            '/uretim/ayarlar/kapakDesenYonu',
            '/uretim/ayarlar/camModeli',
            '/uretim/ayarlar/suluk',
            '/uretim/ayarlar/bandRengi',
            '/uretim/ayarlar/baza',
            '/uretim/ayarlar/gorunenYan',
            '/uretim/ayarlar/camProfili',
            '/uretim/ayarlar/modul',
            '/uretim/ayarlar/mobilya',
            '/uretim/ayarlar/govdeMalzemesi'
          ]}
          exact={true}
          component={DefaultSettingsListContainer}
          isAuthenticated={isAuthenticated}
        />
        <ProtectedRoute
          path='/uretim/ayarlar/:name'
          exact={true}
          component={UretimSettingContainer}
          isAuthenticated={isAuthenticated}
        />
        <ProtectedRoute
          path='/uretim/ayarlar'
          exact={true}
          component={UretimSettingListContainer}
          isAuthenticated={isAuthenticated}
        />
        <ProtectedRoute
          path='/uretim/musteriler'
          exact={true}
          component={CustomerListContainer}
          isAuthenticated={isAuthenticated}
        />
        <ProtectedRoute
          path={['/uretim/musteriler/yeni', '/uretim/musteriler/:id']}
          exact={true}
          component={CustomerEditContainer}
          isAuthenticated={isAuthenticated}
        />
        <ProtectedRoute
          path={['/uretim/muteahhitler/yeni', '/uretim/muteahhitler/:id']}
          exact={true}
          component={CustomerEditContainer}
          has_projects={true}
          isAuthenticated={isAuthenticated}
        />
        <ProtectedRoute
          path='/uretim/raporlar'
          exact={true}
          component={WeeklyListContainer}
          isAuthenticated={isAuthenticated}
        />
        <ProtectedRoute
          path={['/uretim/raporlar/topluAcilan', '/uretim/raporlar/topluAcilan/:id']}
          exact={true}
          component={TopluAcilanContainer}
          isAuthenticated={isAuthenticated}
        />
        <ProtectedRoute
          path={['/uretim/raporlar/haftalik', '/uretim/raporlar/haftalik/:id']}
          exact={true}
          component={WeeklyEditContainer}
          isAuthenticated={isAuthenticated}
        />
      </Switch>
    </>
  )
}

export default UretimSubMenu
