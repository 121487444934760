import React, { useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../../../store/actions'
import Loading from '../../../Common/Loading'
import Aux from '../../../hoc/Aux'
import DeleteConfirmAlert from '../../../Common/DeleteConfirmAlert'
import ConfirmActionAlert from '../../../Common/ConfirmActionAlert'
import SettingsSearchBar from '../../../Common/SettingsSearchBar'
import Paging from '../../../Common/Paging'

function DefaultSettingsListContainer(props) {
  const location = useLocation()
  const dispatch = useDispatch()
  const { data, paging, loading } = useSelector((state) => state.uretimSettings)
  const settingName = data.name || location.pathname.split('/')[3]
  const showClone = settingName === 'mobilya'
  const showParcaAksamAksesuar = settingName === 'modul' || settingName === 'mobilya'
  const { token } = useSelector((state) => state.auth)
  const handleFetch = (query = '', currentPage = 1) => {
    dispatch(actions.fetchUretimSettingV2WithName(token, settingName, query, currentPage))
  }
  const handleSettingDelete = (id) => dispatch(actions.onSettingV2Delete(token, settingName, id))
  const handleSettingClone = (id) => dispatch(actions.onSettingV2Clone(token, settingName, id))

  const handleClearSearchClick = () => {
    handleFetch()
  }
  const handleSearchClick = (query) => {
    dispatch(actions.onUretimSearchV2Click(token, settingName, query))
  }
  const handleIlkClick = () => {
    handleFetch(paging.q || '', 1)
  }
  const handleGeriClick = () => {
    handleFetch(paging.q || '', ~~paging.currentPage - 1)
  }
  const handleIleriClick = () => {
    handleFetch(paging.q || '', ~~paging.currentPage + 1)
  }
  const handleSonClick = () => {
    handleFetch(paging.q || '', ~~paging.totalPageCount)
  }
  useEffect(() => {
    handleFetch()
  }, [])

  return (
    <>
      <SettingsSearchBar
        title={data.description}
        paging={paging}
        handleSearchClick={handleSearchClick}
        handleClearSearchClick={handleClearSearchClick}
        settingName={settingName}
      />

      <div className='container-fluid'>
        {loading ? (
          <Loading />
        ) : (
          <div className='row'>
            <div className='col-6  py-1 px-lg-3 border bg-light'>
              <strong>Aciklama</strong>
            </div>
            <div className='col-1  py-1 px-lg-3 border bg-light'>
              <strong>Aktif</strong>
            </div>
            <div className='col-5  py-1 px-lg-3 border bg-light'>
              <strong>#</strong>
            </div>
            {data.list.map((settingItem) => (
              <Aux key={settingItem.id}>
                <div className='col-6  py-1 px-lg-3 border'>{settingItem.uretim_fields.description}<p
                    className="text-muted">{settingItem.uretim_fields.note}</p></div>
                <div className='col-1  py-1 px-lg-3 border'>{settingItem.isactive ? 'Aktif' : 'Pasif'}</div>
                <div className='col-5 py-1 px-lg-3 border'>
                  {(showParcaAksamAksesuar) && (
                    <>
                      <NavLink className='btn btn-outline-primary float-begin  ml-sm-2' to={`/uretim/ayarlar/${settingName}/${settingItem.id}/parcalar`}>
                        Parcalar
                      </NavLink>
                      <NavLink className='btn btn-outline-primary float-begin  ml-sm-2' to={`/uretim/ayarlar/${settingName}/${settingItem.id}/aksamlar`}>
                        Aksamlar
                      </NavLink>
                      <NavLink className='btn btn-outline-primary float-begin  ml-sm-2' to={`/uretim/ayarlar/${settingName}/${settingItem.id}/aksesuarlar`}>
                        Aksesuarlar
                      </NavLink>
                    </>
                  )}
                  <DeleteConfirmAlert
                    deleteButtonStyle='btn btn-danger float-end'
                    onRemove={() => handleSettingDelete(settingItem.id)}
                  />
                  {showClone && <ConfirmActionAlert
                      actionButtonText='Kopyala'
                      actionButtonStyle='btn btn-warning float-end'
                    onClick={() => handleSettingClone(settingItem.id)}
                  />}
                  <NavLink
                    className='btn btn-outline-primary float-end'
                    to={`/uretim/ayarlar/${settingName}/${settingItem.id}`}
                  >
                    Degistir
                  </NavLink>
                </div>
              </Aux>
            ))}
          </div>
        )}
      </div>
      {paging.totalPageCount > 1 && (
        <Paging
          {...paging}
          handleIleriClick={handleIleriClick}
          handleSonClick={handleSonClick}
          handleGeriClick={handleGeriClick}
          handleIlkClick={handleIlkClick}
        />
      )}
    </>
  )
}
export default DefaultSettingsListContainer
