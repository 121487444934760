import React, { useEffect } from 'react'
import { Alert, Button, Col, Row } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../../store/actions'
import Paging from '../../Common/Paging'
import { Link } from 'react-router-dom'
import OrderList from './OrderList'
import Loading from '../../Common/Loading'
import CloneOrderModal from './CloneOrderModal'
import SearchInput from '../../Common/SearchInput'
const Orders = (props) => {
  const dispatch = useDispatch()
  const { list, paging, loading, error, redirect } = useSelector((state) => state.order)
  const { token } = useSelector((state) => state.auth)

  const onFetchOrders = (page, term) => dispatch(actions.fetchOrders(token, page, term))
  useEffect(() => {
    if (redirect) {
      props.history.push(`${redirect}?warn=true`)
    }
    onFetchOrders(1)
    return () => {
      dispatch(actions.resetOrdersRedirect())
    }
  }, [token, redirect, props.history, dispatch])

  const handleIlkClick = () => onFetchOrders(1, paging.q)

  const handleGeriClick = () => onFetchOrders(~~paging.currentPage - 1, paging.q)

  const handleSonClick = () => onFetchOrders(~~paging.totalPageCount, paging.q)

  const handleIleriClick = () => {
    onFetchOrders(~~paging.currentPage + 1, paging.q)
  }
  const handleCloneOrderAction = (payload) => dispatch(actions.toggleCloneOrderModal(token, payload))
  const handleDuplicateOrderAction = (payload) => dispatch(actions.dublicateOrder(token, payload))

  const handleClearSearchClick = () => dispatch(actions.ordersSearchClick(token, ''))

  const handleSearchClick = (query) => dispatch(actions.ordersSearchClick(token, query))

  return (
    <>
      <Row>
        <Col sm='12'>
          <SearchInput title='Siparisler' onSearchClick={handleSearchClick}>
            <Link className='btn btn-success ml-1 float-end' activeclassname='active' to='/uretim/siparisler/mutfak'>
              <i className='fa fa-plus' /> Mutfak
            </Link>
            <Link className='btn btn-warning ml-1 float-end' activeclassname='active' to='/uretim/siparisler/mobilya'>
              <i className='fa fa-plus' /> Mobilya
            </Link>
            <Link className='btn btn-primary ml-1 float-end' to='/uretim/siparisler/duvarDolabi/acilan'>
              <i className='fa fa-plus' />
              Acilan <span className='d-none d-md-inline'>Duvar Dolabi</span>
            </Link>
            <Link className='btn btn-info ml-1 float-end' to='/uretim/siparisler/duvarDolabi/surme'>
              <i className='fa fa-plus' />
              Surme <span className='d-none d-md-inline'>Duvar Dolabi</span>
            </Link>
          </SearchInput>
        </Col>
        {paging.q && (
          <Col sm='12'>
            <Alert color='info'>
              <Button color='danger' onClick={handleClearSearchClick}>
                <i className='fas fa-times' />
              </Button>
              Arama "<strong>{paging.q}</strong>"
            </Alert>
          </Col>
        )}
        {error && (
          <Col sm='12'>
            <Alert color='danger'>Siparişleri yüklerken hata oluştu — {error}</Alert>
          </Col>
        )}
        {loading ? <Loading /> : <OrderList orders={list} onDuplicateOrder={handleDuplicateOrderAction} toggleCloneOrderModal={handleCloneOrderAction} />}
      </Row>
      <CloneOrderModal toggleCloneOrderModal={handleCloneOrderAction} />
      <Paging
        {...paging}
        handleIleriClick={handleIleriClick}
        handleSonClick={handleSonClick}
        handleGeriClick={handleGeriClick}
        handleIlkClick={handleIlkClick}
      />
    </>
  )
}

export default Orders
