import {toast} from "react-toastify";
import {cloneWithMap, toastOptions} from "../../../shared";
import {initLookup} from "../../dataModels/LookupModals";
import {initTezgah} from "../../dataModels/TezgahModal";
// TEZGAH
export const updateTezgahProperty = ( state, action ) => {
  const tezgahlar = cloneWithMap(state.kitchenForm.tezgahlar);
  const tezgah = tezgahlar.find(x=>x.id === action.payload.id)
  tezgah[action.payload.name] = action.payload.value;
  return {
    ...state,
    kitchenForm: {
      ...state.kitchenForm,
      tezgahlar
    },
    lookup: initLookup()
  }
}
export const removeTezgahKitchenOrder = ( state, action ) => {
  return {...state, kitchenForm: {...state.kitchenForm, tezgahlar:state.kitchenForm.tezgahlar.filter(d=>d.id !== action.payload.id)} }
}
export const addTezgahKitchenOrder = ( state, action ) => {
  const tezgah = initTezgah()
  toast.success(`Tezgah eklendi!`, toastOptions);
  return {...state, kitchenForm: {...state.kitchenForm, tezgahlar:[...state.kitchenForm.tezgahlar,tezgah]} }
}
