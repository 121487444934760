import { instance as axios } from '../../axios-api'
import * as actionTypes from './actionTypes'
import { apiPath } from '../../shared'
import { errorHandler } from './errorHandler'

export const fetchProducts = (token, page) => {
  return async (dispatch) => {
    try {
      dispatch(fetchProductsStart())
      const response = await axios(token).get(apiPath.productList(page))
      dispatch(fetchProductsSuccess(response.data))
    } catch (error) {
      errorHandler(dispatch, fetchProductsFail, error)
    }
  }
}
export const fetchProductsStart = () => ({
  type: actionTypes.FETCH_PRODUCTS_LIST_START
})
export const fetchProductsSuccess = (payload) => ({
  type: actionTypes.FETCH_PRODUCTS_LIST_SUCCESS,
  payload
})
export const fetchProductsFail = (error) => ({
  type: actionTypes.FETCH_PRODUCTS_LIST_FAIL,
  error: error
})

export const lookupProductGroups = (token) => {
  return async (dispatch) => {
    try {
      dispatch(lookupProductGroupStart())
      const response = await axios(token).get(apiPath.lookupProductGroup())
      dispatch(lookupProductGroupSuccess(response.data))
    } catch (error) {
      errorHandler(dispatch, lookupProductGroupFail, error)
    }
  }
}
export const lookupProductGroupSuccess = (payload) => ({
  type: actionTypes.PRODUCT_GROUP_LOOKUP_SUCCESS,
  payload
})
export const lookupProductGroupFail = (err) => ({
  type: actionTypes.PRODUCT_GROUP_LOOKUP_FAIL,
  payload: err
})
export const lookupProductGroupStart = () => ({
  type: actionTypes.PRODUCT_GROUP_LOOKUP_START
})
export const fetchProduct = (token, id) => {
  return async (dispatch) => {
    try {
      dispatch(fetchProductStart())
      const response = await axios(token).get(apiPath.product(id))
      dispatch(fetchProductSuccess(response.data))
    } catch (error) {
      errorHandler(dispatch, fetchProductFail, error)
    }
  }
}
export const fetchProductStart = () => ({
  type: actionTypes.FETCH_PRODUCT_START
})
export const fetchProductSuccess = (payload) => ({
  type: actionTypes.FETCH_PRODUCT_SUCCESS,
  payload
})
export const fetchProductFail = (error) => ({
  type: actionTypes.FETCH_PRODUCT_FAIL,
  error
})

export const fetchProductNew = () => ({
  type: actionTypes.FETCH_PRODUCT_NEW
})

export const saveProductStart = () => ({
  type: actionTypes.SAVE_PRODUCT_START
})
export const saveProductSuccess = (payload) => ({
  type: actionTypes.SAVE_PRODUCT_SUCCESS,
  payload
})
export const saveProductFail = (error) => ({
  type: actionTypes.SAVE_PRODUCT_FAIL,
  error
})

export const toggleCompanyModal = (payload) => ({
  type: actionTypes.TOGGLE_PRODUCT_COMPANY_MODAL,
  payload
})
export const lookupCompany = (token, inputValue) => {
  return async (dispatch) => {
    try {
      dispatch(lookupCompanyStart())
      const response = await axios(token).get(apiPath.companySearch(inputValue))
      dispatch(lookupCompanySuccess(response.data))
    } catch (error) {
      errorHandler(dispatch, lookupCompanyFail, error)
    }
  }
}
export const lookupCompanySuccess = (payload) => ({
  type: actionTypes.PRODUCT_COMPANY_LOOKUP_SUCCESS,
  payload
})
export const lookupCompanyFail = (err) => ({
  type: actionTypes.PRODUCT_COMPANY_LOOKUP_FAIL,
  payload: err
})
export const lookupCompanyStart = () => ({
  type: actionTypes.PRODUCT_COMPANY_LOOKUP_START
})
export const updateCompanyModalProperty = (payload) => ({
  type: actionTypes.CHANGE_PRODUCT_COMPANY_MODAL_PROPERTY,
  payload
})

export const removeCompanyFromProduct = (companyId) => ({
  type: actionTypes.REMOVE_PRODUCT_COMPANY,
  payload: companyId
})

export const saveProduct = (token, product) => {
  return async (dispatch) => {
    try {
      dispatch(saveProductStart())
      const options = {
        method: product.id ? 'PUT' : 'POST',
        url: product.id ? apiPath.product(product.id) : apiPath.products(),
        data: product
      }
      const response = await axios(token)(options)
      dispatch(saveProductSuccess(response.data))
    } catch (error) {
      errorHandler(dispatch, saveProductFail, error)
    }
  }
}

export const updateProductProperty = (event) => {
  const { name, value } = event
  return {
    type: actionTypes.UPDATE_PRODUCT_PROPERTY,
    payload: { name, value }
  }
}
export const productSearchClick = (token, inputValue) => {
  return async (dispatch) => {
    try {
      dispatch(productSearchClickStart())
      const response = await axios(token).get(apiPath.productSearch(inputValue))
      dispatch(productSearchClickSuccess(response.data))
    } catch (error) {
      errorHandler(dispatch, productSearchClickFail, error)
    }
  }
}
const productSearchClickStart = () => ({
  type: actionTypes.PRODUCT_SEARCH_CLICK_START
})
const productSearchClickSuccess = (payload) => ({
  type: actionTypes.PRODUCT_SEARCH_CLICK_SUCCESS,
  payload
})
const productSearchClickFail = (err) => ({
  type: actionTypes.PRODUCT_SEARCH_CLICK_FAIL,
  payload: err
})
