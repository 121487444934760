import shortid from 'shortid'

export const initKitchenModulModalData = () => ({
  siranumarasi: 0,
  id: shortid.generate(),
  adet: 1,
  modul: null,
  kulpmodeli: null,
  kapakmodeli: null,
  kapakmalzemesi: null,
  govdemalzemesi: null,
  govderengi: null,
  kapakdesenyonu: null,
  kapakrenk: null,
  gorunenyan: { aciklama: 'YOK', isactive: true, id: '1459' },
  acilisyonu: null,
  ozelislem: '',
  metalCerceveliKapak: false,
  metalCerceveliKapakAdet: 0,
  camModeli: null,
  camProfili: null,
  kulpYonu: null
})
export const initMobilyaModulModalData = () => ({
  siranumarasi: 0,
  id: shortid.generate(),
  adet: 1,
  modul: null,
  kulpmodeli: null,
  kapakmodeli: null,
  kapakmalzemesi: null,
  govdemalzemesi: null,
  govderengi: null,
  kapakrenk: null,
  ozelislem: '',
})
export const initMobilyaModulModal = () => ({
  show: false,
  index: -1,
  data: initMobilyaModulModalData()
})
export const initModulModal = (type, wardrobeType) => ({
  show: false,
  index: -1,
  data: type === 'wardrobe' ? initWardrobeModulModalData(wardrobeType === 'surme') : initKitchenModulModalData()
})
export const initWardrobeModulModalData = (isSurmeOrder) => ({
  siranumarasi: 0,
  id: shortid.generate(),
  adet: '',
  modul: null,
  ozelislem: '',
  ...(!isSurmeOrder && {
    kulpmodeli: null,
    kapakmodeli: null,
    kapakmalzemesi: null,
    kapakyonu: null,
    kapakrenk: null,
    metalCerceveliKapak: false,
    metalCerceveliKapakAdet: 0,
    camModeli: null,
    camProfili: null,
    kulpYonu: null,
    aynaliKapak: false,
    dolapAynaModeli: null,
    aynaliKapakOlcu: '',
    aynaliKapakAdet: null
  })
})
