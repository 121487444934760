import cloneDeep from 'lodash/cloneDeep'
import set from 'lodash/set'
import { initAksesuarModal, initAksesuarModalNew } from '../../dataModels/AksesuarModal'
import { initLookup } from '../../dataModels/LookupModals'
import { cloneWithMap } from '../../../shared'

export const updateModulAksesuarModalNew = (state, action) => {
  const newData = { ...state.aksesuarModal.new }
  newData[action.payload.name] = action.payload.value
  return { ...state, ...{ aksesuarModal: { ...state.aksesuarModal, new: newData } } }
}
export const updateModulAksesuarModal = (state, action) => {
  const cloneAksesuarModal = cloneDeep(state.aksesuarModal)
  set(cloneAksesuarModal, action.payload.name, action.payload.value)
  return { ...state, ...{ aksesuarModal: cloneAksesuarModal } }
}
export const toggleModulAksesuarModal = (state, action) => {
  switch (action.payload.action) {
    case 'cancel':
      return { ...state, ...{ aksesuarModal: initAksesuarModal(), lookup: initLookup() } }
    case 'add':
      const { miktar, aksesuar, not } = state.aksesuarModal.new
      const newAksesuar = { ...aksesuar, miktar, not }
      const cloneData = cloneWithMap(state.aksesuarModal.data)
      cloneData.push(newAksesuar)
      return {
        ...state,
        ...{ aksesuarModal: { ...state.aksesuarModal, data: cloneData, new: initAksesuarModalNew() } }
      }
    case 'delete':
      let newData = cloneWithMap(state.aksesuarModal.data)
      newData = newData.filter((x) => x.id !== action.payload.id)

      return { ...state, ...{ aksesuarModal: { ...state.aksesuarModal, data: newData } } }
    case 'save':
      const newModuller = cloneDeep(state.kitchenForm.moduller)
      newModuller[state.aksesuarModal.index].modul.aksesuarlar = cloneWithMap(state.aksesuarModal.data)
      newModuller[state.aksesuarModal.index].modul.rafcikar = state.aksesuarModal.rafcikar
      newModuller[state.aksesuarModal.index].modul.mentesecikar = state.aksesuarModal.mentesecikar
      return {
        ...state,
        ...{
          aksesuarModal: initAksesuarModal(),
          lookup: initLookup(),
          kitchenForm: { ...state.kitchenForm, moduller: newModuller }
        }
      }
    default:
      const { aksesuarlar, rafcikar, mentesecikar } = state.kitchenForm.moduller[action.payload.index].modul
      const cloneAksesuarlar = cloneWithMap(aksesuarlar)
      return {
        ...state,
        ...{
          aksesuarModal: {
            ...state.aksesuarModal,
            show: !state.aksesuarModal.show,
            index: action.payload.index,
            data: cloneAksesuarlar,
            rafcikar,
            mentesecikar
          }
        }
      }
  }
}
