import React from 'react'
import NumberInput from '../../Core/NumberInput'
const BandTipi = (props) => {
  return (
    <div className='col-md-5 col-sm-12'>
      <NumberInput
        label='Fiyat €'
        step='0.001'
        name='fiyat'
        dataRow={props.index}
        value={props.fiyat}
        onChange={props.onChange}
        required
      />
    </div>
  )
}

export default BandTipi
