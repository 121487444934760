import React from 'react'
import { confirmAlert } from 'react-confirm-alert'

import 'react-confirm-alert/src/react-confirm-alert.css'

const ConfirmActionAlert = (props) => {
  const getId = () => {
    return props.id || new Date().getTime().toString()
  }
  const handleConfirm = (e) => {
    e.preventDefault()
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div>
            <h1>Onaylayın</h1>
            <p>Son sansin, emin misin?</p>
            <button className='btn btn-secondary' onClick={onClose}>
              Hayır, vazgectim!
            </button>
            <button
              id={'action-button-' + getId()}
              className='btn btn-success'
              onClick={() => {
                props.onClick()
                onClose()
              }}
            >
              Evet, son kararim.
            </button>
          </div>
        )
      }
    })
  }
  return (
    <button id={getId()} className={props.actionButtonStyle || 'btn btn-warning'} onClick={handleConfirm}>
      {props.actionButtonText}
    </button>
  )
}

export default ConfirmActionAlert
