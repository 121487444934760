import React from 'react'
import { NavLink, Switch } from 'react-router-dom'
import ProtectedRoute from '../hoc/ProtectedRoute'
import ProductOrderListContainer from '../Alim/ProductOrders/ProductOrderListContainer'
import ProductOrderEditContainer from '../Alim/ProductOrders/ProductOrderEditContainer'
import ProductGroupsEditContainer from '../Alim/ProductGroups/ProductGroupsEditContainer'
import ProductGroupsListContainer from '../Alim/ProductGroups/ProductGroupsListContainer'
import ProductListContainer from '../Alim/Products/ProductListContainer'
import ProductEditContainer from '../Alim/Products/ProductEditContainer'
import CompanyListContainer from '../Alim/Companies/CompanyListContainer'
import CompanyEditContainer from '../Alim/Companies/CompanyEditContainer'

const AlimSubMenu = ({ isAuthenticated }) => {
  return (
    <>
      <div className='row'>
        <div className='col-12 p-0'>
          <ul className='nav nav-pills nav-fill navbar-dark bg-dark green-active'>
            <li className='nav-item bg-Ciddi m-1'>
              <NavLink className='nav-link' to='/alim/siparisler'>
                Siparişler
              </NavLink>
            </li>
            <li className='nav-item bg-Ciddi m-1'>
              <NavLink className='nav-link' to='/alim/urunler'>
                Urunler
              </NavLink>
            </li>
            <li className='nav-item bg-Ciddi m-1'>
              <NavLink className='nav-link' to='/alim/sirketler'>
                Sirketler
              </NavLink>
            </li>
            <li className='nav-item bg-Ciddi m-1'>
              <NavLink className='nav-link' to='/alim/urun-gruplari'>
                Urun Gruplari
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      <Switch>
        <ProtectedRoute
          path='/alim/siparisler'
          exact={true}
          component={ProductOrderListContainer}
          isAuthenticated={isAuthenticated}
        />
        <ProtectedRoute
          path={['/alim/siparisler/yeni', '/alim/siparisler/:id']}
          exact={true}
          component={ProductOrderEditContainer}
          isAuthenticated={isAuthenticated}
        />
        <ProtectedRoute
          path={['/alim/urun-gruplari/new', '/alim/urun-gruplari/:id']}
          exact={true}
          component={ProductGroupsEditContainer}
          isAuthenticated={isAuthenticated}
        />
        <ProtectedRoute
          path='/alim/urun-gruplari'
          exact={true}
          component={ProductGroupsListContainer}
          isAuthenticated={isAuthenticated}
        />
        <ProtectedRoute
          path='/alim/urunler'
          exact={true}
          component={ProductListContainer}
          isAuthenticated={isAuthenticated}
        />
        <ProtectedRoute
          path={['/alim/urunler/yeni', '/alim/urunler/:id']}
          exact={true}
          component={ProductEditContainer}
          isAuthenticated={isAuthenticated}
        />
        <ProtectedRoute
          path='/alim/sirketler'
          exact={true}
          component={CompanyListContainer}
          isAuthenticated={isAuthenticated}
        />
        <ProtectedRoute
          path={['/alim/sirketler/yeni', '/alim/sirketler/:id']}
          exact={true}
          component={CompanyEditContainer}
          isAuthenticated={isAuthenticated}
        />
      </Switch>
    </>
  )
}

export default AlimSubMenu
