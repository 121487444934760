import React from 'react'
import NumberInput from '../../Core/NumberInput'
import { Input, Label } from 'reactstrap'

const KulpModeliSettings = (props) => {
  const { index, ebatliKulp, kapakBoyuBuyuk, onChange, kapakBoyuKucuk, fiyat } = props
  return (
    <div className='row'>
      <div className='mb-3 col-md-6'>
        <NumberInput
          label='Kapak Boyunu Buyuk'
          name='kapakBoyuBuyuk'
          dataRow={index}
          value={kapakBoyuBuyuk}
          onChange={onChange}
          valid={~~kapakBoyuBuyuk > 0}
          invalid={!kapakBoyuBuyuk}
          required
        />
        <NumberInput
          label='Kapak Boyunu Kucult'
          name='kapakBoyuKucuk'
          dataRow={index}
          value={kapakBoyuKucuk}
          onChange={onChange}
          valid={~~kapakBoyuKucuk > 0}
          invalid={!kapakBoyuKucuk}
          required
        />
      </div>
      <div className='mb-3 col-12'>
        <div className='form-check form-switch'>
          <Input
            className='form-check-input'
            type='checkbox'
            role='switch'
            id='ebatliKulp'
            name='ebatliKulp'
            value='true'
            data-row={index}
            onChange={onChange}
            checked={ebatliKulp ? 'checked' : ''}
          />
          <Label className='form-check-label' htmlFor='ebatliKulp'>
            Ebatli Kulp?
          </Label>
        </div>
      </div>
      <div className='col-md-2 col-sm-12'>
        <NumberInput label='Fiyat €' name='fiyat' step='0.001' dataRow={index} value={fiyat} onChange={onChange} required />
      </div>
    </div>
  )
}

export default KulpModeliSettings
