import { toast } from 'react-toastify'
import set from 'lodash/set'
import cloneDeep from 'lodash/cloneDeep'
import remove from 'lodash/remove'
import { v4 as uuidv4 } from 'uuid'
import * as actionTypes from '../actions/actionTypes'
import { PRODUCT_ORDER_STATUS_OLUSTURULAN, toasterMessages, toastErrorOptions, toastOptions } from '../../shared'
import { initialPaging } from '../dataModels/CommonModals'

const initOrder = ({ currency, price }) => ({
  quantity: 0,
  price: price ? price : 0,
  currency: currency ? { ...currency } : null
})
const initialSelection = () => ({ productGroupId: '', productId: '', companyId: '' })
export const initProductOrderModal = () => ({
  show: false,
  isEditing: false,
  loadingCompanies: false,
  error: null,
  productsTree: [],
  companiesForProduct: [],
  orderToEdit: null,
  selection: initialSelection()
})
const initialProductOrderForm = () => ({
  loading: true,
  saving: false,
  error: null,
  id: undefined,
  description: '',
  note: '',
  orders: [],
  productOrderModal: initProductOrderModal()
})

const initialState = {
  error: null,
  loading: true,
  search: { query: '' },
  paging: initialPaging(),
  productOrderForm: initialProductOrderForm(),
  list: [],
  filters: {
    status: PRODUCT_ORDER_STATUS_OLUSTURULAN
  }
}

const fetchProductOrdersFail = (state, action) => {
  toast.error(toasterMessages.errorWithMessage(action.error.message), toastErrorOptions)
  return { ...state, ...{ loading: false, error: action.error.message } }
}
const removeProductOrderRow = (state, action) => {
  let orders = JSON.parse(JSON.stringify(state.productOrderForm.orders))
  remove(orders, (x) => x.id === action.payload.id)
  return {
    ...state,
    productOrderForm: {
      ...state.productOrderForm,
      orders
    }
  }
}
const setEditedOrderPaymentDetails = (state, action) => {
  const { selection, companiesForProduct } = state.productOrderForm.productOrderModal
  let orders = JSON.parse(JSON.stringify(state.productOrderForm.orders))
  const orderToEdit = orders.find((x) => x.id === action.payload.id)
  remove(orders, (x) => x.id === action.payload.id)

  const clonedCompaniesForProduct = JSON.parse(JSON.stringify(companiesForProduct))
  clonedCompaniesForProduct.forEach((x) => {
    if (x.company.id === selection.companyId) {
      x.order = action.payload.order
    }
  })
  return {
    ...state,
    productOrderForm: {
      ...state.productOrderForm,
      orders,
      productOrderModal: {
        ...state.productOrderForm.productOrderModal,
        companiesForProduct: clonedCompaniesForProduct,
        orderToEdit
      }
    }
  }
}
const addToProductOrderModalOrder = (state, action) => {
  const { selection, companiesForProduct, productsTree } = state.productOrderForm.productOrderModal
  const product = productsTree
    .filter((pg) => pg.children !== null)
    .flatMap((pg) => pg.children)
    .find((p) => p.value === Number(selection.productId))
  const orderFound = companiesForProduct.find((o) => o.company.id === selection.companyId)
  const orderToAdd = JSON.parse(JSON.stringify(orderFound))
  orderToAdd.id = uuidv4()
  orderToAdd.product = { ...product }
  orderToAdd.status = PRODUCT_ORDER_STATUS_OLUSTURULAN
  orderToAdd.status_log = {
    [PRODUCT_ORDER_STATUS_OLUSTURULAN]: { date: new Date().toISOString() }
  }

  return {
    ...state,
    productOrderForm: {
      ...state.productOrderForm,
      orders: [...state.productOrderForm.orders, orderToAdd],
      productOrderModal: {
        ...state.productOrderForm.productOrderModal,
        companiesForProduct: [],
        selection: initialSelection(),
        orderToEdit: null
      }
    }
  }
}
const resetProductOrderModalOrder = (state, action) => {
  const companiesForProduct = state.productOrderForm.productOrderModal.companiesForProduct.map((t) => ({
    ...t,
    order: initOrder({ price: Number(t.paymentDetails.price), currency: t.paymentDetails.currency })
  }))

  return {
    ...state,
    productOrderForm: {
      ...state.productOrderForm,
      productOrderModal: {
        ...state.productOrderForm.productOrderModal,
        companiesForProduct
      }
    }
  }
}
const updateProductOrderModalProperty = (state, action) => {
  const productOrderModal = JSON.parse(JSON.stringify(state.productOrderForm.productOrderModal))
  set(productOrderModal, action.payload.name, action.payload.value)
  let orders = null
  if (action.payload.name === 'show' && action.payload.value === false) {
    set(productOrderModal, 'companiesForProduct', [])
    set(productOrderModal, 'selection', initialSelection())
    set(productOrderModal, 'isEditing', false)

    if (productOrderModal.orderToEdit) {
      orders = JSON.parse(JSON.stringify(state.productOrderForm.orders))
      const orderToAdd = productOrderModal.orderToEdit
      orders.push(orderToAdd)
      set(productOrderModal, 'orderToEdit', null)
    }
  }
  return {
    ...state,
    productOrderForm: {
      ...state.productOrderForm,
      productOrderModal: productOrderModal,
      orders: orders || state.productOrderForm.orders
    }
  }
}
const changeStatusFilter = (state, action) => {
  return {
    ...state,
    filters: {
      ...state.filters,
      status: action.payload
    }
  }
}
const updateProductOrderFormProperty = (state, action) => {
  const productOrderForm = cloneDeep(state.productOrderForm)
  set(productOrderForm, action.payload.name, action.payload.value)
  return {
    ...state,
    productOrderForm
  }
}
const fetchProductOrdersSuccess = (state, action) => {
  toast.success(`Ürün siparişleri yüklendi!`, toastOptions)
  return { ...state, ...{ list: action.payload.orders, paging: action.payload.paging, loading: false, error: null } }
}

const fetchProductOrdersStart = (state, action) => {
  return { ...state, ...{ list: [], loading: true, error: null, paging: initialPaging() } }
}

const fetchProductOrderStart = (state, action) => {
  return {
    ...state,
    productOrderForm: initialProductOrderForm()
  }
}

const fetchProductOrderSuccess = (state, action) => {
  toast.success(`Ürün siparişi yuklendi!`, toastOptions)
  const { orders, id, note, description, productsTree } = action.payload
  return {
    ...state,
    productOrderForm: {
      ...state.productOrderForm,
      loading: false,
      error: null,
      id,
      note,
      description,
      orders: orders || [],
      productOrderModal: {
        ...state.productOrderForm.productOrderModal,
        productsTree
      }
    }
  }
}
const fetchProductOrderFail = (state, action) => {
  toast.error(toasterMessages.errorWithMessage(action.error.message), toastErrorOptions)
  return {
    ...state,
    productOrderForm: {
      ...state.productOrderForm,
      loading: false,
      error: action.error.message
    }
  }
}

const onSearchClickFail = (state, action) => {
  toast.error(toasterMessages.errorWithTitle('Siparis ararken'), toastErrorOptions)
  return {
    ...state,
    loading: false
  }
}
const onSearchClickStart = (state, action) => {
  return {
    ...state,
    loading: true
  }
}

const onSearchClickSuccess = (state, action) => {
  toast.success(`Arama tamamlandi!`, toastOptions)
  return {
    ...state,
    ...{
      error: null,
      loading: false,
      list: action.payload.orders,
      paging: action.payload.paging
    }
  }
}
const onCompaniesForProductStart = (state, action) => {
  return {
    ...state,
    productOrderForm: {
      ...state.productOrderForm,
      productOrderModal: {
        ...state.productOrderForm.productOrderModal,
        loadingCompanies: true,
        companiesForProduct: []
      }
    }
  }
}
const onCompaniesForProductSuccess = (state, action) => {
  const companiesForProduct = action.payload.companies
    ? action.payload.companies.map((cfp) => ({
        ...cfp,
        order: initOrder({ price: cfp.paymentDetails.price, currency: cfp.paymentDetails.currency })
      }))
    : []
  return {
    ...state,
    productOrderForm: {
      ...state.productOrderForm,
      productOrderModal: {
        ...state.productOrderForm.productOrderModal,
        loadingCompanies: false,
        companiesForProduct
      }
    }
  }
}
const onCompaniesForProductFail = (state, action) => {
  toast.error(toasterMessages.errorWithTitle(`Şirketler yüklenirken`), toastErrorOptions)
  return {
    ...state,
    productOrderForm: {
      ...state.productOrderForm,
      productOrderModal: {
        ...state.productOrderForm.productOrderModal,
        loadingCompanies: false,
        error: action.error.message
      }
    }
  }
}
const saveProductOrderStart = (state, action) => {
  return {
    ...state,
    productOrderForm: {
      ...state.productOrderForm,
      loading: false,
      saving: true,
      error: null
    }
  }
}
const saveProductOrderSuccess = (state, action) => {
  toast.success(`Sipariş kaydedildi!`, toastOptions)
  return {
    ...state,
    productOrderForm: {
      ...state.productOrderForm,
      id: action.payload,
      saving: false,
      error: null
    }
  }
}
const changeProductOrderNotification = (state, action) => {
  const result = state.list.find((o) => o.id === action.payload.orderId)
  const productOrderRow = JSON.parse(JSON.stringify(result))
  const order = productOrderRow.order_fields.find((x) => x.id === action.payload.id)
  order.status_log[action.payload.status] = {
    ...order.status_log[action.payload.status],
    [action.payload.name]: action.payload.value
  }
  return {
    ...state,
    list: state.list.map((x) => (x.id === action.payload.orderId ? productOrderRow : x))
  }
}
const saveProductOrderFail = (state, action) => {
  toast.error(toasterMessages.errorWithTitle(`Siparisi kaydederken`), toastErrorOptions)
  return {
    ...state,
    productOrderForm: {
      ...state.productOrderForm,
      saving: false,
      error: action.error.message
    }
  }
}
const changeProductOrderStatusStart = (state, action) => {
  const list = JSON.parse(JSON.stringify(state.list))
  const order = list.find((x) => x.id === action.payload.id).order_fields.find((x) => x.id === action.payload.order_id)
  const currentStatus = order.status
  order.status_log[currentStatus].loading = true
  return {
    ...state,
    list
  }
}
const changeProductOrderStatusSuccess = (state, action) => {
  toast.success(`Siparis durumu degisirtirildi!`, toastOptions)
  const list = JSON.parse(JSON.stringify(state.list))
  const order = list.find((x) => x.id === action.payload.id)
  order.order_fields = order.order_fields.filter((x) => x.id !== action.payload.order_id)
  return {
    ...state,
    list
  }
}
const changeProductOrderStatusFail = (state, action) => {
  toast.error(toasterMessages.errorWithTitle(`Siparis durumu degisirken`), toastErrorOptions)
  const list = JSON.parse(JSON.stringify(state.list))
  const order = list.find((x) => x.id === action.payload.id).order_fields.find((x) => x.id === action.payload.order_id)
  const currentStatus = order.status
  order.status_log[currentStatus].loading = false
  return {
    ...state,
    list,
    error: action.error.message
  }
}
export const productOrdersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PRODUCT_ORDERS_CHANGE_NOTIFICATION:
      return changeProductOrderNotification(state, action)

    case actionTypes.REMOVE_PRODUCT_ORDER_ROW:
      return removeProductOrderRow(state, action)

    case actionTypes.SET_EDITED_ORDER_MODAL_PAYMENT_DETAILS:
      return setEditedOrderPaymentDetails(state, action)

    case actionTypes.ADD_TO_PRODUCT_ORDER_MODAL_ORDER:
      return addToProductOrderModalOrder(state, action)

    case actionTypes.RESET_PRODUCT_ORDER_MODAL_ORDER:
      return resetProductOrderModalOrder(state, action)

    case actionTypes.UPDATE_PRODUCT_ORDER_MODAL_PROPERTY:
      return updateProductOrderModalProperty(state, action)

    case actionTypes.UPDATE_PRODUCT_ORDER_FORM_PROPERTY:
      return updateProductOrderFormProperty(state, action)

    case actionTypes.FETCH_PRODUCT_ORDERS_LIST_SUCCESS:
      return fetchProductOrdersSuccess(state, action)

    case actionTypes.FETCH_PRODUCT_ORDERS_LIST_FAIL:
      return fetchProductOrdersFail(state, action)

    case actionTypes.FETCH_PRODUCT_ORDERS_LIST_START:
      return fetchProductOrdersStart(state, action)

    case actionTypes.FETCH_PRODUCT_ORDER_SUCCESS:
      return fetchProductOrderSuccess(state, action)

    case actionTypes.FETCH_PRODUCT_ORDER_FAIL:
      return fetchProductOrderFail(state, action)

    case actionTypes.FETCH_PRODUCT_ORDER_START:
      return fetchProductOrderStart(state, action)

    case actionTypes.PRODUCT_ORDERS_SEARCH_CLICK_START:
      return onSearchClickStart(state, action)

    case actionTypes.PRODUCT_ORDERS_SEARCH_CLICK_SUCCESS:
      return onSearchClickSuccess(state, action)

    case actionTypes.PRODUCT_ORDERS_SEARCH_CLICK_FAIL:
      return onSearchClickFail(state, action)

    case actionTypes.GET_COMPANIES_FOR_PRODUCT_START:
      return onCompaniesForProductStart(state, action)

    case actionTypes.GET_COMPANIES_FOR_PRODUCT_SUCCESS:
      return onCompaniesForProductSuccess(state, action)

    case actionTypes.GET_COMPANIES_FOR_PRODUCT_FAIL:
      return onCompaniesForProductFail(state, action)

    case actionTypes.SAVE_PRODUCT_ORDER_START:
      return saveProductOrderStart(state, action)

    case actionTypes.SAVE_PRODUCT_ORDER_SUCCESS:
      return saveProductOrderSuccess(state, action)

    case actionTypes.SAVE_PRODUCT_ORDER_FAIL:
      return saveProductOrderFail(state, action)

    case actionTypes.CHANGE_PRODUCT_ORDER_STATUS_FILTER:
      return changeStatusFilter(state, action)

    case actionTypes.PRODUCT_ORDERS_CHANGE_STATUS_START:
      return changeProductOrderStatusStart(state, action)

    case actionTypes.PRODUCT_ORDERS_CHANGE_STATUS_SUCCESS:
      return changeProductOrderStatusSuccess(state, action)

    case actionTypes.PRODUCT_ORDERS_CHANGE_STATUS_FAIL:
      return changeProductOrderStatusFail(state, action)

    default:
      return state
  }
}
