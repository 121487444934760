export const cloneWithMap = (value) => value.map((item) => ({ ...item }))

export const apiPath = {
  uretimSettingsDataIdProp: (name, id, prop) => `/api/settings/uretim/${name}/data/${id}/${prop}`,
  uretimSettingsV2DataId: (name, id) => `/api/v2/settings/uretim/${name}/data/${id}`,
  uretimSettingsDataId: (name, id) => `/api/settings/uretim/${name}/data/${id}`,
  uretimSettingsV2CloneId: (name, id) => `/api/v2/settings/uretim/${name}/clone/${id}`,
  uretimSettingsReport: (name) => `/api/reports/settings/${name}`,
  uretimSettingsData: (name) => `/api/settings/uretim/${name}/data`,
  uretimSettingsV2Data: (name) => `/api/v2/settings/uretim/${name}/data`,
  uretimSettingsLookup: (name, value) => `/api/settings/uretim/${name}/lookup?q=${encodeURIComponent(value)}`,
  uretimSettingsGetById: (name, id) => `/api/settings/uretim/${name}/${id}`,
  getMobilyaById: (id) => `/api/settings/uretim/mobilya/${id}`,
  uretimSettingsV2Search: (name, inputValue) => `/api/v2/settings/uretim/${name}?q=${encodeURIComponent(inputValue)}`,
  uretimSettingsSearch: (name, inputValue) => `/api/settings/uretim/${name}?q=${encodeURIComponent(inputValue)}`,
  uretimSettingsWithFields: (fields) => (fields ? `/api/settings/uretim?fields=${fields}` : '/api/settings/uretim'),
  uretimSettingsWithPage: (name, page) => `/api/settings/uretim/${name}?page=${page}`,
  uretimSettingsV2WithPage: (name, page) => `/api/v2/settings/uretim/${name}?page=${page}`,
  uretimSettingsV2GetById: (name, id) => `/api/v2/settings/uretim/${name}/${id}`,
  uretimSettingsList: () => '/api/settings/uretim/list',
  listProductGroup: () => '/api/settings/alim/product-group/list',
  lookupProductGroup: () => '/api/settings/alim/product-group/lookup',
  createProductGroup: () => '/api/settings/alim/product-group',
  alimProductGroupWithId: (id) => `/api/settings/alim/product-group/${id}`,
  alimProductGroupSearch: (inputValue) => `/api/settings/alim/product-group?q=${encodeURIComponent(inputValue)}`,
  authLogin: () => '/api/auth/login',
  authVerify: () => '/api/auth/verify',
  orderClone: () => `/api/orders/clone`,
  orderDuplicate: (id) => `/api/orders/duplicate/${id}`,
  ordersWithPage: (page, term) =>
    term ? `/api/orders?page=${page}&q=${encodeURIComponent(term)}` : `/api/orders?page=${page}`,
  users: () => '/api/users',
  user: (id) => `/api/users/${id}`,
  wardrobeOrders: () => `/api/orders/wardrobe`,
  wardrobeOrder: (id) => `/api/orders/wardrobe/${id}`,
  kitchenOrderList: () => `/api/orders/kitchen`,
  mobilyaOrderList: () => `/api/orders/mobilya`,
  mobilyaOrder: (id) => `/api/orders/mobilya/${id}`,
  kitchenOrder: (id) => `/api/orders/kitchen/${id}`,
  topluWardrobeAcilanReport: (id) => `/api/reports/toplu/wardrobe/acilan/${id}`,
  mobilyaOrderReport: (id) => `/api/reports/mobilya/${id}`,
  kitchenOrderReport: (id) => `/api/reports/kitchen/${id}`,
  acilanOrderReport: (id) => `/api/reports/wardrobe/acilan/${id}`,
  surmeOrderReport: (id) => `/api/reports/wardrobe/surme/${id}`,
  orderGroupKitchenReport: (id) => `/api/reports/orderGroup/kitchen/${id}`,
  orderGroupKitchenReportFiles: (id) => `/api/reports/orderGroup/kitchen/${id}/files`,
  orderGroupWardrobeReport: (id) => `/api/reports/orderGroup/wardrobe/${id}`,
  orderGroupKitchenMakinaReport: (id) => `/api/reports/orderGroup/makina/kitchen/${id}`,
  orderGroupWardrobeMakinaReport: (id) => `/api/reports/orderGroup/makina/wardrobe/${id}`,
  productOrdersChangeStatus: (id, order_id) => `/api/product-orders/${id}/order/${order_id}/status`,
  productOrdersSearch: (inputValue) => `/api/product-orders/list?q=${encodeURIComponent(inputValue)}`,
  productOrdersList: (page, status) => `/api/product-orders/list?page=${page}&status=${status}`,
  getProductOrder: (orderId) => `/api/product-orders/${orderId}`,
  getCompaniesProduct: (productId) => `/api/products/${productId}/companies`,
  productList: (page) => `/api/products/list?page=${page}`,
  productSearch: (inputValue) => `/api/products/list?q=${encodeURIComponent(inputValue)}`,
  product: (id) => `/api/products/${id}`,
  products: () => `/api/products`,
  companyList: (inputValue) => `/api/companies/list?q=${encodeURIComponent(inputValue)}`,
  companies: () => `/api/companies`,
  company: (id) => `/api/companies/${id}`,
  saveProductOrders: (id) => (id ? `/api/product-orders/${id}` : '/api/product-orders'),
  productTree: () => `/api/settings/alim/product_tree`,
  companySearch: (inputValue) => `/api/companies/lookup?q=${encodeURIComponent(inputValue)}`,
  customer: (id) => `/api/customers/${id}`,
  customerSearch: (inputValue) => `/api/customers/search?q=${encodeURIComponent(inputValue)}&has_projects=false`,
  muteahhitSearch: (inputValue) => `/api/customers/search?q=${encodeURIComponent(inputValue)}&has_projects=true`,
  customers: (page, term) =>
    term ? `/api/customers?q=${encodeURIComponent(term)}&page=${page}` : `/api/customers?page=${page}`,
  groupsWithPage: (page) => `/api/orders/groups?page=${page}`,
  groupEdit: (id) => `/api/orders/groups/${id}`,
  groupUpdate: (id) => `/api/orders/groups/${id}`,
  groupCreate: () => `/api/orders/groups`,
  ordersSearchByType: (type, term) => `/api/orders/${type}/search?q=${encodeURIComponent(term)}`,
  ordersSearch: (term) => `/api/orders/search?q=${encodeURIComponent(term)}`
}
export const URETIM_SETTINGS_ADMIN_ROLE = 'uretim-settings-admin'
export const USER_ADMIN_ROLE = 'user-admin'
export const paymentTypes = [
  { id: 'pesin', aciklama: 'Pesin' },
  { id: '1ayVadeli', aciklama: '1 ay vadeli' },
  { id: '2ayVadeli', aciklama: '2 ay vadeli' },
  { id: '3ayVadeli', aciklama: '3 ay vadeli' },
  { id: '4ayVadeli', aciklama: '4 ay vadeli' },
  { id: '5ayVadeli', aciklama: '5 ay vadeli' },
  { id: '6ayVadeli', aciklama: '6 ay vadeli' },
  { id: '7ayVadeli', aciklama: '7 ay vadeli' },
  { id: '8ayVadeli', aciklama: '8 ay vadeli' }
]
export const deliveryTypes = [
  { id: 'saat', aciklama: 'Saat' },
  { id: 'gun', aciklama: 'Gun' },
  { id: 'ay', aciklama: 'Ay' }
]
export const currencies = [
  { id: 'eur', aciklama: 'EUR' },
  { id: 'gbp', aciklama: 'GBP' },
  { id: 'tl', aciklama: 'TL' },
  { id: 'dollar', aciklama: 'Dolar' }
]
export const measures = [
  { id: 'adet', aciklama: 'Adet' },
  { id: 'kilo', aciklama: 'Kilo' },
  { id: 'palet', aciklama: 'Palet' },
  { id: 'paket', aciklama: 'Paket' },
  { id: 'metre-tul', aciklama: 'Metre-tül' },
  { id: 'metre-kare', aciklama: 'Metre kare' }
]

export const parcaTipiGrupArkaliklar = 'Arkaliklar'
export const parcaTipiGrupGovdeYan = 'Govde Yan'
export const parcaTipiGrupVasistasYanTabla = 'Vasistas Yan Tabla'
export const parcaTipiGrupBuzlukYanLambri = 'Buzluk Yan Lambri'
export const parcaTipiGrupKapaklar = 'Kapaklar'
export const parcaTipiGrupLambri = 'Lambri'
export const dolapAraDikme = 'DOLAP ARA DIKME'
export const parcaTipiToUpdateWhenUzunlukChanged = [dolapAraDikme]
export const groupsToUpdateWhenUzunlukChanged = [
  parcaTipiGrupArkaliklar,
  parcaTipiGrupGovdeYan,
  parcaTipiGrupVasistasYanTabla,
  parcaTipiGrupBuzlukYanLambri,
  parcaTipiGrupKapaklar,
  parcaTipiGrupLambri
]

export const parcaTipiGrupOptions = [
  { id: 'Arkaliklar', aciklama: parcaTipiGrupArkaliklar },
  { id: 'buzlukYanLambri', aciklama: parcaTipiGrupBuzlukYanLambri },
  { id: 'dekorasyon', aciklama: 'Dekorasyon' },
  { id: 'cekmeceArkasi', aciklama: 'Cekmece Arkasi' },
  { id: 'cekmeceTabani', aciklama: 'Cekmece Tabani' },
  { id: 'Govde', aciklama: 'Govde' },
  { id: 'govdeYan', aciklama: parcaTipiGrupGovdeYan },
  { id: 'Kapaklar', aciklama: parcaTipiGrupKapaklar },
  { id: 'Lambri', aciklama: parcaTipiGrupLambri },
  { id: 'Raf', aciklama: 'Raf' },
  { id: 'vasistasYanTabla', aciklama: parcaTipiGrupVasistasYanTabla }
]

export const aynaKenariOptions = [
  { id: 'islemsiz', aciklama: 'Islemsiz' },
  { id: 'kanal', aciklama: 'Kanal' },
  { id: 'ronde', aciklama: 'Ronde' },
  { id: 'bizote', aciklama: 'Bizote' }
]
export const kulpYonuOptions = [
  { id: 'kulpsuz', aciklama: 'Kulpsuz' },
  { id: 'yatay', aciklama: 'Yatay' },
  { id: 'dikey', aciklama: 'Dikey' }
]

export const cities = [
  { aciklama: 'Kibris', id: 100 },
  { aciklama: 'Adana', id: 1 },
  { aciklama: 'Adıyaman', id: 2 },
  { aciklama: 'Afyonkarahisar', id: 3 },
  { aciklama: 'Ağrı', id: 4 },
  { aciklama: 'Aksaray', id: 68 },
  { aciklama: 'Amasya', id: 5 },
  { aciklama: 'Ankara', id: 6 },
  { aciklama: 'Antalya', id: 7 },
  { aciklama: 'Ardahan', id: 75 },
  { aciklama: 'Artvin', id: 8 },
  { aciklama: 'Aydın', id: 9 },
  { aciklama: 'Balıkesir', id: 10 },
  { aciklama: 'Bartın', id: 74 },
  { aciklama: 'Batman', id: 72 },
  { aciklama: 'Bayburt', id: 69 },
  { aciklama: 'Bilecik', id: 11 },
  { aciklama: 'Bingöl', id: 12 },
  { aciklama: 'Bitlis', id: 13 },
  { aciklama: 'Bolu', id: 14 },
  { aciklama: 'Burdur', id: 15 },
  { aciklama: 'Bursa', id: 16 },
  { aciklama: 'Çanakkale', id: 17 },
  { aciklama: 'Çankırı', id: 18 },
  { aciklama: 'Çorum', id: 19 },
  { aciklama: 'Denizli', id: 20 },
  { aciklama: 'Diyarbakır', id: 21 },
  { aciklama: 'Düzce', id: 81 },
  { aciklama: 'Edirne', id: 22 },
  { aciklama: 'Elazığ', id: 23 },
  { aciklama: 'Erzincan', id: 24 },
  { aciklama: 'Erzurum', id: 25 },
  { aciklama: 'Eskişehir', id: 26 },
  { aciklama: 'Gaziantep', id: 27 },
  { aciklama: 'Giresun', id: 28 },
  { aciklama: 'Gümüşhane', id: 29 },
  { aciklama: 'Hakkari', id: 30 },
  { aciklama: 'Hatay', id: 31 },
  { aciklama: 'Iğdır', id: 76 },
  { aciklama: 'Isparta', id: 32 },
  { aciklama: 'İstanbul', id: 34 },
  { aciklama: 'İzmir', id: 35 },
  { aciklama: 'Kahramanmaraş', id: 46 },
  { aciklama: 'Karabük', id: 78 },
  { aciklama: 'Karaman', id: 70 },
  { aciklama: 'Kars', id: 36 },
  { aciklama: 'Kastamonu', id: 37 },
  { aciklama: 'Kayseri', id: 38 },
  { aciklama: 'Kırıkkale', id: 71 },
  { aciklama: 'Kırklareli', id: 39 },
  { aciklama: 'Kırşehir', id: 40 },
  { aciklama: 'Kilis', id: 79 },
  { aciklama: 'Kocaeli', id: 41 },
  { aciklama: 'Konya', id: 42 },
  { aciklama: 'Kütahya', id: 43 },
  { aciklama: 'Malatya', id: 44 },
  { aciklama: 'Manisa', id: 45 },
  { aciklama: 'Mardin', id: 47 },
  { aciklama: 'Mersin', id: 33 },
  { aciklama: 'Muğla', id: 48 },
  { aciklama: 'Muş', id: 49 },
  { aciklama: 'Nevşehir', id: 50 },
  { aciklama: 'Niğde', id: 51 },
  { aciklama: 'Ordu', id: 52 },
  { aciklama: 'Osmaniye', id: 80 },
  { aciklama: 'Rize', id: 53 },
  { aciklama: 'Sakarya', id: 54 },
  { aciklama: 'Samsun', id: 55 },
  { aciklama: 'Siirt', id: 56 },
  { aciklama: 'Sinop', id: 57 },
  { aciklama: 'Sivas', id: 58 },
  { aciklama: 'Şanlıurfa', id: 63 },
  { aciklama: 'Şırnak', id: 73 },
  { aciklama: 'Tekirdağ', id: 59 },
  { aciklama: 'Tokat', id: 60 },
  { aciklama: 'Trabzon', id: 61 },
  { aciklama: 'Tunceli', id: 62 },
  { aciklama: 'Uşak', id: 64 },
  { aciklama: 'Van', id: 65 },
  { aciklama: 'Yalova', id: 77 },
  { aciklama: 'Yozgat', id: 66 },
  { aciklama: 'Zonguldak', id: 67 }
]

export const toastOptions = {
  autoClose: 1500,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true
}
export const toastErrorOptions = {
  autoClose: false,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true
}
export const toastWarnOptions = {
  ...toastErrorOptions,
  autoClose: 5000,
}
export const toastOptionsPermenant = {
  autoClose: false,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true
}

export const localDateTimeStringOptions = {
  month: 'long',
  year: 'numeric',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit'
}
export const localDateStringOptions = { month: 'long', year: 'numeric', day: 'numeric' }
export const ALT_PERVAZ = 'Alt Pervaz'
export const UST_PERVAZ = 'Ust Pervaz'
export const SAG_PERVAZ = 'Sag Pervaz'
export const SOL_PERVAZ = 'Sol Pervaz'
export const SAG_GORUNEN_YAN = 'Sag Gorunen Yan'
export const SOL_GORUNEN_YAN = 'Sol Gorunen Yan'
export const ALT_CERCEVE = 'Alt Cerceve'
export const UST_CERCEVE = 'Ust Cerceve'
export const SAG_CERCEVE = 'Sag Cerceve'
export const SOL_CERCEVE = 'Sol Cerceve'
export const initPervazBaza = () => ({ uzun1: false, uzun2: false, kisa1: false, kisa2: false })

export const toasterMessages = {
  errorWithTitle: (title) => `${title} bir hata oluştu. Lütfen sayfayı yenileyin!`,
  errorWithMessage: (message) => `Sitede bir hata olustu(${message}), lütfen tekrar deneyin!`
}

export const AKSAM_AKSESUAR_GRUP_METAL_BAR = 'METAL BAR'
export const AKSAM_AKSESUAR_GRUP_DOLAP_ASKI_BORUSU = 'DOLAP ASKI BORUSU'
export const AKSAM_AKSESUAR_GRUP_VIDA_GURUBU = 'vida gurubu'

export const PRODUCT_ORDER_STATUS_OLUSTURULAN = 'olusturulan'
export const PRODUCT_ORDER_STATUS_TEKLIF_BEKLEYEN = 'teklif_bekleyen'
export const PRODUCT_ORDER_STATUS_TESLIM_BEKLEYEN = 'teslim_bekleyen'
export const PRODUCT_ORDER_STATUS_TAMAMLANAN = 'tamamlanan'
export const getNextStatus = (currentStatus) => {
  let nextStatus = ''
  switch (currentStatus) {
    case PRODUCT_ORDER_STATUS_OLUSTURULAN:
      nextStatus = PRODUCT_ORDER_STATUS_TEKLIF_BEKLEYEN
      break
    case PRODUCT_ORDER_STATUS_TEKLIF_BEKLEYEN:
      nextStatus = PRODUCT_ORDER_STATUS_TESLIM_BEKLEYEN
      break
    case PRODUCT_ORDER_STATUS_TESLIM_BEKLEYEN:
      nextStatus = PRODUCT_ORDER_STATUS_TAMAMLANAN
      break
    default:
      nextStatus = PRODUCT_ORDER_STATUS_TEKLIF_BEKLEYEN
      break
  }
  return nextStatus
}
