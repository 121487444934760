import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardTitle, Col } from 'reactstrap'

const UserList = (props) => {
  return props.list.map((s) => {
    return (
      <Col sm='12' md='6' lg='3' key={s.id}>
        <Card body>
          <CardTitle>{s.profile.displayName}</CardTitle>
          <Link className='btn btn-secondary float-end' to={'/kullanicilar/' + s.id}>
            Degistir
          </Link>
        </Card>
      </Col>
    )
  })
}

export default UserList
