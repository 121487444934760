import * as actionTypes from '../actions/actionTypes'
import { toast } from 'react-toastify'
import { toasterMessages, toastOptions, toastErrorOptions } from '../../shared/index'
import { initialPaging } from '../dataModels/CommonModals'
import set from 'lodash/set'
const initLookup = () => ({
  result: [],
  isLoading: false,
  isSelecting: false
})
export const initCloneOrderModalNew = () => ({
  id: 0,
  muteahhit: null,
  musteri: null,
  sicilno: '',
  konutno: '',
  teslimtarihi: '',
  type: '',
  dolapAdet: 0,
  dolapTakilacakOda: ''
})
export const initCloneOrderModal = () => ({
  show: false,
  saving: false,
  error: null,
  new: initCloneOrderModalNew()
})
const initialState = {
  list: [],
  paging: initialPaging(),
  loading: true,
  error: null,
  lookup: initLookup(),
  redirect: null,
  cloneOrderModal: initCloneOrderModal()
}

const fetchOrdersSuccess = (state, action) => {
  toast.success(`Siparisler yuklendi!`, toastOptions)
  return { ...state, ...{ list: action.payload.orders, paging: action.payload.paging, loading: false, error: null } }
}
const resetOrdersRedirect = (state, action) => {
  return { ...state, ...{ redirect: null } }
}
const fetchOrderStart = (state, action) => {
  return {
    ...state,
    ...{ list: [], loading: true, error: null, redirect: null, paging: initialPaging(), lookup: initLookup() }
  }
}

const fetchOrdersFail = (state, action) => {
  toast.error(toasterMessages.errorWithMessage(action.error.message), toastErrorOptions)
  return { ...state, ...{ loading: false, error: action.error.message } }
}
const updateCloneOrderModalProperty = (state, action) => {
  const newData = { ...state.cloneOrderModal.new }
  set(newData, action.payload.name, action.payload.value)
  return { ...state, cloneOrderModal: { ...state.cloneOrderModal, new: newData } }
}
const lookupStart = (state, action) => {
  return { ...state, lookup: { result: [], isLoading: true } }
}
const lookupSuccess = (state, action) => {
  return { ...state, lookup: { result: action.payload, isLoading: false } }
}
const lookupFail = (state, action) => {
  return { ...state, lookup: { result: [], isLoading: false } }
}
const duplicateOrderSuccess = (state, action) => {
  toast.success(`Siparis kopyalanmistir!`, toastOptions)
  return {
    ...state,
    ...{ redirect: action.payload }
  }
}
const toggleCloneOrderModal = (state, action) => {
  switch (action.payload.action) {
    case 'cancel':
      return { ...state, ...{ cloneOrderModal: initCloneOrderModal(), lookup: initLookup() } }
    case 'save':
      toast.success(`Siparis kopyalanmistir!`, toastOptions)
      return {
        ...state,
        ...{ cloneOrderModal: initCloneOrderModal(), lookup: initLookup(), redirect: action.payload.redirect }
      }
    case 'show':
      return {
        ...state,
        ...{
          cloneOrderModal: {
            new: { ...initCloneOrderModalNew(), id: action.payload.id, type: action.payload.type },
            show: true
          },
          lookup: initLookup()
        }
      }
    default:
      return state
  }
}

const cloneOrderStart = (state, action) => {
  return { ...state, ...{ cloneOrderModal: { ...state.cloneOrderModal, saving: true, error: null } } }
}
const cloneOrderFail = (state, action) => {
  toast.error(toasterMessages.errorWithMessage(action.error.message), toastErrorOptions)
  return { ...state, ...{ cloneOrderModal: { ...state.cloneOrderModal, saving: false, error: action.error.message } } }
}
// SEARCHING
const onSearchClickSuccess = (state, action) => {
  return {
    ...state,
    ...{
      error: null,
      loading: false,
      list: action.payload.orders,
      paging: action.payload.paging,
      lookup: []
    }
  }
}

const onSearchClickFail = (state, action) => {
  return {
    ...state,
    loading: false
  }
}
const onSearchClickStart = (state, action) => {
  return {
    ...state,
    loading: true
  }
}
export const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ORDERS_SUCCESS:
      return fetchOrdersSuccess(state, action)

    case actionTypes.FETCH_ORDERS_FAIL:
      return fetchOrdersFail(state, action)

    case actionTypes.FETCH_ORDERS_START:
      return fetchOrderStart(state, action)

    case actionTypes.CHANGE_CLONE_ORDER_MODAL_PROPERTY:
      return updateCloneOrderModalProperty(state, action)

    case actionTypes.ORDERS_CLONE_ORDER_MODAL_TOGGLE:
      return toggleCloneOrderModal(state, action)

    case actionTypes.ORDERS_CLONE_ORDER_MODAL_SAVE_START:
      return cloneOrderStart(state, action)
    case actionTypes.ORDERS_CLONE_ORDER_MODAL_SAVE_FAIL:
      return cloneOrderFail(state, action)
    case actionTypes.ORDERS_DUPLICATE_ORDER_SUCCESS:
      return duplicateOrderSuccess(state, action)

    case actionTypes.ORDERS_LOOKUP_START:
      return lookupStart(state, action)
    case actionTypes.ORDERS_LOOKUP_SUCCESS:
      return lookupSuccess(state, action)
    case actionTypes.ORDERS_LOOKUP_FAIL:
      return lookupFail(state, action)

    case actionTypes.ORDERS_SEARCH_CLICK_START:
      return onSearchClickStart(state, action)
    case actionTypes.ORDERS_SEARCH_CLICK_SUCCESS:
      return onSearchClickSuccess(state, action)
    case actionTypes.ORDERS_SEARCH_CLICK_FAIL:
      return onSearchClickFail(state, action)

    case actionTypes.ORDERS_RESET_REDIRECT:
      return resetOrdersRedirect(state, action)

    default:
      return state
  }
}
