import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input, Label } from 'reactstrap'
import RequiredFeedback from '../../../Common/RequiredFeedback'
import * as actions from '../../../../store/actions'
import { NavLink } from 'react-router-dom'
import Loading from '../../../Common/Loading'
import IsActiveBadge from '../../../Common/IsActiveBadge'
import NumberInput from '../../../Core/NumberInput'
import CamProfiliFields from "./CamProfiliFeilds";

const DefaultSettingsForm = (props) => {
  const dispatch = useDispatch()
  const { token } = useSelector((state) => state.auth)
  const {
    data: {
      description,
      formData: { id, aciklama, isactive, fiyat, uzunlukPayi, genislikPayi, note }
    },
    loading
  } = useSelector((state) => state.uretimSettings)
  const pathArray = props.match.path.split('/')
  const settingName = pathArray[3] //todo can we do this better?
  const settingId = props.match.params.id //todo can we do this better?
  const isCamProfili = settingName === 'camProfili'
  const isMobilya = settingName === 'mobilya'
  const handleSave = (e) => {
    e.preventDefault()
    const item = { id, aciklama, isactive }
    if (isCamProfili) {
      item.uzunlukPayi=uzunlukPayi
      item.genislikPayi=genislikPayi
    }
    if (isMobilya) {
      item.note=note
    }
    if (settingHasPrice()) {
      item.fiyat = Number(fiyat)
    }
    dispatch(actions.onSettingV2DataSave(token, settingName, item))
  }
  const settingHasPrice = () => {
    return (
      settingName === 'tezgah' ||
      settingName === 'suluk' ||
      settingName === 'camModeli' ||
      settingName === 'dekorasyon' ||
      settingName === 'camProfili' ||
      settingName === 'baza' ||
      settingName === 'govdeMalzemesi'
    )
  }
  const handleStateChange = (e) => {
    const name = e.target.name
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
    dispatch(actions.onSettingStateChange({ name, value }))
  }
  useEffect(() => {
    const handleFetch = () => dispatch(actions.fetchUretimSettingV2WithId(token, settingName, settingId))
    if (settingId) {
      handleFetch()
    }
  }, [settingId, dispatch, token, settingName])
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <h1>{description}</h1>
          <form onSubmit={handleSave}>
            <div className='form-group row'>
              <div className='form-group col-md-6'>
                <Label>Aciklama</Label>
                <Input
                  type='text'
                  name='data.formData.aciklama'
                  onChange={handleStateChange}
                  value={aciklama}
                  valid={aciklama && aciklama.length > 0}
                  invalid={!aciklama}
                  required
                />
                <RequiredFeedback />
              </div>
              <div className='form-group col-md-6'>
                <Label>Aktif/Pasif</Label>
                <IsActiveBadge name='data.formData.isactive' value={isactive} onChange={handleStateChange} />
              </div>
            </div>
            {settingHasPrice() && (
              <div className='form-group col-md-6'>
                <NumberInput
                  label='Fiyat €'
                  step='0.001'
                  name='data.formData.fiyat'
                  value={fiyat}
                  onChange={handleStateChange}
                  required
                />
              </div>
            )}
            {isCamProfili && <CamProfiliFields handleStateChange={handleStateChange} uzunlukPayi={uzunlukPayi} genislikPayi={genislikPayi} />}
            {isMobilya &&  <div className='col-md-12'>
              <Label htmlFor='not'>Not</Label>
              <textarea
                  className='form-control'
                  value={note}
                  onChange={handleStateChange}
                  name='data.formData.note'
                  id='note'
                  rows='3'
              />
            </div>}
            <div className='form-group row'>
              <div className='form-group col-md-12'>
                <NavLink className='btn btn-secondary float-start' to={`/uretim/ayarlar/${settingName}`}>
                  Vazgec
                </NavLink>
                <button type='submit' className='btn btn-primary float-end'>
                  <i className='fas fa-save' />
                  Kaydet
                </button>
              </div>
            </div>
          </form>
        </>
      )}
    </>
  )
}

export default DefaultSettingsForm
