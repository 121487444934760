import React from 'react'
import NumberInput from '../../../Core/NumberInput'
import { Card, CardBody, CardHeader } from 'reactstrap'
import Select from '../../../Core/Select'

const SurmeDolapKapaklar = (props) => {
  return (
    <Card className='shadow mb-2'>
      <CardHeader>Kapak Adet</CardHeader>
      <CardBody>
        <div className='form-group row'>
          <div className='form-group col-12 col-sm-4 col-lg-2'>
            <NumberInput
              label='Ayna/Cam Kapak Adet'
              name='camKapakAdet'
              value={props.camKapakAdet}
              onChange={props.onChange}
            />
          </div>
          <div className='form-group col-12 col-sm-4 col-lg-2'>
            <NumberInput
              label='Ahsap Kapak Adet'
              name='ahsapKapakAdet'
              value={props.ahsapKapakAdet}
              onChange={props.onChange}
            />
          </div>
          {Number(props.camKapakAdet) > 0 && (
            <div className='form-group col-12 col-sm-4 col-lg-4'>
              <Select
                name='dolapAynaModeli'
                value={props.dolapAynaModeli}
                onChange={props.updateSelect}
                text='Dolap Ayna Modeli'
                options={props.lookup.result}
                onInputChange={(e) => props.lookupSetting(e, 'dolapAynaModeli')}
                onSelectMenuClose={props.onSelectMenuClose}
                isLoading={props.lookup.isLoading}
              />
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  )
}

export default SurmeDolapKapaklar
