import React, { PureComponent } from 'react'
import Select from '../../../Core/Select'
import { Card, CardHeader, CardBody } from 'reactstrap'

class SiparisFields extends PureComponent {
  render() {
    return (
      <Card className='shadow mb-2'>
        <CardHeader>Siparis Ozellikleri</CardHeader>
        <CardBody>
          <div className='form-group row'>
            <div className='form-group col-md-6 col-lg-4'>
              <Select
                name='kulpmodeli'
                value={this.props.kulpmodeli}
                onChange={this.props.updateSelect}
                text='Kulp Modeli'
                options={this.props.lookup.result}
                onSelectMenuClose={this.props.onSelectMenuClose}
                onInputChange={(e) => this.props.lookupSetting(e, 'kulpModeli')}
                isLoading={this.props.lookup.isLoading}
              />
            </div>
            <div className='form-group col-md-6 col-lg-4'>
              <Select
                name='kapakmodeli'
                value={this.props.kapakmodeli}
                onChange={this.props.updateSelect}
                text='Kapak Modeli'
                options={this.props.lookup.result}
                onSelectMenuClose={this.props.onSelectMenuClose}
                onInputChange={(e) => this.props.lookupSetting(e, 'kapakModel')}
                isLoading={this.props.lookup.isLoading}
              />
            </div>
            <div className='form-group col-md-6 col-lg-4'>
              <Select
                name='kapakrenk'
                value={this.props.kapakrenk}
                onChange={this.props.updateSelect}
                text='Kapak Renk'
                options={
                  this.props.kapakmodeli && this.props.kapakmodeli.kapakRenk ? this.props.kapakmodeli.kapakRenk : []
                }
              />
            </div>
            <div className='form-group col-md-6 col-lg-4'>
              <Select
                name='kapakmalzemesi'
                value={this.props.kapakmalzemesi}
                onChange={this.props.updateSelect}
                text='Kapak Malzemesi'
                options={
                  this.props.kapakmodeli && this.props.kapakmodeli.kapakRenk ? this.props.kapakmodeli.kapakRenk : []
                }
              />
            </div>
            <div className='form-group col-md-6 col-lg-4'>
              <Select
                name='kapakdesenyonu'
                value={this.props.kapakdesenyonu}
                onChange={this.props.updateSelect}
                text='Kapak Desen Yonu'
                options={this.props.lookup.result}
                onSelectMenuClose={this.props.onSelectMenuClose}
                onInputChange={(e) => this.props.lookupSetting(e, 'kapakDesenYonu')}
                isLoading={this.props.lookup.isLoading}
              />
            </div>

            <div className='form-group col-md-6 col-lg-4'>
              <Select
                name='govderengi'
                value={this.props.govderengi}
                onChange={this.props.updateSelect}
                text='Govde Rengi'
                options={this.props.lookup.result}
                onSelectMenuClose={this.props.onSelectMenuClose}
                onInputChange={(e) => this.props.lookupSetting(e, 'govdeRengi')}
                isLoading={this.props.lookup.isLoading}
              />
            </div>
            <div className='form-group col-md-6 col-lg-4'>
              <Select
                name='govdemalzemesi'
                value={this.props.govdemalzemesi}
                onChange={this.props.updateSelect}
                text='Govde Malzemesi'
                options={this.props.lookup.result}
                onSelectMenuClose={this.props.onSelectMenuClose}
                onInputChange={(e) => this.props.lookupSetting(e, 'govdeMalzemesi')}
                isLoading={this.props.lookup.isLoading}
              />
            </div>
            <div className='form-group col-md-6'>
              <Select
                name='cekmecesistemi'
                value={this.props.cekmecesistemi}
                onChange={this.props.updateSelect}
                text='Cekmece Sistemi'
                options={this.props.lookup.result}
                onSelectMenuClose={this.props.onSelectMenuClose}
                onInputChange={(e) => this.props.lookupSetting(e, 'cekmeceSistemi')}
                isLoading={this.props.lookup.isLoading}
              />
            </div>

            <div className='form-group col-md-6'>
              <label htmlFor='ozelistekler'>Ozel istekler</label>
              <textarea
                className='form-control'
                onChange={this.props.updateMutfakState}
                value={this.props.ozelistekler}
                name='ozelistekler'
                id='ozelistekler'
                rows='3'
              />
            </div>
          </div>
        </CardBody>
      </Card>
    )
  }
}
export default SiparisFields
