import * as actionTypes from '../actions/actionTypes'
import set from 'lodash/set'
import cloneDeep from 'lodash/cloneDeep'
import { toast } from 'react-toastify'
import {
  ALT_PERVAZ,
  SAG_PERVAZ,
  SOL_PERVAZ,
  toastOptions,
  toastErrorOptions,
  toastOptionsPermenant,
  UST_PERVAZ,
  UST_CERCEVE,
  SAG_CERCEVE,
  SAG_GORUNEN_YAN,
  SOL_CERCEVE,
  SOL_GORUNEN_YAN,
  ALT_CERCEVE,
  toasterMessages,
  initPervazBaza,
  parcaTipiToUpdateWhenUzunlukChanged,
  groupsToUpdateWhenUzunlukChanged,
  parcaTipiGrupGovdeYan,
  parcaTipiGrupVasistasYanTabla,
  parcaTipiGrupKapaklar
} from '../../shared'
import { initParcaModal, initParcaModalNew } from '../dataModels/ParcaModals'
import { initAksamModal, initAksamModalNew } from '../dataModels/AksamModal'
import { initialPaging, initLookup } from '../dataModels/CommonModals'
import { initAksesuarModal, initAksesuarModalNew } from '../dataModels/AksesuarModal'
const initPervazlar = () => [
  { name: ALT_PERVAZ, isActive: false, en: 12, boy: 0, ozellik: '', baza: { ...initPervazBaza() } },
  { name: UST_PERVAZ, isActive: false, en: 12, boy: 0, ozellik: '', baza: { ...initPervazBaza() } },
  { name: SAG_PERVAZ, isActive: false, en: 8, boy: 0, ozellik: '', baza: { ...initPervazBaza() } },
  { name: SOL_PERVAZ, isActive: false, en: 8, boy: 0, ozellik: '', baza: { ...initPervazBaza() } },
  { name: SAG_GORUNEN_YAN, isActive: false, en: 0, boy: 0, ozellik: '', baza: { ...initPervazBaza() } },
  { name: SOL_GORUNEN_YAN, isActive: false, en: 0, boy: 0, ozellik: '', baza: { ...initPervazBaza() } },
  { name: ALT_CERCEVE, isActive: false, en: 0, boy: 0, ozellik: '' },
  { name: UST_CERCEVE, isActive: false, en: 0, boy: 0, ozellik: '' },
  { name: SAG_CERCEVE, isActive: false, en: 0, boy: 0, ozellik: '' },
  { name: SOL_CERCEVE, isActive: false, en: 0, boy: 0, ozellik: '' }
]
const initModal = () => ({
  show: false,
  isNew: false,
  loading: false,
  index: 0,
  fiyat: 0
})

const initSettingFormData = () => ({
  id: '',
  aciklama: '',
  isactive: false
})
const initSettingData = () => ({
  name: '',
  description: '',
  formData: initSettingFormData(),
  list: []
})
const initialState = {
  error: null,
  loading: true,
  downloading: false,
  setting: null,
  paging: initialPaging(),
  editModal: initModal(),
  parcaModal: initParcaModal(),
  aksamModal: initAksamModal(),
  aksesuarModal: initAksesuarModal(),
  list: [],
  lookup: initLookup(),
  referenceData: {},
  data: initSettingData()
}

const uretimSettingListStart = (state, action) => {
  return { ...state, ...{ error: null, loading: true, list: [], paging: initialPaging() } }
}

const uretimSettingListSuccess = (state, action) => {
  toast.success(`Ayarlar yuklendi!`, toastOptions)
  return {
    ...state,
    error: null,
    loading: false,
    list: action.payload,
    data: initSettingData()
  }
}

const uretimSettingListFail = (state, action) => {
  toast.error(toasterMessages.errorWithTitle(`Ayarlar yuklenirken`), toastErrorOptions)
  return {
    ...state,
    error: action.error,
    loading: false,
    data: initSettingData()
  }
}

const getSettingStart = (state, action) => {
  return {
    ...state,
    error: null,
    loading: true,
    setting: null,
    paging: initialPaging(),
    lookup: initLookup()
  }
}
const getSettingV2WithIdStart = (state, action) => {
  return {
    ...state,
    ...{
      error: null,
      loading: true
    }
  }
}
const getSettingV2WithIdSuccess = (state, action) => {
  toast.success(`Ayar ${action.payload.aciklama} yuklenmistir!`, toastOptions)
  return {
    ...state,
    error: null,
    loading: false,
    data: {
      ...state.data,
      formData: { ...action.payload },
      list: []
    },
    lookup: initLookup()
  }
}
const getSettingSuccess = (state, action) => {
  toast.success(`Ayar yuklenmistir!`, toastOptions)
  const { result, paging, referenceData } = action.payload
  return {
    ...state,
    ...{
      error: null,
      loading: false,
      setting: result,
      paging: paging,
      referenceData,
      lookup: initLookup()
    }
  }
}
const getSettingV2Success = (state, action) => {
  toast.success(`Ayar ${state.data.description} yuklenmistir!`, toastOptions)
  return {
    ...state,
    error: null,
    loading: false,
    data: {
      ...state.data,
      list: action.payload.result,
      formData: initSettingFormData()
    },
    paging: action.payload.paging,
    referenceData: {},
    lookup: initLookup()
  }
}

const getSettingFail = (state, action) => {
  toast.error(toasterMessages.errorWithMessage(action.error.message), toastErrorOptions)

  return {
    ...state,
    error: action.error,
    loading: false,
    setting: null,
    data: { ...state.data, list: [], formData: initSettingFormData() },
    paging: initialPaging(),
    lookup: initLookup()
  }
}
const onSettingSelectValueChange = (state, action) => {
  const { name, selected, index } = action.payload
  const currentData = cloneDeep(state.setting.data)
  set(currentData[index], name, selected)
  return { ...state, ...{ setting: { ...state.setting, data: currentData } } }
}
const onSettingPropertyChangeInArray = (state, action) => {
  const { name, selected, index } = action.payload
  const currentData = cloneDeep(state.setting.data)
  currentData[index][name] = selected ? selected.map((item) => ({ id: item.id, aciklama: item.aciklama })) : []
  return { ...state, ...{ setting: { ...state.setting, data: currentData } } }
}
const onSettingPervazPropertyChange = (state, action) => {
  const { index, pervazName, property, value } = action.payload
  const currentData = cloneDeep(state.setting.data)
  const pervaz = currentData[index].pervazlar.find((x) => x.name === pervazName)

  if (property === 'isActive' && !value) {
    let pervazEn = 0
    if (pervazName === SAG_PERVAZ || pervazName === SOL_PERVAZ) {
      pervazEn = 8
    } else if (pervazName === ALT_PERVAZ || pervazName === UST_PERVAZ) {
      pervazEn = 12
    }
    pervaz.en = pervazEn
    pervaz.boy = 0
    pervaz.ozellik = ''
  }
  pervaz[property] = value
  return { ...state, ...{ setting: { ...state.setting, data: currentData } } }
}
const onSettingStateChange = (state, action) => {
  const { name, value } = action.payload
  const clonedState = cloneDeep(state)
  set(clonedState, name, value)
  return clonedState
}
const onSettingPropertyChange = (state, action) => {
  const { row, name, value } = action.payload.event
  const currentData = cloneDeep(state.setting.data)
  set(currentData[row], name, value)

  return { ...state, ...{ setting: { ...state.setting, data: currentData } } }
}
const onRowAdded = (state, action) => {
  let newSettingData = { id: null, aciklama: '', isactive: true }
  switch (state.setting.name) {
    case 'aksam':
      newSettingData.tip = 'Imalat'
      break
    case 'aksesuar':
      newSettingData.tip = 'Imalat'
      break
    case 'parcaTipi':
      newSettingData.grup = 'Kapaklar'
      newSettingData.bandTipi = {
        uzunUst: {},
        uzunAlt: {},
        kisaSag: {},
        kisaSol: {}
      }
      break
    case 'kapakRenk':
      newSettingData.bandRengi = {}
      break
    case 'dolapAynaModeli':
      newSettingData.aynaRengi = ''
      newSettingData.aynaKenari = null
      break
    case 'govdeRengi':
      newSettingData.bandRengi = {}
      break
    case 'parca':
      newSettingData.genislik = 0
      newSettingData.uzunluk = 0
      newSettingData.boyut = 0
      newSettingData.tip = null
      break
    case 'kapama':
      newSettingData.en = 0
      newSettingData.boy = 0
      newSettingData.bandTipi = {
        uzunUst: {},
        uzunAlt: {},
        kisaSag: {},
        kisaSol: {}
      }
      break
    case 'kapakModel':
      newSettingData.kapakRenk = []
      break
    case 'surmeKulpVeMekanizma':
      newSettingData.surmeKulp = ''
      newSettingData.mekanizma = ''
      newSettingData.surmeKapakCalismaPayi = {
        enEkle: 0,
        boyEkle: 0,
        enCikar: 0,
        boyCikar: 0
      }
      newSettingData.kapakKesimOlcusu = {
        enEkle: 0,
        boyEkle: 0,
        enCikar: 0,
        boyCikar: 0
      }
      newSettingData.aynaKesimOlcusu = {
        enEkle: 0,
        boyEkle: 0,
        enCikar: 0,
        boyCikar: 0
      }
      break
    case 'duvarDolabiTipi':
      newSettingData.pervazlar = initPervazlar()
      break
    case 'kulpModeli':
      newSettingData.ebatliKulp = false
      newSettingData.kapakBoyuBuyuk = 0
      newSettingData.kapakBoyuKucuk = 0
      break
    case 'cekmeceSistemi':
      newSettingData.cekmeceArkasiEnCikar = 0
      newSettingData.cekmeceArkasiBoyCikarBordursuz = 0
      newSettingData.cekmeceArkasiBoyCikar = 0
      newSettingData.cekmeceTabaniEnCikar = 0
      newSettingData.cekmeceTabaniBoyCikar = 0
      newSettingData.tabanMelamiKalinligi = 0
      break
    case 'mobilya':
    case 'modul':
      newSettingData.aksesuarlar = []
      newSettingData.parcalar = []
      newSettingData.aksamlar = []
      break
    default:
      break
  }
  const cloneData = cloneDeep(state.setting.data)
  cloneData.push(newSettingData)
  return {
    ...state,
    ...{
      setting: { ...state.setting, data: cloneData },
      ...{ editModal: { ...state.editModal, show: true, index: cloneData.length - 1, isNew: true } }
    }
  }
}
const onSettingSelectedSuccess = (state, action) => {
  return {
    ...state,
    data: {
      ...state.data,
      name: action.payload.name,
      description: action.payload.description
    }
  }
}
const onSettingV2DeleteSuccess = (state, action) => {
  toast.success(`Silme islemi tamamlandi!`, toastOptions)
  const list = state.data.list.filter((x) => x.id !== action.payload)
  return { ...state, data: { ...state.data, list } }
}
const onSettingV2DeleteFail = (state, action) => {
  toast.error(toasterMessages.errorWithMessage(action.error.message), toastErrorOptions)
  return state
}
const onRowRemoved = (state, action) => {
  const currentData = cloneDeep(state.setting.data)
  currentData.splice(action.payload.index, 1)
  toast.success(`Degisikler kaydedilmistir!`, toastOptions)
  return { ...state, ...{ setting: { ...state.setting, data: currentData } } }
}
const onRowSavedStart = (state, action) => {
  return { ...state, ...{ editModal: { ...state.editModal, loading: true } } }
}
const onRowSavedSuccess = (state, action) => {
  const clonedData = cloneDeep(state.setting.data)
  const row = clonedData.find((x) => x.id === null)
  set(row, 'id', action.payload.id)
  if (action.payload.message !== '') {
    toast.info(action.payload.message, toastOptionsPermenant)
  }
  toast.success(`Degisikler kaydedilmistir!`, toastOptions)
  return {
    ...state,
    ...{ editModal: { ...state.editModal, loading: false }, setting: { ...state.setting, data: clonedData } }
  }
}
const onRowSavedFail = (state, action) => {
  toast.error(toasterMessages.errorWithMessage(action.error.message), toastErrorOptions)
  return { ...state, ...{ editModal: { ...state.editModal, loading: false } } }
}
const onSettingV2SaveStart = (state, action) => {
  return { ...state, ...{ loading: true } }
}
const onSettingV2SaveSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    data: {
      ...state.data,
      formData: initSettingFormData()
    }
  }
}
const onSettingV2SaveFail = (state, action) => {
  toast.error(toasterMessages.errorWithMessage(action.error.message), toastErrorOptions)
  return { ...state, ...{ loading: false } }
}
const onSearchKapakRenkSuccess = (state, action) => {
  return { ...state, lookup: { result: action.payload.data, isLoading: false } }
}

const onSearchKapakRenkFail = (state, action) => {
  return { ...state, lookup: initLookup() }
}

const onSearchParcaTipiSuccess = (state, action) => {
  return { ...state, lookup: { result: action.payload.data, isLoading: true } }
}

const onSearchParcaTipiFail = (state, action) => {
  return { ...state, lookup: initLookup() }
}
const onToggleEditModal = (state, action) => {
  const show = !state.editModal.show
  return { ...state, ...{ lookup: initLookup(), editModal: { ...state.editModal, index: action.payload.index, show } } }
}

// PARCA MODAL
const updateModulParcaModalNew = (state, action) => {
  const { name, value } = action.payload
  const newData = { ...state.parcaModal.new, [name]: value }
  if (name === 'parca') {
    newData.genislik = value.genislik
    newData.uzunluk = value.uzunluk
    newData.boyut = value.boyut || 18
  }
  return { ...state, ...{ parcaModal: { ...state.parcaModal, new: newData } } }
}
const updateModulParcaModal = (state, action) => {
  const { index, value, name } = action.payload
  const resultedData = cloneDeep(state.parcaModal.data)
  const parca = resultedData[index]
  //TODO: This logic is repeated multiple times
  if (
    name === 'uzunluk' &&
    (parca.tip.grup === parcaTipiGrupVasistasYanTabla || parca.tip.grup === parcaTipiGrupGovdeYan)
  ) {
    const hasVasistasYanTabla = resultedData.some((p) => p.tip.grup === parcaTipiGrupVasistasYanTabla)
    const currentValue = Number(parca[name])
    const nextValue = Number(value)
    const difference = nextValue - currentValue
    parca[name] = value
    resultedData.forEach((parcaToUpdate, arrayIndex) => {
      if (
        (groupsToUpdateWhenUzunlukChanged.indexOf(parcaToUpdate.tip.grup) > -1 ||
          parcaTipiToUpdateWhenUzunlukChanged.indexOf(parcaToUpdate.tip.aciklama) > -1) &&
        arrayIndex !== index
      ) {
        if (
          hasVasistasYanTabla &&
          parcaTipiGrupKapaklar === parcaToUpdate.tip.grup &&
          Number(parcaToUpdate.miktar) > 1
        ) {
          parcaToUpdate[name] = Number(parcaToUpdate[name]) + difference / Number(parcaToUpdate.miktar)
        } else {
          parcaToUpdate[name] = Number(parcaToUpdate[name]) + difference
        }
      }
    })
  } else {
    parca[name] = value
  }
  return { ...state, ...{ parcaModal: { ...state.parcaModal, data: resultedData } } }
}
const errorModulParcaModal = (state, action) => {
  toast.error(toasterMessages.errorWithTitle(`Parca kaydedilirken`), toastErrorOptions)
  return state
}
const toggleModulParcaModal = (state, action) => {
  switch (action.payload.action) {
    case 'cancel':
      return {
        ...state,
        parcaModal: {
          modulId: '',
          ...initParcaModal()
        }
      }
    case 'add':
      const { genislik, uzunluk, miktar, boyut, isactive, parca } = state.parcaModal.new
      if (state.parcaModal.data.map((x) => x.id).includes(~~parca.id)) {
        toast.error(`Parca listede mevcut!`, { ...toastOptions, autoClose: 3000 })
        return state
      }
      const newParca = { ...parca, genislik, uzunluk, miktar, boyut, isactive }
      const cloneData = [...state.parcaModal.data, newParca]
      return { ...state, ...{ parcaModal: { ...state.parcaModal, data: cloneData, new: initParcaModalNew() } } }
    case 'delete':
      const newData = [...state.parcaModal.data]
      newData.splice(action.payload.index, 1)
      return { ...state, parcaModal: { ...state.parcaModal, data: newData } }
    case 'save':
      toast.success(`Parcalar kaydedilmistir!`, toastOptions)
      const editedParcalar = cloneDeep(state.parcaModal.data)
      const moduller = cloneDeep(state.setting.data)
      moduller.find((x) => x.id === action.payload.modulId).parcalar = editedParcalar
      return {
        ...state,
        setting: {
          ...state.setting,
          data: moduller
        },
        parcaModal: {
          modulId: '',
          ...initParcaModal()
        }
      }
    default:
      const modul = state.setting.data[action.payload.index]
      const parcalar = cloneDeep(modul.parcalar)
      return {
        ...state,
        ...{
          parcaModal: {
            ...state.parcaModal,
            show: !state.parcaModal.show,
            index: action.payload.index,
            modulId: modul.id,
            data: parcalar
          }
        }
      }
  }
}

//REPORT FUNCTIONS
const onDownloadReportStart = (state, action) => {
  return {
    ...state,
    ...{
      downloading: true,
      error: null
    }
  }
}
const onDownloadReportSuccess = (state, action) => {
  const { data, reportName } = action.payload
  const url = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `${reportName}.xlsx`) //or any other extension
  document.body.appendChild(link)
  link.click()
  return {
    ...state,
    ...{
      downloading: false,
      error: null
    }
  }
}
const onDownloadReportFail = (state, action) => {
  toast.error(toasterMessages.errorWithTitle(`Rapor indirilirken`), toastErrorOptions)
  return {
    ...state,
    ...{
      downloading: false,
      error: action.payload
    }
  }
}
// SEARCHING
const onSearchV2ClickSuccess = (state, action) => {
  toast.success(`Ayar ${action.payload.result.description} yuklenmistir!`, toastOptions)
  return {
    ...state,
    error: null,
    loading: false,
    paging: action.payload.paging,
    data: {
      ...state.data,
      list: action.payload.result
    },
    lookup: []
  }
}
const onSearchClickSuccess = (state, action) => {
  toast.success(`Ayar ${action.payload.result.description} yuklenmistir!`, toastOptions)
  return {
    ...state,
    ...{
      error: null,
      loading: false,
      setting: action.payload.result,
      paging: action.payload.paging,
      lookup: []
    }
  }
}
const onSearchClickFail = (state, action) => {
  return {
    ...state,
    loading: false
  }
}
const onSearchClickStart = (state, action) => {
  return {
    ...state,
    loading: true
  }
}
// AKSAM MODAL
const toggleModulAksamModal = (state, action) => {
  switch (action.payload.action) {
    case 'cancel':
      return { ...state, ...{ aksamModal: initAksamModal() } }
    case 'add':
      const cloneData = cloneDeep(state.aksamModal.data)
      const newAksam = cloneDeep(state.aksamModal.new.aksam)
      if (cloneData.map((x) => x.id).includes(~~newAksam.id)) {
        toast.error(`Aksam listede mevcut!`, { ...toastOptions, autoClose: 3000 })
        return state
      }
      newAksam.miktar = state.aksamModal.new.miktar
      newAksam.id = ~~state.aksamModal.new.aksam.id
      cloneData.push(newAksam)
      return { ...state, ...{ aksamModal: { ...state.aksamModal, data: cloneData, new: initAksamModalNew() } } }
    case 'delete':
      let newData = cloneDeep(state.aksamModal.data)
      newData = newData.filter((x) => {
        return x.id !== action.payload.id
      })
      return { ...state, ...{ aksamModal: { ...state.aksamModal, data: newData } } }
    case 'save':
      toast.success(`Aksamlar kaydedilmistir!`, toastOptions)
      const newModuller = cloneDeep(state.setting.data)
      newModuller[state.aksamModal.index].aksamlar = [...state.aksamModal.data]
      return {
        ...state,
        ...{
          setting: {
            ...state.setting,
            data: newModuller
          },
          aksamModal: initAksamModal()
        }
      }
    default:
      const { index } = action.payload
      const modul = state.setting.data[index]
      return {
        ...state,
        ...{
          aksamModal: {
            ...state.aksamModal,
            show: !state.aksamModal.show,
            index,
            modulId: modul.id,
            data: [...modul.aksamlar]
          }
        }
      }
  }
}
const updateModulAksamModalNew = (state, action) => {
  return {
    ...state,
    ...{
      aksamModal: { ...state.aksamModal, new: { ...state.aksamModal.new, [action.payload.name]: action.payload.value } }
    }
  }
}
const updateModulAksamModal = (state, action) => {
  const resultedData = state.aksamModal.data.map((value, index) => {
    const copiedValue = { ...value }
    if (~~action.payload.row === index) {
      copiedValue[action.payload.name] = action.payload.value
    }
    return copiedValue
  })
  return { ...state, ...{ aksamModal: { ...state.aksamModal, data: resultedData } } }
}
// AKSESUAR MODAL
const updateModulAksesuarModalNew = (state, action) => {
  return {
    ...state,
    ...{
      aksesuarModal: {
        ...state.aksesuarModal,
        new: { ...state.aksesuarModal.new, [action.payload.name]: action.payload.value }
      }
    }
  }
}
const updateModulAksesuarModal = (state, action) => {
  const clonedAksesuarModal = cloneDeep(state.aksesuarModal)
  set(clonedAksesuarModal, action.payload.name,action.payload.value)
  return { ...state, ...{ aksesuarModal: clonedAksesuarModal } }
}
const toggleModulAksesuarModal = (state, action) => {
  switch (action.payload.action) {
    case 'cancel':
      return { ...state, ...{ aksesuarModal: initAksesuarModal() } }
    case 'add':
      const newAksesuar = {
        ...state.aksesuarModal.new.aksesuar,
        miktar: state.aksesuarModal.new.miktar,
        not: state.aksesuarModal.new.not,
        id: ~~state.aksesuarModal.new.aksesuar.id
      }
      const existingData = [...state.aksesuarModal.data, newAksesuar]
      return {
        ...state,
        ...{ aksesuarModal: { ...state.aksesuarModal, data: existingData, new: initAksesuarModalNew() } }
      }
    case 'delete':
      const newData = state.aksesuarModal.data.filter((x) => {
        return x.id !== action.payload.id
      })

      return { ...state, ...{ aksesuarModal: { ...state.aksesuarModal, data: newData } } }
    case 'save':
      toast.success(`Aksesuar kaydedilmistir!`, toastOptions)
      const newModuller = state.setting.data.map((value, index) => {
        let clonedAksesuar = { ...value }
        if (index === ~~state.aksesuarModal.index) {
          clonedAksesuar.aksesuarlar = [...state.aksesuarModal.data]
        }
        return clonedAksesuar
      })
      return {
        ...state,
        ...{
          setting: {
            ...state.setting,
            data: newModuller
          },
          aksesuarModal: initAksesuarModal()
        }
      }
    default:
      const { index } = action.payload
      const modul = state.setting.data[index]
      return {
        ...state,
        aksesuarModal: {
          ...state.aksesuarModal,
          show: !state.aksesuarModal.show,
          index,
          modulId: modul.id,
          data: [...modul.aksesuarlar]
        }
      }
  }
}
const onLookupReset = (state, action) => {
  return { ...state, lookup: { result: [], isLoading: false } }
}
const onLookupStart = (state, action) => {
  return { ...state, lookup: { result: [], isLoading: true } }
}
const onLookupSuccess = (state, action) => {
  return { ...state, lookup: { result: action.payload.data, isLoading: false } }
}

const onLookupFail = (state, action) => {
  return { ...state, lookup: initLookup() }
}

export const uretimSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SETTING_URETIM_LIST_START:
      return uretimSettingListStart(state, action)
    case actionTypes.SETTING_URETIM_LIST_SUCCESS:
      return uretimSettingListSuccess(state, action)
    case actionTypes.SETTING_URETIM_LIST_FAIL:
      return uretimSettingListFail(state, action)

    case actionTypes.URETIM_SETTING_FETCH_BY_NAME_START:
      return getSettingStart(state, action)
    case actionTypes.URETIM_SETTING_FETCH_BY_NAME_SUCCESS:
      return getSettingSuccess(state, action)
    case actionTypes.URETIM_SETTING_V2_FETCH_BY_ID_START:
      return getSettingV2WithIdStart(state, action)
    case actionTypes.URETIM_SETTING_V2_FETCH_BY_ID_SUCCESS:
      return getSettingV2WithIdSuccess(state, action)
    case actionTypes.URETIM_SETTING_V2_FETCH_BY_ID_FAIL:
      return getSettingFail(state, action)
    case actionTypes.URETIM_SETTING_V2_FETCH_BY_NAME_SUCCESS:
      return getSettingV2Success(state, action)
    case actionTypes.URETIM_SETTING_FETCH_BY_NAME_FAIL:
      return getSettingFail(state, action)
    case actionTypes.SETTING_PERVAZ_INPUT_CHANGE:
      return onSettingPervazPropertyChange(state, action)
    case actionTypes.SETTING_INPUT_CHANGE:
      return onSettingPropertyChange(state, action)
    case actionTypes.SETTING_STATE_CHANGE:
      return onSettingStateChange(state, action)
    case actionTypes.SETTING_SELECT_VALUE_CHANGE_IN_ARRAY:
      return onSettingPropertyChangeInArray(state, action)
    case actionTypes.SETTING_SELECT_VALUE_CHANGE:
      return onSettingSelectValueChange(state, action)
    case actionTypes.SETTING_DATA_ROW_ADDED:
      return onRowAdded(state, action)
    case actionTypes.SETTING_DATA_ROW_REMOVED:
      return onRowRemoved(state, action)
    case actionTypes.SETTING_DATA_ROW_SAVE_START:
      return onRowSavedStart(state, action)
    case actionTypes.SETTING_DATA_ROW_SAVE_SUCCESS:
      return onRowSavedSuccess(state, action)
    case actionTypes.SETTING_DATA_ROW_SAVE_FAIL:
      return onRowSavedFail(state, action)

    case actionTypes.URETIM_SETTING_SELECTED:
      return onSettingSelectedSuccess(state, action)
    case actionTypes.URETIM_SETTING_V2_DELETE_SUCCESS:
      return onSettingV2DeleteSuccess(state, action)
    case actionTypes.URETIM_SETTING_V2_DELETE_FAIL:
      return onSettingV2DeleteFail(state, action)

    case actionTypes.URETIM_SETTING_V2_SAVE_START:
      return onSettingV2SaveStart(state, action)
    case actionTypes.URETIM_SETTING_V2_SAVE_SUCCESS:
      return onSettingV2SaveSuccess(state, action)
    case actionTypes.URETIM_SETTING_V2_SAVE_FAIL:
      return onSettingV2SaveFail(state, action)
    case actionTypes.TOGGLE_EDIT_MODAL:
      return onToggleEditModal(state, action)
    case actionTypes.LOAD_KAPAK_RENK_SUCCESS:
      return onSearchKapakRenkSuccess(state, action)
    case actionTypes.LOAD_KAPAK_RENK_FAIL:
      return onSearchKapakRenkFail(state, action)
    case actionTypes.LOAD_PARCA_TIPI_SUCCESS:
      return onSearchParcaTipiSuccess(state, action)
    case actionTypes.LOAD_PARCA_TIPI_FAIL:
      return onSearchParcaTipiFail(state, action)

    case actionTypes.ERROR_SETTING_MODUL_PARCA_MODAL:
      return errorModulParcaModal(state, action)
    case actionTypes.TOGGLE_SETTING_MODUL_PARCA_MODAL:
      return toggleModulParcaModal(state, action)
    case actionTypes.UPDATE_SETTING_MODUL_PARCA_MODAL:
      return updateModulParcaModal(state, action)
    case actionTypes.UPDATE_SETTING_MODUL_PARCA_MODAL_NEW:
      return updateModulParcaModalNew(state, action)

    case actionTypes.TOGGLE_SETTING_MODUL_AKSAM_MODAL:
      return toggleModulAksamModal(state, action)
    case actionTypes.UPDATE_SETTING_MODUL_AKSAM_MODAL:
      return updateModulAksamModal(state, action)
    case actionTypes.UPDATE_SETTING_MODUL_AKSAM_MODAL_NEW:
      return updateModulAksamModalNew(state, action)

    case actionTypes.TOGGLE_SETTING_MODUL_AKSESUAR_MODAL:
      return toggleModulAksesuarModal(state, action)
    case actionTypes.UPDATE_SETTING_MODUL_AKSESUAR_MODAL:
      return updateModulAksesuarModal(state, action)
    case actionTypes.UPDATE_SETTING_MODUL_AKSESUAR_MODAL_NEW:
      return updateModulAksesuarModalNew(state, action)

    case actionTypes.URETIM_SETTING_SEARCH_CLICK_START:
      return onSearchClickStart(state, action)
    case actionTypes.URETIM_SETTING_SEARCH_CLICK_SUCCESS:
      return onSearchClickSuccess(state, action)
    case actionTypes.URETIM_SETTING_SEARCH_CLICK_FAIL:
      return onSearchClickFail(state, action)
    case actionTypes.URETIM_SETTING_V2_SEARCH_CLICK_START:
      return onSearchClickStart(state, action)
    case actionTypes.URETIM_SETTING_V2_SEARCH_CLICK_SUCCESS:
      return onSearchV2ClickSuccess(state, action)
    case actionTypes.URETIM_SETTING_V2_SEARCH_CLICK_FAIL:
      return onSearchClickFail(state, action)

    case actionTypes.SETTING_DOWNLOAD_REPORT:
      return onDownloadReportStart(state, action)
    case actionTypes.SETTING_DOWNLOAD_REPORT_SUCCESS:
      return onDownloadReportSuccess(state, action)
    case actionTypes.SETTING_DOWNLOAD_REPORT_FAIL:
      return onDownloadReportFail(state, action)

    case actionTypes.SETTINGS_SEARCH_LOOKUP_RESET:
      return onLookupReset(state, action)
    case actionTypes.SETTINGS_SEARCH_LOOKUP_START:
      return onLookupStart(state, action)
    case actionTypes.SETTINGS_SEARCH_LOOKUP_SUCCESS:
      return onLookupSuccess(state, action)
    case actionTypes.SETTINGS_SEARCH_LOOKUP_FAIL:
      return onLookupFail(state, action)
    default:
      return state
  }
}
