import React from 'react'
import { FormFeedback, Input, Label } from 'reactstrap'
import { aynaKenariOptions } from '../../../shared'
import Select from '../../Core/Select'
import NumberInput from '../../Core/NumberInput'

const DolapAynaModeli = (props) => {
  return (
    <>
      <div className='form-group row'>
        <div className='form-group col-6'>
          <Label htmlFor='aynaRengi'>Ayna Rengi</Label>
          <Input
            type='text'
            className='form-control'
            onChange={props.onChange}
            valid={!!props.aynaRengi}
            value={props.aynaRengi}
            invalid={!props.aynaRengi}
            data-row={props.index}
            required
            name='aynaRengi'
            placeholder='Ayna Rengi'
          />
          <FormFeedback>* Zorunlu alan</FormFeedback>
        </div>
        <div className='form-group col-6'>
          <Select
            name={'aynaKenari_' + props.index}
            text='Ayna Kenari'
            isMulti={false}
            value={props.aynaKenari}
            onChange={props.onSelectChange}
            options={aynaKenariOptions}
            getOptionValue={(option) => option['id']}
            getOptionLabel={(option) => option['aciklama']}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-md-2 col-sm-12'>
          <NumberInput
            label='Fiyat €'
            step='0.001'
            name='fiyat'
            dataRow={props.index}
            value={props.fiyat}
            onChange={props.onChange}
            required
          />
        </div>
      </div>
    </>
  )
}

export default DolapAynaModeli
