import shortid from "shortid";

export const initTezgah = () => ({
  id:shortid.generate(),
  tezgah: null,
  adet: '',
  baglantielemanadeti:'',
  alinadeti:'',
  en:'',
  boy:'',
  kalinlik:'',
  yon: null
})
