import React from 'react'
import { Badge, Form, Col, Row } from 'reactstrap'
import NumberInput from '../../Core/NumberInput'
import Select from '../../Core/Select'
import { currencies } from '../../../shared'
const CompaniesForProduct = (props) => {
  return props.companiesForProduct.length === 0 ? (
    <span>Sirket bulunamadi</span>
  ) : (
    <>
      {props.companiesForProduct.map(({ company, paymentDetails, order }, index) => {
        return (
          <Form key={company.id} onSubmit={(e) => props.handleSaveRow(e, company.id)}>
            <Row form>
              <Col md={5}>
                <div className='form-check'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='companyRadios'
                    id={`company-${company.id}`}
                    value={company.id}
                    checked={company.id === props.selection.companyId}
                    onChange={(e) => props.updateCompany({ company })}
                  />
                  <label className='form-check-label' htmlFor='companyRadios'>
                    {company.name}
                  </label>
                </div>
                <Badge color='secondary' pill>
                  Min Siparis Adeti: {paymentDetails.minOrderAmount} {paymentDetails.measure.aciklama}
                </Badge>
                <Badge color='secondary' pill>
                  Odeme Sekli: {paymentDetails.paymentType.aciklama}
                </Badge>
                <Badge color='secondary' pill>
                  Termin Tarihi: {paymentDetails.deliveryRate} {paymentDetails.deliveryType.aciklama}
                </Badge>
              </Col>
              <Col md={2}>
                <Select
                  label='Para Birimi'
                  name={`companiesForProduct[${index}].order.currency`}
                  text='Para birimi'
                  value={order.currency}
                  onChange={props.onSelectChange}
                  options={currencies}
                  isDisabled={company.id !== props.selection.companyId}
                />
              </Col>
              <Col md={2}>
                <NumberInput
                  label='Fiyat'
                  bsSize='sm'
                  name={`companiesForProduct[${index}].order.price`}
                  step='0.001'
                  placeholder='Fiyat'
                  value={order.price}
                  onChange={props.onChange}
                  valid={order.price > 0}
                  invalid={order.price === '' || order.price <= 0}
                  required
                  disabled={company.id !== props.selection.companyId}
                />
              </Col>
              <Col md={2}>
                <NumberInput
                  label={paymentDetails.measure.aciklama}
                  bsSize='sm'
                  name={`companiesForProduct[${index}].order.quantity`}
                  placeholder={paymentDetails.measure.aciklama}
                  value={order.quantity}
                  onChange={props.onChange}
                  valid={order.quantity > 0}
                  invalid={isNaN(order.quantity) || Number(order.quantity) <= 0}
                  required
                  disabled={company.id !== props.selection.companyId}
                />
              </Col>
              <Col md={1} className={company.id !== props.selection.companyId ? 'd-none' : ''}>
                <button
                  type='submit'
                  className='btn btn-primary btn-sm mt-4'
                  disabled={company.id !== props.selection.companyId}
                >
                  <i className='fas fa-save' />
                  Kaydet
                </button>
              </Col>
            </Row>
          </Form>
        )
      })}
    </>
  )
}

export default CompaniesForProduct
