export const initialPaging = () => ({
  total: 0,
  currentPage: 1,
  totalPageCount: 1,
  limit: 40,
  q: undefined
})
export const initLookup = () => ({
  result: [],
  isLoading: false
})
