import Select from '../../Core/Select'
import React from 'react'
import NumberInput from '../../Core/NumberInput'

const KapakModel = (props) => {
  return (
    <>
      <Select
        name={'kapakRenk.' + props.index}
        text='Kapak Renkler'
        isMulti
        onInputChange={props.searchKapakRenk}
        value={props.kapakRenk}
        onChange={props.handleSelectValueChange}
        options={props.options}
        getOptionValue={(option) => option['id']}
        getOptionLabel={(option) => option['aciklama']}
      />
      <div className='col-md-2 col-sm-12'>
        <NumberInput
          label='Fiyat €'
          step='0.001'
          name='fiyat'
          dataRow={props.index}
          value={props.fiyat}
          onChange={props.onInputChange}
          required
        />
      </div>
    </>
  )
}

export default KapakModel
