import { initialPaging, initLookup } from '../dataModels/CommonModals'
import * as actionTypes from '../actions/actionTypes'
import { toast } from 'react-toastify'
import { toasterMessages, toastErrorOptions, toastOptions } from '../../shared'
import cloneDeep from 'lodash/cloneDeep'
import set from 'lodash/set'

export const initProductModalNew = () => ({
  products: [],
  checked: [],
  expanded: [],
  paymentDetails: {
    measure: null,
    minOrderAmount: '',
    paymentType: null,
    deliveryType: null,
    deliveryRate: '',
    currency: null,
    price: '',
    priceHistory: []
  }
})
export const initProductModal = () => ({
  show: false,
  mode: 'create',
  new: initProductModalNew()
})
const initialCompany = () => ({
  id: '',
  name: '',
  isactive: true,
  products: [],
  fields: {
    city: '',
    paymentType: '',
    personincharge: '',
    email: '',
    address: '',
    telephone: ''
  }
})

const initialState = {
  error: null,
  search: {
    query: ''
  },
  loading: true,
  paging: initialPaging(),
  companyForm: initialCompany(),
  list: [],
  lookup: [],
  productModal: initProductModal()
}

const fetchCompaniesSuccess = (state, action) => {
  toast.success(`Sirketler yuklendi!`, toastOptions)
  return {
    ...state,
    ...{
      list: action.payload.result,
      companyForm: initialCompany(),
      paging: action.payload.paging,
      loading: false,
      error: null
    }
  }
}

const fetchCompaniesStart = (state, action) => {
  return { ...state, ...{ list: [], loading: true, error: null, paging: initialPaging() } }
}

const fetchCompaniesFail = (state, action) => {
  toast.error(toasterMessages.errorWithMessage(action.error.message), toastErrorOptions)
  return { ...state, ...{ loading: false, error: action.error.message } }
}
const fetchCompanySuccess = (state, action) => {
  toast.success(`Sirket yuklendi!`, toastOptions)
  return { ...state, ...{ list: [], companyForm: action.payload, loading: false, error: null } }
}

const fetchCompanyStart = (state, action) => {
  return {
    ...state,
    ...{ list: [], loading: true, companyForm: initialCompany(), error: null, paging: initialPaging() }
  }
}

const fetchCompanyFail = (state, action) => {
  toast.error(toasterMessages.errorWithMessage(action.error.message), toastErrorOptions)
  return { ...state, ...{ loading: false, error: action.error.message } }
}
const fetchCompanyNew = (state, action) => {
  return {
    ...state,
    ...{ error: null, loading: false, companyForm: initialCompany(), productModal: initProductModal() }
  }
}

const getProductTreeStart = (state, action) => {
  return {
    ...state,
    productModal: {
      ...state.productModal,
      new: {
        ...state.productModal.new,
        products: [],
        checked: [],
        expanded: []
      }
    }
  }
}
const getProductTreeSuccess = (state, action) => {
  toast.success(`urunler yuklendi!`, toastOptions)
  const products = action.payload
  const existingProducts = state.companyForm.products.map((x) => x.id)
  products.forEach((p) => {
    if (p.children !== null) {
      p.children.forEach((c) => {
        if (existingProducts.includes(c.value)) {
          c.disabled = true
        }
      })
    }
  })
  return {
    ...state,
    productModal: {
      ...state.productModal,
      new: {
        ...state.productModal.new,
        products
      }
    }
  }
}
const getProductTreeFail = (state, action) => {
  toast.error(toasterMessages.errorWithMessage(action.error.message), toastErrorOptions)
  return { ...state, ...{ error: action.error.message } }
}
const toggleProductModal = (state, action) => {
  switch (action.payload.action) {
    case 'update':
      const paymentDetails = { ...state.productModal.new.paymentDetails }
      const updatedProducts = state.companyForm.products.map((x) => {
        if (x.id === state.productModal.new.products[0].id) {
          if (
            paymentDetails.currency.id !== x.paymentDetails.currency.id ||
            Number(paymentDetails.price) !== x.paymentDetails.price
          ) {
            const date = new Date()
            if (!paymentDetails.priceHistory) {
              paymentDetails.priceHistory = []
            }
            paymentDetails.priceHistory = [
              ...paymentDetails.priceHistory,
              {
                price: x.paymentDetails.price,
                currency: { ...x.paymentDetails.currency },
                date: date.toISOString()
              }
            ]
          }

          return { ...x, paymentDetails }
        }
        return x
      })
      return {
        ...state,
        productModal: initProductModal(),
        companyForm: { ...state.companyForm, products: updatedProducts }
      }
    case 'create':
      if (state.productModal.new.checked.length === 0) {
        toast.error(`Lütfen Ürün seçiniz!`, toastErrorOptions)
        return state
      }
      const removeExisitingProducts = state.companyForm.products.filter(
        (x) => !state.productModal.new.checked.includes(x.id.toString())
      )
      const flattenedProductList = state.productModal.new.products
        .filter((x) => x.children !== null)
        .map((x) => x.children)
        .flat()
      const selectedProducts = flattenedProductList
        .filter((x) => state.productModal.new.checked.includes(x.value.toString()))
        .map((p) => ({
          id: p.value,
          description: p.label,
          paymentDetails: { ...state.productModal.new.paymentDetails }
        }))
      const result = [...removeExisitingProducts, ...selectedProducts]
      return { ...state, productModal: initProductModal(), companyForm: { ...state.companyForm, products: result } }
    case 'cancel':
      return { ...state, productModal: initProductModal(), lookup: initLookup() }
    case 'edit':
      const selectedProduct = state.companyForm.products.find((x) => x.id === action.payload.productId)
      const editingProduct = [{ description: selectedProduct.description, id: selectedProduct.id }]
      return {
        ...state,
        productModal: {
          ...state.productModal,
          new: {
            ...state.productModal.new,
            products: editingProduct,
            paymentDetails: { ...selectedProduct.paymentDetails }
          },
          mode: 'edit',
          show: true
        }
      }
    default:
      return { ...state, productModal: { ...state.productModal, mode: 'create', show: true } }
  }
}

const saveCompanyStart = (state, action) => {
  return { ...state, ...{ error: null, loading: true } }
}

const saveCompanySuccess = (state, action) => {
  toast.success(`Sirket kaydedildi!`, toastOptions)
  return {
    ...state,
    ...{
      error: null,
      loading: false,
      companyForm: { ...state.companyForm, id: action.payload.id }
    }
  }
}

const saveCompanyFail = (state, action) => {
  toast.error(toasterMessages.errorWithTitle(`Sirket kaydederken`), toastErrorOptions)
  return {
    ...state,
    ...{
      error: action.error.response.data,
      loading: false
    }
  }
}
const updateCompanyProperty = (state, action) => {
  const clonedState = cloneDeep(state)
  set(clonedState.companyForm, action.payload.name, action.payload.value)
  return clonedState
}
const updateProductModalProperty = (state, action) => {
  const newData = cloneDeep(state.productModal.new)
  set(newData, action.payload.name, action.payload.value)
  return { ...state, ...{ productModal: { ...state.productModal, new: newData } } }
}
const removeCompanyProduct = (state, action) => {
  const productId = action.payload
  const products = state.companyForm.products.filter((p) => p.id !== productId)
  return {
    ...state,
    error: null,
    companyForm: {
      ...state.companyForm,
      products
    }
  }
}
export const companiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_COMPANIES_LIST_SUCCESS:
      return fetchCompaniesSuccess(state, action)

    case actionTypes.FETCH_COMPANIES_LIST_FAIL:
      return fetchCompaniesFail(state, action)

    case actionTypes.FETCH_COMPANIES_LIST_START:
      return fetchCompaniesStart(state, action)

    case actionTypes.FETCH_COMPANY_SUCCESS:
      return fetchCompanySuccess(state, action)

    case actionTypes.FETCH_COMPANY_FAIL:
      return fetchCompanyFail(state, action)

    case actionTypes.FETCH_COMPANY_START:
      return fetchCompanyStart(state, action)

    case actionTypes.FETCH_COMPANY_NEW:
      return fetchCompanyNew(state, action)

    case actionTypes.SAVE_COMPANY_START:
      return saveCompanyStart(state, action)

    case actionTypes.SAVE_COMPANY_SUCCESS:
      return saveCompanySuccess(state, action)

    case actionTypes.SAVE_COMPANY_FAIL:
      return saveCompanyFail(state, action)

    case actionTypes.UPDATE_COMPANY_PROPERTY:
      return updateCompanyProperty(state, action)

    case actionTypes.TOGGLE_COMPANY_PRODUCT_MODAL:
      return toggleProductModal(state, action)

    case actionTypes.GET_PRODUCT_TREE_START:
      return getProductTreeStart(state, action)

    case actionTypes.GET_PRODUCT_TREE_SUCCESS:
      return getProductTreeSuccess(state, action)

    case actionTypes.GET_PRODUCT_TREE_FAIL:
      return getProductTreeFail(state, action)

    case actionTypes.CHANGE_COMPANY_PRODUCT_MODAL_PROPERTY:
      return updateProductModalProperty(state, action)

    case actionTypes.REMOVE_COMPANY_PRODUCT:
      return removeCompanyProduct(state, action)

    default:
      return state
  }
}
