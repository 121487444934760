import React, { useEffect } from 'react'
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Input,
  ListGroup,
  ListGroupItem,
  Row
} from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loading from '../../Common/Loading'
import * as actions from '../../../store/actions'
import { StringInput } from '../../Core'
import DeleteConfirmAlert from '../../Common/DeleteConfirmAlert'
import { Link, NavLink } from 'react-router-dom'

const TopluAcilanContainer = (props) => {
  const dispatch = useDispatch()
  const { groupForm } = useSelector((state) => state.groups)
  const { token } = useSelector((state) => state.auth)
  useEffect(() => {
    props.match.params.id
      ? dispatch(actions.fetchOrderGroup(token, props.match.params.id))
      : dispatch(actions.newOrderGroup())
  }, [props.match.params.id, token, dispatch])

  const handleSave = (e) => {
    e.preventDefault()
    const data = {
      name: groupForm.name,
      type: 'topluAcilan',
      wardrobeOrders: groupForm.wardrobeOrders.map((i) => ~~i.id)
    }
    dispatch(actions.saveOrderGroup(token, groupForm.id, data))
  }
  const handleGroupFormPropertyChange = (event) => {
    const { name, value } = event.currentTarget
    dispatch(actions.updateOrderGroupFormProperty(name, value))
  }
  const handleWardrobeOrderSelect = (name, selected, event) => dispatch(actions.addWardrobeOrder(selected))
  const handleSearchOrder = (type) => (item) => {
    const value = item.currentTarget.value
    if (value !== '') {
      dispatch(actions.orderGroupSearchOrder(token, value, type))
    }
  }
  const getTypeDescription = (item) => {
    return item.data_type === 'acilan'
      ? `${item.dolapTakilacakOda} Acilan Dolap`
      : `${item.dolapTakilacakOda} Surme Dolap`
  }
  const handleWardrobeOrderRemove = (selected) => dispatch(actions.removeWardrobeOrder(selected))
  const handleDownloadTopluAcilanReport = () =>
    dispatch(actions.onDownloadTopluAcilanReport(token, groupForm.name, groupForm.id))
  return (
    <Row>
      {groupForm.error && (
        <Col sm={12}>
          <Alert color='danger'>Toplu dolap yuklerken hata oluştu — {groupForm.error}</Alert>
        </Col>
      )}
      <Col sm='12'>
        <h3>Toplu dolap</h3>
        {groupForm.loading ? (
          <Loading />
        ) : (
          <form onSubmit={handleSave}>
            <div className='form-group col-md-12'>
              <StringInput onChange={handleGroupFormPropertyChange} value={groupForm.name} name='name' label='Isim' />
            </div>
            <Card className='pt-2'>
              <CardHeader>Duvar Dolabi Siparişleri</CardHeader>
              <CardBody>
                <div className='row'>
                  <div className='col-sm-12 col-md-6'>
                    <Input
                      type='text'
                      className='form-control'
                      onChange={handleSearchOrder('wardrobe')}
                      name='duvar-dolabi-siparis'
                      id='duvar-dolabi-siparis'
                      placeholder='arama'
                    />
                    <ListGroup>
                      {groupForm.lookup.wardrobe.isLoading && <Loading />}
                      {groupForm.lookup.wardrobe.result.map((item) => (
                        <ListGroupItem key={item.id}>
                          <Button
                            size='sm'
                            outline
                            onClick={() => handleWardrobeOrderSelect('wardrobe', item)}
                            color='primary'
                          >
                            +
                          </Button>
                          {item.isimsoyisim} ({item.sicilno} - {item.konutno})
                          <Badge className='float-end' color='secondary' pill>
                            {item.muteahhit} - {item.proje}
                          </Badge>
                          <Badge color='secondary'>{getTypeDescription(item)}</Badge>
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                  </div>
                  <div className='col-sm-12 col-md-6'>
                    <ListGroup>
                      <ListGroupItem color='info'>Secilmiş duvar dolabi siparişleri:</ListGroupItem>
                      {groupForm.wardrobeOrders.length === 0 && (
                        <ListGroupItem className='font-weight-lighter'>Herhangi bir siparis mevcut degil</ListGroupItem>
                      )}
                      {groupForm.wardrobeOrders.map((o) => {
                        return (
                          <ListGroupItem className='justify-content-between' key={o.id}>
                            {`${o.isimsoyisim} (${o.sicilno} - ${o.konutno})`}
                            <DeleteConfirmAlert
                              deleteButtonStyle='btn btn-danger float-end'
                              onRemove={() => handleWardrobeOrderRemove(o)}
                            />
                            <Link
                              className='btn btn-secondary float-end'
                              to={`/uretim/siparisler/duvarDolabi/${o.data_type}/${o.id}`}
                            >
                              Degistir
                            </Link>
                          </ListGroupItem>
                        )
                      })}
                    </ListGroup>
                  </div>
                </div>
              </CardBody>
              <CardFooter>
                {groupForm.id && groupForm.wardrobeOrders.length > 0 && (
                  <Button
                    color='primary float-end m-1'
                    disabled={groupForm.downloading || groupForm.loading}
                    onClick={handleDownloadTopluAcilanReport}
                  >
                    <i className='far fa-file-excel' />
                    Rapor Hazirla
                  </Button>
                )}
              </CardFooter>
            </Card>
            <nav className='navbar fixed-bottom navbar-dark bg-dark justify-content-between'>
              <NavLink className='btn btn-warning  m-1' to='/uretim/raporlar'>
                Vazgec
              </NavLink>
              {!groupForm.loading && (
                <button type='submit' className='btn btn-primary float-end m-1'>
                  <i className='fas fa-save' />
                  Kaydet
                </button>
              )}
            </nav>
          </form>
        )}
      </Col>
    </Row>
  )
}

export default TopluAcilanContainer
