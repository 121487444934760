import { Card, CardBody, CardHeader, Input, Label } from 'reactstrap'
import React from 'react'
import { NavLink } from 'react-router-dom'
import IsActiveBadge from '../../Common/IsActiveBadge'
import Select from '../../Core/Select'
import { cities, paymentTypes, currencies, measures, deliveryTypes } from '../../../shared'
import ProductModal from '../../Common/ProductModal'
import CompanyFormProducts from './CompanyFormProducts'

const CompanyForm = (props) => {
  const {
    name,
    isactive,
    fields: { email, personincharge, paymentType, city, address, telephone, note }
  } = props.companyForm
  return (
    <form onSubmit={props.onSave}>
      <ProductModal
        productModal={props.productModal}
        onProductModalClick={props.onProductModalClick}
        onChange={props.updateProductModalProperty}
        onSelectChange={props.updateProductModalSelectProperty}
        currencies={currencies}
        measures={measures}
        paymentTypes={paymentTypes}
        deliveryTypes={deliveryTypes}
      />
      <Card className='shadow mb-2'>
        <CardHeader>Sirket</CardHeader>
        <CardBody>
          <div className='form-group row'>
            <div className='form-group col-md-6'>
              <Label htmlFor='name'>Isim</Label>
              <Input
                type='text'
                className='form-control'
                onChange={props.onChange}
                valid={!!name}
                value={name}
                invalid={!name}
                name='name'
                placeholder='Isim'
                required
              />
            </div>
            <div className='form-group col-md-6'>
              <Label htmlFor='email'>Email</Label>
              <input
                type='email'
                autoComplete='off'
                className='form-control'
                onChange={props.onChange}
                placeholder='Email'
                value={email}
                name='fields.email'
                required
              />
            </div>
            <div className='form-group col-md-6'>
              <Label htmlFor='personincharge'>Iligili kisi</Label>
              <Input
                type='text'
                className='form-control'
                onChange={props.onChange}
                valid={!!personincharge}
                value={personincharge}
                invalid={!personincharge}
                name='fields.personincharge'
                placeholder='Iligili kisi'
                required
              />
            </div>
            <div className='form-group col-md-6'>
              <Select
                name='fields.paymentType'
                text='Odeme Sekli'
                placeholder='Odeme Sekli'
                value={paymentType}
                onChange={props.onSelectChange}
                options={paymentTypes}
              />
            </div>

            <div className='form-group col-md-2'>
              <Label htmlFor='name'>Aktif/Pasif</Label>
              <IsActiveBadge value={isactive} onChange={props.onChange} />
            </div>
          </div>
        </CardBody>
      </Card>
      <Card className='shadow mb-2'>
        <CardHeader>Adres/Telefon</CardHeader>
        <CardBody>
          <div className='form-group row'>
            <div className='form-group col-md-6'>
              <Label htmlFor='fields.address'>Adres</Label>
              <textarea
                className='form-control'
                value={address}
                onChange={props.onChange}
                name='fields.address'
                rows='3'
              />
            </div>
            <div className='form-group col-md-6'>
              <Label htmlFor='fields.telephone'>Telefon</Label>
              <Input
                type='text'
                className='form-control'
                value={telephone}
                onChange={props.onChange}
                name='fields.telephone'
                placeholder='Telefon'
              />
            </div>
            <div className='form-group col-md-6'>
              <Select
                name='fields.city'
                text='Sehir'
                placeholder='Sehir'
                value={city}
                onChange={props.onSelectChange}
                options={cities}
              />
            </div>
          </div>
        </CardBody>
      </Card>
      <CompanyFormProducts {...props} />

      <Card className='shadow mb-2'>
        <CardHeader>Not</CardHeader>
        <CardBody>
          <div className='form-group row'>
            <div className='form-group col-md-12'>
              <textarea className='form-control' value={note} onChange={props.onChange} name='fields.note' rows='3' />
            </div>
          </div>
        </CardBody>
      </Card>
      <div className='form-group row'>
        <NavLink className='btn btn-secondary  m-1' to='/alim/sirketler'>
          Vazgec
        </NavLink>
        <button type='submit' className='btn btn-primary'>
          <i className='fas fa-save' />
          Kaydet
        </button>{' '}
      </div>
    </form>
  )
}

export default CompanyForm
