import React from 'react'
import { Card, CardBody, CardHeader } from 'reactstrap'
import NumberInput from '../../../Core/NumberInput'
import SurmeDolapKapaklar from './SurmeDolapKapaklar'
const DolapOlculer = (props) => {
  return (
    <Card className='shadow mb-2'>
      <CardHeader>Olculer</CardHeader>
      <CardBody>
        <Card className='shadow mb-2'>
          <CardHeader>Yapilacak Olan</CardHeader>
          <CardBody>
            <div className='form-group row'>
              <div className='form-group col-12 col-sm-4 col-lg-3'>
                <NumberInput
                  label='Yapilacak Olan En(mm)'
                  name='yapilacakOlan.en'
                  value={props.yapilacakOlan.en}
                  onChange={props.updateWardrobeState}
                />
              </div>
              <div className='form-group col-12 col-sm-4 col-lg-3'>
                <NumberInput
                  label='Yapilacak Olan Boy(mm)'
                  name='yapilacakOlan.boy'
                  value={props.yapilacakOlan.boy}
                  onChange={props.updateWardrobeState}
                />
              </div>
              <div className='form-group col-12 col-sm-4 col-lg-3'>
                <NumberInput
                  label='Yapilacak Olan Derinlik(mm)'
                  name='yapilacakOlan.derinlik'
                  value={props.yapilacakOlan.derinlik}
                  onChange={props.updateWardrobeState}
                />
              </div>
            </div>
          </CardBody>
        </Card>
        <Card className='shadow mb-2'>
          <CardHeader>Bosluk Olcusu</CardHeader>
          <CardBody>
            <div className='form-group row'>
              <div className='form-group col-12 col-sm-4 col-lg-3'>
                <NumberInput
                  label='Bosluk Olcusu En(mm)'
                  name='boslukOlcusu.en'
                  value={props.boslukOlcusu.en}
                  onChange={props.updateWardrobeState}
                />
              </div>
              <div className='form-group col-12 col-sm-4 col-lg-3'>
                <NumberInput
                  label='Bosluk Olcusu Boy(mm)'
                  name='boslukOlcusu.boy'
                  value={props.boslukOlcusu.boy}
                  onChange={props.updateWardrobeState}
                />
              </div>
              <div className='form-group col-12 col-sm-4  col-lg-3'>
                <NumberInput
                  label='Bosluk Olcusu Derinlik(mm)'
                  name='boslukOlcusu.derinlik'
                  value={props.boslukOlcusu.derinlik}
                  onChange={props.updateWardrobeState}
                />
              </div>
            </div>
          </CardBody>
        </Card>
        {props.tip === 'surme' && (
          <SurmeDolapKapaklar
            camKapakAdet={props.camKapakAdet}
            ahsapKapakAdet={props.ahsapKapakAdet}
            onChange={props.updateWardrobeState}
            dolapAynaModeli={props.dolapAynaModeli}
            updateSelect={props.updateSelect}
            lookupSetting={props.lookupSetting}
            lookup={props.lookup}
            onSelectMenuClose={props.onSelectMenuClose}
          />
        )}
      </CardBody>
    </Card>
  )
}

export default DolapOlculer
