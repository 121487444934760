import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import './Layout.css'
import { InputGroup, InputGroupText } from 'reactstrap'
import { USER_ADMIN_ROLE } from '../../shared'

const Layout = ({ isAuthenticated, profile, children }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }
  return (
    <div className='row' id='main'>
      {isAuthenticated && profile && (
        <nav className='navbar navbar-expand-lg navbar navbar-dark bg-dark p-0'>
          <NavLink className='navbar-brand Sidebar-Brand' to='/uretim/siparisler'>
            <img
              src='https://fnc-cdn.fra1.digitaloceanspaces.com/ciddi-mutfak/prod/CiddiLogoOnly.png'
              width='80'
              height='40'
              className='d-inline-block align-top'
              alt=''
            />
            Ciddi Mutfak
          </NavLink>

          <button
            className='navbar-toggler'
            type='button'
            data-toggle='collapse'
            data-target='#navbarSupportedContent'
            aria-controls='navbarSupportedContent'
            onClick={toggle}
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon' />
          </button>
          <div className={isOpen ? 'navbar-collapse' : 'collapse navbar-collapse'} id='navbarSupportedContent'>
            <ul className='nav nav-pills mr-auto mt-lg-0'>
              <li className='nav-item Sidebar-Link'>
                <NavLink className='nav-link' activeclassname='active' to='/alim'>
                  Alım
                </NavLink>
              </li>
              <li className='nav-item Sidebar-Link'>
                <NavLink className='nav-link' activeclassname='active' to='/uretim'>
                  Uretim
                </NavLink>
              </li>
              {profile && profile.roles && profile.roles.indexOf(USER_ADMIN_ROLE) > -1 && (
                <li className='nav-item Sidebar-Link'>
                  <NavLink className='nav-link' activeclassname='active' to='/kullanicilar'>
                    Kullanıcılar
                  </NavLink>
                </li>
              )}
            </ul>
          </div>
          <div className='float-end'>
            <InputGroup>
              <InputGroupText className='green-black border-green'>
                <i className='fas fa-user'>{profile.displayName}</i>
              </InputGroupText>
              <NavLink className='btn btn-danger' to='/auth/logout'>
                <i className='fas fa-sign-out-alt' /> Cikis
              </NavLink>
            </InputGroup>
          </div>
        </nav>
      )}

      <div className='container-fluid'>{children}</div>
    </div>
  )
}
export default Layout
