import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";

export const updatePervazInputChange = ( state, action ) => {
  const pervazlar = cloneDeep(state.wardrobeForm.tipi.pervazlar);
  const pervaz = pervazlar.find(p => p.name === action.payload.name)
  set(pervaz, action.payload.property, action.payload.value)
  return {
    ...state,
    wardrobeForm: {
      ...state.wardrobeForm,
      tipi: {
        ...state.wardrobeForm.tipi,
        pervazlar
      }
    }
  }

};
