import React from 'react'
import { localDateStringOptions } from '../../../shared'
import { Link } from 'react-router-dom'
import { Badge } from 'reactstrap'
import ConfirmActionAlert from "../../Common/ConfirmActionAlert";

const OrderListItem = (props) => {
  const {type, data, id, created, haftalar} = props.order
  const toLocaleDate = (teslimtarihi) => {
    return new Date(teslimtarihi).toLocaleDateString('tr', localDateStringOptions)
  }

  const isMobilyaOrder = type === 'mobilya'
  const isKitchenOrder = type === 'kitchen'
  const isAcilanOrder = data.type === 'acilan'
  const getTypeDescription = () => {
    if(isKitchenOrder){
     return 'Mutfak'
    }
    if(isMobilyaOrder){
      return 'Mobilya'
    }
    if(isAcilanOrder){
      return `${data.dolapTakilacakOda} Acilan Dolap`
    }
    return `${data.dolapTakilacakOda} Surme Dolap`
  }
  const getHighlight = () => {
    return isKitchenOrder ? 'bg-success' : isMobilyaOrder ? 'bg-warning' : (isAcilanOrder ? 'bg-primary' : 'bg-info')
  }
  const muteahhitAdi =
    data.muteahhit && data.muteahhit.description
      ? `${data.muteahhit.description} (${data.muteahhit.isimsoyisim})`
      : ''
  const getLinks = () => {
    let link = `/uretim/siparisler/mutfak/${id}`

    if (type === 'mobilya') {
      link = `/uretim/siparisler/mobilya/${id}`
    }
    else if (type === 'wardrobe') {
      link = `/uretim/siparisler/duvarDolabi/${data.type}/${id}`
    }
    return (
      <>
        <Link className='btn btn-link' to={link}>
          Degistir
        </Link>
        <button
          type='button'
          className='btn btn-secondary'
          id='toggle-clone-button'
          onClick={() => props.onClickCopy({ action: 'show', id, type: getTypeDescription() })}
        >
          Kopyala
        </button>
          <ConfirmActionAlert
              actionButtonText='Tekrarla'
              actionButtonStyle='btn btn-secondary float-end'
              onClick={() => props.onDuplicateOrder( {id, type: type, data_type : data.type })}
          />
      </>
    )
  }
  return (
    <tr className='d-flex' key={id}>
      <td className={getHighlight() + ' text-white col-4'}>
        {`${data.musteri.isimsoyisim}/${muteahhitAdi}`}
        <div>
          <Badge className='float-start' pill>
            {getTypeDescription()}
          </Badge>
        </div>
        <small className='float-end'>{toLocaleDate(created)}</small>
      </td>
      <td className='col-2'>{haftalar ? haftalar.join(',') : '--'}</td>
      <td className='d-none d-sm-none d-md-block col-1'>{data.konutno}</td>
      <td className='col-md-1 col-lg-1'>{data.sicilno}</td>
      <td className='d-none d-sm-none d-md-block col-2'>{toLocaleDate(data.teslimtarihi)}</td>
      <td className='col-sm-3 col-md-2'>{getLinks()}</td>
    </tr>
  )
}

export default OrderListItem
