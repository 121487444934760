import React from 'react'
import DeleteConfirmAlert from '../DeleteConfirmAlert'
import { Button, Badge, ListGroup, ListGroupItem, ListGroupItemHeading } from 'reactstrap'
const ModulList = (props) => {
  return (
    <ListGroup>
      {props.moduller
        .sort((a, b) => (Number(a.siranumarasi) > Number(b.siranumarasi) ? 1 : -1))
        .map((item, index) => {
          return (
            <ListGroupItem key={`${item.modul.id}-${item.siranumarasi}`}>
              <span className='float-start badge bg-dark rounded-pill'>{item.siranumarasi}</span>
              <ListGroupItemHeading className='text-center'>
                {item.adet} adet {item.modul.aciklama}
              </ListGroupItemHeading>

              <div className='d-none d-md-block'>
                {props.type !== 'mobilya' && (<>
                    <Badge color='secondary' pill>
                      Kapak Modeli:{item.kapakmodeli && item.kapakmodeli.aciklama}
                    </Badge>
                    <Badge color='secondary' pill>
                      Kapak Renk:{item.kapakrenk && item.kapakrenk.aciklama}
                    </Badge>
                    </>
                  )}
                {props.type === 'acilan-wardrobe' && (
                  <>
                    <Badge color='secondary' pill>
                      Kapak Yonu: {item.kapakyonu && item.kapakyonu.aciklama}
                    </Badge>
                    <Badge color='secondary' pill>
                      Kulp modeli:{item.kulpmodeli && item.kulpmodeli.aciklama}
                    </Badge>
                    <Badge color='secondary' pill>
                      Metal Cerceveli Kapak:{item.metalCerceveliKapak ? 'Evet' : 'Hayir'}
                    </Badge>
                    <Badge color='secondary' pill>
                      Aynali Kapak:{item.aynaliKapak ? 'Evet' : 'Hayir'}
                    </Badge>
                  </>
                )}
                {props.type === 'kitchen' && (
                  <>
                    <Badge color='secondary' pill>
                      Govde Malzemesi:{item.govdemalzemesi && item.govdemalzemesi.aciklama}
                    </Badge>
                    <Badge color='secondary' pill>
                      Govde Rengi:{item.govderengi && item.govderengi.aciklama}
                    </Badge>
                    <Badge color='secondary' pill>
                      Kulp modeli:{item.kulpmodeli && item.kulpmodeli.aciklama}
                    </Badge>
                    <Badge color='secondary' pill>
                      Gorunen Yan:{item.gorunenyan && item.gorunenyan.aciklama}
                    </Badge>
                    <Badge color='secondary' pill>
                      Metal Cerceveli Kapak:{item.metalCerceveliKapak ? 'Evet' : 'Hayir'}
                    </Badge>
                  </>
                )}
                {item.modul.aksesuarlar.map((aks, index) => {
                  return (
                    <Badge pill color='danger' key={`${aks.id}-${index}`}>
                      {aks.miktar} adet {aks.aciklama}
                    </Badge>
                  )
                })}
              </div>
              <div>
                <Button
                  className='mx-1'
                  outline
                  color='primary'
                  size='sm'
                  onClick={() => props.onModulEditClick('', index)}
                >
                  <i className='fas fa-edit' />
                  Degistir
                </Button>
                <Button
                  className='mx-1'
                  outline
                  color='primary'
                  size='sm'
                  onClick={() => props.onModulAksamClick({ index })}
                >
                  Aksamlar
                </Button>
                <Button
                  className='mx-1'
                  outline
                  color='primary'
                  size='sm'
                  onClick={() => props.onModulParcaClick({ index })}
                >
                  Parcalar
                </Button>
                <Button
                  className='mx-1'
                  outline
                  color='primary'
                  size='sm'
                  onClick={() => props.onModulAksesuarClick({ index })}
                >
                  Aksesuarlar
                </Button>
                <Button
                  className='mx-1'
                  outline
                  color='primary'
                  size='sm'
                  onClick={() => props.onModulEditClick('clone', index)}
                >
                  <i className='fas fa-copy' />
                  Kopyala
                </Button>
                <DeleteConfirmAlert
                  deleteButtonStyle='btn btn-outline-danger btn-small float-end'
                  onRemove={() => props.onModulRemoveClick(index)}
                />
              </div>
            </ListGroupItem>
          )
        })}
    </ListGroup>
  )
}

export default ModulList
