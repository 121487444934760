import React from 'react'
import PervazItem from '../Orders/Wardrobe/PervazItem'
const PervazSettings = (props) => {
  function handlePervazInputChange(event, pervazName) {
    const property = event.target.name
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
    const index = event.target.dataset.row
    props.onPervazInputChange(index, pervazName, property, value)
  }
  return (
    <div className='form-group row'>
      {props.pervazlar.map((pervaz, index) => {
        return (
          <PervazItem
            key={`pervaz-item-${index}`}
            {...pervaz}
            index={props.index}
            showExtraProps={true}
            onChange={handlePervazInputChange}
          />
        )
      })}
    </div>
  )
}

export default PervazSettings
