import React from 'react'
import { Card, CardBody, CardHeader } from 'reactstrap'
import Select from '../../../../Core/Select'
import DeleteConfirmAlert from '../../../../Common/DeleteConfirmAlert'
import NumberInput from '../../../../Core/NumberInput'

const TezgahItem = (props) => (
  <Card className='shadow mb-2' key={props.id}>
    <CardHeader>
      Tezgah {props.index + 1}{' '}
      <DeleteConfirmAlert deleteButtonStyle='btn btn-danger float-end' onRemove={() => props.onTezgahRemoveClick(props.id)} />
    </CardHeader>
    <CardBody>
      <div className='form-group row'>
        <div className='form-group col-lg-5 col-xl-5'>
          <Select
            name={`tezgah.${props.id}`}
            text='Tezgah'
            value={props.tezgah}
            onChange={props.onSelectChange}
            options={props.lookup.result}
            onInputChange={(e) => props.lookupSetting(e, 'tezgah')}
            isLoading={props.lookup.isLoading}
            onSelectMenuClose={props.onSelectMenuClose}
            disableRequired={true}
          />
        </div>
        <div className='form-group col-lg-2 col-xl-2'>
          <NumberInput
            optional={true}
            label='Adet'
            name={`adet.${props.id}`}
            value={props.adet}
            onChange={props.onInputChange}
          />
        </div>
        <div className='form-group col-lg-5 col-xl-5'>
          <Select
            name={`yon.${props.id}`}
            text='Yon'
            value={props.yon}
            onChange={props.onSelectChange}
            options={props.lookup.result}
            onInputChange={(e) => props.lookupSetting(e, 'tezgahYon')}
            onSelectMenuClose={props.onSelectMenuClose}
            isLoading={props.lookup.isLoading}
            disableRequired={true}
          />
        </div>
      </div>
      <div className='form-group row'>
        <div className='form-group col-lg-2 col-xl-2'>
          <NumberInput
            optional={true}
            label='En'
            name={`en.${props.id}`}
            value={props.en}
            onChange={props.onInputChange}
          />
        </div>
        <div className='form-group col-lg-2 col-xl-2'>
          <NumberInput
            optional={true}
            label='Boy'
            name={`boy.${props.id}`}
            value={props.boy}
            onChange={props.onInputChange}
          />
        </div>
        <div className='form-group col-lg-2 col-xl-2'>
          <NumberInput
            optional={true}
            label='Kalinlik'
            name={`kalinlik.${props.id}`}
            value={props.kalinlik}
            onChange={props.onInputChange}
          />
        </div>
        <div className='form-group col-lg-4 col-xl-4'>
          <NumberInput
            optional={true}
            label='Baglanti Elm Adeti'
            name={`baglantielemanadeti.${props.id}`}
            value={props.baglantielemanadeti}
            onChange={props.onInputChange}
          />
        </div>
        <div className='form-group col-lg-2 col-xl-2'>
          <NumberInput
            optional={true}
            label='Alin Adeti'
            name={`alinadeti.${props.id}`}
            value={props.alinadeti}
            onChange={props.onInputChange}
          />
        </div>
      </div>
    </CardBody>
  </Card>
)

export default TezgahItem
