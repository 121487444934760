import React, { PureComponent } from 'react'
import { Card, CardHeader, CardBody, Button, Row, Col } from 'reactstrap'
import Select from '../Core/Select'
import DeleteConfirmAlert from './DeleteConfirmAlert'
import NumberInput from '../Core/NumberInput'

class Dekorasyon extends PureComponent {
  render() {
    const {
      dekorasyonlar,
      lookup,
      lookupSetting,
      onDekorasyonAddClick,
      onDekorasyonRemoveClick,
      onInputChange,
      onSelectChange,
      onSelectMenuClose
    } = this.props
    return (
      <Card className='shadow mb-2'>
        <CardHeader>
          Dekorasyon
          <Button outline color='primary' className='float-end' onClick={onDekorasyonAddClick}>
            <i className='fas fa-plus' />
            Dekorasyon Ekle
          </Button>
        </CardHeader>
        <CardBody>
          <Row>
            {dekorasyonlar &&
              dekorasyonlar.map((item, index) => {
                return (
                  <Col md='12' key={item.id}>
                    <Card className='shadow mb-2' key={item.id}>
                      <CardHeader>
                        Dekorasyon {index + 1}{' '}
                        <DeleteConfirmAlert
                          deleteButtonStyle='btn btn-danger float-end'
                          className='p-2'
                          onRemove={() => onDekorasyonRemoveClick(item.id)}
                        />
                      </CardHeader>
                      <CardBody>
                        <div className='row'>
                          <div className='form-group col-sm-12 col-md-2'>
                            <NumberInput
                              label='Adet'
                              name={`adet.${item.id}`}
                              value={item.adet}
                              onChange={onInputChange}
                              valid={!!item.adet}
                              invalid={!item.adet}
                            />
                          </div>
                          <div className='form-group col-sm-12 col-md-5'>
                            <Select
                              name={`dekorasyon.${item.id}`}
                              value={item.dekorasyon}
                              onChange={onSelectChange}
                              text='Dekorasyon'
                              options={lookup.result}
                              onInputChange={(e) => lookupSetting(e, 'dekorasyon')}
                              onSelectMenuClose={onSelectMenuClose}
                              isLoading={lookup.isLoading}
                              disableRequired={true}
                            />
                          </div>
                          <div className='form-group col-sm-12 col-md-5'>
                            <Select
                              name={`kapakRenk.${item.id}`}
                              value={item.kapakRenk}
                              onChange={onSelectChange}
                              text='Kapak Renk'
                              options={lookup.result}
                              onInputChange={(e) => lookupSetting(e, 'kapakRenk')}
                              onSelectMenuClose={onSelectMenuClose}
                              isLoading={lookup.isLoading}
                              disableRequired={true}
                            />
                          </div>
                          <div className='form-group col-sm-12 col-md-2'>
                            <NumberInput
                              label='En'
                              name={`en.${item.id}`}
                              value={item.en}
                              onChange={onInputChange}
                              valid={!!item.en}
                              invalid={!item.en}
                            />
                          </div>
                          <div className='form-group col-sm-12 col-md-2'>
                            <NumberInput
                              label='Boy'
                              name={`boy.${item.id}`}
                              value={item.boy}
                              onChange={onInputChange}
                              valid={!!item.boy}
                              invalid={!item.boy}
                            />
                          </div>
                          <div className='form-group col-sm-12 col-md-2'>
                            <NumberInput
                              label='Kalinlik'
                              name={`kalinlik.${item.id}`}
                              value={item.kalinlik}
                              onChange={onInputChange}
                              valid={!!item.kalinlik}
                              invalid={!item.kalinlik}
                            />
                          </div>
                          <div className='form-group col-md-6'>
                            <label htmlFor={`not.${item.id}`}>Not</label>
                            <textarea
                              className='form-control'
                              value={item.not}
                              onChange={onInputChange}
                              name={`not.${item.id}`}
                              rows='3'
                            />
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                )
              })}
          </Row>
        </CardBody>
      </Card>
    )
  }
}
export default Dekorasyon
