import React, { Component } from 'react'
import ReactSelect from 'react-select'
import Aux from '../hoc/Aux'
import { Label, Input, FormFeedback } from 'reactstrap'
const noop = () => {
  // no operation (do nothing real quick)
}

class Select extends Component {
  constructor() {
    super()
    this.handleChange = this.handleChange.bind(this)
  }
  selectRef = null
  setSelectRef = (ref) => {
    this.selectRef = ref
  }
  handleChange = (selected, action) => {
    this.props.onChange(this.props.name, selected, action)
  }
  getValue = () => {
    if (this.props.value) return this.props.value
    if (this.props.defaultValue) return this.props.defaultValue
    return ''
  }
  render() {
    const enableRequired = !this.props.disableRequired
    const hideLabel = this.props.hideLabel
    return (
      <Aux>
        {!hideLabel && <Label htmlFor={this.props.name}>{this.props.text}</Label>}
        <ReactSelect
          name={this.props.name}
          ref={this.setSelectRef}
          isDisabled={this.props.isDisabled || false}
          isMulti={this.props.isMulti || false}
          isLoading={this.props.isLoading || false}
          onInputChange={this.props.onInputChange || null}
          placeholder={this.props.placeholder || 'Secim yapiniz...'}
          defaultValue={this.props.defaultValue}
          value={this.getValue()}
          onMenuClose={this.props.onSelectMenuClose}
          options={this.props.options}
          onChange={this.handleChange}
          getOptionValue={this.props.getOptionValue ? this.props.getOptionValue : (option) => option['id']}
          getOptionLabel={this.props.getOptionLabel ? this.props.getOptionLabel : (option) => option['aciklama']}
          styles={this.props.customStyles}
        />
        {enableRequired && (
          <Aux>
            <Input
              tabIndex={-1}
              autoComplete='off'
              style={{
                opacity: 0,
                width: '100%',
                height: 0,
                position: 'absolute'
              }}
              value={this.getValue()}
              onChange={noop}
              onFocus={() => this.selectRef.focus()}
              required
              valid={!!this.getValue()}
              invalid={!this.getValue()}
            />
            <FormFeedback>* Zorunlu alan</FormFeedback>
          </Aux>
        )}
      </Aux>
    )
  }
}

export default Select
