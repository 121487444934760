import React, { PureComponent } from 'react'
import { Button, FormFeedback, Input, Label } from 'reactstrap'
import Select from '../../../Core/Select'
import NumberInput from '../../../Core/NumberInput'
import { kulpYonuOptions } from '../../../../shared'
class WardrobeModulForm extends PureComponent {
  handleSave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.props.save()
  }
  handleInputChange = (event) => {
    let value = event.target.value
    if (event.target.type === 'checkbox') {
      value = event.target.checked
    }
    this.props.onChange(event.target.name, value)
  }
  render() {
    return (
      <form onSubmit={this.handleSave}>
        <div className='form-group row'>
          <div className='form-group col-md-2 col-lg-2'>
            <NumberInput
              label='Modul Adet'
              placeholder='Modul Adet'
              name='adet'
              value={this.props.modulModal.data.adet}
              onChange={this.handleInputChange}
            />
          </div>
          <div className='form-group col-md-10 col-lg-10'>
            <Select
              name='modul'
              onChange={this.props.selectSetting}
              value={this.props.modulModal.data.modul}
              text='Modul'
              options={this.props.lookup.result}
              onInputChange={(e) => this.props.lookupSetting(e, 'modul')}
              onSelectMenuClose={this.props.onSelectMenuClose}
              isLoading={this.props.lookup.isLoading || this.props.lookup.isSelecting}
              isDisabled={this.props.lookup.isSelecting}
            />
          </div>
        </div>
        {this.props.tipi === 'acilan' && (
          <>
            <div className='form-group row'>
              <div className='form-group col-md-6 col-lg-4'>
                <Select
                  name='kulpmodeli'
                  value={this.props.modulModal.data.kulpmodeli}
                  onChange={this.props.onChange}
                  text='Kulp Modeli'
                  defaultValue={this.props.wardrobeOrder.kulpmodeli}
                  options={this.props.lookup.result}
                  onInputChange={(e) => this.props.lookupSetting(e, 'kulpModeli')}
                  onSelectMenuClose={this.props.onSelectMenuClose}
                  isLoading={this.props.lookup.isLoading}
                />
              </div>
              <div className='form-group col-md-6 col-lg-4'>
                <Select
                  name='kapakyonu'
                  onChange={this.props.onChange}
                  text='Kapak Yonu'
                  value={this.props.modulModal.data.kapakyonu}
                  defaultValue={this.props.wardrobeOrder.kapakyonu}
                  options={this.props.lookup.result}
                  onInputChange={(e) => this.props.lookupSetting(e, 'kapakYon')}
                  onSelectMenuClose={this.props.onSelectMenuClose}
                  isLoading={this.props.lookup.isLoading}
                />
              </div>
              <div className='form-group col-md-6 col-lg-4'>
                <Select
                  name='kapakmodeli'
                  onChange={this.props.onChange}
                  text='Kapak Modeli'
                  value={this.props.modulModal.data.kapakmodeli}
                  defaultValue={this.props.wardrobeOrder.kapakmodeli}
                  options={this.props.lookup.result}
                  onInputChange={(e) => this.props.lookupSetting(e, 'kapakModel')}
                  onSelectMenuClose={this.props.onSelectMenuClose}
                  isLoading={this.props.lookup.isLoading}
                />
              </div>
              <div className='form-group col-md-6 col-lg-4'>
                <Select
                  name='kapakrenk'
                  onChange={this.props.onChange}
                  text='Kapak Renk'
                  value={this.props.modulModal.data.kapakrenk}
                  defaultValue={this.props.wardrobeOrder.kapakrenk}
                  options={
                    this.props.modulModal.data.kapakmodeli && this.props.modulModal.data.kapakmodeli.kapakRenk
                      ? this.props.modulModal.data.kapakmodeli.kapakRenk
                      : []
                  }
                />
              </div>
              <div className='form-group col-md-6 col-lg-4'>
                <Select
                  name='kapakmalzemesi'
                  value={this.props.modulModal.data.kapakmalzemesi}
                  onChange={this.props.onChange}
                  defaultValue={this.props.wardrobeOrder.kapakmalzemesi}
                  text='Kapak Malzemesi'
                  options={
                    this.props.modulModal.data.kapakmodeli && this.props.modulModal.data.kapakmodeli.kapakRenk
                      ? this.props.modulModal.data.kapakmodeli.kapakRenk
                      : []
                  }
                />
              </div>
            </div>

            <div className='row p-3'>
              <div className='form-check form-switch'>
                <Input
                  className='form-check-input'
                  type='checkbox'
                  role='switch'
                  id='metalCerceveliKapak'
                  name='metalCerceveliKapak'
                  value='true'
                  onChange={this.handleInputChange}
                  checked={this.props.modulModal.data.metalCerceveliKapak ? 'checked' : ''}
                />
                <Label className='form-check-label' htmlFor='metalCerceveliKapak'>
                  Metal Cerceveli Kapak
                </Label>
              </div>
              {this.props.modulModal.data.metalCerceveliKapak && (
                <>
                  <div className='form-group col-lg-3'>
                    <NumberInput
                      min={1}
                      name='metalCerceveliKapakAdet'
                      label='Adet'
                      value={this.props.modulModal.data.metalCerceveliKapakAdet}
                      onChange={this.handleInputChange}
                      valid={this.props.modulModal.data.metalCerceveliKapakAdet > 0}
                      required
                    />
                  </div>
                  <div className='form-group col-lg-3'>
                    <Select
                      name='camModeli'
                      value={this.props.modulModal.data.camModeli || null}
                      onChange={this.props.onChange}
                      text='Cam Modeli'
                      options={this.props.lookup.result}
                      onInputChange={(e) => this.props.lookupSetting(e, 'camModeli')}
                      onSelectMenuClose={this.props.onSelectMenuClose}
                      isLoading={this.props.lookup.isLoading}
                    />
                  </div>
                  <div className='form-group col-lg-3'>
                    <Select
                      name='camProfili'
                      value={this.props.modulModal.data.camProfili || null}
                      onChange={this.props.onChange}
                      text='Cam Profili'
                      options={this.props.lookup.result}
                      onInputChange={(e) => this.props.lookupSetting(e, 'camProfili')}
                      onSelectMenuClose={this.props.onSelectMenuClose}
                      isLoading={this.props.lookup.isLoading}
                    />
                  </div>
                  <div className='form-group col-lg-3'>
                    <Select
                      name='kulpYonu'
                      value={this.props.modulModal.data.kulpYonu || null}
                      onChange={this.props.onChange}
                      text='Kulp Yonu'
                      options={kulpYonuOptions}
                    />
                  </div>
                </>
              )}
            </div>
            <div className='row p-3'>
              <div className='form-check form-switch'>
                <Input
                  className='form-check-input'
                  type='checkbox'
                  role='switch'
                  id='aynaliKapak'
                  name='aynaliKapak'
                  value='true'
                  onChange={this.handleInputChange}
                  checked={this.props.modulModal.data.aynaliKapak ? 'checked' : ''}
                />
                <Label className='form-check-label' htmlFor='aynaliKapak'>
                  Aynali Kapak
                </Label>
              </div>
              {this.props.modulModal.data.aynaliKapak && (
                <>
                  <div className='col-lg-3'>
                    <NumberInput
                      min={1}
                      name='aynaliKapakAdet'
                      label='Adet'
                      value={this.props.modulModal.data.aynaliKapakAdet}
                      onChange={this.handleInputChange}
                      valid={this.props.modulModal.data.aynaliKapakAdet > 0}
                      required
                    />
                  </div>
                  <div className='col-sm-6  col-md-3'>
                    <Label htmlFor='aynaliKapakOlcu'>Olcu</Label>
                    <Input
                      type='text'
                      className='form-control'
                      onChange={this.handleInputChange}
                      valid={!!this.props.modulModal.data.aynaliKapakOlcu}
                      value={this.props.modulModal.data.aynaliKapakOlcu}
                      invalid={!this.props.modulModal.data.aynaliKapakOlcu}
                      required
                      name='aynaliKapakOlcu'
                      placeholder='Olcu'
                    />
                    <FormFeedback>* Zorunlu alan</FormFeedback>
                  </div>
                  <div className='col-lg-3'>
                    <Select
                      name='dolapAynaModeli'
                      isMulti={false}
                      onChange={this.props.onChange}
                      text='Dolap Ayna Modeli'
                      value={this.props.modulModal.data.dolapAynaModeli}
                      options={this.props.lookup.result}
                      onInputChange={(e) => this.props.lookupSetting(e, 'dolapAynaModeli')}
                      onSelectMenuClose={this.props.onSelectMenuClose}
                      isLoading={this.props.lookup.isLoading}
                      getOptionValue={(option) => option['id']}
                      getOptionLabel={(option) => option['aciklama']}
                    />
                  </div>
                </>
              )}
            </div>
          </>
        )}
        <div className='row'>
          <div className='col-sm-12 col-md-6'>
            <label htmlFor='ozelislem'>Ozel istekler</label>
            <textarea
              className='form-control'
              onChange={this.handleInputChange}
              value={this.props.modulModal.data.ozelislem}
              name='ozelislem'
              id='ozelislem'
              rows='3'
            />
          </div>
        </div>
        <Button color='secondary' className='float-start' onClick={this.props.cancel}>
          Vazgec
        </Button>
        <button type='submit' className='btn btn-primary'>
          <i className='fas fa-save' />
          Kaydet
        </button>
      </form>
    )
  }
}

export default WardrobeModulForm
