import * as actionTypes from '../actions/actionTypes'
import { toast } from 'react-toastify'
import { toasterMessages, toastOptions, toastErrorOptions } from '../../shared'
import { initialPaging, initLookup } from '../dataModels/CommonModals'

export const resetLookupsResult = () => ({
  kitchen: initLookup(),
  wardrobe: initLookup()
})
export const initialGroupForm = () => ({
  saving: false,
  loading: false,
  downloading: false,
  error: null,
  lookup: resetLookupsResult(),
  id: null,
  name: '',
  files: [],
  fileName: '',
  kitchenOrders: [],
  wardrobeOrders: []
})
const initialState = {
  list: [],
  paging: initialPaging(),
  loading: false,
  groupForm: initialGroupForm(),
  error: null
}
const orderGroupLookupReset = (state, action) => {
  return {
    ...state,
    groupForm: { ...state.groupForm, lookup: { ...state.groupForm.lookup, [action.payload.type]: initLookup() } }
  }
}
const fetchGroupsSuccess = (state, action) => {
  toast.success(`Haftaliklar yuklendi!`, toastOptions)
  return { ...state, ...{ list: action.payload.orders, paging: action.payload.paging, loading: false, error: null } }
}

const fetchGroupsStart = (state, action) => {
  return { ...state, ...{ list: [], loading: true, error: null, paging: initialPaging() } }
}

const fetchGroupsFail = (state, action) => {
  toast.error(toasterMessages.errorWithMessage(action.error.message), toastErrorOptions)
  return { ...state, ...{ loading: false, error: action.error.message } }
}
const fetchGroupFilesStart = (state, action) => {
  return {
    ...state,
    groupForm: {
      ...state.groupForm,
      files: [],
      downloading: true
    }
  }
}
const fetchGroupFilesSuccess = (state, action) => {
  toast.success(`Raporlar yuklendi!`, toastOptions)
  let { files } = action.payload
  return {
    ...state,
    groupForm: {
      ...state.groupForm,
      files: files || [],
      downloading: false
    }
  }
}
const fetchGroupFilesFail = (state, action) => {
  toast.error(toasterMessages.errorWithMessage(action.error.message), toastErrorOptions)
  return {
    ...state,
    groupForm: {
      ...state.groupForm,
      error: action.error.message,
      downloading: false
    }
  }
}

const fetchGroupSuccess = (state, action) => {
  toast.success(`Haftalik yuklendi!`, toastOptions)
  const {groups, files} = action.payload
  return {
    ...state,
    groupForm: {
      ...state.groupForm,
      loading: false,
      files,
      id: groups.id,
      name: groups.name,
      kitchenOrders: groups.kitchenOrders || [],
      wardrobeOrders: groups.wardrobeOrders || []
    }
  }
}

const fetchGroupStart = (state, action) => {
  return {
    ...state,
    list: [],
    paging: initialPaging(),
    groupForm: {
      ...initialGroupForm(),
      loading: true
    }
  }
}

const fetchGroupFail = (state, action) => {
  toast.error(toasterMessages.errorWithMessage(action.error.message), toastErrorOptions)
  return {
    ...state,
    groupForm: {
      ...state.groupForm,
      loading: false,
      lookup: resetLookupsResult(),
      error: action.error.message
    }
  }
}

const saveGroupSuccess = (state, action) => {
  toast.success(`Haftalik kaydedildi!`, toastOptions)
  return {
    ...state,
    groupForm: {
      ...state.groupForm,
      saving: false,
      id: action.payload.id
    }
  }
}

const saveGroupStart = (state, action) => {
  return {
    ...state,
    list: [],
    paging: initialPaging(),
    groupForm: {
      ...state.groupForm,
      saving: true
    }
  }
}

const saveGroupFail = (state, action) => {
  toast.error(toasterMessages.errorWithMessage(action.error.message), toastErrorOptions)
  return {
    ...state,
    groupForm: {
      ...state.groupForm,
      saving: false,
      error: action.error.message
    }
  }
}
const newOrderGroup = (state, action) => {
  return {
    ...state,
    groupForm: initialGroupForm()
  }
}
const updateOrderGroupFormProperty = (state, action) => {
  const { name, value } = action.payload
  return {
    ...state,
    groupForm: {
      ...state.groupForm,
      [name]: value
    }
  }
}
const searchOrderStart = (state, action) => {
  return {
    ...state,
    groupForm: {
      ...state.groupForm,
      lookup: { ...state.groupForm.lookup, [action.payload.type]: { result: [], isLoading: true } }
    }
  }
}
const searchOrderSuccess = (state, action) => {
  return {
    ...state,
    groupForm: {
      ...state.groupForm,
      lookup: { ...state.groupForm.lookup, [action.payload.type]: { result: action.payload.data, isLoading: false } }
    }
  }
}

const searchOrderFail = (state, action) => {
  return { ...state, groupForm: { ...state.groupForm, lookup: resetLookupsResult() } }
}
const removeKitchenOrder = (state, action) => {
  return {
    ...state,
    groupForm: {
      ...state.groupForm,
      kitchenOrders: state.groupForm.kitchenOrders.filter((o) => o.id !== action.payload.order.id)
    }
  }
}
const addKitchenOrder = (state, action) => {
  if (state.groupForm.kitchenOrders.some((o) => Number(o.id) === Number(action.payload.order.id))) {
    toast.error('Bu siparis listede mevcut', toastOptions)
    return state
  }
  return {
    ...state,
    groupForm: {
      ...state.groupForm,
      kitchenOrders: [...state.groupForm.kitchenOrders, action.payload.order]
    }
  }
}
const removeWardrobeOrder = (state, action) => {
  return {
    ...state,
    groupForm: {
      ...state.groupForm,
      wardrobeOrders: state.groupForm.wardrobeOrders.filter((o) => o.id !== action.payload.order.id)
    }
  }
}
const addWardrobeOrder = (state, action) => {
  if (state.groupForm.wardrobeOrders.some((o) => Number(o.id) === Number(action.payload.order.id))) {
    toast.error('Bu siparis listede mevcut', toastOptions)
    return state
  }
  return {
    ...state,
    groupForm: {
      ...state.groupForm,
      wardrobeOrders: [...state.groupForm.wardrobeOrders, action.payload.order]
    }
  }
}

//REPORT FUNCTIONS
const onDownloadReportStart = (state, action) => {
  return {
    ...state,
    groupForm: {
      ...state.groupForm,
      downloading: true,
      error: null
    }
  }
}
const onReportS3UploadSuccess = (state, action) => {
  const { fileName } = action.payload
  toast.info(`${fileName.split('/').pop()} rapor hazirlaniyor`, toastErrorOptions)
  return {
    ...state,
    groupForm: {
      ...state.groupForm,
      fileName,
      downloading: false,
      error: null
    }
  }
}
const onDownloadReportSuccess = (state, action) => {
  const { data, reportName } = action.payload
  const url = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `${reportName}.xlsx`) //or any other extension
  document.body.appendChild(link)
  link.click()
  return {
    ...state,
    groupForm: {
      ...state.groupForm,
      downloading: false,
      error: null
    }
  }
}
const onDownloadReportFail = (state, action) => {
  toast.error(toasterMessages.errorWithTitle(`Rapor indirilirken`), toastErrorOptions)
  return {
    ...state,
    groupForm: {
      ...state.groupForm,
      downloading: false,
      error: action.payload
    }
  }
}
export const groupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ORDER_GROUPS_SUCCESS:
      return fetchGroupsSuccess(state, action)

    case actionTypes.FETCH_ORDER_GROUPS_FAIL:
      return fetchGroupsFail(state, action)

    case actionTypes.FETCH_ORDER_GROUPS_START:
      return fetchGroupsStart(state, action)

    case actionTypes.FETCH_ORDER_GROUP_SUCCESS:
      return fetchGroupSuccess(state, action)

    case actionTypes.FETCH_ORDER_GROUP_FAIL:
      return fetchGroupFail(state, action)

    case actionTypes.FETCH_ORDER_GROUP_START:
      return fetchGroupStart(state, action)

    case actionTypes.FETCH_ORDER_GROUP_FILES_SUCCESS:
      return fetchGroupFilesSuccess(state, action)

    case actionTypes.FETCH_ORDER_GROUP_FILES_FAIL:
      return fetchGroupFilesFail(state, action)

    case actionTypes.FETCH_ORDER_GROUP_FILES_START:
      return fetchGroupFilesStart(state, action)

    case actionTypes.SAVE_ORDER_GROUP_SUCCESS:
      return saveGroupSuccess(state, action)

    case actionTypes.SAVE_ORDER_GROUP_FAIL:
      return saveGroupFail(state, action)

    case actionTypes.SAVE_ORDER_GROUP_START:
      return saveGroupStart(state, action)

    case actionTypes.UPDATE_ORDER_GROUP_FORM_PROPERTY:
      return updateOrderGroupFormProperty(state, action)

    case actionTypes.NEW_ORDER_GROUP:
      return newOrderGroup(state, action)

    case actionTypes.ORDER_GROUP_RESET_LOOKUP:
      return orderGroupLookupReset(state, action)

    case actionTypes.ORDER_GROUP_ADD_KITCHEN_ORDER:
      return addKitchenOrder(state, action)

    case actionTypes.ORDER_GROUP_REMOVE_KITCHEN_ORDER:
      return removeKitchenOrder(state, action)

    case actionTypes.ORDER_GROUP_ADD_WARDROBE_ORDER:
      return addWardrobeOrder(state, action)

    case actionTypes.ORDER_GROUP_REMOVE_WARDROBE_ORDER:
      return removeWardrobeOrder(state, action)

    case actionTypes.ORDER_GROUP_ORDER_SEARCH_SUCCESS:
      return searchOrderSuccess(state, action)

    case actionTypes.ORDER_GROUP_ORDER_SEARCH_FAIL:
      return searchOrderFail(state, action)

    case actionTypes.ORDER_GROUP_ORDER_SEARCH_START:
      return searchOrderStart(state, action)

    case actionTypes.ORDER_GROUP_DOWNLOAD_REPORT_START:
      return onDownloadReportStart(state, action)
    case actionTypes.ORDER_GROUP_DOWNLOAD_REPORT_SUCCESS:
      return onDownloadReportSuccess(state, action)
    case actionTypes.ORDER_GROUP_UPLOAD_S3_REPORT_SUCCESS:
      return onReportS3UploadSuccess(state, action)
    case actionTypes.ORDER_GROUP_DOWNLOAD_REPORT_FAIL:
      return onDownloadReportFail(state, action)

    default:
      return state
  }
}
