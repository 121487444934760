import React from 'react'
import NumberInput from '../../Core/NumberInput'
import { FormFeedback, Input, Label } from 'reactstrap'
const SurmeKulpVeMekanizma = (props) => {
  let { surmeKapakCalismaPayi, surmeKulp, mekanizma, index, kapakKesimOlcusu, aynaKesimOlcusu, fiyat } = props
  if (!kapakKesimOlcusu) {
    kapakKesimOlcusu = {
      enEkle: 0,
      boyEkle: 0,
      enCikar: 0,
      boyCikar: 0
    }
  }
  if (!aynaKesimOlcusu) {
    aynaKesimOlcusu = {
      enEkle: 0,
      boyEkle: 0,
      enCikar: 0,
      boyCikar: 0
    }
  }
  return (
    <>
      <div className='row'>
        <div className='form-group col-6'>
          <Label htmlFor='surmeKulp'>Surme Kulp</Label>
          <Input
            type='text'
            className='form-control'
            onChange={props.onChange}
            valid={!!surmeKulp}
            value={surmeKulp}
            invalid={!surmeKulp}
            data-row={index}
            required
            name='surmeKulp'
            placeholder='Surme Kulp'
          />
          <FormFeedback>* Zorunlu alan</FormFeedback>
        </div>
        <div className='form-group col-6'>
          <Label htmlFor='mekanizma'>Mekanizma</Label>
          <Input
            type='text'
            className='form-control'
            onChange={props.onChange}
            valid={!!mekanizma}
            value={mekanizma}
            invalid={!mekanizma}
            data-row={index}
            required
            name='mekanizma'
            placeholder='Mekanizma'
          />
          <FormFeedback>* Zorunlu alan</FormFeedback>
        </div>
        <div className='col-md-2 col-sm-12'>
          <NumberInput label='Fiyat €' name='fiyat' step='0.001' dataRow={index} value={fiyat} onChange={props.onChange} required />
        </div>
      </div>
      <div className='row'>
        <div className='col-md-3'>
          <NumberInput
            label='En Ekle(cm)'
            name='surmeKapakCalismaPayi.enEkle'
            onChange={props.onChange}
            dataRow={index}
            value={surmeKapakCalismaPayi.enEkle}
          />
        </div>
        <div className='col-md-3'>
          <NumberInput
            label='Boy Ekle(cm)'
            name='surmeKapakCalismaPayi.boyEkle'
            onChange={props.onChange}
            dataRow={index}
            value={surmeKapakCalismaPayi.boyEkle}
          />
        </div>
        <div className='col-md-3'>
          <NumberInput
            label='En Cikar(cm)'
            name='surmeKapakCalismaPayi.enCikar'
            onChange={props.onChange}
            dataRow={index}
            value={surmeKapakCalismaPayi.enCikar}
          />
        </div>
        <div className='col-md-3'>
          <NumberInput
            label='Boy Cikar(cm)'
            name='surmeKapakCalismaPayi.boyCikar'
            onChange={props.onChange}
            dataRow={index}
            value={surmeKapakCalismaPayi.boyCikar}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-md-3'>
          <NumberInput
            label='Kapak Kesim En Ekle(cm)'
            name='kapakKesimOlcusu.enEkle'
            onChange={props.onChange}
            dataRow={index}
            value={kapakKesimOlcusu.enEkle}
          />
        </div>
        <div className='col-md-3'>
          <NumberInput
            label='Kapak Kesim Boy Ekle(cm)'
            name='kapakKesimOlcusu.boyEkle'
            onChange={props.onChange}
            dataRow={index}
            value={kapakKesimOlcusu.boyEkle}
          />
        </div>
        <div className='col-md-3'>
          <NumberInput
            label='Kapak Kesim En Cikar(cm)'
            name='kapakKesimOlcusu.enCikar'
            onChange={props.onChange}
            dataRow={index}
            value={kapakKesimOlcusu.enCikar}
          />
        </div>
        <div className='col-md-3'>
          <NumberInput
            label='Kapak Kesim Boy Cikar(cm)'
            name='kapakKesimOlcusu.boyCikar'
            onChange={props.onChange}
            dataRow={index}
            value={kapakKesimOlcusu.boyCikar}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-md-3'>
          <NumberInput
            label='Ayna Kesim En Ekle(cm)'
            name='aynaKesimOlcusu.enEkle'
            onChange={props.onChange}
            dataRow={index}
            value={aynaKesimOlcusu.enEkle}
          />
        </div>
        <div className='col-md-3'>
          <NumberInput
            label='Ayna Kesim Boy Ekle(cm)'
            name='aynaKesimOlcusu.boyEkle'
            onChange={props.onChange}
            dataRow={index}
            value={aynaKesimOlcusu.boyEkle}
          />
        </div>
        <div className='col-md-3'>
          <NumberInput
            label='Ayna Kesim En Cikar(cm)'
            name='aynaKesimOlcusu.enCikar'
            onChange={props.onChange}
            dataRow={index}
            value={aynaKesimOlcusu.enCikar}
          />
        </div>
        <div className='col-md-3'>
          <NumberInput
            label='Ayna Kesim Boy Cikar(cm)'
            name='aynaKesimOlcusu.boyCikar'
            onChange={props.onChange}
            dataRow={index}
            value={aynaKesimOlcusu.boyCikar}
          />
        </div>
      </div>
    </>
  )
}

export default SurmeKulpVeMekanizma
