import React, { useEffect } from 'react'
import * as actions from '../../store/actions'
import { useDispatch, useSelector } from 'react-redux'
import Aux from '../hoc/Aux'
import UserEdit from './UserEdit'
import Loading from '../Common/Loading'

const UserEditContainer = (props) => {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.users)
  const { token } = useSelector((state) => state.auth)

  useEffect(() => {
    if (props.match.params.id) {
      dispatch(actions.fetchUser(token, props.match.params.id))
    }
  }, [props.match.params.id, token, dispatch])
  return <Aux>{user.loading ? <Loading /> : <UserEdit />}</Aux>
}

export default UserEditContainer
