import { Alert, Button, Input, InputGroup, InputGroupText } from 'reactstrap'
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'

const SettingsSearchBar = (props) => {
  const [query, setQuery] = useState('')
  const onClearSearchClick = () => {
    props.handleClearSearchClick()
  }
  const onSearchClick = () => {
    props.handleSearchClick(query)
    setQuery('')
  }
  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-4'>
          <h1>{props.title}</h1>
        </div>
        <div className='col-8 p-2'>
          <InputGroup>
            <Input placeholder='...' value={query} name='query' onChange={(e) => setQuery(e.target.value)} />
            <Button color='secondary' onClick={onSearchClick}>
              <i className='fas fa-search' />
              Arama
            </Button>

            <NavLink className='btn btn-primary ml-sm-2' to={`/uretim/ayarlar/${props.settingName}/yeni`}>
              <i className='fa fa-plus' /> Yeni
            </NavLink>
          </InputGroup>
        </div>
      </div>
      {props.paging.q && (
        <div className='row'>
          <div className='col'>
            <Alert color='info'>
              <Button color='danger' onClick={onClearSearchClick}>
                <i className='fas fa-times' />
              </Button>
              Arama "<strong>{props.paging.q}</strong>"
            </Alert>
          </div>
        </div>
      )}
    </div>
  )
}

export default SettingsSearchBar
