import * as actionTypes from './actionTypes'
import { instance as axios } from '../../axios-api'
import { apiPath } from '../../shared'
import { errorHandler } from './errorHandler'

export const resetKitchenOrderForm = () => ({
  type: actionTypes.RESET_KITCHEN_ORDER_FORM
})
export const fetchKitchenOrderSuccess = (data) => ({
  type: actionTypes.FETCH_KITCHEN_ORDER_SUCCESS,
  data
})

export const fetchKitchenOrderFail = (error) => ({
  type: actionTypes.FETCH_KITCHEN_ORDER_FAIL,
  error: error
})

export const fetchKitchenOrderStart = () => ({
  type: actionTypes.FETCH_KITCHEN_ORDER_START
})

export const saveKitchenOrderStart = () => ({
  type: actionTypes.SAVE_KITCHEN_ORDER_START
})
export const saveKitchenOrderSuccess = (data) => ({
  type: actionTypes.SAVE_KITCHEN_ORDER_SUCCESS,
  data
})

export const saveKitchenOrderFail = (error) => ({
  type: actionTypes.SAVE_KITCHEN_ORDER_FAIL,
  error: error
})

export const removeKitchenModul = (index) => ({
  type: actionTypes.REMOVE_MODUL_KITCHEN_ORDER,
  payload: { index }
})

export const addTezgah = () => ({
  type: actionTypes.ADD_TEZGAH_KITCHEN_ORDER
})

export const removeTezgah = (id) => ({
  type: actionTypes.REMOVE_TEZGAH_KITCHEN_ORDER,
  payload: { id }
})

//DEKORASYON
export const updateKitchenDekorasyonProperty = (id, name, value) => ({
  type: actionTypes.UPDATE_KITCHEN_ORDER_DEKORASYON_PROPERTY,
  payload: { id, name, value }
})

export const addKitchenDekorasyon = () => ({
  type: actionTypes.ADD_KITCHEN_ORDER_DEKORASYON
})

export const removeKitchenDekorasyon = (id) => ({
  type: actionTypes.REMOVE_KITCHEN_ORDER_DEKORASYON,
  payload: { id }
})

// AKSESUAR MODAL
export const updateKitchenAksesuarModal = (action) => ({
  type: actionTypes.UPDATE_KITCHEN_MODUL_AKSESUAR_MODAL,
  payload: action
})
export const updateAksesuarModalNew = (name, value) => ({
  type: actionTypes.UPDATE_KITCHEN_MODUL_AKSESUAR_MODAL_NEW,
  payload: { name, value }
})
export const toggleKitchenModulAksesuarModal = (payload) => ({
  type: actionTypes.TOGGLE_KITCHEN_MODUL_AKSESUAR_MODAL,
  payload
})

// AKSAM MODAL
export const updateKitchenAksamModal = (action) => ({
  type: actionTypes.UPDATE_KITCHEN_MODUL_AKSAM_MODAL,
  payload: action
})
export const updateKitchenAksamModalNew = (name, value) => ({
  type: actionTypes.UPDATE_KITCHEN_MODUL_AKSAM_MODAL_NEW,
  payload: { name, value }
})
export const toggleKitchenModulAksamModal = (payload) => ({
  type: actionTypes.TOGGLE_KITCHEN_MODUL_AKSAM_MODAL,
  payload
})

// PARCA MODAL
export const updateKitchenParcaModal = (action) => ({
  type: actionTypes.UPDATE_KITCHEN_MODUL_PARCA_MODAL,
  payload: action
})

export const updateKitchenParcaModalNew = (name, value) => ({
  type: actionTypes.UPDATE_KITCHEN_MODUL_PARCA_MODAL_NEW,
  payload: { name, value }
})

export const toggleKitchenModulParcaModal = (payload) => ({
  type: actionTypes.TOGGLE_KITCHEN_MODUL_PARCA_MODAL,
  payload
})

// MODUL MODAL
export const toggleKitchenModulModal = (payload) => ({
  type: actionTypes.TOGGLE_KITCHEN_MODUL_MODAL,
  payload
})

export const updateTezgahProperty = (id, name, value) => ({
  type: actionTypes.UPDATE_KITCHEN_ORDER_TEZGAH_PROPERTY,
  payload: { id, name, value }
})

export const addKapama = () => ({
  type: actionTypes.ADD_KAPAMA_KITCHEN_ORDER
})

export const removeKapama = (id) => ({
  type: actionTypes.REMOVE_KAPAMA_KITCHEN_ORDER,
  payload: { id }
})

export const updateKapamaProperty = (id, name, value) => ({
  type: actionTypes.UPDATE_KITCHEN_ORDER_KAPAMA_PROPERTY,
  payload: { id, name, value }
})

export const updateModulModalDataProperty = (property, value) => ({
  type: actionTypes.UPDATE_KITCHEN_ORDER_MODUL_MODAL_DATA_CHANGE,
  payload: { property, value }
})
export const updateKitchenOrderProperty = (event) => {
  const field = event.target.name
  const value = event.target.value
  const target = event.target
  return {
    type: actionTypes.UPDATE_KITCHEN_ORDER_PROPERTY,
    payload: { field, value, target }
  }
}

export const updateMutfakOrderSelect = (field, value, updateModuller) => ({
  type: actionTypes.UPDATE_KITCHEN_ORDER_SELECT,
  payload: { field, value, updateModuller }
})

export const saveKitchenOrder = (token, data) => {
  return async (dispatch) => {
    try {
      dispatch(saveKitchenOrderStart())
      const resp = await axios(token)({
        method: data.id ? 'PUT' : 'POST',
        url: data.id ? apiPath.kitchenOrder(data.id) : apiPath.kitchenOrderList(),
        data
      })
      dispatch(saveKitchenOrderSuccess(resp.data))
    } catch (error) {
      errorHandler(dispatch, saveKitchenOrderFail, error)
    }
  }
}
export const fetchKitchenOrder = (token, id) => {
  return async (dispatch) => {
    try {
      dispatch(fetchKitchenOrderStart())
      const response = await axios(token).get(apiPath.kitchenOrder(id))
      dispatch(fetchKitchenOrderSuccess({ order: response.data }))
    } catch (error) {
      errorHandler(dispatch, fetchKitchenOrderFail, error)
    }
  }
}

export const searchMuteahhit = (token, inputValue) => {
  return async (dispatch) => {
    try {
      dispatch(lookupStart())
      const response = await axios(token).get(apiPath.muteahhitSearch(inputValue))
      dispatch(lookupSuccess(response.data))
    } catch (error) {
      errorHandler(dispatch, lookupFail, error)
    }
  }
}
export const searchMusteri = (token, inputValue) => {
  return async (dispatch) => {
    try {
      dispatch(lookupStart())
      const response = await axios(token).get(apiPath.customerSearch(inputValue))
      dispatch(lookupSuccess(response.data))
    } catch (error) {
      errorHandler(dispatch, lookupFail, error)
    }
  }
}
export const selectSetting = (token, item, name) => {
  return async (dispatch) => {
    try {
      dispatch(lookupSelectionStart())
      const response = await axios(token).get(apiPath.uretimSettingsGetById(name, item.id))
      dispatch(updateModulModalDataProperty(name, response.data))
      dispatch(lookupSelectionSuccess())
    } catch (error) {
      errorHandler(dispatch, lookupFail, error)
    }
  }
}
export const searchSetting = (token, name, inputValue) => {
  return async (dispatch) => {
    try {
      dispatch(lookupStart())
      const response = await axios(token).get(apiPath.uretimSettingsLookup(name, inputValue))
      dispatch(lookupSuccess(response.data))
    } catch (error) {
      errorHandler(dispatch, lookupFail, error)
    }
  }
}
export const lookupSelectionStart = () => ({
  type: actionTypes.SEARCH_LOOKUP_SELECTION_START
})
export const lookupSelectionSuccess = () => ({
  type: actionTypes.SEARCH_LOOKUP_SELECTION_SUCCESS
})
export const lookupStart = () => ({
  type: actionTypes.SEARCH_LOOKUP_START
})
export const lookupFail = (error) => ({
  type: actionTypes.SEARCH_LOOKUP_FAIL,
  payload: { error }
})
export const lookupSuccess = (data) => ({
  type: actionTypes.SEARCH_LOOKUP_SUCCESS,
  payload: { data }
})
export const onSelectMenuClose = () => ({
  type: actionTypes.SEARCH_LOOKUP_RESET
})

// REPORT DOWNLOAD
export const onDownloadKitchenReport = (token, reportName, id) => {
  return async (dispatch) => {
    try {
      dispatch(onDownloadReportStart())
      const options = {
        url: apiPath.kitchenOrderReport(id),
        method: 'GET',
        responseType: 'blob' // important
      }
      const response = await axios(token)(options)
      dispatch(onDownloadReportSuccess(response.data, reportName))
    } catch (error) {
      errorHandler(dispatch, onDownloadReportFail, error)
    }
  }
}
const onDownloadReportStart = () => ({
  type: actionTypes.KITCHEN_ORDER_DOWNLOAD_REPORT
})
const onDownloadReportSuccess = (data, reportName) => ({
  type: actionTypes.KITCHEN_ORDER_DOWNLOAD_REPORT_SUCCESS,
  payload: { data, reportName }
})
const onDownloadReportFail = (error) => ({
  type: actionTypes.KITCHEN_ORDER_DOWNLOAD_REPORT_FAIL,
  payload: error
})
