import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../../store/actions'
import { Label, Col, Input, Row, CardHeader, Card, CardBody } from 'reactstrap'
import Loading from '../../Common/Loading'
import { NavLink } from 'react-router-dom'
import Error from '../../Common/Error'

const ProductGroupsEditContainer = (props) => {
  const dispatch = useDispatch()
  const { loading, productGroupForm, error } = useSelector((state) => state.productGroups)
  const { token } = useSelector((state) => state.auth)

  useEffect(() => {
    dispatch(actions.fetchProductGroup(token, props.match.params.id))
  }, [props.match.params.id, token, dispatch])
  const handleSave = (e) => {
    e.preventDefault()
    dispatch(actions.saveProductGroup(token, productGroupForm))
  }
  const handleInputChange = (e) => {
    const target = e.target
    dispatch(actions.alimInputChange(target.name, target.value))
  }
  const { fields, id } = productGroupForm
  return (
    <Row>
      <Col sm='12'>
        <h3>Urun Grubu</h3>
      </Col>
      {error && <Error error={error} />}
      {loading ? (
        <Loading />
      ) : (
        <Col sm='12'>
          <form onSubmit={handleSave}>
            <Card className='shadow mb-2'>
              <CardHeader>Ozellikler</CardHeader>
              <CardBody>
                <Label htmlFor='title'>Isim</Label>
                <Input
                  type='text'
                  name='fields.description'
                  required
                  value={fields.description}
                  onChange={handleInputChange}
                />
                <Input type='hidden' name='id' value={id} />
              </CardBody>
            </Card>
            <nav className='navbar fixed-bottom navbar-dark bg-dark justify-content-between'>
              <NavLink className='btn btn-warning  m-1' to='/alim/urun-gruplari'>
                Vazgec
              </NavLink>
              <button type='submit' className='btn btn-primary'>
                <i className='fas fa-save' />
                Kaydet
              </button>
            </nav>
          </form>
        </Col>
      )}
    </Row>
  )
}

export default ProductGroupsEditContainer
