import NumberInput from '../../Core/NumberInput'
import React from 'react'

const CekmceSistemiSettings = (props) => {
  const {
    index,
    cekmeceTabaniEnCikar,
    cekmeceTabaniBoyCikar,
    onChange,
    cekmeceArkasiEnCikar,
    cekmeceArkasiBoyCikar,
    cekmeceArkasiBoyCikarBordursuz,
    tabanMelamiKalinligi,
    fiyat
  } = props
  return (
    <>
      <div className='row'>
        <div className='form-group col-md-6'>
          <NumberInput
            label='Cekmece Arkasi En Cikar'
            name='cekmeceArkasiEnCikar'
            dataRow={index}
            value={cekmeceArkasiEnCikar}
            onChange={onChange}
            valid={~~cekmeceArkasiEnCikar > 0}
            invalid={!cekmeceArkasiEnCikar}
            required
          />
        </div>
      </div>
      <div className='row'>
        <div className='form-group col-md-12'>
          <div className='form-group col-md-5'>
            <NumberInput
              label='Cekmece Arkasi Yukseklik Cikar Bordurlu'
              name='cekmeceArkasiBoyCikar'
              dataRow={index}
              value={cekmeceArkasiBoyCikar}
              onChange={onChange}
              valid={~~cekmeceArkasiBoyCikar > 0}
              invalid={!cekmeceArkasiBoyCikar}
              required
            />
          </div>
          <div className='form-group col-md-5'>
            <NumberInput
              label='Cekmece Arkasi Yukseklik Cikar Bordursuz'
              name='cekmeceArkasiBoyCikarBordursuz'
              dataRow={index}
              value={cekmeceArkasiBoyCikarBordursuz}
              onChange={onChange}
              valid={~~cekmeceArkasiBoyCikarBordursuz > 0}
              invalid={!cekmeceArkasiBoyCikarBordursuz}
              required
            />
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='form-group col-md-4'>
          <NumberInput
            label='Cekmece Tabani En Cikar'
            name='cekmeceTabaniEnCikar'
            dataRow={index}
            value={cekmeceTabaniEnCikar}
            onChange={onChange}
            valid={~~cekmeceTabaniEnCikar > 0}
            invalid={!cekmeceTabaniEnCikar}
            required
          />
          <NumberInput
            label='Cekmece Tabani Derinlik Cikar'
            name='cekmeceTabaniBoyCikar'
            dataRow={index}
            value={cekmeceTabaniBoyCikar}
            onChange={onChange}
            valid={~~cekmeceTabaniBoyCikar > 0}
            invalid={!cekmeceTabaniBoyCikar}
            required
          />
          <NumberInput
            label='Taban Melami Kalinligi'
            name='tabanMelamiKalinligi'
            dataRow={index}
            value={tabanMelamiKalinligi}
            onChange={onChange}
            valid={~~tabanMelamiKalinligi > 0}
            invalid={!tabanMelamiKalinligi}
            required
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-md-2 col-sm-12'>
          <NumberInput label='Fiyat €' name='fiyat' step='0.001' dataRow={index} value={fiyat} onChange={onChange} required />
        </div>
      </div>
    </>
  )
}

export default CekmceSistemiSettings
