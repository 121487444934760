import React from "react";
import {CardHeader,CardBody,Card} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import * as actions from "../../../../store/actions";
import NumberInput from "../../../Core/NumberInput";

const GolaDetails = (props) => {
    const dispatch = useDispatch()
    const {
        kitchenForm: { golaDetails }
    } = useSelector((state) => state.orderForm)
    const handleInputChange = (event) => dispatch(actions.updateKitchenOrderProperty(event))
    return (<Card className='shadow mb-2'>
        <CardHeader>Gola Detaylari</CardHeader>
        <CardBody>
            <div className='row'>
                <div className='mb-3 col-lg-3'>
                    <NumberInput
                        min={0}
                        name='golaDetails.tekliGolaSolKapakAdeti'
                        label='Tekli Gola Sol Kapak Adeti'
                        value={golaDetails?.tekliGolaSolKapakAdeti || 0}
                        onChange={handleInputChange}
                        valid={golaDetails?.tekliGolaSolKapakAdeti > 0}
                        required
                    />
                </div>
                <div className='mb-3 col-lg-3'>
                    <NumberInput
                        min={0}
                        name='golaDetails.tekliGolaSagKapakAdeti'
                        label='Tekli Gola Sag Kapak Adeti'
                        value={golaDetails?.tekliGolaSagKapakAdeti || 0}
                        onChange={handleInputChange}
                        valid={golaDetails?.tekliGolaSagKapakAdeti > 0}
                        required
                    />
                </div>
                <div className='mb-3 col-lg-3'>
                    <NumberInput
                        min={0}
                        name='golaDetails.ciftliGolaKapakAdeti'
                        label='Ciftli Gola Kapak Adeti'
                        value={golaDetails?.ciftliGolaKapakAdeti || 0}
                        onChange={handleInputChange}
                        valid={golaDetails?.ciftliGolaKapakAdeti > 0}
                        required
                    />
                </div>
                <div className='mb-3 col-lg-3'>
                    <NumberInput
                        min={0}
                        name='golaDetails.golaBaglantiParcasiAdeti'
                        label='Gola Baglanti Parcasi Adeti'
                        value={golaDetails?.golaBaglantiParcasiAdeti || 0}
                        onChange={handleInputChange}
                        valid={golaDetails?.golaBaglantiParcasiAdeti > 0}
                        required
                    />
                </div>
                <div className='mb-3 col-lg-3'>
                    <NumberInput
                        min={0}
                        name='golaDetails.yatayTekliBirOlcusu'
                        label='Yatay Tekli Bir Olcusu'
                        value={golaDetails?.yatayTekliBirOlcusu || 0}
                        onChange={handleInputChange}
                        valid={golaDetails?.yatayTekliBirOlcusu > 0}
                        required
                    />
                </div>
                <div className='mb-3 col-lg-3'>
                    <NumberInput
                        min={0}
                        name='golaDetails.yatayTekliIkiOlcusu'
                        label='Yatay Tekli Iki Olcusu'
                        value={golaDetails?.yatayTekliIkiOlcusu || 0}
                        onChange={handleInputChange}
                        valid={golaDetails?.yatayTekliIkiOlcusu > 0}
                        required
                    />
                </div>
                <div className='mb-3 col-lg-3'>
                    <NumberInput
                        min={0}
                        name='golaDetails.yatayTekliUcOlcusu'
                        label='Yatay Tekli Uc Olcusu'
                        value={golaDetails?.yatayTekliUcOlcusu || 0}
                        onChange={handleInputChange}
                        valid={golaDetails?.yatayTekliUcOlcusu > 0}
                        required
                    />
                </div>

                <div className='mb-3 col-lg-3'>
                    <NumberInput
                        min={0}
                        name='golaDetails.yatayCiftliBirOlcusu'
                        label='Yatay Ciftli Bir Olcusu'
                        value={golaDetails?.yatayCiftliBirOlcusu || 0}
                        onChange={handleInputChange}
                        valid={golaDetails?.yatayCiftliBirOlcusu > 0}
                        required
                    />
                </div>
                <div className='mb-3 col-lg-3'>
                    <NumberInput
                        min={0}
                        name='golaDetails.yatayCiftliIkiOlcusu'
                        label='Yatay Ciftli Iki Olcusu'
                        value={golaDetails?.yatayCiftliIkiOlcusu || 0}
                        onChange={handleInputChange}
                        valid={golaDetails?.yatayCiftliIkiOlcusu > 0}
                        required
                    />
                </div>
                <div className='mb-3 col-lg-3'>
                    <NumberInput
                        min={0}
                        name='golaDetails.yatayCiftliUcOlcusu'
                        label='Yatay Ciftli Uc Olcusu'
                        value={golaDetails?.yatayCiftliUcOlcusu || 0}
                        onChange={handleInputChange}
                        valid={golaDetails?.yatayCiftliUcOlcusu > 0}
                        required
                    />
                </div>
            </div>
        </CardBody>
    </Card>)
}
export default GolaDetails
