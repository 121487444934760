// DEKORASYON - WARDROBE
import {initLookup} from "../../dataModels/LookupModals";
import {initDekorasyon} from '../../dataModels/DekorasyonModal'
export const addDekorasyonWardrobeOrder = (state, action ) => {
  const dekorasyonlar = state.wardrobeForm.dekorasyonlar ? [...state.wardrobeForm.dekorasyonlar] : [];
  const dekorasyon = initDekorasyon()
  return {...state, wardrobeForm: {...state.wardrobeForm, dekorasyonlar:[...dekorasyonlar,dekorasyon]} }
}
export const removeDekorasyonWardrobeOrder = ( state, action ) => {
  return {...state, wardrobeForm: {...state.wardrobeForm, dekorasyonlar:state.wardrobeForm.dekorasyonlar.filter(d=>d.id !== action.payload.id)} }
}

export const updateDekorasyonWardrobeProperty = ( state, action ) => {
  const result = state.wardrobeForm.dekorasyonlar.filter(x=>x.id === action.payload.id)
  const dekorasyon = {...result[0]}
  dekorasyon[action.payload.name] = action.payload.value;
  const dekorasyonlar = state.wardrobeForm.dekorasyonlar.map(el => el.id === dekorasyon.id ? dekorasyon : el)

  return {
    ...state,
    wardrobeForm: {
      ...state.wardrobeForm,
      dekorasyonlar
    },
    lookup: initLookup()
  }
}
