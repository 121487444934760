import React from 'react'
import { confirmAlert } from 'react-confirm-alert'

import 'react-confirm-alert/src/react-confirm-alert.css'

const DeleteConfirmAlert = (props) => {
  const getId = () => {
    return props.id || new Date().getTime().toString()
  }
  const handleConfirm = (e) => {
    e.preventDefault()
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div>
            <h1>Onaylayın</h1>
            <p>Silmek istediğinizden emin misiniz?</p>
            <button className='btn btn-secondary' onClick={onClose}>
              Hayır, bırak kalsın
            </button>
            <button
              id={'delete-button-' + getId()}
              className='btn btn-danger'
              onClick={() => {
                props.onRemove()
                onClose()
              }}
            >
              Evet, sil gitsin!
            </button>
          </div>
        )
      }
    })
  }
  return (
    <button id={getId()} className={props.deleteButtonStyle || 'btn btn-danger'} onClick={handleConfirm}>
      <i className='far fa-trash-alt' /> Sil
    </button>
  )
}

export default DeleteConfirmAlert
