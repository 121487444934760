import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Alert, Button, Card, CardBody, CardFooter, CardHeader} from "reactstrap";
import MusteriBilgiFormu from "../../../Common/MusteriBilgiFormu";
import * as actions from "../../../../store/actions";
import {ModulAksamForm, ModulAksesuarForm, ModulList, ModulParcaForm} from "../Kitchen";
import MobilyaModulForm from "./MobilyaModulForm";
import UretimSiparisFormFooter from "../../../Common/UretimSiparisFormFooter";

const MobilyaForm = (props) => {
  const dispatch = useDispatch()
  const {
    modulModal,
    parcaModal,
    aksamModal,
    aksesuarModal,
    saving,
    mobilyaForm,
    lookup,
    downloading
  } = useSelector((state) => state.orderForm)
  const { token } = useSelector((state) => state.auth)
  const onSaveOrder = (data) => dispatch(actions.saveMobilyaOrder(token, data))

  const updateSelect = (name, value, action) => dispatch(actions.updateMobilyaOrderSelect(name, value, false))
  const updateSiparisOzellikleri = (name, value, action) => dispatch(actions.updateMobilyaOrderSelect(name, value, true))
  const updateState = (event) => dispatch(actions.updateMobilyaOrderProperty(event))
  const onSearchMusteri = (value) => dispatch(actions.searchMusteri(token, value))
  const onSearchMuteahhit = (value) => dispatch(actions.searchMuteahhit(token, value))
  const onSelectMenuClose = () => dispatch(actions.onSelectMenuClose())
  const onLookupSetting = (name, value) => dispatch(actions.searchSetting(token, name, value))
  const onModulModalClick = (action, index) => dispatch(actions.toggleMobilyaModulModal({ action, index }))
  const parcaModalNewOnChange = (name, value) => dispatch(actions.updateMobilyaParcaModalNew(name, value))
  const parcaModalOnChange = (action) => dispatch(actions.updateMobilyaParcaModal(action))
  const onModulParcaClick = ({ index, action }) => dispatch(actions.toggleMobilyaModulParcaModal({ index, action }))
  const onModulAksamClick = ({ action, index, id }) =>
    dispatch(actions.toggleMobilyaModulAksamModal({ action, index, id }))
  const onSelectSetting = (name, id) => dispatch(actions.mobilyaSelectSetting(token, name, id))
  const onModulRemoveClick = (index) => dispatch(actions.removeMobilyaModul(index))
  const aksamModalOnChange = (action) => dispatch(actions.updateMobilyaAksamModal(action))
  const aksesuarModalOnChange = (action) => dispatch(actions.updateMobilyaAksesuarModal(action))
  const updateModulModalDataChange = (property, value) =>
    dispatch(actions.updateMobilyaModulModalDataProperty(property, value))
  const onModulAksesuarClick = ({ action, index, id }) =>
    dispatch(actions.toggleMobilyaModulAksesuarModal({ action, index, id }))
  const aksamModalNewOnChange = (name, value) => dispatch(actions.updateMobilyaAksamModalNew(name, value))
  const aksesuarModalNewOnChange = (name, value) => dispatch(actions.updateMobilyaAksesuarModalNew(name, value))
  const handleParcaModalOnChange = (index) => (name, value) => {
    parcaModalOnChange({ name, index: Number(index), value })
  }
  const handleParcaAction = (action) => (e) => {
    onModulParcaClick({ action })
  }
  const handleLookupSetting = (e, name) => {
    if (e !== '') {
      onLookupSetting(name, e)
    }
  }
  const handleSearchMusteri = (e) => {
    if (e !== '') {
      onSearchMusteri(e)
    }
  }
  const handleSearchMuteahhit = (e) => {
    if (e !== '') {
      onSearchMuteahhit(e)
    }
  }
  const handleDownload = () => {
    dispatch(actions.onDownloadMobilyaReport(token, `${mobilyaForm.musteri.isimsoyisim}-mutfak-${mobilyaForm.konutno}`, mobilyaForm.id))
  }
  const handleSave = (e) => {
    e.preventDefault()
    onSaveOrder(mobilyaForm)
  }
  const handleAddModulClick = (e) => {
    onModulModalClick('new')
  }
  const handleModulModalAction = (action) => (e) => {
    onModulModalClick(action)
  }
  const handleAksamAction = (action) => (e) => {
    onModulAksamClick({ action })
  }
  const handleAksamModalOnChange = (event) => {
    aksamModalOnChange({ name: event.target.name, value: event.target.value, row: event.target.dataset.row })
  }
  const handleSelectSetting = (e, name) => {
    if (e !== '') {
      onSelectSetting(name, e)
    }
  }
  const handleAksesuarModalOnChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
    aksesuarModalOnChange({ name: event.target.name, value, row: event.target.dataset.row })
  }
  const handleAksesuarAction = (action) => (e) => {
    onModulAksesuarClick({ action })
  }
  return (
    <form onSubmit={handleSave}>
      {props.location.search !== '' && <Alert color='danger'>Ozel notlari konrol etmeyi unutmayiniz!</Alert>}
      <MusteriBilgiFormu
        searchMusteri={handleSearchMusteri}
        searchMuteahhit={handleSearchMuteahhit}
        musteri={mobilyaForm.musteri}
        muteahhit={mobilyaForm.muteahhit}
        teslimtarihi={mobilyaForm.teslimtarihi}
        sicilno={mobilyaForm.sicilno}
        konutno={mobilyaForm.konutno}
        lookup={lookup}
        onSelectMenuClose={onSelectMenuClose}
        onSelectChange={updateSelect}
        onChange={updateState}
      />
      <Card className='shadow mb-2'>
        <CardHeader>
          Moduller
          <Button className='float-end' outline color='primary' onClick={handleAddModulClick}>
            <i className='fas fa-plus' />
            Modul Ekle
          </Button>
        </CardHeader>
        <CardBody>
          <div className='form-group row'>
            <div className='col-12'>
              <MobilyaModulForm
                onChange={updateModulModalDataChange}
                lookupSetting={handleLookupSetting}
                selectSetting={handleSelectSetting}
                onSelectMenuClose={onSelectMenuClose}
                lookup={lookup}
                modulModal={modulModal}
                save={handleModulModalAction('save')}
                cancel={handleModulModalAction('cancel')}
              />
              <ModulParcaForm
                parcaModal={parcaModal}
                onChange={handleParcaModalOnChange}
                onRemove={onModulParcaClick}
                onAdd={handleParcaAction('add')}
                save={handleParcaAction('save')}
                cancel={handleParcaAction('cancel')}
                onToggleBandlar={handleParcaAction('toggleBandlar')}
                lookupSetting={handleLookupSetting}
                onSelectMenuClose={onSelectMenuClose}
                lookup={lookup}
                newOnChange={parcaModalNewOnChange}
                enableEditParcaBandlar={true}
                showRenk={true}
              />
              <ModulAksamForm
                aksamModal={aksamModal}
                onChange={handleAksamModalOnChange}
                onRemove={onModulAksamClick}
                onAdd={handleAksamAction('add')}
                save={handleAksamAction('save')}
                cancel={handleAksamAction('cancel')}
                lookupSetting={handleLookupSetting}
                onSelectMenuClose={onSelectMenuClose}
                lookup={lookup}
                newOnChange={aksamModalNewOnChange}
              />
              <ModulAksesuarForm
                aksesuarModal={aksesuarModal}
                onChange={handleAksesuarModalOnChange}
                onRemove={onModulAksesuarClick}
                onAdd={handleAksesuarAction('add')}
                save={handleAksesuarAction('save')}
                cancel={handleAksesuarAction('cancel')}
                lookupSetting={handleLookupSetting}
                onSelectMenuClose={onSelectMenuClose}
                lookup={lookup}
                showRafCikar={true}
                newOnChange={aksesuarModalNewOnChange}
              />
              <ModulList
                moduller={mobilyaForm.moduller}
                toggle={mobilyaForm.toggle}
                onModulRemoveClick={onModulRemoveClick}
                onModulEditClick={onModulModalClick}
                onModulParcaClick={onModulParcaClick}
                onModulAksamClick={onModulAksamClick}
                onModulAksesuarClick={onModulAksesuarClick}
                type='mobilya'
              />
            </div>
          </div>
        </CardBody>
        <CardFooter>
          <Button className='float-end' outline color='primary' onClick={handleAddModulClick}>
            <i className='fas fa-plus' />
            Modul Ekle
          </Button>
        </CardFooter>
      </Card>
      <UretimSiparisFormFooter
        downloading={downloading}
        saving={saving}
        handleDownload={handleDownload}
        id={mobilyaForm.id}
      />
    </form>
  )
}

export default MobilyaForm
