import React, { useState } from 'react'
import { Col, CardHeader, CardBody, Card, Row, CardText, Badge, CardFooter } from 'reactstrap'
import { Link } from 'react-router-dom'
import * as moment from 'moment'
import 'moment/locale/tr'
import {
  getNextStatus,
  localDateStringOptions,
  PRODUCT_ORDER_STATUS_OLUSTURULAN,
  PRODUCT_ORDER_STATUS_TAMAMLANAN,
  PRODUCT_ORDER_STATUS_TEKLIF_BEKLEYEN,
  PRODUCT_ORDER_STATUS_TESLIM_BEKLEYEN
} from '../../../shared'
import Loading from '../../Common/Loading'
import ProductOrderNotification from './ProductOrderNotification'

const ProductOrderList = (props) => {
  const [items, setItems] = useState([])

  const showNotificationProperties = (id, status) => {
    return items.includes(id) && status !== PRODUCT_ORDER_STATUS_TESLIM_BEKLEYEN
  }
  const isOpen = (id) => {
    return items.includes(id)
  }
  const removeItem = (id) => {
    const arr = items.filter((e) => e !== id)
    setItems(arr)
  }
  const addItem = (id) => {
    setItems([...items, id])
  }
  const getCountDown = (status_log, currentStatus) => {
    if (currentStatus === PRODUCT_ORDER_STATUS_TAMAMLANAN || currentStatus === PRODUCT_ORDER_STATUS_OLUSTURULAN) {
      return <></>
    }
    moment.locale('tr')
    let notify = moment(status_log.date).add(status_log.notification_number, 'hours')
    if (currentStatus.notification_key === 'ay') {
      notify = moment(status_log.date).add(status_log.notification_number, 'months')
    } else if (currentStatus.notification_key === 'gun') {
      notify = moment(status_log.date).add(status_log.notification_number, 'days')
    }
    return (
      <CardFooter>
        <i className='fas fa-bell'></i>{' '}
        <small className={moment.utc().isAfter(notify) ? 'text-danger' : 'text-success'}>{notify.fromNow()}</small>
      </CardFooter>
    )
  }
  const toLocaleDate = (date) => {
    return new Date(date).toLocaleDateString('tr', localDateStringOptions)
  }
  const getOrdersForCompany = (list, orderId, companyId) => {
    return list.find((x) => x.id === orderId).order_fields.filter((x) => x.company.id === companyId)
  }
  const getUniqueCompanies = (list, orderId) => {
    const order = list.find((item) => item.id === orderId)
    if (!order.order_fields) {
      return []
    }
    const companies = order.order_fields.map((x) => x.company.id)
    const companiesSet = new Set(companies)
    return Array.from(companiesSet)
  }
  const getOrderCompanyName = (list, orderId, companyId) => {
    const order = list.find((x) => x.id === orderId).order_fields.find((x) => x.company.id === companyId)
    return order.company.name
  }
  const disableSaveNotification = (item) => {
    const nextStatus = getNextStatus(item.status)
    const status_log = item.status_log[nextStatus]
    return (
      (status_log === undefined ||
        !status_log.notification_number ||
        !status_log.notification_key ||
        status_log.notification_key === '') &&
      item.status !== PRODUCT_ORDER_STATUS_TESLIM_BEKLEYEN
    )
  }
  const getHeader = (status) => {
    let cardHeaderClass = ''
    switch (status) {
      case PRODUCT_ORDER_STATUS_TAMAMLANAN:
        cardHeaderClass = 'text-center text-white bg-success'
        break
      case PRODUCT_ORDER_STATUS_TEKLIF_BEKLEYEN:
        cardHeaderClass = 'text-center text-white bg-primary'
        break
      case PRODUCT_ORDER_STATUS_TESLIM_BEKLEYEN:
        cardHeaderClass = 'text-center text-white bg-info'
        break
      default:
        cardHeaderClass = 'text-center text-white bg-secondary'
        break
    }
    return cardHeaderClass
  }
  const getBadge = (status) => {
    let color = ''
    let description = ''
    switch (status) {
      case PRODUCT_ORDER_STATUS_TAMAMLANAN:
        color = 'success float-end'
        description = 'Tamamlandi'
        break
      case PRODUCT_ORDER_STATUS_TEKLIF_BEKLEYEN:
        color = 'primary float-end'
        description = 'Teklif Beklenen'
        break
      case PRODUCT_ORDER_STATUS_TESLIM_BEKLEYEN:
        color = 'info float-end'
        description = 'Teslim Beklenen'
        break
      default:
        color = 'secondary float-end'
        description = 'Olusturulan'
        break
    }
    return <Badge color={color}>{description}</Badge>
  }
  return (
    <Col sm={12}>
      {props.list.map((order) => (
        <Card key={order.id}>
          <CardHeader>
            <strong>{order.description}</strong>
            <Link className='btn btn-secondary btn-sm float-end' to={'/alim/siparisler/' + order.id}>
              <i className='fas fa-edit' /> Degistir
            </Link>
          </CardHeader>
          <CardBody>
            {getUniqueCompanies(props.list, order.id).map((companyId) => (
              <Card key={companyId + '-' + order.id}>
                <CardHeader>{getOrderCompanyName(props.list, order.id, companyId)}</CardHeader>
                <CardBody>
                  <Row>
                    {getOrdersForCompany(props.list, order.id, companyId).map((item) => {
                      return (
                        <Card key={item.id}>
                          <CardHeader className={getHeader(item.status)}>{item.product.label}</CardHeader>
                          <CardBody>
                            <h6 className='card-subtitle mb-2 text-muted'>
                              {item.paymentDetails.paymentType.aciklama}, {item.paymentDetails.deliveryRate}{' '}
                              {item.paymentDetails.deliveryType.aciklama}{' '}
                            </h6>

                            <CardText>
                              {item.order.quantity} {item.paymentDetails.measure.aciklama} x {item.order.price}
                              {item.order.currency.aciklama} = {item.order.price * item.order.quantity}
                              {item.order.currency.aciklama}
                            </CardText>
                            <p className='card-text'>
                              <small className='text-muted'>{toLocaleDate(item.status_log[item.status].date)}</small>
                            </p>
                            {item.status_log[item.status].loading ? (
                              <Loading />
                            ) : (
                              <ProductOrderNotification
                                showNotificationProperties={showNotificationProperties}
                                handleChangeNotification={props.handleChangeNotification}
                                handleChangeStatus={props.handleChangeStatus}
                                item={item}
                                isOpen={isOpen}
                                disableSaveNotification={disableSaveNotification}
                                addItem={addItem}
                                orderId={order.id}
                                removeItem={removeItem}
                              />
                            )}
                            {getBadge(item.status)}

                            {getCountDown(item.status_log[item.status], item.status)}
                          </CardBody>
                        </Card>
                      )
                    })}
                  </Row>
                </CardBody>
              </Card>
            ))}
          </CardBody>
        </Card>
      ))}
    </Col>
  )
}

export default ProductOrderList
