import React from "react";
import {Link} from "react-router-dom";
import { Table} from "reactstrap";

const CustomerList = (props) => {
  return <Table>
    <thead>
    <tr className="d-flex">
      <th scope="col" className="col-4">Isim</th>
      <th scope="col" className="col-3">Telefon Numarasi</th>
      <th scope="col" className="col-3">Adres</th>
      <th scope="col" className="col-2">#</th>
    </tr>
    </thead>
    <tbody>
    {props.list.map(item=> (<tr className="d-flex" key={item.id}>
      <td className={(item.has_projects ? "bg-success": "bg-primary") + " col-4 text-white"}>{item.data.isimSoyisim}</td>
      <td className="col-3">{item.data.telefon}</td>
      <td className="col-3">{item.data.adres}</td>
      <td className="col-2">
        <Link to={item.has_projects ? `/uretim/muteahhitler/${item.id}` : `/uretim/musteriler/${item.id}`}>Degistir</Link>
      </td>
    </tr>))}
    </tbody>
  </Table>

}

export default CustomerList
