import NumberInput from "../../../Core/NumberInput";
import React from "react";

const CamProfiliFields = (props) => {
    const {uzunlukPayi,genislikPayi,handleStateChange} = props
    return (
        <>
            <div className='form-group col-md-5'>
                <NumberInput
                    step='1'
                    label='Uzunluk'
                    name={`data.formData.uzunlukPayi`}
                    value={uzunlukPayi}
                    onChange={handleStateChange}
                />
            </div>
            <div className='form-group col-md-5'>
                <NumberInput
                    step='1'
                    label='Genislik'
                    name={`data.formData.genislikPayi`}
                    value={genislikPayi}
                    onChange={handleStateChange}
                />
            </div>
        </>
    )
}

export default CamProfiliFields
