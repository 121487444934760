import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch, withRouter, Redirect } from 'react-router-dom'
import { datadogLogs } from '@datadog/browser-logs'
import Login from './components/Auth/Login'
import Layout from './components/Layout/Layout'
import * as actions from './store/actions/index'
import ProtectedRoute from './components/hoc/ProtectedRoute'
import { ToastContainer } from 'react-toastify'
import UserEditContainer from './components/User/UserEditContainer'
import UserListContainer from './components/User/UserListContainer'
import UretimSubMenu from './components/Layout/UretimSubMenu'
import AlimSubMenu from './components/Layout/AlimSubMenu'

const App = (props) => {
  const { token, profile } = useSelector((state) => state.auth)
  const isAuthenticated = token !== null
  const dispatch = useDispatch()

  useEffect(() => {
    const path = props.location.pathname

    dispatch(actions.authCheckState(path))
    if (process.env.NODE_ENV === 'production') {
      datadogLogs.init({
        clientToken: 'pubd8b07ccef02e6bbed56b89ed888fdc19',
        site: 'datadoghq.eu',
        forwardErrorsToLogs: true,
        sampleRate: 100,
        service: 'ciddidestek-ui'
      })
    }
  }, [])

  return (
    <div className='container-fluid'>
      <ToastContainer
        position='bottom-right'
        theme='colored'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
      <Layout isAuthenticated={isAuthenticated} profile={profile}>
        <Switch>
          <ProtectedRoute
            path='/kullanicilar'
            exact={true}
            component={UserListContainer}
            isAuthenticated={isAuthenticated}
          />
          <ProtectedRoute
            path='/kullanicilar/yeni'
            exact={true}
            component={UserEditContainer}
            isAuthenticated={isAuthenticated}
          />
          <ProtectedRoute
            path='/kullanicilar/:id'
            exact={true}
            component={UserEditContainer}
            isAuthenticated={isAuthenticated}
          />
          <Route exact path='/alim' render={() => <Redirect to='/alim/siparisler' />} />
          <Route exact path='/uretim' render={() => <Redirect to='/uretim/siparisler' />} />
          <Route path='/alim/*' exact>
            <AlimSubMenu isAuthenticated={isAuthenticated} />
          </Route>
          <Route path='/uretim/*' exact>
            <UretimSubMenu isAuthenticated={isAuthenticated} profile={profile} />
          </Route>
          <Route exact path='/auth/login' component={Login} />
          <Route exact path='/auth/logout' component={Login} />
          {!isAuthenticated && <Redirect to='/auth/login' />}
        </Switch>
      </Layout>
    </div>
  )
}

export default withRouter(App)
