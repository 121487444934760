import { initParcaModal, initParcaModalNew } from '../../dataModels/ParcaModals'
import { initLookup } from '../../dataModels/LookupModals'
import cloneDeep from 'lodash/cloneDeep'

export const updateWardrobeModulParcaModalNew = (state, action) => {
  const { name, value } = action.payload
  const newData = { ...state.parcaModal.new, [name]: value }
  if (name === 'parca') {
    newData.genislik = value.genislik
    newData.uzunluk = value.uzunluk
  }
  return { ...state, ...{ parcaModal: { ...state.parcaModal, new: newData } } }
}
export const toggleWardrobeModulParcaModal = (state, action) => {
  switch (action.payload.action) {
    case 'toggleBandlar':
      return { ...state, parcaModal: { ...state.parcaModal, showBand: !state.parcaModal.showBand } }
    case 'cancel':
      return { ...state, ...{ parcaModal: initParcaModal(), lookup: initLookup() } }
    case 'add':
      const { genislik, uzunluk, miktar, boyut, isactive, parca } = state.parcaModal.new
      const newParca = { ...parca, genislik, uzunluk, miktar, boyut, isactive }
      const cloneData = cloneDeep(state.parcaModal.data)
      cloneData.push(newParca)
      return { ...state, ...{ parcaModal: { ...state.parcaModal, data: cloneData, new: initParcaModalNew() } } }
    case 'delete':
      const newData = cloneDeep(state.parcaModal.data)
      newData.splice(action.payload.index, 1)
      return { ...state, ...{ parcaModal: { ...state.parcaModal, data: newData } } }
    case 'save':
      const newModuller = cloneDeep(state.wardrobeForm.moduller)
      newModuller[state.parcaModal.index].modul.parcalar = cloneDeep(state.parcaModal.data)
      return {
        ...state,
        ...{
          lookup: initLookup(),
          parcaModal: initParcaModal(),
          wardrobeForm: { ...state.wardrobeForm, moduller: newModuller }
        }
      }
    default:
      const parcalar = cloneDeep(state.wardrobeForm.moduller[action.payload.index].modul.parcalar)
      return {
        ...state,
        ...{
          parcaModal: {
            ...state.parcaModal,
            show: !state.parcaModal.show,
            index: action.payload.index,
            data: parcalar
          }
        }
      }
  }
}
