import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardTitle, Row, Col, Button } from 'reactstrap'
import * as actions from '../../../store/actions'
import Loading from '../../Common/Loading'

function UretimSettingListContainer() {
  const dispatch = useDispatch()
  const { loading, list } = useSelector((state) => state.uretimSettings)
  const { token } = useSelector((state) => state.auth)

  useEffect(() => {
    dispatch(actions.fetchUretimSettings(token))
  }, [token, dispatch])
  const handleSettingSelectClick = (name, description) => {
    dispatch(actions.onSettingSelected(name, description))
  }
  return (
    <Row>
      <Col sm='12'>
        <h3>Uretim Ayarlar</h3>
      </Col>
      {loading ? (
        <Loading />
      ) : (
        list.map((s) => {
          return (
            <Col sm='4' key={s.id}>
              <Card body>
                <CardTitle>{s.description}</CardTitle>
                <Button
                  color='secondary'
                  className='float-end'
                  onClick={(e) => handleSettingSelectClick(s.name, s.description)}
                >
                  Degistir
                </Button>
              </Card>
            </Col>
          )
        })
      )}
    </Row>
  )
}
export default UretimSettingListContainer
