import React from 'react'
import { Input, Label } from 'reactstrap'
import Select from '../../Core/Select'
import NumberInput from '../../Core/NumberInput'
import {
  AKSAM_AKSESUAR_GRUP_DOLAP_ASKI_BORUSU,
  AKSAM_AKSESUAR_GRUP_METAL_BAR,
  AKSAM_AKSESUAR_GRUP_VIDA_GURUBU
} from '../../../shared'

const AksamSettings = (props) => {
  const { data, index, onChange, grup, handleSelectValueChange, aksamgrup } = props
  const aksam = data[index]
  const isImalatAksam = (aksam) => aksam.tip === 'Imalat'
  const isMontajAksam = (aksam) => aksam.tip === 'Montaj'
  return (
    <div className='row'>
      <div className='col-md-3 col-sm-12'>
        <Label>Tip</Label>

        <div className='btn-group btn-group-toggle' data-toggle='buttons'>
          <label className={isImalatAksam(aksam) ? 'btn btn-success' : 'btn btn-secondary'}>
            <Input
              className='form-check-input'
              type='radio'
              name='tip'
              id='imalat'
              value='Imalat'
              checked={isImalatAksam(aksam)}
              onChange={onChange}
              data-row={index}
            />
            imalat aksesuar
          </label>
          <label className={isMontajAksam(aksam) ? 'btn btn-success' : 'btn btn-secondary'}>
            <Input
              className='form-check-input'
              type='radio'
              name='tip'
              id='montaj'
              value='Montaj'
              checked={isMontajAksam(aksam)}
              onChange={onChange}
              data-row={index}
            />
            montaj aksesuar
          </label>
        </div>
      </div>
      <div className='col-md-4 col-sm-12'>
        <Select
          name={'grup_' + index}
          text='Grup'
          isMulti={false}
          value={grup}
          onChange={handleSelectValueChange}
          options={aksamgrup}
          getOptionValue={(option) => option['id']}
          getOptionLabel={(option) => option['aciklama']}
        />
      </div>
      {grup &&
        (grup.aciklama === AKSAM_AKSESUAR_GRUP_METAL_BAR ||
          grup.aciklama === AKSAM_AKSESUAR_GRUP_DOLAP_ASKI_BORUSU) && (
          <div className='col-md-2 col-sm-12'>
            <NumberInput label='Ölçü' name='olcu' dataRow={index} value={aksam.olcu} onChange={onChange} required />
          </div>
        )}
      {grup && grup.aciklama.trim() === AKSAM_AKSESUAR_GRUP_VIDA_GURUBU && (
        <div className='col-md-2 col-sm-12'>
          <NumberInput
            label='Paketdeki Vida Sayisi'
            name='paketvidasayisi'
            dataRow={index}
            value={aksam.paketvidasayisi}
            onChange={onChange}
            required
          />
        </div>
      )}
      <div className='col-md-2 col-sm-12'>
        <NumberInput label='Fiyat €' step='0.001' name='fiyat' dataRow={index} value={aksam.fiyat} onChange={onChange} required />
      </div>
    </div>
  )
}

export default AksamSettings
