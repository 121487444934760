import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Alert, Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import * as actions from '../../../store/actions'
import CustomerList from './CustomerList'
import Loading from '../../Common/Loading'
import Paging from '../../Common/Paging'
import SearchInput from '../../Common/SearchInput'

const CustomerListContainer = () => {
  const dispatch = useDispatch()

  const { loading, error, list, paging } = useSelector((state) => state.customers)
  const { token } = useSelector((state) => state.auth)
  const onFetch = (page, term) => dispatch(actions.fetchCustomers(token, page, term))

  useEffect(() => {
    onFetch(1)
  }, [token])
  const handleClearSearchClick = () => onFetch(1, '')
  const onSearchClick = (query) => onFetch(1, query)
  const handleIlkClick = () => {
    onFetch(1, paging.q)
  }
  const handleGeriClick = () => {
    onFetch(~~paging.currentPage - 1, paging.q)
  }
  const handleSonClick = () => {
    onFetch(~~paging.totalPageCount, paging.q)
  }
  const handleIleriClick = () => {
    onFetch(~~paging.currentPage + 1, paging.q)
  }
  return (
    <>
      <Row>
        <Col sm='12'>
          <SearchInput title='Musteriler/Mutahitler' onSearchClick={onSearchClick}>
            <Link className='btn btn-primary float-end' activeclassname='active' to='/uretim/musteriler/yeni'>
              <i className='fa fa-plus' /> Yeni Musteri
            </Link>
            <Link className='btn btn-success float-end' activeclassname='active' to='/uretim/muteahhitler/yeni'>
              <i className='fa fa-plus' /> Yeni Muteahhit
            </Link>
          </SearchInput>
        </Col>
        {paging.q && (
          <Col sm='12'>
            <Alert color='info'>
              <Button color='danger' onClick={handleClearSearchClick}>
                <i className='fas fa-times' />
              </Button>
              Arama "<strong>{paging.q}</strong>"
            </Alert>
          </Col>
        )}
        {error && (
          <Col sm={12}>
            <Alert color='danger'>Musterileri yuklerken hata oluştu — {error}</Alert>
          </Col>
        )}
        {loading ? <Loading /> : <CustomerList list={list} />}
      </Row>
      <Paging
        {...paging}
        handleIleriClick={handleIleriClick}
        handleSonClick={handleSonClick}
        handleGeriClick={handleGeriClick}
        handleIlkClick={handleIlkClick}
      />
    </>
  )
}
export default CustomerListContainer
