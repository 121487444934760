import {initAcilanData} from "../../dataModels/AcilanAndSurmeModal";
import {toast} from "react-toastify";
import {toasterMessages, toastErrorOptions} from "../../../shared";
import {initModulModal} from "../../dataModels/ModulModal";
import {initLookup} from "../../dataModels/LookupModals";

export const fetchAcilanWardrobeOrderSuccess = ( state, action ) => {
  let {order } = action.data;
  const wardrobeForm = !order ? initAcilanData() : order.data;
  wardrobeForm.id = !order ? null : order.id
  return {...state, ...{ wardrobeForm, loading: false, error: null } }
}

export const fetchAcilanWardrobeOrderFail = ( state, action ) => {
  toast.error(toasterMessages.errorWithMessage(action.error.message),toastErrorOptions);
  return {...state, ...{ loading: false, error: action.error.message } }
}
export const fetchAcilanWardrobeOrderStart = ( state, action ) => {
  return {...state, ...{ loading: true, error: null, kitchenForm: undefined, wardrobeForm: initAcilanData(), modulModal: initModulModal('wardrobe','acilan'), lookup:initLookup() } }
}
