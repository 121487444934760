import React from 'react'
import { Table } from 'reactstrap'
import { Link } from 'react-router-dom'

const CompanyList = (props) => {
  return (
    <Table>
      <thead>
        <tr className='d-flex'>
          <th scope='col' className='col-1'>
            #
          </th>
          <th scope='col' className='col-3'>
            Isim
          </th>
          <th scope='col' className='col-3'>
            Sehir
          </th>
          <th scope='col' className='col-3'>
            Email
          </th>
          <th scope='col' className='col-3'>
            Ilgili Kisi
          </th>
        </tr>
      </thead>
      <tbody>
        {props.list.map((item) => (
          <tr className='d-flex' key={item.id}>
            <th scope='row' className='col-1'>
              <Link className='btn btn-secondary float-end' to={'/alim/sirketler/' + item.id}>
                Degistir
              </Link>
            </th>
            <td className='col-3'>{item.name}</td>
            <td className='col-3'>{item.fields.city.aciklama}</td>
            <td className='col-3'>{item.fields.email}</td>
            <td className='col-3'>{item.fields.personincharge}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default CompanyList
