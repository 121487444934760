import React, { Component } from 'react';
import { Route, Redirect } from "react-router-dom";

class ProtectedRoute extends Component {
  render() {
    const { component: Component, ...rest } = this.props
    return (
      <Route
        {...rest}
        render={props => (
          rest.isAuthenticated ?
            <Component {...props} {...rest} /> :
            <Redirect to='/auth/login' />
        )}
      />
    )
  }
}

export default ProtectedRoute;
