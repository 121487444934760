import React from 'react'
import { Badge, Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row } from 'reactstrap'
import { Modal } from '../../Uretim/Orders/Kitchen'
import Loading from '../../Common/Loading'
import CompaniesForProduct from './CompaniesForProduct'

const ProductOrderModal = (props) => {
  const handleSelectChangeModalProperty = (name, selected, event) => {
    props.onChangeModalProperty(name, selected)
  }
  const handleChangeModalProperty = (e) => {
    props.onChangeModalProperty(e.target.name, e.target.value !== '' ? Number(e.target.value) : e.target.value)
  }
  const handleSaveRow = (e, companyId) => {
    e.preventDefault()
    e.stopPropagation()
    const selectedCompany = props.companiesForProduct.find((x) => x.company.id === companyId)
    if (selectedCompany.order.price > 0 && selectedCompany.order.quantity > 0) {
      props.addToOrder()
    }
  }
  const updateProduct = (e) => {
    props.onChangeModalProperty('selection.productId', e.target.value)
    props.onChangeModalProperty('selection.companyId', '')
    props.getCompaniesForProduct(e.target.value)
    props.resetOrder()
  }
  const updateProductGroup = (e) => {
    props.onChangeModalProperty('selection.productGroupId', e.target.value)
    props.onChangeModalProperty('selection.productId', '')
    props.onChangeModalProperty('selection.companyId', '')
    props.onChangeModalProperty('companiesForProduct', [])
    props.resetOrder()
  }
  const updateCompany = ({ company }) => {
    props.onChangeModalProperty('selection.companyId', company.id)
    props.resetOrder()
  }

  const { productGroupId, productId } = props.selection
  return (
    <Modal modal={props.show} size='xl' title='Urun Ekle'>
      {!props.isEditing && (
        <>
          <Card>
            <CardHeader>Urun Gruplari</CardHeader>
            <CardBody>
              {props.productsTree.map(({ value, label, children }) => {
                return (
                  <FormGroup key={value} check inline className='p-2 border border-light'>
                    <Label check>
                      <Input
                        type='radio'
                        name='productGroup'
                        value={value}
                        checked={value === productGroupId}
                        onChange={updateProductGroup}
                      />
                      {label} <Badge pill>{children ? children.length : 0}</Badge>
                    </Label>
                  </FormGroup>
                )
              })}
            </CardBody>
          </Card>
          <Card>
            <CardHeader>Urunler</CardHeader>
            <CardBody>
              {productGroupId !== '' &&
                props.productsTree
                  .filter(({ value }) => value === productGroupId)
                  .map(({ children }) => {
                    return (
                      children &&
                      children.map(({ value, label }) => {
                        return (
                          <FormGroup key={value} check inline className='p-2 border border-light'>
                            <Label check>
                              <Input
                                type='radio'
                                name='products'
                                value={value}
                                checked={value.toString() === productId}
                                onChange={updateProduct}
                              />
                              {label}
                            </Label>
                          </FormGroup>
                        )
                      })
                    )
                  })}
            </CardBody>
          </Card>
        </>
      )}
      <Card>
        <CardHeader>Sirketler</CardHeader>
        <CardBody>
          {props.loadingCompanies && <Loading />}
          {!props.loadingCompanies && (
            <CompaniesForProduct
              companiesForProduct={props.companiesForProduct}
              selection={props.selection}
              handleSaveRow={handleSaveRow}
              updateCompany={updateCompany}
              onChange={handleChangeModalProperty}
              onSelectChange={handleSelectChangeModalProperty}
            />
          )}
        </CardBody>
      </Card>
      <Row>
        <Col sm='12'>
          <Button color='secondary' name='cancel-button' onClick={() => props.onChangeModalProperty('show', false)}>
            Kapat
          </Button>
        </Col>
      </Row>
    </Modal>
  )
}

export default ProductOrderModal
