import React, { PureComponent } from 'react'
import Select from '../../../Core/Select'
import { Modal } from '../../../Uretim/Orders/Kitchen'
import NumberInput from '../../../Core/NumberInput'
class MobilyaModulForm extends PureComponent {
  handleSave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.props.save()
  }
  handleInputChange = (event) => {
    let value = event.target.value
    if (event.target.type === 'checkbox') {
      value = event.target.checked
    }
    this.props.onChange(event.target.name, value)
  }


  render() {
    const modul = this.props.modulModal.data
    return (
      <Modal
        modal={this.props.modulModal.show}
        index={this.props.modulModal.index}
        save={this.handleSave}
        cancel={this.props.cancel}
        size='xl'
        title='Modul Ekle/Degistir'
      >
        <form>
          <div className='mb-3 row'>
            <div className='mb-3 col-sm-3 col-lg-2'>
              <NumberInput
                label='Modul Adet'
                name='adet'
                min={1}
                valid={modul.adet > 0}
                value={modul.adet}
                onChange={this.handleInputChange}
                required
              />
            </div>
            <div className='mb-3 col-sm-6 col-lg-8'>
              <Select
                name='modul'
                value={modul ? modul.modul : null}
                onChange={this.props.selectSetting}
                text='Modul'
                inputValue={this.props.lookup.inputValue}
                options={this.props.lookup.result}
                onInputChange={(e) => this.props.lookupSetting(e, 'mobilya')}
                onSelectMenuClose={this.props.onSelectMenuClose}
                isLoading={this.props.lookup.isLoading || this.props.lookup.isSelecting}
                isDisabled={this.props.lookup.isSelecting}
              />
            </div>
            <div className='mb-3 col-sm-3 col-lg-2'>
              <NumberInput
                label='Sira'
                name='siranumarasi'
                min={1}
                valid={modul.siranumarasi > 0}
                value={modul.siranumarasi}
                onChange={this.handleInputChange}
                required
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <label htmlFor='ozelislem'>Ozel istekler</label>
              <textarea
                className='form-control'
                value={modul ? modul.ozelislem : null}
                onChange={this.handleInputChange}
                name='ozelislem'
                id='ozelislem'
                rows='3'
              />
            </div>
          </div>
        </form>
      </Modal>
    )
  }
}

export default MobilyaModulForm
