import { instance as axios } from '../../axios-api'
import { apiPath } from '../../shared'
import * as actionTypes from './actionTypes'
import { errorHandler, errorHandlerWithProps } from './errorHandler'

export const fetchProductOrders = (token, page, status) => {
  return async (dispatch) => {
    try {
      dispatch(fetchProductOrdersStart())
      const response = await axios(token).get(apiPath.productOrdersList(page, status))
      dispatch(fetchProductOrdersSuccess(response.data))
    } catch (error) {
      errorHandler(dispatch, fetchProductOrdersFail, error)
    }
  }
}
export const fetchProductOrdersStart = () => ({
  type: actionTypes.FETCH_PRODUCT_ORDERS_LIST_START
})
export const fetchProductOrdersSuccess = (payload) => ({
  type: actionTypes.FETCH_PRODUCT_ORDERS_LIST_SUCCESS,
  payload
})
export const fetchProductOrdersFail = (error) => ({
  type: actionTypes.FETCH_PRODUCT_ORDERS_LIST_FAIL,
  error: error
})
export const resetProductOrder = () => ({
  type: actionTypes.RESET_PRODUCT_ORDER_MODAL_ORDER
})
export const addToProductOrder = () => ({
  type: actionTypes.ADD_TO_PRODUCT_ORDER_MODAL_ORDER
})
export const removeProductOrderRow = (id) => ({
  type: actionTypes.REMOVE_PRODUCT_ORDER_ROW,
  payload: { id }
})

export const editProductOrderForCompany = (token, order) => {
  return async (dispatch) => {
    try {
      dispatch(getCompaniesForProductStart())
      const result = await axios(token).get(apiPath.getCompaniesProduct(order.product.value))
      dispatch(getCompaniesForProductSuccess(result.data))
      dispatch(updateProductOrderModalProperty('show', true))
      dispatch(updateProductOrderModalProperty('isEditing', true))
      dispatch(updateProductOrderModalProperty('selection.companyId', order.company.id))
      dispatch(updateProductOrderModalProperty('selection.productId', order.product.value))
      dispatch(setEditedOrderPaymentDetails(order.order, order.id))
    } catch (error) {
      errorHandler(dispatch, getCompaniesForProductFail, error)
    }
  }
}

export const setEditedOrderPaymentDetails = (order, id) => ({
  type: actionTypes.SET_EDITED_ORDER_MODAL_PAYMENT_DETAILS,
  payload: { order, id }
})
export const updateProductOrderModalProperty = (name, value) => ({
  type: actionTypes.UPDATE_PRODUCT_ORDER_MODAL_PROPERTY,
  payload: { name, value }
})
export const getCompaniesForProduct = (token, productId) => {
  return async (dispatch) => {
    try {
      dispatch(getCompaniesForProductStart())
      const result = await axios(token).get(apiPath.getCompaniesProduct(productId))
      dispatch(getCompaniesForProductSuccess(result.data))
    } catch (error) {
      errorHandler(dispatch, getCompaniesForProductFail, error)
    }
  }
}
const getCompaniesForProductStart = () => ({
  type: actionTypes.GET_COMPANIES_FOR_PRODUCT_START
})
const getCompaniesForProductSuccess = (companies) => ({
  type: actionTypes.GET_COMPANIES_FOR_PRODUCT_SUCCESS,
  payload: companies
})
const getCompaniesForProductFail = (error) => ({
  type: actionTypes.GET_COMPANIES_FOR_PRODUCT_FAIL,
  error: error
})
export const updateProductOrderProperty = (name, value) => ({
  type: actionTypes.UPDATE_PRODUCT_ORDER_FORM_PROPERTY,
  payload: { name, value }
})
export const saveProductOrder = (token, data) => {
  return async (dispatch) => {
    try {
      dispatch(saveProductOrderStart())
      const { description, orders, note } = data
      const options = {
        method: data.id ? 'PUT' : 'POST',
        url: apiPath.saveProductOrders(data.id),
        data: { description, orders, note }
      }
      const response = await axios(token)(options)
      dispatch(saveProductOrderSuccess(response.data))
    } catch (error) {
      errorHandler(dispatch, saveProductOrderFail, error)
    }
  }
}
const saveProductOrderStart = () => ({
  type: actionTypes.SAVE_PRODUCT_ORDER_START
})
const saveProductOrderSuccess = (payload) => ({
  type: actionTypes.SAVE_PRODUCT_ORDER_SUCCESS,
  payload
})
const saveProductOrderFail = (error) => ({
  type: actionTypes.SAVE_PRODUCT_ORDER_FAIL,
  error: error
})
export const fetchProductOrder = (token, orderId) => {
  return async (dispatch) => {
    try {
      dispatch(fetchProductOrderStart())
      const productsTreeResult = await axios(token).get(apiPath.productTree())
      const payload = { productsTree: productsTreeResult.data }
      if (orderId) {
        const response = await axios(token).get(apiPath.getProductOrder(orderId))
        const {
          id,
          data: { note, description, orders }
        } = response.data
        payload.id = id
        payload.orders = orders
        payload.note = note
        payload.description = description
      }
      dispatch(fetchProductOrderSuccess(payload))
    } catch (error) {
      errorHandler(dispatch, fetchProductOrderFail, error)
    }
  }
}
const fetchProductOrderStart = () => ({
  type: actionTypes.FETCH_PRODUCT_ORDER_START
})
const fetchProductOrderSuccess = (payload) => ({
  type: actionTypes.FETCH_PRODUCT_ORDER_SUCCESS,
  payload
})
const fetchProductOrderFail = (error) => ({
  type: actionTypes.FETCH_PRODUCT_ORDER_FAIL,
  error: error
})
export const changeProductOrderStatus = (token, id, order_id, status) => async (dispatch) => {
  try {
    dispatch(changeProductOrderStatusStart(id, order_id, status))
    await axios(token).put(apiPath.productOrdersChangeStatus(id, order_id), status)
    dispatch(changeProductOrderStatusSuccess(id, order_id, status))
  } catch (error) {
    errorHandlerWithProps(dispatch, changeProductOrderStatusFail, error, { id, order_id, status })
  }
}
const changeProductOrderStatusStart = (id, order_id, status) => ({
  type: actionTypes.PRODUCT_ORDERS_CHANGE_STATUS_START,
  payload: { id, order_id, status }
})
const changeProductOrderStatusSuccess = (id, order_id, status) => ({
  type: actionTypes.PRODUCT_ORDERS_CHANGE_STATUS_SUCCESS,
  payload: { id, order_id, status }
})
const changeProductOrderStatusFail = (id, order_id, status, err) => ({
  type: actionTypes.PRODUCT_ORDERS_CHANGE_STATUS_FAIL,
  payload: { id, order_id, status, err }
})
export const changeProductOrderNotification = (name, value, status, id, orderId) => ({
  type: actionTypes.PRODUCT_ORDERS_CHANGE_NOTIFICATION,
  payload: { name, value, status, id, orderId }
})
export const productOrdersSearchClick = (token, inputValue) => {
  return async (dispatch) => {
    try {
      dispatch(productOrdersSearchClickStart())
      const response = await axios(token).get(apiPath.productOrdersSearch(inputValue))
      dispatch(productOrdersSearchClickSuccess(response.data))
    } catch (error) {
      errorHandler(dispatch, productOrdersSearchClickFail, error)
    }
  }
}
const productOrdersSearchClickStart = () => ({
  type: actionTypes.PRODUCT_ORDERS_SEARCH_CLICK_START
})
const productOrdersSearchClickSuccess = (payload) => ({
  type: actionTypes.PRODUCT_ORDERS_SEARCH_CLICK_SUCCESS,
  payload
})
const productOrdersSearchClickFail = (err) => ({
  type: actionTypes.PRODUCT_ORDERS_SEARCH_CLICK_FAIL,
  payload: err
})
export const changeStatusFilter = (status) => ({
  type: actionTypes.CHANGE_PRODUCT_ORDER_STATUS_FILTER,
  payload: status
})
