import * as actionTypes from './actionTypes'
import { instance as axios } from '../../axios-api'
import { apiPath } from '../../shared'
import { errorHandler } from './errorHandler'

const fetchOrderGroupsSuccess = (payload) => ({
  type: actionTypes.FETCH_ORDER_GROUPS_SUCCESS,
  payload
})

const fetchOrderGroupsFail = (error) => ({
  type: actionTypes.FETCH_ORDER_GROUPS_FAIL,
  error
})

const fetchOrderGroupsStart = () => ({
  type: actionTypes.FETCH_ORDER_GROUPS_START
})

export const fetchOrderGroups = (token, page) => {
  return async (dispatch) => {
    try {
      dispatch(fetchOrderGroupsStart())
      const response = await axios(token).get(apiPath.groupsWithPage(page))
      dispatch(fetchOrderGroupsSuccess(response.data))
    } catch (error) {
      errorHandler(dispatch, fetchOrderGroupsFail, error)
    }
  }
}
const fetchOrderGroupSuccess = (groups,files) => ({
  type: actionTypes.FETCH_ORDER_GROUP_SUCCESS,
  payload: {groups, files}
})

const fetchOrderGroupFail = (error) => ({
  type: actionTypes.FETCH_ORDER_GROUP_FAIL,
  error: error
})

const fetchOrderGroupStart = () => ({
  type: actionTypes.FETCH_ORDER_GROUP_START
})

export const fetchOrderGroup = (token, id) => {
  return async (dispatch) => {
    try {
      dispatch(fetchOrderGroupStart())
      const order = await axios(token).get(apiPath.groupEdit(id))
      const files = await axios(token).get(apiPath.orderGroupKitchenReportFiles(id))
      dispatch(fetchOrderGroupSuccess(order.data,files.data))
    } catch (error) {
      errorHandler(dispatch, fetchOrderGroupFail, error)
    }
  }
}

const fetchOrderGroupFilesStart = () => ({
  type: actionTypes.FETCH_ORDER_GROUP_FILES_START
})
const fetchOrderGroupFilesFail = (error) => ({
  type: actionTypes.FETCH_ORDER_GROUP_FILES_FAIL,
  error: error
})
const fetchOrderGroupFilesSuccess = (files) => ({
  type: actionTypes.FETCH_ORDER_GROUP_FILES_SUCCESS,
  payload: {files}
})
export const fetchOrderGroupFiles = (token, id) => {
  return async (dispatch) => {
    try {
      dispatch(fetchOrderGroupFilesStart())
      const files = await axios(token).get(apiPath.orderGroupKitchenReportFiles(id))
      dispatch(fetchOrderGroupFilesSuccess(files.data))
    } catch (error) {
      errorHandler(dispatch, fetchOrderGroupFilesFail, error)
    }
  }
}


const saveOrderGroupSuccess = (groups) => ({
  type: actionTypes.SAVE_ORDER_GROUP_SUCCESS,
  payload: groups
})

const saveOrderGroupFail = (error) => ({
  type: actionTypes.SAVE_ORDER_GROUP_FAIL,
  error: error
})

const saveOrderGroupStart = () => ({
  type: actionTypes.SAVE_ORDER_GROUP_START
})

export const saveOrderGroup = (token, id, data) => {
  return async (dispatch) => {
    try {
      dispatch(saveOrderGroupStart())
      const response = await axios(token)({
        method: id ? 'put' : 'post',
        url: id ? apiPath.groupUpdate(id) : apiPath.groupCreate(),
        data
      })
      dispatch(saveOrderGroupSuccess(response.data))
    } catch (error) {
      errorHandler(dispatch, saveOrderGroupFail, error)
    }
  }
}
export const updateOrderGroupFormProperty = (name, value) => ({
  type: actionTypes.UPDATE_ORDER_GROUP_FORM_PROPERTY,
  payload: { name, value }
})
export const newOrderGroup = () => ({
  type: actionTypes.NEW_ORDER_GROUP
})
export const orderGroupLookupReset = (type) => ({
  type: actionTypes.ORDER_GROUP_RESET_LOOKUP,
  payload: { type }
})
export const addKitchenOrder = (order) => ({
  type: actionTypes.ORDER_GROUP_ADD_KITCHEN_ORDER,
  payload: { order }
})
export const removeKitchenOrder = (order) => ({
  type: actionTypes.ORDER_GROUP_REMOVE_KITCHEN_ORDER,
  payload: { order }
})
export const addWardrobeOrder = (order) => ({
  type: actionTypes.ORDER_GROUP_ADD_WARDROBE_ORDER,
  payload: { order }
})
export const removeWardrobeOrder = (order) => ({
  type: actionTypes.ORDER_GROUP_REMOVE_WARDROBE_ORDER,
  payload: { order }
})
export const orderGroupSearchOrder = (token, term, type) => {
  return async (dispatch) => {
    try {
      dispatch(searchOrderStart(type))
      const path = apiPath.ordersSearchByType(type, term)
      const response = await axios(token).get(path)
      dispatch(searchOrderSuccess(type, response.data))
    } catch (error) {
      errorHandler(dispatch, searchOrderFail, error)
    }
  }
}
export const searchOrderStart = (type) => ({
  type: actionTypes.ORDER_GROUP_ORDER_SEARCH_START,
  payload: { type }
})
export const searchOrderFail = (error) => ({
  type: actionTypes.ORDER_GROUP_ORDER_SEARCH_FAIL,
  payload: error
})
export const searchOrderSuccess = (type, data) => ({
  type: actionTypes.ORDER_GROUP_ORDER_SEARCH_SUCCESS,
  payload: { type, data }
})

// REPORT DOWNLOAD
export const onDownloadOrderGroupKitchenMakinaReport = (token, reportName, id) => {
  return async (dispatch) => {
    try {
      dispatch(onDownloadReportStart())
      const options = {
        url: apiPath.orderGroupKitchenMakinaReport(id),
        method: 'GET',
        responseType: 'blob', // important
        timeout: 30*60*1000
      }
      const response = await axios(token)(options)
      dispatch(onDownloadReportSuccess(response.data, `${reportName}-mutfak-makina`))
    } catch (error) {
      errorHandler(dispatch, onDownloadReportFail, error)
    }
  }
}
export const onDownloadOrderGroupWadrobeMakinaReport = (token, reportName, id) => {
  return async (dispatch) => {
    try {
      dispatch(onDownloadReportStart())
      const options = {
        url: apiPath.orderGroupWardrobeMakinaReport(id),
        method: 'GET',
        responseType: 'blob', // important
        timeout: 30*60*1000
      }
      const response = await axios(token)(options)
      dispatch(onDownloadReportSuccess(response.data, `${reportName}-duvar-dolabi-makina`))
    } catch (error) {
      errorHandler(dispatch, onDownloadReportFail, error)
    }
  }
}
export const onDownloadKitchenOrderGroupReport = (token, reportName, id) => {
  return async (dispatch) => {
    try {
      dispatch(onDownloadReportStart())
      const options = {
        url: apiPath.orderGroupKitchenReport(id),
        method: 'GET',
        timeout: 30*60*1000
      }
      const response = await axios(token)(options)
      dispatch(onReportS3UploadSuccess(response.data))
    } catch (error) {
      errorHandler(dispatch, onDownloadReportFail, error)
    }
  }
}
export const onDownloadWardrobeOrderGroupReport = (token, reportName, id) => {
  return async (dispatch) => {
    try {
      dispatch(onDownloadReportStart())
      const options = {
        url: apiPath.orderGroupWardrobeReport(id),
        method: 'GET',
        responseType: 'blob', // important
        timeout: 30*60*1000
      }
      const response = await axios(token)(options)
      dispatch(onDownloadReportSuccess(response.data, reportName))
    } catch (error) {
      errorHandler(dispatch, onDownloadReportFail, error)
    }
  }
}
export const onDownloadTopluAcilanReport = (token, reportName, id) => {
  return async (dispatch) => {
    try {
      dispatch(onDownloadReportStart())
      const options = {
        url: apiPath.topluWardrobeAcilanReport(id),
        method: 'GET',
        responseType: 'blob', // important
        timeout: 30*60*1000
      }
      const response = await axios(token)(options)
      dispatch(onDownloadReportSuccess(response.data, reportName))
    } catch (error) {
      errorHandler(dispatch, onDownloadReportFail, error)
    }
  }
}
const onDownloadReportStart = () => ({
  type: actionTypes.ORDER_GROUP_DOWNLOAD_REPORT_START
})
const onDownloadReportSuccess = (data, reportName) => ({
  type: actionTypes.ORDER_GROUP_DOWNLOAD_REPORT_SUCCESS,
  payload: { data, reportName }
})
const onReportS3UploadSuccess = ({fileName}) => ({
  type: actionTypes.ORDER_GROUP_UPLOAD_S3_REPORT_SUCCESS,
  payload: { fileName }
})
const onDownloadReportFail = (error) => ({
  type: actionTypes.ORDER_GROUP_DOWNLOAD_REPORT_FAIL,
  payload: error
})
