import React, { useEffect } from 'react'
import * as actions from '../../../store/actions'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Button, Col, Row } from 'reactstrap'
import Loading from '../../Common/Loading'
import ProductList from './ProductList'
import Paging from '../../Common/Paging'
import SearchInput from '../../Common/SearchInput'
import { Link } from 'react-router-dom'

const ProductListContainer = () => {
  const dispatch = useDispatch()
  const { loading, list, paging } = useSelector((state) => state.products)
  const { token } = useSelector((state) => state.auth)
  const onFetch = (token, page) => dispatch(actions.fetchProducts(token, page))

  useEffect(() => {
    onFetch(token, 1)
  }, [token])
  const handleClearSearchClick = () => {
    dispatch(actions.productSearchClick(token, ''))
  }
  const handleSearchClick = (term) => dispatch(actions.productSearchClick(token, term))

  const handleIlkClick = () => onFetch(token, 1)

  const handleGeriClick = () => onFetch(token, ~~paging.currentPage - 1)

  const handleSonClick = () => onFetch(token, ~~paging.totalPageCount)

  const handleIleriClick = () => onFetch(token, ~~paging.currentPage + 1)

  return (
    <>
      <Row>
        <Col sm='4'>
          <h3>Urun Gruplari</h3>
        </Col>
        <Col sm='8'>
          <SearchInput title='Urun ismi' onSearchClick={handleSearchClick}>
            <Link disabled className='btn btn-primary float-end' activeclassname='active' to='/alim/urunler/yeni'>
              <i className='fa fa-plus' /> Yeni
            </Link>
          </SearchInput>
        </Col>
        {paging.q && (
          <Col sm='12'>
            <Alert color='info'>
              <Button color='danger' onClick={handleClearSearchClick}>
                <i className='fas fa-times' />
              </Button>
              Arama "<strong>{paging.q}</strong>"
            </Alert>
          </Col>
        )}
        {loading ? <Loading /> : <ProductList list={list} />}
      </Row>
      <Paging
        {...paging}
        handleIleriClick={handleIleriClick}
        handleSonClick={handleSonClick}
        handleGeriClick={handleGeriClick}
        handleIlkClick={handleIlkClick}
      />
    </>
  )
}

export default ProductListContainer
