import * as actionTypes from './actionTypes'
import { instance as axios } from '../../axios-api'
import { apiPath } from '../../shared'
import { errorHandler } from './errorHandler'

export const fetchOrdersSuccess = (orders) => ({
  type: actionTypes.FETCH_ORDERS_SUCCESS,
  payload: orders
})

export const fetchOrdersFail = (error) => ({
  type: actionTypes.FETCH_ORDERS_FAIL,
  error: error
})

export const fetchOrdersStart = () => ({
  type: actionTypes.FETCH_ORDERS_START
})

export const fetchOrders = (token, page, term) => {
  return async (dispatch) => {
    try {
      dispatch(fetchOrdersStart())
      const response = await axios(token).get(apiPath.ordersWithPage(page, term))
      dispatch(fetchOrdersSuccess(response.data))
    } catch (error) {
      errorHandler(dispatch, fetchOrdersFail, error)
    }
  }
}
export const lookupMusteri = (token, inputValue) => {
  return async (dispatch) => {
    try {
      dispatch(lookupStart())
      const response = await axios(token).get(apiPath.customerSearch(inputValue))
      dispatch(lookupSuccess(response.data))
    } catch (error) {
      errorHandler(dispatch, lookupFail, error)
    }
  }
}
export const lookupSuccess = (payload) => ({
  type: actionTypes.ORDERS_LOOKUP_SUCCESS,
  payload
})
export const lookupFail = (err) => ({
  type: actionTypes.ORDERS_LOOKUP_FAIL,
  payload: err
})
export const lookupStart = () => ({
  type: actionTypes.ORDERS_LOOKUP_START
})

export const resetOrdersRedirect = () => ({
  type: actionTypes.ORDERS_RESET_REDIRECT
})
export const updateCloneOrderModalProperty = (payload) => ({
  type: actionTypes.CHANGE_CLONE_ORDER_MODAL_PROPERTY,
  payload
})

export const ordersSearchClickStart = () => ({
  type: actionTypes.ORDERS_SEARCH_CLICK_START
})
export const ordersSearchClickSuccess = (payload) => ({
  type: actionTypes.ORDERS_SEARCH_CLICK_SUCCESS,
  payload
})
export const ordersSearchClickFail = (err) => ({
  type: actionTypes.ORDERS_SEARCH_CLICK_FAIL,
  payload: err
})
export const ordersSearchClick = (token, inputValue) => {
  return async (dispatch) => {
    try {
      dispatch(ordersSearchClickStart())
      const response = await axios(token).get(apiPath.ordersSearch(inputValue))
      dispatch(ordersSearchClickSuccess(response.data))
    } catch (error) {
      errorHandler(dispatch, ordersSearchClickFail, error)
    }
  }
}

export const dublicateOrder = (token, payload) => {
  return async (dispatch) => {
    try{
      const options = {
        method: 'POST',
        url: apiPath.orderDuplicate(payload.id),
      }
      const response = await axios(token)(options)
      let redirect = `/uretim/siparisler/mutfak/${response.data.id}`
      if (payload.type === 'mobilya') {
        redirect = `/uretim/siparisler/mobilya/${response.data.id}`
      }
      if (payload.type === 'wardrobe') {
        redirect = `/uretim/siparisler/duvarDolabi/surme/${response.data.id}`

        if (payload.data_type === 'acilan') {
          redirect = `/uretim/siparisler/duvarDolabi/acilan/${response.data.id}`
        }
      }
      dispatch(actionDuplicateOrderSuccess(redirect))
    } catch (error) {
      errorHandler(dispatch, saveCloneOrderModalFail, error)
    }
  }
}
const actionDuplicateOrderSuccess = (payload) => ({
  type: actionTypes.ORDERS_DUPLICATE_ORDER_SUCCESS,
  payload
})
export const toggleCloneOrderModal = (token, payload) => {
  return async (dispatch) => {
    try {
      if (payload.action !== 'save') {
        dispatch(actionCloneOrderModal(payload))
        return
      }
      dispatch(saveCloneOrderModalStart(payload))
      const options = {
        method: 'POST',
        url: apiPath.orderClone(),
        data: payload.data
      }
      const response = await axios(token)(options)
      let redirect = `/uretim/siparisler/mutfak/${response.data.id}`
      if (payload.type === 'mobilya') {
        redirect = `/uretim/siparisler/mobilya/${response.data.id}`
      }
      if (response.data.type === 'wardrobe') {
        redirect = `/uretim/siparisler/duvarDolabi/surme/${response.data.id}`

        if (response.data.data_type === 'acilan') {
          redirect = `/uretim/siparisler/duvarDolabi/acilan/${response.data.id}`
        }
      }
      payload.redirect = redirect
      dispatch(actionCloneOrderModal(payload))
    } catch (error) {
      errorHandler(dispatch, saveCloneOrderModalFail, error)
    }
  }
}
const actionCloneOrderModal = (payload) => ({
  type: actionTypes.ORDERS_CLONE_ORDER_MODAL_TOGGLE,
  payload
})
const saveCloneOrderModalStart = (payload) => ({
  type: actionTypes.ORDERS_CLONE_ORDER_MODAL_SAVE_START,
  payload
})

const saveCloneOrderModalFail = (payload) => ({
  type: actionTypes.ORDERS_CLONE_ORDER_MODAL_SAVE_FAIL,
  payload
})
