import React from 'react';
import {Button, Col,UncontrolledCollapse,Card,CardBody} from "reactstrap";
const Error = (props) => {
  return (<Col sm="12"><div className="alert alert-danger alert-dismissible fade show" role="alert">
        <p>
          <strong>Hata!</strong> Sitede hata oluştu,lütfen daha sonra tekrar deneyin.
          <Button color="secondary" size="sm" id="toggler">
            <i className="fas fa-caret-down"/>
          </Button>
        </p>
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>

    <UncontrolledCollapse toggler="#toggler">
      <Card>
        <CardBody>
          {props.error.message}: {props.error.details}
        </CardBody>
      </Card>
    </UncontrolledCollapse>
      </div>
  </Col>)
}

export default Error
