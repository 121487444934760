import React from 'react'
import { Button, Card, CardBody, CardFooter, CardHeader, Input, Row, Table, Badge, ButtonGroup } from 'reactstrap'
import { NavLink } from 'react-router-dom'
import groupBy from 'lodash/groupBy'
import sumBy from 'lodash/sumBy'
import ProductOrderModal from './ProductOrderModal'
import DeleteConfirmAlert from '../../Common/DeleteConfirmAlert'
const ProductOrderForm = (props) => {
  const {
    description,
    note,
    orders,
    productOrderModal: { productsTree, selection, show, isEditing, loadingCompanies, companiesForProduct }
  } = props.productOrderForm
  const getCompanyName = (orders, companyId) => {
    const order = orders.find((x) => x.company.id === companyId)
    return order.company.name
  }
  const getUniqueCompanies = (orders) => {
    const ordersByCompany = orders.map((item) => item.company.id)
    const ordersByCompanySet = new Set(ordersByCompany)
    return Array.from(ordersByCompanySet)
  }
  const getTotals = (companyOrders) => {
    const groupByCurrency = groupBy(
      companyOrders.flatMap((x) => x.order),
      'currency.aciklama'
    )
    return (
      <Row className='float-end'>
        {Object.keys(groupByCurrency).map((currency) => {
          return (
            <h5 key={currency}>
              <Badge color='warning' pill key={currency}>
                {sumBy(groupByCurrency[currency], function (o) {
                  return o.quantity * o.price
                })}{' '}
                {currency}
              </Badge>
            </h5>
          )
        })}
      </Row>
    )
  }
  return (
    <>
      <ProductOrderModal
        getCompaniesForProduct={props.getCompaniesForProduct}
        onChangeModalProperty={props.onChangeModalProperty}
        resetOrder={props.resetOrder}
        addToOrder={props.addToOrder}
        removeOrderRow={props.removeOrderRow}
        productsTree={productsTree}
        companiesForProduct={companiesForProduct}
        selection={selection}
        loadingCompanies={loadingCompanies}
        show={show}
        isEditing={isEditing}
      />
      <form onSubmit={props.onSave}>
        <Card>
          <CardHeader>Isim</CardHeader>
          <CardBody>
            <div className='form-group row'>
              <div className='form-group col-md-12'>
                <Input
                  type='text'
                  className='form-control'
                  onChange={props.onChange}
                  valid={!!description}
                  value={description}
                  invalid={!description}
                  name='description'
                  placeholder='Isim'
                  required
                />
              </div>
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            Secilen Urunler
            <Button
              className='float-end'
              outline
              color='primary'
              onClick={() => props.onChangeModalProperty('show', true)}
            >
              <i className='fas fa-plus' />
              Urun Ekle
            </Button>
          </CardHeader>
          <CardBody>
            {getUniqueCompanies(orders).map((companyId) => {
              return (
                <Card key={companyId}>
                  <CardHeader>
                    <strong>{getCompanyName(orders, companyId)}</strong>
                  </CardHeader>
                  <CardBody>
                    <Table hover>
                      <thead>
                        <tr>
                          <th>Urun</th>
                          <th>Min Siparis Adeti</th>
                          <th>Odeme Sekli</th>
                          <th>Termin Tarihi</th>
                          <th>Miktar</th>
                          <th>Fiyat</th>
                          <th>Toplam</th>
                          <th>#</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orders
                          .filter((x) => x.company.id === companyId)
                          .map((item) => {
                            return (
                              <tr key={item.id}>
                                <td>{item.product.label}</td>
                                <td>
                                  {item.paymentDetails.minOrderAmount} {item.paymentDetails.measure.aciklama}
                                </td>
                                <td>{item.paymentDetails.paymentType.aciklama}</td>
                                <td>
                                  {item.paymentDetails.deliveryRate} {item.paymentDetails.deliveryType.aciklama}
                                </td>
                                <td>
                                  {item.order.quantity} {item.paymentDetails.measure.aciklama}
                                </td>
                                <td>
                                  {item.order.price}
                                  {item.order.currency.aciklama}
                                </td>
                                <td>
                                  {item.order.price * item.order.quantity}
                                  {item.order.currency.aciklama}
                                </td>
                                <th scope='row'>
                                  <ButtonGroup size='sm'>
                                    <Button
                                      outline
                                      color='primary'
                                      onClick={() => props.editProductOrderForCompany(item)}
                                    >
                                      <i className='fas fa-edit' />
                                      Degistir
                                    </Button>
                                    <DeleteConfirmAlert
                                      deleteButtonStyle='btn btn-danger'
                                      onRemove={() => props.removeProductOrderRow(item.id)}
                                    />
                                  </ButtonGroup>
                                </th>
                              </tr>
                            )
                          })}
                      </tbody>
                    </Table>
                  </CardBody>
                  <CardFooter>{getTotals(orders.filter((x) => x.company.id === companyId))}</CardFooter>
                </Card>
              )
            })}
          </CardBody>
        </Card>
        <Card>
          <CardHeader>Not</CardHeader>
          <CardBody>
            <div className='form-group row'>
              <div className='form-group col-md-12'>
                <Input
                  type='textarea'
                  className='form-control'
                  onChange={props.onChange}
                  value={note}
                  name='note'
                  placeholder='Not'
                />
              </div>
            </div>
          </CardBody>
        </Card>
        <NavLink className='btn btn-secondary  m-1' to='/alim/siparisler'>
          Vazgec
        </NavLink>
        <button type='submit' className='btn btn-primary'>
          <i className='fas fa-save' />
          Kaydet
        </button>
      </form>
    </>
  )
}

export default ProductOrderForm
