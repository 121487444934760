import {initDekorasyon} from "../../dataModels/DekorasyonModal";
import {toast} from "react-toastify";
import {toastOptions} from "../../../shared";
import {initLookup} from "../../dataModels/LookupModals";

export const removeDekorasyonKitchenOrder = (state, action ) => {
  return {...state, kitchenForm: {...state.kitchenForm, dekorasyonlar:state.kitchenForm.dekorasyonlar.filter(d=>d.id !== action.payload.id)} }
}
export const addDekorasyonKitchenOrder = ( state, action ) => {
  const dekorasyonlar = state.kitchenForm.dekorasyonlar ? [...state.kitchenForm.dekorasyonlar] : [];
  const dekorasyon = initDekorasyon()
  toast.success(`Dekorasyon eklendi!`, toastOptions);
  return {...state, kitchenForm: {...state.kitchenForm, dekorasyonlar:[...dekorasyonlar,dekorasyon]} }
}

export const updateDekorasyonKitchenProperty = ( state, action ) => {
  const result = state.kitchenForm.dekorasyonlar.filter(x=>x.id === action.payload.id)
  const dekorasyon = {...result[0]}
  dekorasyon[action.payload.name] = action.payload.value;
  const dekorasyonlar = state.kitchenForm.dekorasyonlar.map(el => el.id === dekorasyon.id ? dekorasyon : el)

  return {
    ...state,
    kitchenForm: {
      ...state.kitchenForm,
      dekorasyonlar
    },
    lookup: initLookup()
  }
}
