import { initDekorasyon } from './DekorasyonModal'

export const initAcilanData = () => ({
  type: 'acilan',
  id: null,
  muteahhit: null,
  musteri: null,
  teslimtarihi: '',
  konutno: '',
  sicilno: '',
  dolapAdet: 0,
  dolapTakilacakOda: '',
  tipi: null,
  dekorasyonlar: [
    {
      ...initDekorasyon()
    }
  ],
  boslukOlcusu: {
    en: 0,
    boy: 0,
    derinlik: 0
  },
  yapilacakOlan: {
    en: 0,
    boy: 0,
    derinlik: 0
  },
  kulpmodeli: null,
  kapakmalzemesi: null,
  kapakmodeli: null,
  kapakrenk: null,
  cekmecesistemi: null,
  govdemalzemesi: null,
  pervazrenk: null,
  govderengi: null,
  ozelistekler: '',
  kapakdesenyonu: null,
  moduller: []
})

export const initSurmeData = () => ({
  ...initAcilanData(),
  type: 'surme',
  camKapakAdet: 0,
  ahsapKapakAdet: 0,
  surmeKulpVeMekanizma: null,
  dolapAynaModeli: null
})
