import React from 'react'
import { Modal } from '../../Uretim/Orders/Kitchen'
import { Button, Input, Label, Alert } from 'reactstrap'
import Select from '../../Core/Select'
import NumberInput from '../../Core/NumberInput'

const ModulAksesuarForm = (props) => {
  const handleSave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    props.save()
  }
  const handleChange = (action) => {
    action.preventDefault()
    action.stopPropagation()
    props.newOnChange(action.target.name, action.target.value)
  }
  const {
    new: { miktar, aksesuar, not },
    show,
    index,
    data,
    rafcikar,
    mentesecikar
  } = props.aksesuarModal
  return (
    <Modal modal={show} index={index} size='xl' title='Aksesuar Ekle/Degistir'>
      <div className='row'>
        <div className='form-group col-sm-6 col-md-2'>
          <NumberInput
            label='Miktar'
            placeholder='Miktar'
            name='miktar'
            min={0.1}
            value={miktar}
            onChange={handleChange}
          />
        </div>

        <div className='form-group col-sm-6 col-md-5'>
          <Select
            name='aksesuar'
            value={aksesuar}
            onSelectMenuClose={props.onSelectMenuClose}
            onChange={props.newOnChange}
            text='Aksesuar'
            options={props.lookup.result}
            onInputChange={(e) => props.lookupSetting(e, 'aksesuar')}
            isLoading={props.lookup.isLoading}
          />
        </div>
        <div className='form-group col-sm-6 col-md-4'>
          <Label htmlFor='not'>Not</Label>
          <Input
            type='text'
            name='not'
            value={not}
            className='form-control'
            onChange={handleChange}
            placeholder='Not'
          />
        </div>
        <div className='form-group col-sm-6 col-md-1'>
          {aksesuar && miktar > 0 && (
            <Button color='success' name='ekle' className='mt-4' onClick={props.onAdd}>
              Ekle
            </Button>
          )}
        </div>
      </div>
      <form onSubmit={handleSave}>
        <h5>Aksesuarlar:</h5>
        <div className='container'>
          {props.showRafCikar && data.length > 0 && (
            <div className='row'>
              <div className='col-12'>
                <Alert color='secondary'>
                  <div className='form-check form-switch'>
                    <Input
                      className='form-check-input'
                      type='checkbox'
                      role='switch'
                      id='rafcikar'
                      name='rafcikar'
                      value='true'
                      onChange={props.onChange}
                      checked={rafcikar ? 'checked' : ''}
                    />
                    <Label className='form-check-label' htmlFor='rafcikar'>
                      Raf cikarilsin.
                    </Label>
                  </div>
                  <div className='form-check form-switch'>
                    <Input
                      className='form-check-input'
                      type='checkbox'
                      role='switch'
                      id='mentesecikar'
                      name='mentesecikar'
                      value='true'
                      onChange={props.onChange}
                      checked={mentesecikar ? 'checked' : ''}
                    />
                    <Label className='form-check-label' htmlFor='mentesecikar'>
                      Mentese cikarilsin.
                    </Label>
                  </div>
                </Alert>
              </div>
            </div>
          )}
          <div className='row'>
            <div className='col-3'>
              <strong>Miktar</strong>
            </div>
            <div className='col-5'>
              <strong>Aksesuar</strong>
            </div>
            <div className='col-3'>
              <strong>Not</strong>
            </div>
            <div className='col-1'>
              <strong>#</strong>
            </div>
          </div>

          {data.map((item, index) => {
            return (
              <div className='row' key={item.id}>
                <div className='col-3'>
                  <NumberInput
                    hideLabel={true}
                    placeholder='Miktar'
                    name={`data[${index}].miktar`}
                    value={item.miktar}
                    valid={item.miktar > 0}
                    invalid={item.miktar <= 0}
                    dataRow={index}
                    min={0.1}
                    required
                    onChange={props.onChange}
                  />
                </div>
                <div className='col-5'>
                  <div name='aksesuar'>{item.aciklama}</div>
                  <small className='text-muted'>{`${item.grup.aciklama} - ${item.tip}`}</small>
                </div>
                <div className='col-3'>
                  <Input
                    type='text'
                    placeholder='Not'
                    name={`data[${index}].not`}
                    value={item.not || ''}
                    datarow={index}
                    onChange={props.onChange}
                  />
                </div>
                <div className='col-1'>
                  <Button color='danger' onClick={() => props.onRemove({ action: 'delete', id: item.id })}>
                    Sil
                  </Button>
                </div>
              </div>
            )
          })}
        </div>
        <Button color='secondary' className='float-start' onClick={props.cancel}>
          Vazgec
        </Button>
        <button type='submit' className='btn btn-primary'>
          <i className='fas fa-save' />
          Kaydet
        </button>
      </form>
    </Modal>
  )
}

export default ModulAksesuarForm
