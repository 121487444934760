import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../../store/actions'
import { Alert, Button, Col, Row, Table } from 'reactstrap'
import Loading from '../../Common/Loading'
import { Link } from 'react-router-dom'
import SearchInput from '../../Common/SearchInput'

const ProductGroupsListContainer = (props) => {
  const dispatch = useDispatch()
  const { loading, list, paging } = useSelector((state) => state.productGroups)
  const { token } = useSelector((state) => state.auth)

  const onFetch = (token) => dispatch(actions.fetchProductGroups(token))
  const onSearchClick = (token, query) => dispatch(actions.onProductGroupSearchClick(token, query))

  useEffect(() => {
    onFetch(token)
  }, [token])
  const handleClearSearchClick = () => {
    onSearchClick(token, '')
  }
  const handleSearchClick = (query) => {
    onSearchClick(token, query)
  }

  return (
    <>
      <Row>
        <Col sm='4'>
          <h3>Urun Gruplari</h3>
        </Col>
        <Col sm='8'>
          <SearchInput title='Urun grup ismi' onSearchClick={handleSearchClick}>
            <Link disabled className='btn btn-primary float-end' activeclassname='active' to='/alim/urun-gruplari/yeni'>
              <i className='fa fa-plus' /> Yeni
            </Link>
          </SearchInput>
        </Col>
      </Row>
      {paging.q && (
        <Row>
          <Col>
            <Alert color='info'>
              <Button color='danger' onClick={handleClearSearchClick}>
                <i className='fas fa-times' />
              </Button>
              Arama "<strong>{paging.q}</strong>"
            </Alert>
          </Col>
        </Row>
      )}
      <Row>
        <Col sm='12'>
          {loading ? (
            <Loading />
          ) : (
            <Table>
              <thead>
                <tr className='d-flex'>
                  <th scope='col' className='col-1'>
                    #
                  </th>
                  <th scope='col' className='col-11'>
                    Isim
                  </th>
                </tr>
              </thead>
              <tbody>
                {list.map((s) => {
                  return (
                    <tr className='d-flex' key={s.id}>
                      <th scope='row' className='col-1'>
                        <Link className='btn btn-secondary float-end' to={'/alim/urun-gruplari/' + s.id}>
                          Degistir
                        </Link>
                      </th>
                      <td className='col-11'>{s.fields.description}</td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          )}
        </Col>
      </Row>
    </>
  )
}

export default ProductGroupsListContainer
