import React, { useEffect, useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import AsyncSelect from 'react-select/async';
import {NavLink} from "react-router-dom";
import set from 'lodash/set'
import cloneDeep from 'lodash/cloneDeep'
import { toast } from 'react-toastify'
import {Button, Label, Input} from "reactstrap";
import {instance as axios} from "../../../../axios-api";
import {
  apiPath,
  toastOptions,
  toastErrorOptions
} from "../../../../shared";
import Loading from "../../../Common/Loading";
import NumberInput from "../../../Core/NumberInput";
import ModulNavigation from "./ModulNavigation";

const initialNewAksesuar = {aksesuar: {}, miktar: 0}
const ModulAksesuarlarForm = (props) => {
  const { token } = useSelector((state) => state.auth)
  const [state, setState] = useState({error:null, new: { ...initialNewAksesuar }, loading: false, modul: {}})
  const modulId = props.match.params.id
  const entity = props.match.params.entity
  const selectInputRef = useRef();
  const onClear = () => {
    selectInputRef.current.clearValue();
  };
  const handleAksesuarLookup = async (inputValue) =>{
    if (inputValue === '') {
      return []
    }
    const response = await axios(token).get(apiPath.uretimSettingsLookup('aksesuar', inputValue))
    return response.data
  }

  const handleChangeExistingAksesuarProperties = (event) => {
    const {name,value} = event.target
    const clonedAksesuarlar = cloneDeep(state.modul.aksesuarlar)
    set(clonedAksesuarlar,name,value)

    setState(prevState=> ({...prevState, modul: { ...prevState.modul, aksesuarlar: clonedAksesuarlar}}))
  }
  const handleAddAksesuar = () => {
    if (state.modul.aksesuarlar.length >0 && state.modul.aksesuarlar.some(aksesuar => Number(aksesuar.id) === Number(state.new.aksesuar.id)))
    {
      toast.warn(`Aksesuar listede mevcut!`, toastErrorOptions)
      return
    }
    const clonedAksesular = cloneDeep(state.modul.aksesuarlar)
    clonedAksesular.push({ ...state.new.aksesuar, miktar: state.new.miktar})
    setState(prevState=> ({...prevState, new: {...initialNewAksesuar}, modul: { ...prevState.modul, aksesuarlar: clonedAksesular}}))
    onClear()
  }
  const handleAksesuarSelected = (aksesuar) => {
    setState(prevState=> ({...prevState, new: { ...prevState.new, aksesuar}}))
  }
  const handleChangeNewAksesuarProperties = (event) => {
    const {name,value} = event.target
    const clonedNew = cloneDeep(state.new)
    set(clonedNew,name,value)

    setState(prevState=> ({...prevState, new: clonedNew }))
  }
  const whenPopulated = () => {
    return (
      state.new.aksesuar &&
      Number(state.new.miktar) > 0
    )
  }
  const handleRemove = (id) => {
    const aksesuarlar = state.modul.aksesuarlar.filter(aksesuar => aksesuar.id !== id)
    setState(prevState=> ({...prevState, new: {...initialNewAksesuar}, modul: { ...prevState.modul, aksesuarlar}}))
  }
  const handleFetch = async (id) => {
    const response = await axios(token).get(apiPath.uretimSettingsGetById(entity, id))
    return response.data
  }
  const handleSave = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    const options = {
      method: 'PUT',
      url: apiPath.uretimSettingsDataIdProp(entity, modulId, 'aksesuar'),
      data: state.modul.aksesuarlar
    }
    await axios(token)(options)
    toast.success(`Aksesuarlar kaydedildi!`, toastOptions)
  }
  useEffect(() => {
    setState(prevState=> ({...prevState,loading: true}))
    handleFetch(modulId)
      .then(modul => setState(prevState=> ({...prevState, loading: false, modul, error:null})))
      .catch(error => setState(prevState=> ({...prevState, loading: false, error})))
  }, [])
  return (<>
      {state.loading && <Loading/>}
      {!state.loading &&  <>
        <h1>{state.modul.aciklama}</h1>
        <ModulNavigation entity={entity} modulId={modulId}/>
        <div className='row'>
          <h5>Yeni Aksesuar:</h5>
          <div className='form-group col-sm-2'>
            <NumberInput
              step='1'
              label='Miktar'
              name='miktar'
              value={state.new.miktar}
              onChange={handleChangeNewAksesuarProperties}
            />
          </div>
          <div className='form-group col-sm-4'>
            <Label htmlFor='new-aksesuar-select'>Aksesuar</Label>
            <AsyncSelect
              ref={selectInputRef}
              name='new-aksesuar-select'
              cacheOptions={false}
              loadOptions={handleAksesuarLookup}
              onChange={handleAksesuarSelected}
              getOptionValue={(option) => option['id']}
              getOptionLabel={(option) => option['aciklama']}
            />
          </div>
          <div className='form-group col-sm-1'>
            <Button className='mt-4' name='new-aksesuar-button' disabled={!whenPopulated()} color='success' onClick={handleAddAksesuar}>
              Ekle
            </Button>
          </div>
        </div>
        <h5>Mevcut Aksesuarlar:</h5>
        <form onSubmit={handleSave}>
          <div className='row'>
            <div className='col-3'>
              <strong>Miktar</strong>
            </div>
            <div className='col-5'>
              <strong>Aksesuar</strong>
            </div>
            <div className='col-3'>
              <strong>Not</strong>
            </div>
            <div className='col-1'>
              <strong>#</strong>
            </div>
          </div>
          {state.modul.aksesuarlar && state.modul.aksesuarlar.map((item, index) => {
            return (
                <div className='row' key={`${item.id}`}>
                  <div className='form-group col-sm-3'>
                    <NumberInput
                      step='1'
                      label='Miktar'
                      hideLabel={true}
                      name={`${index}.miktar`}
                      value={item.miktar}
                      onChange={handleChangeExistingAksesuarProperties}
                    />
                  </div>
                  <div className='form-group col-5'>
                    <div name='aksesuar'>{item.aciklama}</div>
                    <small className='text-muted'>{`${item.grup.aciklama} - ${item.tip}`}</small>
                  </div>
                  <div className='form-group col-3'>
                    <Input
                      type='text'
                      placeholder='Not'
                      name={`${index}.not`}
                      value={item.not || ''}
                      onChange={handleChangeExistingAksesuarProperties}
                    />
                  </div>
                <div  className='form-group col-1'>
                  <Button
                    className='float-end'
                    color='danger'
                    id={`delete-${index}`}
                    onClick={() => handleRemove(item.id)}
                  >
                    Sil
                  </Button>
                </div>
            </div>)
          })}
          <div className='form-group row mt-4'>
            <div className='form-group col-md-12'>
              <NavLink className='btn btn-secondary float-start' to={`/uretim/ayarlar/${entity}`}>
                Vazgec
              </NavLink>
              <button type='submit' className='btn btn-primary float-end'>
                <i className='fas fa-save' />
                Kaydet
              </button>
            </div>
          </div>
        </form>
      </>
      }
    </>
  )
}
export default ModulAksesuarlarForm
