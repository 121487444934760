import React, { PureComponent } from 'react'
import { Modal } from './Kitchen'
import Select from '../../Core/Select'
import { Button, FormFeedback, Input, Label, Alert } from 'reactstrap'
import NumberInput from '../../Core/NumberInput'
import { DateInput, StringInput } from '../../Core'
import Spinner from '../../Common/Spinner'
import * as actions from '../../../store/actions'
import { useDispatch, useSelector } from 'react-redux'

const CloneOrderModal = (props) => {
  const { cloneOrderModal, lookup } = useSelector((state) => state.order)
  const {
    new: { musteri, konutno, sicilno, id, teslimtarihi, type, dolapAdet, dolapTakilacakOda },
    show,
    saving
  } = cloneOrderModal
  const { token } = useSelector((state) => state.auth)

  const dispatch = useDispatch()
  const onSelectMenuClose = () => dispatch(actions.onSelectMenuClose())
  const handleUpdateCloneOrderModalProperty = (payload) => dispatch(actions.updateCloneOrderModalProperty(payload))
  const handleSave = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const data = { id, musteri, konutno, sicilno, teslimtarihi }
    if (type === 'Acilan Dolap') {
      data.dolapAdet = dolapAdet
      data.dolapTakilacakOda = dolapTakilacakOda
    }
    props.toggleCloneOrderModal({ action: 'save', data })
  }
  const handleInputChange = (event) => {
    const { value, name } = event.currentTarget
    handleUpdateCloneOrderModalProperty({ name, value })
  }
  const handleSelectChange = (name, value) => {
    handleUpdateCloneOrderModalProperty({ name, value })
  }
  const handleSearchMusteri = (e) => {
    if (e !== '') {
      dispatch(actions.lookupMusteri(token, e))
    }
  }

  return (
    <Modal modal={show} size='xl' title='Siparis Kopyala'>
      <form onSubmit={handleSave}>
        <div className='row'>
          <div className='form-group col-sm-12  col-md-4'>
            <Select
              name='musteri'
              text='Musteri'
              isMulti={false}
              onInputChange={handleSearchMusteri}
              onSelectMenuClose={onSelectMenuClose}
              value={musteri}
              onChange={handleSelectChange}
              options={lookup.result}
              isLoading={lookup.isLoading}
              getOptionValue={(option) => option['id']}
              getOptionLabel={(option) => option['isimsoyisim']}
            />
          </div>
          <div className='form-group col-sm-6  col-md-3'>
            <StringInput value={sicilno} name='sicilno' label='Sicil no' onChange={handleInputChange} />
          </div>
          <div className='form-group col-sm-6 col-md-2'>
            <StringInput value={konutno} name='konutno' label='Konut no' onChange={handleInputChange} />
          </div>
          <div className='form-group col-sm-6 col-md-3'>
            <DateInput value={teslimtarihi} name='teslimtarihi' label='Teslim Tarihi' onChange={handleInputChange} />
          </div>
        </div>
        {type === 'Acilan Dolap' && (
          <div className='row'>
            <div className='form-group col-lg-6'>
              <NumberInput
                label='Dolap Adeti'
                name='dolapAdet'
                min={1}
                valid={dolapAdet > 0}
                value={dolapAdet}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className='form-group col-lg-6'>
              <Label htmlFor='dolapTakilacakOda'>Dolap takilacak oda</Label>
              <Input
                type='text'
                className='form-control'
                valid={!!dolapTakilacakOda}
                value={dolapTakilacakOda}
                invalid={!dolapTakilacakOda}
                onChange={handleInputChange}
                required
                name='dolapTakilacakOda'
                placeholder='Dolap takilacak oda'
              />
              <FormFeedback>* Zorunlu alan</FormFeedback>
            </div>
          </div>
        )}
        <div className='row'>
          <div className='form-group col-sm-12'>
            <Alert color='danger'>Ozel notlari konrol etmeyi unutmayiniz!</Alert>
          </div>
          <div className='col-md-12'>
            <Button
              color='secondary'
              name='cancel-button'
              className='float-start'
              onClick={() => props.toggleCloneOrderModal({ action: 'cancel' })}
            >
              Vazgec
            </Button>
            <button type='submit' disabled={saving} className='btn btn-primary'>
              {saving ? <Spinner /> : 'Kaydet'}
            </button>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default CloneOrderModal
