import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../../../store/actions'
import 'react-toastify/dist/ReactToastify.css'
import '../Orders.css'
import './KitchenOrder.css'
import KitchenForm from './KitchenForm'
import Loading from '../../../Common/Loading'

const KitchenOrderContainer = (props) => {
  const dispatch = useDispatch()
  const {
    loading,
    kitchenForm,
    error
  } = useSelector((state) => state.orderForm)
  const { token } = useSelector((state) => state.auth)

  useEffect(() => {
    dispatch(actions.fetchKitchenOrder(token, props.match.params.id))
    return () => {
      dispatch(actions.resetKitchenOrderForm())
    }
  }, [token, props.match.params.id, dispatch])

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-12'>
          <h3>Mutfak Siparis</h3>

          {error && (
            <div className='alert alert-danger alert-dismissible fade show' role='alert'>
              <strong>Hata!</strong> Sitede hata oluştu,lütfen daha sonra tekrar deneyin.
              <button type='button' className='close' data-dismiss='alert' aria-label='Close'>
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
          )}

          {loading || !kitchenForm ? (
            <Loading />
          ) : (
            <KitchenForm location={props.location}/>
          )}
        </div>
      </div>
    </div>
  )
}
export default KitchenOrderContainer
