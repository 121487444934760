import React from 'react'
import NumberInput from '../../Core/NumberInput'
import Select from '../../Core/Select'

const KapamaSettings = (props) => {
  return (
    <div className='row'>
      <div className='form-group col-md-6'>
        <NumberInput label='En' name='en' dataRow={props.index} value={props.en} onChange={props.onChange} />
      </div>
      <div className='form-group col-md-6'>
        <NumberInput label='Boy' name='boy' dataRow={props.index} value={props.boy} onChange={props.onChange} />
      </div>
      <div className='form-group col-md-6 col-lg-3'>
        <Select
          name={`bandTipi.uzunUst_${props.index}`}
          text='Band Tipi Uzun'
          value={props.bandTipi.uzunUst}
          onChange={props.handleSelectValueChange}
          options={props.referenceData.bandTipi}
          disableRequired={true}
        />
      </div>
      <div className='form-group col-md-6 col-lg-3'>
        <Select
          name={`bandTipi.uzunAlt_${props.index}`}
          text='Band Tipi Uzun'
          value={props.bandTipi.uzunAlt}
          onChange={props.handleSelectValueChange}
          options={props.referenceData.bandTipi}
          disableRequired={true}
        />
      </div>
      <div className='form-group col-md-6 col-lg-3'>
        <Select
          name={`bandTipi.kisaSag_${props.index}`}
          text='Band Tipi Kisa'
          value={props.bandTipi.kisaSag}
          onChange={props.handleSelectValueChange}
          options={props.referenceData.bandTipi}
          disableRequired={true}
        />
      </div>
      <div className='form-group col-md-6 col-lg-3'>
        <Select
          name={`bandTipi.kisaSol_${props.index}`}
          text='Band Tipi Kisa'
          value={props.bandTipi.kisaSol}
          onChange={props.handleSelectValueChange}
          options={props.referenceData.bandTipi}
          disableRequired={true}
        />
      </div>
    </div>
  )
}

export default KapamaSettings
