import {ButtonGroup} from "reactstrap";
import {NavLink} from "react-router-dom";
import React from "react";
const ModulNavigation = ({modulId, entity}) => {

  return (<ButtonGroup className='float-end'>
    <NavLink className='btn btn-outline-primary float-begin' to={`/uretim/ayarlar/${entity}/${modulId}/parcalar`}>
      Parcalar
    </NavLink>
    <NavLink className='btn btn-outline-primary float-begin'
             to={`/uretim/ayarlar/${entity}/${modulId}/aksamlar`}>Aksamlar</NavLink>
    <NavLink className='btn btn-outline-primary float-begin'
             to={`/uretim/ayarlar/${entity}/${modulId}/aksesuarlar`}>Aksesuarlar</NavLink>
  </ButtonGroup>)
}
export default ModulNavigation
