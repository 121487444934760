import React from 'react'
import Select from '../../Core/Select'
const GovdeRenk = (props) => {
  return (
    <div className='col-md-5 col-sm-12'>
      <Select
        name={'bandRengi_' + props.index}
        text='Band Rengi'
        isMulti={false}
        value={props.bandRengi}
        onChange={props.onChange}
        options={props.referenceData.bandRengi}
        getOptionValue={(option) => option['id']}
        getOptionLabel={(option) => option['aciklama']}
      />
    </div>
  )
}

export default GovdeRenk
