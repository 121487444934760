import React from 'react'
import { Card, CardBody, CardHeader, FormFeedback, Input, Label } from 'reactstrap'
import NumberInput from '../../../Core/NumberInput'

const WardrobeProperties = (props) => {
  return (
    <Card className='shadow mb-2'>
      <CardHeader>Dolap Ozellikleri</CardHeader>
      <CardBody>
        <div className='form-group row'>
          <div className='form-group col-md-2 col-lg-2'>
            <NumberInput
              label='Dolap Adeti'
              name='dolapAdet'
              min={1}
              valid={props.dolapAdet > 0}
              value={props.dolapAdet}
              onChange={props.onChange}
              required
            />
          </div>
          <div className='form-group col-lg-6 col-xl-3'>
            <Label htmlFor='dolapTakilacakOda'>Dolap takilacak oda</Label>
            <Input
              type='text'
              className='form-control'
              valid={!!props.dolapTakilacakOda}
              value={props.dolapTakilacakOda}
              invalid={!props.dolapTakilacakOda}
              onChange={props.onChange}
              required
              name='dolapTakilacakOda'
              placeholder='Dolap takilacak oda'
            />
            <FormFeedback>* Zorunlu alan</FormFeedback>
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default WardrobeProperties
