import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../store/actions'
import { Alert, Col, InputGroupText, Row, InputGroup } from 'reactstrap'

const Login = (props) => {
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)

  const onChange = (event) => dispatch(actions.authPropertyChange(event))
  const handleLogin = (e) => {
    e.preventDefault()
    dispatch(actions.authenticate(auth.email, auth.password))
  }
  useEffect(() => {
    if (props.match.path === '/auth/logout') {
      dispatch(actions.logout())
      props.history.push(`/auth/login`)
    }
  }, [dispatch, props.match.path])

  return (
    <Row className='login-container'>
      <Col md='5' className='login-div-left text-center  rounded-start rounded-end'>
        <div className='text-white text-center fs-3'>Ciddi Mutfak Destek</div>
        <div className='text-white text-end pt-5 d-none d-md-inline-block '>
          Ciddi Mutfak uretim destek sitesine hosgeldiniz
        </div>
      </Col>
      <Col sm='12' md='7' className='login-div-right text-center rounded-start rounded-end'>
        <form className='form-signin' onSubmit={handleLogin} id='login-form'>
          <img
            className='mx-auto d-block'
            src='https://fnc-cdn.fra1.digitaloceanspaces.com/ciddi-mutfak/prod/cm-logo.png'
            alt=''
          />
          <div className='text-white fs-3'>Siteye Giriş</div>
          <InputGroup className='pt-3'>
            <InputGroupText className='green-black border-green'>
              <i className='fas fa-user' />
            </InputGroupText>
            <label htmlFor='inputEmail' className='sr-only'>
              Email
            </label>
            <input
              type='email'
              id='email'
              name='email'
              autoComplete='off'
              className='form-control form-control-lg border-green'
              placeholder='Email'
              required
              autoFocus
              value={auth.email}
              onChange={onChange}
            />
          </InputGroup>
          <InputGroup className='pt-3'>
            <InputGroupText className='green-black border-green'>
              <i className='fas fa-lock' />
            </InputGroupText>
            <label htmlFor='inputPassword' className='sr-only'>
              Sifre
            </label>
            <input
              type='password'
              id='password'
              name='password'
              autoComplete='off'
              className='form-control form-control-lg border-green'
              placeholder='Sifre'
              required
              value={auth.password}
              onChange={onChange}
            />
          </InputGroup>

          <button className='btn login-btn form-control mt-3' type='submit' name='login'>
            <i className='fas fa-sign-in-alt' aria-hidden='true'>
              Giriş
            </i>
          </button>
          {auth.error && <Alert color='danger'>{auth.error}</Alert>}
        </form>
      </Col>
    </Row>
  )
}

export default Login
