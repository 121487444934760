import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Table,
  UncontrolledCollapse
} from 'reactstrap'
import React from 'react'
import Select from '../../Core/Select'
import IsActiveBadge from '../../Common/IsActiveBadge'
import { NavLink } from 'react-router-dom'
import CompanyModal from '../../Common/CompanyModal'
import { paymentTypes, currencies, measures, deliveryTypes } from '../../../shared'
import DeleteConfirmAlert from '../../Common/DeleteConfirmAlert'
const ProductForm = (props) => {
  const {
    isactive,
    fields: { description, note },
    productGroup,
    companies
  } = props.productForm
  return (
    <form onSubmit={props.onSave}>
      <CompanyModal
        companyModal={props.companyModal}
        onCompanyModalClick={props.onCompanyModalClick}
        currencies={currencies}
        measures={measures}
        lookup={props.lookup}
        lookupCompany={props.lookupCompany}
        paymentTypes={paymentTypes}
        deliveryTypes={deliveryTypes}
        onChange={props.onCompanyModalPropertyChange}
        onSelectChange={props.onCompanyModalSelectChange}
      />
      <Card className='shadow mb-2'>
        <CardHeader>Urun</CardHeader>
        <CardBody>
          <div className='form-group row'>
            <div className='form-group col-md-6'>
              <Label htmlFor='name'>Isim</Label>
              <Input
                type='text'
                className='form-control'
                onChange={props.onChange}
                valid={!!description}
                value={description}
                invalid={!description}
                name='fields.description'
                placeholder='Isim'
                required
              />
            </div>

            <div className='form-group col-md-2'>
              <Select
                name='productGroup'
                text='Grup'
                onChange={props.onSelectChange}
                value={productGroup}
                options={props.referenceData.productGroup}
              />
            </div>
            <div className='form-group col-md-2'>
              <Label htmlFor='name'>Aktif/Pasif</Label>
              <IsActiveBadge value={isactive} onChange={props.onChange} />
            </div>
          </div>
        </CardBody>
      </Card>
      <Card className='shadow mb-2'>
        <CardHeader>
          <strong>Tedarikci firmalar</strong>
          <Button
            className='float-end'
            outline
            color='primary'
            onClick={() => props.onCompanyModalClick({ action: 'new' })}
          >
            <i className='fas fa-plus' />
            Firma Ekle
          </Button>
        </CardHeader>
        <CardBody>
          <div className='form-group row'>
            <Table size='sm'>
              <thead>
                <tr>
                  <th scope='col'>Firma</th>
                  <th scope='col'>Min Siparis Adeti</th>
                  <th scope='col'>Odeme Sekli</th>
                  <th scope='col'>Termin Tarihi</th>
                  <th scope='col' className='w-25'>
                    Fiyat
                  </th>
                  <th scope='col'>#</th>
                </tr>
              </thead>
              <tbody>
                {companies.map((c) => {
                  const showPriceHistory = c.paymentDetails.priceHistory && c.paymentDetails.priceHistory.length > 0
                  return (
                    <tr key={c.company.id}>
                      <td>{c.company.name}</td>
                      <td>
                        {c.paymentDetails.minOrderAmount} {c.paymentDetails.measure.aciklama}
                      </td>
                      <td>{c.paymentDetails.paymentType.aciklama}</td>
                      <td>
                        {c.paymentDetails.deliveryRate}{' '}
                        {c.paymentDetails.deliveryType ? c.paymentDetails.deliveryType.aciklama : ''}
                      </td>
                      <td>
                        {showPriceHistory && (
                          <Button color='secondary' size='sm' id={`toggler${c.company.id}`}>
                            <i className='fas fa-caret-down' />
                          </Button>
                        )}
                        {c.paymentDetails.currency.aciklama} {c.paymentDetails.price}
                        {showPriceHistory && (
                          <UncontrolledCollapse toggler={`#toggler${c.company.id}`}>
                            <ListGroup>
                              {c.paymentDetails.priceHistory.map((ph) => {
                                return (
                                  <ListGroupItem key={ph.date}>
                                    <strong>{ph.date.split('T')[0]}</strong>: {ph.currency.aciklama} {ph.price}
                                  </ListGroupItem>
                                )
                              })}
                            </ListGroup>
                          </UncontrolledCollapse>
                        )}
                      </td>
                      <td>
                        <Button
                          color='primary'
                          onClick={() => props.onCompanyModalClick({ action: 'edit', companyId: c.company.id })}
                        >
                          Degistir
                        </Button>
                        <DeleteConfirmAlert className='p-2' onRemove={() => props.onRemoveCompany(c.company.id)} />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      <Card className='shadow mb-2'>
        <CardHeader>Not</CardHeader>
        <CardBody>
          <div className='form-group row'>
            <div className='form-group col-md-12'>
              <textarea className='form-control' value={note} onChange={props.onChange} name='fields.note' rows='3' />
            </div>
          </div>
        </CardBody>
      </Card>
      <NavLink className='btn btn-secondary  m-1' to='/alim/urunler'>
        Vazgec
      </NavLink>
      <button type='submit' className='btn btn-primary'>
        <i className='fas fa-save' />
        Kaydet
      </button>
    </form>
  )
}

export default ProductForm
