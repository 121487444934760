import React from 'react'
import { Label } from 'reactstrap'
import Select from '../../Core/Select'
import NumberInput from '../../Core/NumberInput'
import Radio from '../../Core/Radio'

const AksesuarSettings = (props) => {
  const { data, index, onChange, handleSelectValueChange, grup, aksamgrup } = props
  const aksesuar = data[index]
  return (
    <div className='row'>
      <div className='col-md-3 col-sm-12'>
        <Label>Tip</Label>

        <div className='btn-group btn-group-toggle' data-toggle='buttons'>
          <Radio
            checked={aksesuar.tip === 'Imalat'}
            name='tip'
            id='imalat'
            value='Imalat'
            onChange={onChange}
            index={index}
            text='Imalat Aksesuar'
          />
          <Radio
            checked={aksesuar.tip === 'Montaj'}
            name='tip'
            id='montaj'
            value='Montaj'
            onChange={onChange}
            index={index}
            text='Montaj Aksesuar'
          />
        </div>
      </div>
      <div className='col-md-5 col-sm-12'>
        <Select
          name={'grup_' + index}
          text='Grup'
          isMulti={false}
          value={grup}
          onChange={handleSelectValueChange}
          options={aksamgrup}
        />
      </div>
      <div className='col-md-3 col-sm-12'>
        <NumberInput label='Fiyat €' name='fiyat' step='0.001' dataRow={index} value={aksesuar.fiyat} onChange={onChange} required />
      </div>
    </div>
  )
}

export default AksesuarSettings
