import { Button, ButtonGroup, Collapse, Input, InputGroup, InputGroupText } from 'reactstrap'
import { deliveryTypes, PRODUCT_ORDER_STATUS_TAMAMLANAN } from '../../../shared'
import React from 'react'

const ProductOrderNotification = (props) => {
  const { showNotificationProperties, item, isOpen, disableSaveNotification, addItem, removeItem, orderId } = props
  return (
    item.status !== PRODUCT_ORDER_STATUS_TAMAMLANAN && (
      <>
        <Collapse isOpen={showNotificationProperties(item.id, item.status)}>
          <InputGroup>
            <InputGroupText>
              <i className='fas fa-bell'></i>
            </InputGroupText>
            <Input
              type='number'
              placeholder='uyari zamani'
              name='notification_number'
              onChange={(event) => props.handleChangeNotification(event, item.id, orderId)}
            />
            <InputGroup>
              <Input
                type='select'
                name='notification_key'
                onChange={(event) => props.handleChangeNotification(event, item.id, orderId)}
              >
                <option value=''>seciniz...</option>
                {deliveryTypes.map((dt) => {
                  return (
                    <option key={dt.id} value={dt.id}>
                      {dt.aciklama}
                    </option>
                  )
                })}
              </Input>
            </InputGroup>
          </InputGroup>
        </Collapse>
        {isOpen(item.id) ? (
          <ButtonGroup size='sm'>
            <Button
              color='success'
              onClick={() => props.handleChangeStatus(orderId, item.id)}
              disabled={disableSaveNotification(item)}
            >
              Kaydet
            </Button>
            <Button color='danger' onClick={() => removeItem(item.id)}>
              Iptal
            </Button>
          </ButtonGroup>
        ) : (
          <Button outline color='primary' size='sm' onClick={() => addItem(item.id)}>
            Onayla
          </Button>
        )}
      </>
    )
  )
}

export default ProductOrderNotification
