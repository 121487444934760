import React, { PureComponent } from 'react'
import { Card, CardHeader, CardBody } from 'reactstrap'
import { StringInput, DateInput } from '../Core'
import Select from '../Core/Select'

class MusteriBilgiFormu extends PureComponent {
  render() {
    const {
      muteahhit,
      musteri,
      teslimtarihi,
      sicilno,
      konutno,
      searchMusteri,
      searchMuteahhit,
      onChange,
      onSelectMenuClose,
      onSelectChange,
      lookup
    } = this.props
    return (
      <Card className='shadow mb-2'>
        <CardHeader>Musteri</CardHeader>
        <CardBody>
          <div className='row'>
            <div className='form-group col-lg-6 col-xl-3'>
              <Select
                name='muteahhit'
                text='Muteahhit'
                isMulti={false}
                onInputChange={searchMuteahhit}
                onSelectMenuClose={onSelectMenuClose}
                value={muteahhit}
                onChange={onSelectChange}
                options={lookup.result}
                isLoading={lookup.isLoading}
                getOptionValue={(option) => option['project_id']}
                getOptionLabel={(option) => `${option['description']}(${option['isimsoyisim']})`}
              />
            </div>
            <div className='form-group col-lg-6 col-xl-3'>
              <Select
                name='musteri'
                text='Musteri'
                isMulti={false}
                onInputChange={searchMusteri}
                onSelectMenuClose={onSelectMenuClose}
                value={musteri}
                onChange={onSelectChange}
                options={lookup.result}
                isLoading={lookup.isLoading}
                getOptionValue={(option) => option['id']}
                getOptionLabel={(option) => option['isimsoyisim']}
              />
            </div>
            <div className='form-group col-lg-6 col-xl-2'>
              <DateInput value={teslimtarihi} name='teslimtarihi' label='Teslim Tarihi' onChange={onChange} />
            </div>
            <div className='form-group col-lg-6 col-xl-2'>
              <StringInput value={sicilno} name='sicilno' label='Sicil no' onChange={onChange} />
            </div>
            <div className='form-group col-lg-6 col-xl-2'>
              <StringInput value={konutno} name='konutno' label='Konut no' onChange={onChange} />
            </div>
          </div>
        </CardBody>
      </Card>
    )
  }
}

export default MusteriBilgiFormu
