import React, { useEffect } from 'react'
import * as actions from '../../../store/actions'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Col, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import WeeklyList from './WeeklyList'
import Loading from '../../Common/Loading'
import Paging from '../../Common/Paging'

function WeeklyListContainer() {
  const dispatch = useDispatch()
  const { loading, error, list, paging } = useSelector((state) => state.groups)
  const { token } = useSelector((state) => state.auth)

  const onFetch = (page) => dispatch(actions.fetchOrderGroups(token, page))

  useEffect(() => {
    onFetch(1)
  }, [token])
  const handleIlkClick = () => {
    onFetch(1)
  }
  const handleGeriClick = () => {
    onFetch(~~paging.currentPage - 1)
  }
  const handleSonClick = () => {
    onFetch(~~paging.totalPageCount)
  }
  const handleIleriClick = () => {
    onFetch(~~paging.currentPage + 1)
  }
  return (
    <>
      <Row>
        <Col sm='12'>
          <span className='fs-3'>Raporlar</span>
          <Link className='btn btn-primary float-end' activeclassname='active' to='/uretim/raporlar/haftalik'>
            <i className='fa fa-plus' /> Yeni Haftalik Rapor
          </Link>
          <Link className='btn btn-success float-end' activeclassname='active' to='/uretim/raporlar/topluAcilan'>
            <i className='fa fa-plus' /> Yeni Toplu Dolap Raporu
          </Link>
        </Col>
        {error && (
          <Col sm={12}>
            <Alert color='danger'>Raporlar yüklerken hata oluştu — {error}</Alert>
          </Col>
        )}
        {loading ? <Loading /> : <WeeklyList list={list} />}
      </Row>
      <Paging
        {...paging}
        handleIleriClick={handleIleriClick}
        handleSonClick={handleSonClick}
        handleGeriClick={handleGeriClick}
        handleIlkClick={handleIlkClick}
      />
    </>
  )
}
export default WeeklyListContainer
