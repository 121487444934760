import { initKitchenData, initGolaDetails } from '../../dataModels/KitchenModals'
import { initModulModal } from '../../dataModels/ModulModal'
import { initLookup } from '../../dataModels/LookupModals'
import { toast } from 'react-toastify'
import { toasterMessages, toastErrorOptions, toastOptions } from '../../../shared'
import cloneDeep from 'lodash/cloneDeep'
import set from 'lodash/set'
import get from 'lodash/get'
import toastGolaMessage from '../../../components/Common/toastGolaMessage'
import {handleGolaKulpModeliParcaUpdate, kulpModeliGolaSecildi} from './common'

export const fetchKitchenOrderStart = (state, action) => {
  return {
    ...state,
    ...{ loading: true, kitchenForm: initKitchenData(), modulModal: initModulModal('kitchen'), lookup: initLookup() }
  }
}

export const fetchKitchenOrderSuccess = (state, action) => {
  let { order } = action.data
  const data = !order ? initKitchenData() : order.data
  data.id = !order ? null : order.id
  return { ...state, ...{ kitchenForm: data, loading: false, error: null, wardrobeForm: undefined } }
}

export const fetchKitchenOrderFail = (state, action) => {
  toast.error(toasterMessages.errorWithMessage(action.error.message), toastErrorOptions)
  return { ...state, ...{ loading: false, error: action.error.message } }
}

export const saveKitchenOrderStart = (state, action) => {
  return { ...state, ...{ saving: true, error: null } }
}
export const saveKitchenOrderSuccess = (state, action) => {
  toast.success(`Siparisiniz kaydedilmistir!`, toastOptions)
  return { ...state, ...{ saving: false, error: null, kitchenForm: { ...state.kitchenForm, id: action.data.id } } }
}
export const saveKitchenOrderFail = (state, action) => {
  toast.error(toasterMessages.errorWithMessage(action.error.message), toastErrorOptions)
  return { ...state, ...{ saving: false, error: action.error.message } }
}

export const resetKitchenOrderForm = (state, action) => {
  return { ...state, kitchenForm: undefined }
}
export const updateKitchenOrderProperty = (state, action) => {
  const { field, value, updateModuller } = action.payload
  const cloneKitchenForm = cloneDeep(state.kitchenForm)
  set(cloneKitchenForm, field, value)
  if (field === 'kapakrenk') {
    cloneKitchenForm.kapakmalzemesi = value
  }
  if (field === 'kulpmodeli') {
    if (kulpModeliGolaSecildi(value)) {
      set(cloneKitchenForm, 'golaDetails', initGolaDetails)
    } else {
      set(cloneKitchenForm, 'golaDetails', undefined)
    }
  }
  if (field === 'kapakmodeli') {
    cloneKitchenForm.kapakrenk = null
    cloneKitchenForm.kapakmalzemesi = null
  }
  // Update values in moduls...
  if (updateModuller) {
    const currentKitchenFormField = get(state.kitchenForm, field)
    const golaKulpModeliModulleri = []
    const updatedModulNames = []
    cloneKitchenForm.moduller.forEach((modulItem) => {
      const currentModulFormField = get(modulItem, field)
      if (
        currentKitchenFormField &&
        currentModulFormField &&
        currentKitchenFormField.aciklama !== currentModulFormField.aciklama
      ) {
        updatedModulNames.push(modulItem.modul.aciklama)
      }

      set(modulItem, field, value)
      if (field === 'kulpmodeli') {
        handleGolaKulpModeliParcaUpdate({
          newKulpModeli: value,
          currentKulpModeli: currentModulFormField,
          modulItem,
          golaKulpModeliModulleri
        })
      }
      if (field === 'kapakrenk') {
        set(modulItem, 'kapakmalzemesi', value)
      }
      if (field === 'kapakmodeli') {
        set(modulItem, 'kapakrenk', null)
        set(modulItem, 'kapakmalzemesi', null)
      }
    })
    if (updatedModulNames.length > 0) {
      toast.warn(`${updatedModulNames.join(', ')} modullerinde farkli degerler mevcut!`, toastErrorOptions)
    }
    if (golaKulpModeliModulleri.length > 0) {
      toast.warn(toastGolaMessage({ golaKulpModeliModulleri }), toastErrorOptions)
    }
  }

  return { ...state, kitchenForm: cloneKitchenForm, lookup: initLookup() }
}
