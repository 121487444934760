import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Alert, Button } from 'reactstrap'
import * as actions from '../../../store/actions'
import Loading from '../../Common/Loading'
import CompanyList from './CompanyList'
import SearchInput from '../../Common/SearchInput'
import { Link } from 'react-router-dom'

const CompanyListContainer = () => {
  const dispatch = useDispatch()
  const { loading, list, paging } = useSelector((state) => state.companies)
  const { token } = useSelector((state) => state.auth)
  const onFetch = (query) => dispatch(actions.fetchCompanies(token, query))
  useEffect(() => {
    onFetch()
  }, [token])
  const handleClearSearchClick = () => {
    onFetch()
  }
  return (
    <>
      <Row>
        <Col sm='4'>
          <h3>Sirketler</h3>
        </Col>
        <Col sm='8'>
          <SearchInput title='Sirket ismi' onSearchClick={onFetch}>
            <Link disabled className='btn btn-primary float-end' activeclassname='active' to='/alim/sirketler/yeni'>
              <i className='fa fa-plus' /> Yeni
            </Link>
          </SearchInput>
        </Col>
      </Row>
      {paging.q && (
        <Row>
          <Col>
            <Alert color='info'>
              <Button color='danger' onClick={handleClearSearchClick}>
                <i className='fas fa-times' />
              </Button>
              Arama "<strong>{paging.q}</strong>"
            </Alert>
          </Col>
        </Row>
      )}
      <Row>{loading ? <Loading /> : <CompanyList list={list} />}</Row>
    </>
  )
}
export default CompanyListContainer
