import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  ListGroup,
  ListGroupItem,
  Button,
  Card,
  CardBody,
  CardFooter,
  Input,
  Badge,
  Row,
  Col,
  CardHeader
} from 'reactstrap'
import { Link, NavLink } from 'react-router-dom'
import DeleteConfirmAlert from '../../Common/DeleteConfirmAlert'
import { StringInput } from '../../Core'
import Loading from '../../Common/Loading'
import * as actions from '../../../store/actions'
import useDebounce from '../../Common/use-debounce'
import {localDateTimeStringOptions} from "../../../shared";

const WeeklyForm = () => {
  const dispatch = useDispatch()
  const spacesUrl = 'https://fnc-space.ams3.digitaloceanspaces.com'

  const { groupForm } = useSelector((state) => state.groups)
  const { token } = useSelector((state) => state.auth)

  const [searchWardrobeTerm, setSearchWardrobeTerm] = useState('')
  const debouncedWardrobeSearchTerm = useDebounce(searchWardrobeTerm, 500)

  const [searchKitchenTerm, setSearchKitchenTerm] = useState('')
  const debouncedKitchenSearchTerm = useDebounce(searchKitchenTerm, 500)

  useEffect(() => {
    if (debouncedKitchenSearchTerm && debouncedKitchenSearchTerm.length > 3) {
      dispatch(actions.orderGroupSearchOrder(token, debouncedKitchenSearchTerm, 'kitchen'))
    } else {
      dispatch(actions.orderGroupLookupReset('kitchen'))
    }
  }, [debouncedKitchenSearchTerm])
  useEffect(() => {
    if (debouncedWardrobeSearchTerm && debouncedWardrobeSearchTerm.length > 3) {
      dispatch(actions.orderGroupSearchOrder(token, debouncedWardrobeSearchTerm, 'wardrobe'))
    } else {
      dispatch(actions.orderGroupLookupReset('wardrobe'))
    }
  }, [debouncedWardrobeSearchTerm])

  const getTypeDescription = (item) => {
    return item.data_type === 'acilan'
      ? `${item.dolapTakilacakOda} Acilan Dolap`
      : `${item.dolapTakilacakOda} Surme Dolap`
  }
  const onPropertyChange = (event) => {
    const { name, value } = event.currentTarget
    dispatch(actions.updateOrderGroupFormProperty(name, value))
  }

  const onKitchenOrderSelect = (name, selected) => dispatch(actions.addKitchenOrder(selected))
  const onWardrobeOrderSelect = (name, selected) => dispatch(actions.addWardrobeOrder(selected))
  const onRemoveKitchenOrder = (selected) => dispatch(actions.removeKitchenOrder(selected))
  const onRemoveWardrobeOrder = (selected) => dispatch(actions.removeWardrobeOrder(selected))

  const onDownloadKitchenReport = () =>
    dispatch(actions.onDownloadKitchenOrderGroupReport(token, groupForm.name, groupForm.id))
  const onDownloadWardrobeReport = () =>
    dispatch(actions.onDownloadWardrobeOrderGroupReport(token, groupForm.name, groupForm.id))

  const onDownloadKitchenMakinaReport = () =>
    dispatch(actions.onDownloadOrderGroupKitchenMakinaReport(token, groupForm.name, groupForm.id))

  const onDownloadWardrobeMakinaReport = () =>
    dispatch(actions.onDownloadOrderGroupWadrobeMakinaReport(token, groupForm.name, groupForm.id))

  const onSave = (e) => {
    e.preventDefault()
    const data = {
      name: groupForm.name,
      kitchenOrders: groupForm.kitchenOrders.map((i) => ~~i.id),
      wardrobeOrders: groupForm.wardrobeOrders.map((i) => ~~i.id)
    }
    dispatch(actions.saveOrderGroup(token, groupForm.id, data))
  }
  const [activeReportTab, setActiveReportTab] = useState('Mutfak');

  const handleRefreshFiles = () => {
    dispatch(actions.fetchOrderGroupFiles(token, groupForm.id))
  }
  const changeActiveReportTab = (value) => {
    if (value === activeReportTab) {
      return;
    }

    setActiveReportTab(value);
  };
  return (
    <>

      <form onSubmit={onSave}>
      <div className='form-group col-md-12'>
        <StringInput onChange={onPropertyChange} value={groupForm.name} name='name' label='Isim' />
      </div>
        <ul className="nav nav-pills pt-2">
          <li className="nav-item">
            <a className={activeReportTab === 'Mutfak' ? 'nav-link active' : 'nav-link'}  onClick={() => changeActiveReportTab('Mutfak')}>Mutfak Siparişleri</a>
          </li>
          <li className="nav-item">
            <a className={activeReportTab !== 'Mutfak' ? 'nav-link active' : 'nav-link'} onClick={() => changeActiveReportTab('Duvar Dolabi')}>Duvar Dolabi Siparişleri</a>
          </li>
        </ul>


      {activeReportTab === 'Mutfak' && <Card>
        <CardBody>
          <div className='row'>
            <div className='col-sm-12 col-md-6'>
              <Input
                type='text'
                className='form-control'
                onChange={(e) => setSearchKitchenTerm(e.target.value)}
                name='siparis'
                id='siparis'
                placeholder='arama'
              />
              <ListGroup>
                {groupForm.lookup.kitchen.isLoading && <Loading/>}
                {groupForm.lookup.kitchen.result.map((item) => (
                  <ListGroupItem key={item.id}>
                    <Button size='sm' outline onClick={() => onKitchenOrderSelect('kitchen', item)} color='primary'>
                      +
                    </Button>
                    {item.isimsoyisim} ({item.sicilno} - {item.konutno})
                    <Badge className='float-end' color='secondary' pill>
                      {item.muteahhit} - {item.proje}
                    </Badge>
                    {item.haftalik && (
                      <Badge className='float-end' color='info' pill>
                        {item.haftalik}
                      </Badge>
                    )}
                  </ListGroupItem>
                ))}
              </ListGroup>
            </div>

            <div className='col-sm-12 col-md-6'>
              <ListGroup>
                <ListGroupItem color='success'>Seçilmiş mutfak siparişleri:</ListGroupItem>
                {groupForm.kitchenOrders.length === 0 && (
                  <ListGroupItem className='font-weight-lighter'>Herhangi bir siparis mevcut degil</ListGroupItem>
                )}
                {groupForm.kitchenOrders.map((o) => {
                  return (
                    <ListGroupItem className='justify-content-between' key={o.id}>
                      {`${o.isimsoyisim} (${o.sicilno} - ${o.konutno})`}
                      <DeleteConfirmAlert
                        deleteButtonStyle='btn btn-danger float-end'
                        onRemove={() => onRemoveKitchenOrder(o)}
                      />
                      <Link className='btn btn-outline-primary float-end' to={`/uretim/siparisler/mutfak/${o.id}`}>
                        Degistir
                      </Link>
                    </ListGroupItem>
                  )
                })}
              </ListGroup>
            </div>
          </div>
        </CardBody>
        <CardFooter>
          {groupForm.id && groupForm.kitchenOrders.length > 0 && (
            <>
              <Button
                color='primary float-end m-1'
                disabled={groupForm.downloading || groupForm.loading}
                onClick={onDownloadKitchenMakinaReport}
              >
                <i className='far fa-file-excel'/>
                Makina Rapor Hazirla
              </Button>
              <Button
                color='primary float-end m-1'
                disabled={groupForm.downloading || groupForm.loading}
                onClick={onDownloadKitchenReport}
              >
                <i className='far fa-file-excel'/>
                Rapor Hazirla
              </Button>
            </>
          )}
        </CardFooter>
      </Card>
      }
      {activeReportTab !== 'Mutfak' && <Card className='pt-2'>
            <CardBody>
              <div className='row'>
                <div className='col-sm-12 col-md-6'>
                  <Input
                    type='text'
                    className='form-control'
                    onChange={(e) => setSearchWardrobeTerm(e.target.value)}
                    name='duvar-dolabi-siparis'
                    id='duvar-dolabi-siparis'
                    placeholder='arama'
                  />
                  <ListGroup>
                    {groupForm.lookup.wardrobe.isLoading && <Loading />}
                    {groupForm.lookup.wardrobe.result.map((item) => (
                      <ListGroupItem key={item.id}>
                        <Button
                          className='float-end'
                          size='sm'
                          outline
                          onClick={() => onWardrobeOrderSelect('wardrobe', item)}
                          color='primary'
                        >
                          +
                        </Button>
                        <div>
                          {item.isimsoyisim} ({item.sicilno} - {item.konutno})
                        </div>

                        <Badge className='float-end' color='secondary' pill>
                          {item.muteahhit} - {item.proje}
                        </Badge>
                        <Badge className='float-start' color='secondary' pill>
                          {getTypeDescription(item)}
                        </Badge>
                        {item.haftalik && (
                          <Badge className='float-end' color='info' pill>
                            {item.haftalik}
                          </Badge>
                        )}
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </div>
                <div className='col-md-6 col-sm-12'>
                  <ListGroup>
                    <ListGroupItem color='info'>Secilmis duvar dolabi siparişleri:</ListGroupItem>
                    {groupForm.wardrobeOrders.length === 0 && (
                      <ListGroupItem className='font-weight-lighter'>Herhangi bir siparis mevcut degil</ListGroupItem>
                    )}
                    {groupForm.wardrobeOrders.map((o) => {
                      return (
                        <ListGroupItem className='justify-content-between' key={o.id}>
                          <Row>
                            <Col sm='12'>
                              {`${o.isimsoyisim} (${o.sicilno} - ${o.konutno})`}
                              <div>
                                <Badge className='float-end' color='secondary' pill>
                                  {o.muteahhit} - {o.proje}
                                </Badge>
                                <Badge className='float-start' color='secondary' pill>
                                  {getTypeDescription(o)}
                                </Badge>
                              </div>
                            </Col>
                            <Col sm='12 pt-2'>
                              <DeleteConfirmAlert
                                deleteButtonStyle='btn btn-danger float-end'
                                onRemove={() => onRemoveWardrobeOrder(o)}
                              />
                              <Link
                                className='btn btn-outline-primary float-end'
                                to={`/uretim/siparisler/duvarDolabi/${o.data_type}/${o.id}`}
                              >
                                Degistir
                              </Link>
                            </Col>
                          </Row>
                        </ListGroupItem>
                      )
                    })}
                  </ListGroup>
                </div>
              </div>
            </CardBody>
            <CardFooter>
              {groupForm.id && groupForm.wardrobeOrders.length > 0 && (
                <>
                  <Button
                    color='primary float-end m-1'
                    disabled={groupForm.downloading || groupForm.loading}
                    onClick={onDownloadWardrobeMakinaReport}
                  >
                    <i className='far fa-file-excel' />
                    Makina Rapor Hazirla
                  </Button>
                  <Button
                    color='primary float-end m-1'
                    disabled={groupForm.downloading || groupForm.loading}
                    onClick={onDownloadWardrobeReport}
                  >
                    <i className='far fa-file-excel' />
                    Rapor Hazirla
                  </Button>
                </>
              )}
            </CardFooter>
          </Card> }


      <nav className='navbar fixed-bottom navbar-dark bg-dark justify-content-between'>
        <NavLink className='btn btn-warning  m-1' to='/uretim/raporlar'>
          Vazgec
        </NavLink>
        {!groupForm.loading && (
          <button type='submit' className='btn btn-primary float-end m-1'>
            <i className='fas fa-save' />
            Kaydet
          </button>
        )}
      </nav>
    </form>
      {activeReportTab === 'Mutfak' && <Card>
        <CardHeader>Raporlar:
          <Button
            type='button'
            className='m-1'
            disabled={groupForm.downloading || groupForm.loading}
            color='primary'
            onClick={handleRefreshFiles}
          >
            <i className="fa fa-sync"/> Raporlari indir
          </Button>
        </CardHeader>
        <CardBody>
          <ul className="list-group">
            {groupForm.files.map((file) => {
              if(file.Key === groupForm.fileName) {
                return <li className="list-group-item list-group-item-success">
                  {new Date(file.LastModified).toLocaleDateString('tr', localDateTimeStringOptions)} <a href={encodeURI(`${spacesUrl}/${file.Key}`)}>{file.Key.split('/').pop()}</a>
                </li>
              }
              return <li className="list-group-item">
                {new Date(file.LastModified).toLocaleDateString('tr', localDateTimeStringOptions)} <a href={encodeURI(`${spacesUrl}/${file.Key}`)}>{file.Key.split('/').pop()}</a>
              </li>
            })}
          </ul>
        </CardBody>
      </Card>}
    </>
  )
}

export default WeeklyForm
