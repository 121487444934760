import * as actionTypes from '../actions/actionTypes'
import { v4 as uuidv4 } from 'uuid'
import { toast } from 'react-toastify'
import { toasterMessages, toastErrorOptions, toastOptions } from '../../shared'
import { initialPaging } from '../dataModels/CommonModals'
import cloneDeep from 'lodash/cloneDeep'
import set from 'lodash/set'

const initialCustomer = (has_projects) => ({
  id: '',
  has_projects: has_projects || false,
  data: { isimSoyisim: '', adres: '', telefon: '' },
  projects: []
})

const initialState = {
  error: null,
  loading: true,
  list: [],
  customer: initialCustomer(),
  paging: initialPaging()
}

const fetchCustomerListStart = (state, action) => {
  return {
    ...state,
    ...{
      error: null,
      loading: true,
      paging: initialPaging(),
      customer: initialCustomer(),
      list: []
    }
  }
}

const fetchCustomerListSuccess = (state, action) => {
  toast.success(`Musteriler yuklendi!`, toastOptions)
  return {
    ...state,
    ...{
      error: null,
      loading: false,
      list: action.payload.result,
      paging: action.payload.paging
    }
  }
}

const fetchCustomerListFail = (state, action) => {
  toast.error(toasterMessages.errorWithTitle(`Musteriler yuklerken`), toastErrorOptions)
  return {
    ...state,
    ...{
      error: action.error,
      loading: false
    }
  }
}

const fetchCustomerStart = (state, action) => {
  return { ...state, ...{ error: null, loading: true, paging: initialPaging(), customer: initialCustomer() } }
}

const fetchCustomerSuccess = (state, action) => {
  toast.success(`Musteri yuklendi!`, toastOptions)
  return {
    ...state,
    ...{
      error: null,
      loading: false,
      customer: action.payload
    }
  }
}
const fetchCustomerFail = (state, action) => {
  toast.error(toasterMessages.errorWithTitle(`Musteri yuklerken`), toastErrorOptions)
  return {
    ...state,
    ...{
      error: action.error,
      loading: false
    }
  }
}

const saveCustomerStart = (state, action) => {
  return { ...state, ...{ error: null, loading: true } }
}

const saveCustomerSuccess = (state, action) => {
  toast.success(`Musteri kaydedildi!`, toastOptions)
  return {
    ...state,
    ...{
      error: null,
      loading: false,
      customer: { ...state.customer, id: action.payload.id }
    }
  }
}

const saveCustomerFail = (state, action) => {
  toast.error(toasterMessages.errorWithTitle(`Musteri kaydederken`), toastErrorOptions)
  return {
    ...state,
    ...{
      error: action.error.response.data,
      loading: false
    }
  }
}

const updateCustomerDataProperty = (state, action) => {
  const { name, value } = action.payload
  const cloneCustomer = cloneDeep(state.customer)
  set(cloneCustomer, name, value)
  return {
    ...state,
    customer: cloneCustomer
  }
}

const fetchCustomerNew = (state, action) => {
  const { has_projects } = action.payload
  const customer = initialCustomer(has_projects)
  return { ...state, ...{ error: null, loading: false, customer } }
}
const addProject = (state, action) => {
  const cloneCustomer = cloneDeep(state.customer)
  cloneCustomer.projects.push({ project_id: uuidv4(), description: action.payload })
  return { ...state, customer: cloneCustomer }
}

export const customersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CUSTOMER_LIST_START:
      return fetchCustomerListStart(state, action)
    case actionTypes.FETCH_CUSTOMER_LIST_SUCCESS:
      return fetchCustomerListSuccess(state, action)
    case actionTypes.FETCH_CUSTOMER_LIST_FAIL:
      return fetchCustomerListFail(state, action)

    case actionTypes.FETCH_CUSTOMER_START:
      return fetchCustomerStart(state, action)
    case actionTypes.FETCH_CUSTOMER_SUCCESS:
      return fetchCustomerSuccess(state, action)
    case actionTypes.FETCH_CUSTOMER_FAIL:
      return fetchCustomerFail(state, action)

    case actionTypes.FETCH_CUSTOMER_NEW:
      return fetchCustomerNew(state, action)

    case actionTypes.SAVE_CUSTOMER_START:
      return saveCustomerStart(state, action)
    case actionTypes.SAVE_CUSTOMER_SUCCESS:
      return saveCustomerSuccess(state, action)
    case actionTypes.SAVE_CUSTOMER_FAIL:
      return saveCustomerFail(state, action)

    case actionTypes.UPDATE_CUSTOMER_DATA_PROPERTY:
      return updateCustomerDataProperty(state, action)

    case actionTypes.CUSTOMER_ADD_PROJECT:
      return addProject(state, action)

    default:
      return state
  }
}
