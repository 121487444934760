import React from 'react'
import { Button, Card, CardHeader, CardBody, Alert, CardFooter } from 'reactstrap'
import {
  Baza,
  Kapama,
  ModulAksamForm,
  ModulAksesuarForm,
  ModulForm,
  ModulList,
  ModulParcaForm,
  Suluk,
  Tezgah,
  Dekorasyon
} from './index'
import SiparisFields from './SiparisFields'
import MusteriBilgiFormu from '../../../Common/MusteriBilgiFormu'
import UretimSiparisFormFooter from '../../../Common/UretimSiparisFormFooter'
import {useDispatch, useSelector} from "react-redux";
import * as actions from "../../../../store/actions";
import GolaDetails from "./GolaDetails";

const KitchenForm = (props) => {
  const dispatch = useDispatch()
  const {
    modulModal,
    parcaModal,
    aksamModal,
    aksesuarModal,
    saving,
    kitchenForm,
    lookup,
    downloading
  } = useSelector((state) => state.orderForm)
  const { token } = useSelector((state) => state.auth)

  const onLookupSetting = (name, value) => dispatch(actions.searchSetting(token, name, value))
  const onSelectSetting = (name, id) => dispatch(actions.selectSetting(token, name, id))
  const onSearchMuteahhit = (value) => dispatch(actions.searchMuteahhit(token, value))
  const onSearchMusteri = (value) => dispatch(actions.searchMusteri(token, value))
  const onDownloadKitchenReport = (reportName, id) => dispatch(actions.onDownloadKitchenReport(token, reportName, id))
  const onModulModalClick = (action, index) => dispatch(actions.toggleKitchenModulModal({ action, index }))
  const updateDekorasyonState = (id, name, value) => dispatch(actions.updateKitchenDekorasyonProperty(id, name, value))
  const updateKapamaState = (id, name, value) => dispatch(actions.updateKapamaProperty(id, name, value))
  const onKapamaAdd = () => dispatch(actions.addKapama())
  const onKapamaRemove = (id) => dispatch(actions.removeKapama(id))
  const onSaveOrder = (data) => dispatch(actions.saveKitchenOrder(token, data))
  const onModulParcaClick = ({ index, action }) => dispatch(actions.toggleKitchenModulParcaModal({ index, action }))
  const onModulAksesuarClick = ({ action, index, id }) =>
    dispatch(actions.toggleKitchenModulAksesuarModal({ action, index, id }))
  const onModulAksamClick = ({ action, index, id }) =>
    dispatch(actions.toggleKitchenModulAksamModal({ action, index, id }))
  const aksesuarModalOnChange = (action) => dispatch(actions.updateKitchenAksesuarModal(action))
  const aksamModalOnChange = (action) => dispatch(actions.updateKitchenAksamModal(action))
  const parcaModalOnChange = (action) => dispatch(actions.updateKitchenParcaModal(action))
  const onSelectMenuClose = () => dispatch(actions.onSelectMenuClose())
  const aksesuarModalNewOnChange = (name, value) => dispatch(actions.updateAksesuarModalNew(name, value))
  const onModulRemoveClick = (index) => dispatch(actions.removeKitchenModul(index))
  const updateMutfakState = (event) => dispatch(actions.updateKitchenOrderProperty(event))
  const updateSelect = (name, value, action) => dispatch(actions.updateMutfakOrderSelect(name, value, false))

  const onDekorasyonAdd = () => dispatch(actions.addKitchenDekorasyon())
  const onDekorasyonRemove = (id) => dispatch(actions.removeKitchenDekorasyon(id))
  const updateModulModalDataChange = (property, value) =>
    dispatch(actions.updateModulModalDataProperty(property, value))
  const parcaModalNewOnChange = (name, value) => dispatch(actions.updateKitchenParcaModalNew(name, value))
  const aksamModalNewOnChange = (name, value) => dispatch(actions.updateKitchenAksamModalNew(name, value))
  const updateSiparisOzellikleri = (name, value, action) => dispatch(actions.updateMutfakOrderSelect(name, value, true))

  const handleAddModulClick = (e) => {
    onModulModalClick('new')
  }
  const handleDownload = () => {
    onDownloadKitchenReport(
      `${kitchenForm.musteri.isimsoyisim}-mutfak-${kitchenForm.konutno}`,
      kitchenForm.id
    )
  }
  const handleSearchMusteri = (e) => {
    if (e !== '') {
      onSearchMusteri(e)
    }
  }
  const handleSearchMuteahhit = (e) => {
    if (e !== '') {
      onSearchMuteahhit(e)
    }
  }
  const handleLookupSetting = (e, name) => {
    if (e !== '') {
      onLookupSetting(name, e)
    }
  }

  const handleSelectSetting = (e, name) => {
    if (e !== '') {
      onSelectSetting(name, e)
    }
  }

  const handleSelectChangeDekorasyonState = (nameAndId, selected, event) => {
    const [name, id] = nameAndId.split('.')
    updateDekorasyonState(id, name, selected)
  }
  const handleInputChangeDekorasyonState = (event) => {
    const [name, id] = event.currentTarget.name.split('.')
    const value = event.currentTarget.value
    updateDekorasyonState(id, name, value)
  }

  const handleInputChangeKapamaState = (event) => {
    const [name, id] = event.currentTarget.name.split('.')
    const value = event.currentTarget.value
    updateKapamaState(id, name, value)
  }
  const handleSelectChangeKapamaState = (nameAndId, selected, event) => {
    const [name, id] = nameAndId.split('.')
    updateKapamaState(id, name, selected)
  }
  const handleAddKapama = (e) => {
    e.stopPropagation()
    onKapamaAdd()
    return false
  }
  const handleRemoveKapama = (id) => {
    onKapamaRemove(id)
    return false
  }
  const handleModulModalAction = (action) => (e) => {
    onModulModalClick(action)
  }
  const handleSave = (e) => {
    e.preventDefault()
    onSaveOrder(kitchenForm)
  }

  const handleParcaAction = (action) => (e) => {
    onModulParcaClick({ action })
  }
  const handleAksesuarAction = (action) => (e) => {
    onModulAksesuarClick({ action })
  }
  const handleAksamAction = (action) => (e) => {
    onModulAksamClick({ action })
  }
  const handleAksesuarModalOnChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
    aksesuarModalOnChange({ name: event.target.name, value, row: event.target.dataset.row })
  }
  const handleAksamModalOnChange = (event) => {
    aksamModalOnChange({ name: event.target.name, value: event.target.value, row: event.target.dataset.row })
  }
  const handleParcaModalOnChange = (index) => (name, value) => {
    parcaModalOnChange({ name, index: Number(index), value })
  }

  return (
    <form onSubmit={handleSave}>
      {props.location.search !== '' && <Alert color='danger'>Ozel notlari konrol etmeyi unutmayiniz!</Alert>}
      <MusteriBilgiFormu
        searchMusteri={handleSearchMusteri}
        searchMuteahhit={handleSearchMuteahhit}
        musteri={kitchenForm.musteri}
        muteahhit={kitchenForm.muteahhit}
        teslimtarihi={kitchenForm.teslimtarihi}
        sicilno={kitchenForm.sicilno}
        konutno={kitchenForm.konutno}
        lookup={lookup}
        onSelectMenuClose={onSelectMenuClose}
        onSelectChange={updateSelect}
        onChange={updateMutfakState}
      />
      <SiparisFields
        lookupSetting={handleLookupSetting}
        onSelectMenuClose={onSelectMenuClose}
        lookup={lookup}
        govdemalzemesi={kitchenForm.govdemalzemesi}
        govderengi={kitchenForm.govderengi}
        kapakrenk={kitchenForm.kapakrenk}
        kapakdesenyonu={kitchenForm.kapakdesenyonu}
        kapakmodeli={kitchenForm.kapakmodeli}
        kapakmalzemesi={kitchenForm.kapakmalzemesi}
        kulpmodeli={kitchenForm.kulpmodeli}
        cekmecesistemi={kitchenForm.cekmecesistemi}
        ozelistekler={kitchenForm.ozelistekler}
        updateMutfakState={updateMutfakState}
        updateSelect={updateSiparisOzellikleri}
      />
      {kitchenForm.kulpmodeli?.aciklama.startsWith('GOLA') && <GolaDetails/>}
      <Baza
        onInputChange={updateMutfakState}
        onSelectChange={updateSelect}
        lookupSetting={handleLookupSetting}
        onSelectMenuClose={onSelectMenuClose}
        lookup={lookup}
        {...kitchenForm.baza}
      />
      <Dekorasyon
        lookupSetting={handleLookupSetting}
        onSelectMenuClose={onSelectMenuClose}
        lookup={lookup}
        dekorasyonlar={kitchenForm.dekorasyonlar}
        onSelectChange={handleSelectChangeDekorasyonState}
        onInputChange={handleInputChangeDekorasyonState}
        onDekorasyonAddClick={onDekorasyonAdd}
        onDekorasyonRemoveClick={onDekorasyonRemove}
      />
      <Suluk/>
      <Tezgah/>
      <Kapama
        lookupSetting={handleLookupSetting}
        onSelectMenuClose={onSelectMenuClose}
        lookup={lookup}
        kapamalar={kitchenForm.kapamalar}
        onInputChange={handleInputChangeKapamaState}
        onSelectChange={handleSelectChangeKapamaState}
        onKapamaAddClick={handleAddKapama}
        onKapamaRemoveClick={handleRemoveKapama}
      />
      <Card className='shadow mb-2'>
        <CardHeader>
          Moduller
          <Button className='float-end' outline color='primary' onClick={handleAddModulClick}>
            <i className='fas fa-plus' />
            Modul Ekle
          </Button>
        </CardHeader>
        <CardBody>
          <div className='form-group row'>
            <div className='col-12'>
              <ModulForm
                onChange={updateModulModalDataChange}
                lookupSetting={handleLookupSetting}
                selectSetting={handleSelectSetting}
                onSelectMenuClose={onSelectMenuClose}
                lookup={lookup}
                kapakdesenyonu={kitchenForm.kapakdesenyonu}
                kapakmodeli={kitchenForm.kapakmodeli}
                kapakmalzemesi={kitchenForm.kapakmalzemesi}
                kapakrenk={kitchenForm.kapakrenk}
                kulpmodeli={kitchenForm.kulpmodeli}
                govdemalzemesi={kitchenForm.govdemalzemesi}
                govderengi={kitchenForm.govderengi}
                modulModal={modulModal}
                save={handleModulModalAction('save')}
                cancel={handleModulModalAction('cancel')}
              />
              <ModulParcaForm
                parcaModal={parcaModal}
                onChange={handleParcaModalOnChange}
                onRemove={onModulParcaClick}
                onAdd={handleParcaAction('add')}
                save={handleParcaAction('save')}
                cancel={handleParcaAction('cancel')}
                onToggleBandlar={handleParcaAction('toggleBandlar')}
                lookupSetting={handleLookupSetting}
                onSelectMenuClose={onSelectMenuClose}
                lookup={lookup}
                newOnChange={parcaModalNewOnChange}
                enableEditParcaBandlar={true}
              />
              <ModulAksamForm
                aksamModal={aksamModal}
                onChange={handleAksamModalOnChange}
                onRemove={onModulAksamClick}
                onAdd={handleAksamAction('add')}
                save={handleAksamAction('save')}
                cancel={handleAksamAction('cancel')}
                lookupSetting={handleLookupSetting}
                onSelectMenuClose={onSelectMenuClose}
                lookup={lookup}
                newOnChange={aksamModalNewOnChange}
              />
              <ModulAksesuarForm
                aksesuarModal={aksesuarModal}
                onChange={handleAksesuarModalOnChange}
                onRemove={onModulAksesuarClick}
                onAdd={handleAksesuarAction('add')}
                save={handleAksesuarAction('save')}
                cancel={handleAksesuarAction('cancel')}
                lookupSetting={handleLookupSetting}
                onSelectMenuClose={onSelectMenuClose}
                lookup={lookup}
                showRafCikar={true}
                newOnChange={aksesuarModalNewOnChange}
              />
              <ModulList
                moduller={kitchenForm.moduller}
                toggle={kitchenForm.toggle}
                onModulRemoveClick={onModulRemoveClick}
                onModulEditClick={onModulModalClick}
                onModulParcaClick={onModulParcaClick}
                onModulAksamClick={onModulAksamClick}
                onModulAksesuarClick={onModulAksesuarClick}
                type='kitchen'
              />
            </div>
          </div>
        </CardBody>
        <CardFooter>
          <Button className='float-end' outline color='primary' onClick={handleAddModulClick}>
            <i className='fas fa-plus' />
            Modul Ekle
          </Button>
        </CardFooter>
      </Card>
      <UretimSiparisFormFooter
        downloading={downloading}
        saving={saving}
        handleDownload={handleDownload}
        id={kitchenForm.id}
      />
    </form>
  )
}

export default KitchenForm
