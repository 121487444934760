import {initDekorasyon} from "./DekorasyonModal";
import shortid from "shortid";
export const initGolaDetails = {
  tekliGolaSolKapakAdeti:0,
  tekliGolaSagKapakAdeti:0,
  ciftliGolaKapakAdeti:0,
  golaBaglantiParcasiAdeti:0,
  yatayTekliBirOlcusu:0,
  yatayTekliIkiOlcusu:0,
  yatayTekliUcOlcusu:0,
  yatayCiftliBirOlcusu:0,
  yatayCiftliIkiOlcusu:0,
  yatayCiftliUcOlcusu:0
}
export const initKitchenData = () => ({
  id: null,
  muteahhit: null,
  musteri: null,
  teslimtarihi: '',
  konutno: '',
  sicilno: '',
  kulpmodeli: null,
  kapakmodeli: null,
  kapakmalzemesi: null,
  kapakrenk: null,
  cekmecesistemi: null,
  govdemalzemesi: null,
  govderengi: null,
  ozelistekler: '',
  kapakdesenyonu: null,
  baza: {
    item: null,
    koseadeti: '',
    adabar:''
  },
  suluk: {
    item: null,
    adet: '',
    kapakadet: '',
    ickoseadet: '',
    diskoseadet: ''
  },
  dekorasyonlar: [{
    ...initDekorasyon()
  }],
  tezgahlar: [{
    id:shortid.generate(),
    tezgah:null,
    adet: '',
    baglantielemanadeti:'',
    alinadeti:'',
    en:'',
    boy:'',
    kalinlik:'',
    yon: null
  }],
  kapamalar:[{
    id:shortid.generate(),
    adet:'',
    kapama: null,
    kapamaRenk: null
  }],
  moduller: []
})
