import React from "react";
import {FormFeedback, Input, Label} from "reactstrap";

const StringInput = (props) => {
  const {onChange,value,name,label} = props
  return (<>
    <Label htmlFor={name}>{label}</Label>
    <Input type="text"
           className="form-control"
           onChange={onChange}
           valid={!!value}
           value={value}
           invalid={!value}
           required
           name={name}
           id={name}
           placeholder={label}/>
    <FormFeedback>
      * Zorunlu alan
    </FormFeedback>
  </>)
}

export default StringInput
