export const FETCH_KITCHEN_ORDER_SUCCESS = 'FETCH_KITCHEN_ORDER_SUCCESS'
export const FETCH_KITCHEN_ORDER_FAIL = 'FETCH_KITCHEN_ORDER_FAIL'
export const FETCH_KITCHEN_ORDER_START = 'FETCH_KITCHEN_ORDER_START'

export const REMOVE_MODUL_KITCHEN_ORDER = 'REMOVE_MODUL_KITCHEN_ORDER'

export const RESET_MOBILYA_ORDER_FORM = 'RESET_MOBILYA_ORDER_FORM'
export const RESET_WARDROBE_ORDER_FORM = 'RESET_WARDROBE_ORDER_FORM'
export const RESET_KITCHEN_ORDER_FORM = 'RESET_KITCHEN_ORDER_FORM'

export const KITCHEN_ORDER_DOWNLOAD_REPORT = 'KITCHEN_ORDER_DOWNLOAD_REPORT'
export const KITCHEN_ORDER_DOWNLOAD_REPORT_SUCCESS = 'KITCHEN_ORDER_DOWNLOAD_REPORT_SUCCESS'
export const KITCHEN_ORDER_DOWNLOAD_REPORT_FAIL = 'KITCHEN_ORDER_DOWNLOAD_REPORT_FAIL'

export const ADD_KITCHEN_ORDER_DEKORASYON = 'ADD_KITCHEN_ORDER_DEKORASYON'
export const REMOVE_KITCHEN_ORDER_DEKORASYON = 'REMOVE_KITCHEN_ORDER_DEKORASYON'
export const UPDATE_KITCHEN_ORDER_DEKORASYON_PROPERTY = 'UPDATE_KITCHEN_ORDER_DEKORASYON_PROPERTY'

export const ADD_WARDROBE_ORDER_DEKORASYON = 'ADD_WARDROBE_ORDER_DEKORASYON'
export const REMOVE_WARDROBE_ORDER_DEKORASYON = 'REMOVE_WARDROBE_ORDER_DEKORASYON'
export const UPDATE_WARDROBE_ORDER_DEKORASYON_PROPERTY = 'UPDATE_WARDROBE_ORDER_DEKORASYON_PROPERTY'

export const ADD_TEZGAH_KITCHEN_ORDER = 'ADD_TEZGAH_KITCHEN_ORDER'
export const REMOVE_TEZGAH_KITCHEN_ORDER = 'REMOVE_TEZGAH_KITCHEN_ORDER'
export const UPDATE_KITCHEN_ORDER_TEZGAH_PROPERTY = 'UPDATE_KITCHEN_ORDER_TEZGAH_PROPERTY'

export const TOGGLE_KITCHEN_MODUL_AKSESUAR_MODAL = 'TOGGLE_KITCHEN_MODUL_AKSESUAR_MODAL'
export const UPDATE_KITCHEN_MODUL_AKSESUAR_MODAL = 'UPDATE_KITCHEN_MODUL_AKSESUAR_MODAL'
export const UPDATE_KITCHEN_MODUL_AKSESUAR_MODAL_NEW = 'UPDATE_KITCHEN_MODUL_AKSESUAR_MODAL_NEW'

export const TOGGLE_KITCHEN_MODUL_AKSAM_MODAL = 'TOGGLE_KITCHEN_MODUL_AKSAM_MODAL'
export const UPDATE_KITCHEN_MODUL_AKSAM_MODAL = 'UPDATE_KITCHEN_MODUL_AKSAM_MODAL'
export const UPDATE_KITCHEN_MODUL_AKSAM_MODAL_NEW = 'UPDATE_KITCHEN_MODUL_AKSAM_MODAL_NEW'

export const UPDATE_WARDROBE_MODUL_AKSAM_MODAL_NEW = 'UPDATE_WARDROBE_MODUL_AKSAM_MODAL_NEW'
export const UPDATE_WARDROBE_MODUL_AKSAM_MODAL = 'UPDATE_WARDROBE_MODUL_AKSAM_MODAL'

export const TOGGLE_ACILAN_WARDROBE_MODUL_AKSAM_MODAL = 'TOGGLE_ACILAN_WARDROBE_MODUL_AKSAM_MODAL'
export const TOGGLE_ACILAN_WARDROBE_MODUL_AKSESUAR_MODAL = 'TOGGLE_ACILAN_WARDROBE_MODUL_AKSESUAR_MODAL'

export const UPDATE_ACILAN_WARDROBE_MODUL_AKSESUAR_MODAL = 'UPDATE_ACILAN_WARDROBE_MODUL_AKSESUAR_MODAL'
export const UPDATE_ACILAN_WARDROBE_MODUL_AKSESUAR_MODAL_NEW = 'UPDATE_ACILAN_WARDROBE_MODUL_AKSESUAR_MODAL_NEW '

export const TOGGLE_KITCHEN_MODUL_PARCA_MODAL = 'TOGGLE_KITCHEN_MODUL_PARCA_MODAL'
export const UPDATE_KITCHEN_MODUL_PARCA_MODAL = 'UPDATE_KITCHEN_MODUL_PARCA_MODAL'
export const UPDATE_KITCHEN_MODUL_PARCA_MODAL_NEW = 'UPDATE_KITCHEN_MODUL_PARCA_MODAL_NEW'

export const TOGGLE_ACILAN_WARDROBE_MODUL_PARCA_MODAL = 'TOGGLE_ACILAN_WARDROBE_MODUL_PARCA_MODAL'
export const UPDATE_ACILAN_WARDROBE_MODUL_PARCA_MODAL = 'UPDATE_ACILAN_WARDROBE_MODUL_PARCA_MODAL'
export const UPDATE_ACILAN_WARDROBE_MODUL_PARCA_MODAL_NEW = 'UPDATE_ACILAN_WARDROBE_MODUL_PARCA_MODAL_NEW'

export const ERROR_SETTING_MODUL_PARCA_MODAL = 'ERROR_SETTING_MODUL_PARCA_MODAL'
export const TOGGLE_SETTING_MODUL_PARCA_MODAL = 'TOGGLE_SETTING_MODUL_PARCA_MODAL'
export const UPDATE_SETTING_MODUL_PARCA_MODAL = 'UPDATE_SETTING_MODUL_PARCA_MODAL'
export const UPDATE_SETTING_MODUL_PARCA_MODAL_NEW = 'UPDATE_SETTING_MODUL_PARCA_MODAL_NEW'

export const ERROR_SETTING_MODUL_AKSAM_MODAL = 'ERROR_SETTING_MODUL_AKSAM_MODAL'
export const TOGGLE_SETTING_MODUL_AKSAM_MODAL = 'TOGGLE_SETTING_MODUL_AKSAM_MODAL'
export const UPDATE_SETTING_MODUL_AKSAM_MODAL_NEW = 'UPDATE_SETTING_MODUL_AKSAM_MODAL_NEW'
export const UPDATE_SETTING_MODUL_AKSAM_MODAL = 'UPDATE_SETTING_MODUL_AKSAM_MODAL'

export const ERROR_SETTING_MODUL_AKSESUAR_MODAL = 'ERROR_SETTING_MODUL_AKSESUAR_MODAL'
export const TOGGLE_SETTING_MODUL_AKSESUAR_MODAL = 'TOGGLE_SETTING_MODUL_AKSESUAR_MODAL'
export const UPDATE_SETTING_MODUL_AKSESUAR_MODAL_NEW = 'UPDATE_SETTING_MODUL_AKSESUAR_MODAL_NEW'
export const UPDATE_SETTING_MODUL_AKSESUAR_MODAL = 'UPDATE_SETTING_MODUL_AKSESUAR_MODAL'

export const TOGGLE_MOBILYA_MODUL_MODAL = 'TOGGLE_MOBILYA_MODUL_MODAL'
export const UPDATE_MOBILYA_MODUL_PARCA_MODAL = 'UPDATE_MOBILYA_MODUL_PARCA_MODAL'
export const UPDATE_MOBILYA_MODUL_PARCA_MODAL_NEW = 'UPDATE_MOBILYA_MODUL_PARCA_MODAL_NEW'

export const UPDATE_MOBILYA_ORDER_MODUL_MODAL_DATA_CHANGE = 'UPDATE_MOBILYA_ORDER_MODUL_MODAL_DATA_CHANGE'
export const REMOVE_MODUL_MOBILYA_ORDER = 'REMOVE_MODUL_MOBILYA_ORDER'


export const TOGGLE_KITCHEN_MODUL_MODAL = 'TOGGLE_KITCHEN_MODUL_MODAL'
export const REMOVE_MODUL_ACILAN_WARDROBE_ORDER = 'REMOVE_MODUL_ACILAN_WARDROBE_ORDER'

export const SAVE_KITCHEN_ORDER_START = 'SAVE_KITCHEN_ORDER_START'
export const SAVE_KITCHEN_ORDER_SUCCESS = 'SAVE_KITCHEN_ORDER_SUCCESS'
export const SAVE_KITCHEN_ORDER_FAIL = 'SAVE_KITCHEN_ORDER_FAIL'

export const ACILAN_WARDROBE_ORDER_DOWNLOAD_REPORT = 'ACILAN_WARDROBE_ORDER_DOWNLOAD_REPORT'
export const ACILAN_WARDROBE_ORDER_DOWNLOAD_REPORT_SUCCESS = 'ACILAN_WARDROBE_ORDER_DOWNLOAD_REPORT_SUCCESS'
export const ACILAN_WARDROBE_ORDER_DOWNLOAD_REPORT_FAIL = 'ACILAN_WARDROBE_ORDER_DOWNLOAD_REPORT_FAIL'

export const UPDATE_KITCHEN_ORDER_MODUL_MODAL_DATA_CHANGE = 'UPDATE_KITCHEN_ORDER_MODUL_MODAL_DATA_CHANGE'

export const ADD_KAPAMA_KITCHEN_ORDER = 'ADD_KAPAMA_KITCHEN_ORDER'
export const REMOVE_KAPAMA_KITCHEN_ORDER = 'REMOVE_KAPAMA_KITCHEN_ORDER'
export const UPDATE_KITCHEN_ORDER_KAPAMA_PROPERTY = 'UPDATE_KITCHEN_ORDER_KAPAMA_PROPERTY'

export const MOBILYA_ORDER_DOWNLOAD_REPORT = 'MOBILYA_ORDER_DOWNLOAD_REPORT'
export const MOBILYA_ORDER_DOWNLOAD_REPORT_SUCCESS = 'MOBILYA_ORDER_DOWNLOAD_REPORT_SUCCESS'
export const MOBILYA_ORDER_DOWNLOAD_REPORT_FAIL = 'MOBILYA_ORDER_DOWNLOAD_REPORT_FAIL'

export const SAVE_MOBILYA_ORDER_FAIL = 'SAVE_MOBILYA_ORDER_FAIL'
export const SAVE_MOBILYA_ORDER_START = 'SAVE_MOBILYA_ORDER_START'
export const SAVE_MOBILYA_ORDER_SUCCESS = 'SAVE_MOBILYA_ORDER_SUCCESS'

export const FETCH_MOBILYA_ORDER_START = 'FETCH_MOBILYA_ORDER_START'
export const FETCH_MOBILYA_ORDER_SUCCESS = 'FETCH_MOBILYA_ORDER_SUCCESS'
export const FETCH_MOBILYA_ORDER_FAIL = 'FETCH_MOBILYA_ORDER_FAIL'
export const UPDATE_MOBILYA_ORDER_PROPERTY = 'UPDATE_MOBILYA_ORDER_PROPERTY'

export const UPDATE_KITCHEN_ORDER_PROPERTY = 'UPDATE_KITCHEN_ORDER_PROPERTY'
export const UPDATE_KITCHEN_ORDER_SELECT = 'UPDATE_KITCHEN_ORDER_SELECT'

export const CHANGE_CLONE_ORDER_MODAL_PROPERTY = 'CHANGE_CLONE_ORDER_MODAL_PROPERTY'
export const ORDERS_CLONE_ORDER_MODAL_TOGGLE = 'ORDERS_CLONE_ORDER_MODAL_TOGGLE'
export const ORDERS_DUPLICATE_ORDER_SUCCESS = 'ORDERS_DUPLICATE_ORDER_SUCCESS'

export const ORDERS_CLONE_ORDER_MODAL_SAVE_START = 'ORDERS_CLONE_ORDER_MODAL_SAVE_START'
export const ORDERS_CLONE_ORDER_MODAL_SAVE_FAIL = 'ORDERS_CLONE_ORDER_MODAL_SAVE_FAIL'

export const ORDERS_SEARCH_CLICK_START = 'ORDERS_SEARCH_CLICK_START'
export const ORDERS_SEARCH_CLICK_SUCCESS = 'ORDERS_SEARCH_CLICK_SUCCESS'
export const ORDERS_SEARCH_CLICK_FAIL = 'ORDERS_SEARCH_CLICK_FAIL'

export const FETCH_ORDERS_START = 'FETCH_ORDERS_START'
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS'
export const FETCH_ORDERS_FAIL = 'FETCH_ORDERS_FAIL'

export const ORDERS_LOOKUP_START = 'ORDERS_LOOKUP_START'
export const ORDERS_LOOKUP_SUCCESS = 'ORDERS_LOOKUP_SUCCESS'
export const ORDERS_LOOKUP_FAIL = 'ORDERS_LOOKUP_FAIL'

export const ORDERS_RESET_REDIRECT = 'ORDERS_RESET_REDIRECT'

export const FETCH_ORDER_GROUPS_START = 'FETCH_ORDER_GROUPS_START'
export const FETCH_ORDER_GROUPS_SUCCESS = 'FETCH_ORDER_GROUPS_SUCCESS'
export const FETCH_ORDER_GROUPS_FAIL = 'FETCH_ORDER_GROUPS_FAIL'

export const NEW_ORDER_GROUP = 'NEW_ORDER_GROUP'
export const ORDER_GROUP_RESET_LOOKUP = 'ORDER_GROUP_RESET_LOOKUP'
export const ORDER_GROUP_ADD_KITCHEN_ORDER = 'ORDER_GROUP_ADD_KITCHEN_ORDER'
export const ORDER_GROUP_REMOVE_KITCHEN_ORDER = 'ORDER_GROUP_REMOVE_KITCHEN_ORDER'
export const ORDER_GROUP_ADD_WARDROBE_ORDER = 'ORDER_GROUP_ADD_WARDROBE_ORDER'
export const ORDER_GROUP_REMOVE_WARDROBE_ORDER = 'ORDER_GROUP_REMOVE_WARDROBE_ORDER'

export const FETCH_ORDER_GROUP_START = 'FETCH_ORDER_GROUP_START'
export const FETCH_ORDER_GROUP_SUCCESS = 'FETCH_ORDER_GROUP_SUCCESS'
export const FETCH_ORDER_GROUP_FAIL = 'FETCH_ORDER_GROUP_FAIL'

export const FETCH_ORDER_GROUP_FILES_START = 'FETCH_ORDER_GROUP_FILES_START'
export const FETCH_ORDER_GROUP_FILES_SUCCESS = 'FETCH_ORDER_GROUP_FILES_SUCCESS'
export const FETCH_ORDER_GROUP_FILES_FAIL = 'FETCH_ORDER_GROUP_FILES_FAIL'

export const SAVE_ORDER_GROUP_START = 'SAVE_ORDER_GROUP_START'
export const SAVE_ORDER_GROUP_SUCCESS = 'SAVE_ORDER_GROUP_SUCCESS'
export const SAVE_ORDER_GROUP_FAIL = 'SAVE_ORDER_GROUP_FAIL'

export const ORDER_GROUP_ORDER_SEARCH_START = 'ORDER_GROUP_ORDER_SEARCH_START'
export const ORDER_GROUP_ORDER_SEARCH_FAIL = 'ORDER_GROUP_ORDER_SEARCH_FAIL'
export const ORDER_GROUP_ORDER_SEARCH_SUCCESS = 'ORDER_GROUP_ORDER_SEARCH_SUCCESS'

export const ORDER_GROUP_DOWNLOAD_REPORT_START = 'ORDER_GROUP_DOWNLOAD_REPORT_START'
export const ORDER_GROUP_DOWNLOAD_REPORT_SUCCESS = 'ORDER_GROUP_DOWNLOAD_REPORT_SUCCESS'
export const ORDER_GROUP_DOWNLOAD_REPORT_FAIL = 'ORDER_GROUP_DOWNLOAD_REPORT_FAIL'
export const ORDER_GROUP_UPLOAD_S3_REPORT_SUCCESS = 'ORDER_GROUP_UPLOAD_S3_REPORT_SUCCESS'

export const UPDATE_ORDER_GROUP_FORM_PROPERTY = 'UPDATE_ORDER_GROUP_FORM_PROPERTY'

export const SAVE_WARDROBE_ORDER_START = 'SAVE_WARDROBE_ORDER_START'
export const SAVE_WARDROBE_ORDER_SUCCESS = 'SAVE_WARDROBE_ORDER_SUCCESS'
export const SAVE_WARDROBE_ORDER_FAIL = 'SAVE_WARDROBE_ORDER_FAIL'

export const FETCH_ACILAN_WARDROBE_ORDER_SUCCESS = 'FETCH_ACILAN_WARDROBE_ORDER_SUCCESS'
export const FETCH_ACILAN_WARDROBE_ORDER_FAIL = 'FETCH_ACILAN_WARDROBE_ORDER_FAIL'
export const FETCH_ACILAN_WARDROBE_ORDER_START = 'FETCH_ACILAN_WARDROBE_ORDER_START'

export const FETCH_SURME_WARDROBE_ORDER_SUCCESS = 'FETCH_SURME_WARDROBE_ORDER_SUCCESS'
export const FETCH_SURME_WARDROBE_ORDER_FAIL = 'FETCH_SURME_WARDROBE_ORDER_FAIL'
export const FETCH_SURME_WARDROBE_ORDER_START = 'FETCH_SURME_WARDROBE_ORDER_START'

export const UPDATE_WARDROBE_PERVAZ_INPUT = 'UPDATE_WARDROBE_PERVAZ_INPUT'

export const UPDATE_WARDROBE_ORDER_SELECT = 'UPDATE_WARDROBE_ORDER_SELECT'
export const UPDATE_WARDROBE_ORDER_PROPERTY = 'UPDATE_WARDROBE_ORDER_PROPERTY'

export const TOGGLE_WARDROBE_MODUL_MODAL = 'TOGGLE_WARDROBE_MODUL_MODAL'
export const UPDATE_WARDROBE_MODUL_MODAL_PROPERTY = 'UPDATE_WARDROBE_MODUL_MODAL_PROPERTY'

export const UPDATE_WARDROBE_ORDER_ARRAY_PROPERTY = 'UPDATE_WARDROBE_ORDER_ARRAY_PROPERTY'

export const UPDATE_MOBILYA_ORDER_SELECT = 'UPDATE_MOBILYA_ORDER_SELECT'
export const TOGGLE_MOBILYA_MODUL_PARCA_MODAL = 'TOGGLE_MOBILYA_MODUL_PARCA_MODAL'
export const TOGGLE_MOBILYA_MODUL_AKSAM_MODAL = 'TOGGLE_MOBILYA_MODUL_AKSAM_MODAL'
export const UPDATE_MOBILYA_MODUL_AKSAM_MODAL = 'UPDATE_MOBILYA_MODUL_AKSAM_MODAL'
export const UPDATE_MOBILYA_MODUL_AKSAM_MODAL_NEW = 'UPDATE_MOBILYA_MODUL_AKSAM_MODAL_NEW'

export const UPDATE_MOBILYA_MODUL_AKSESUAR_MODAL = 'UPDATE_MOBILYA_MODUL_AKSESUAR_MODAL'
export const UPDATE_MOBILYA_MODUL_AKSESUAR_MODAL_NEW = 'UPDATE_MOBILYA_MODUL_AKSESUAR_MODAL_NEW'
export const TOGGLE_MOBILYA_MODUL_AKSESUAR_MODAL = 'TOGGLE_MOBILYA_MODUL_AKSESUAR_MODAL'


export const AUTH_START = 'AUTH_START'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAIL = 'AUTH_FAIL'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const SET_AUTH_PROPERTY = 'SET_AUTH_PROPERTY'

export const SETTING_URETIM_LIST_START = 'SETTING_URETIM_LIST_START'
export const SETTING_URETIM_LIST_SUCCESS = 'SETTING_URETIM_LIST_SUCCESS'
export const SETTING_URETIM_LIST_FAIL = 'SETTING_URETIM_LIST_FAIL'

export const PRODUCT_GROUP_GET_START = 'PRODUCT_GROUP_GET_START'
export const PRODUCT_GROUP_GET_SUCCESS = 'PRODUCT_GROUP_GET_SUCCESS'
export const PRODUCT_GROUP_GET_FAIL = 'PRODUCT_GROUP_GET_FAIL'

export const PRODUCT_GROUP_LIST_START = 'PRODUCT_GROUP_LIST_START'
export const PRODUCT_GROUP_LIST_SUCCESS = 'PRODUCT_GROUP_LIST_SUCCESS'
export const PRODUCT_GROUP_LIST_FAIL = 'PRODUCT_GROUP_LIST_FAIL'

export const PRODUCT_GROUP_SAVE_START = 'PRODUCT_GROUP_SAVE_START'
export const PRODUCT_GROUP_SAVE_SUCCESS = 'PRODUCT_GROUP_SAVE_SUCCESS'
export const PRODUCT_GROUP_SAVE_FAIL = 'PRODUCT_GROUP_SAVE_FAIL'

export const FETCH_PRODUCTS_LIST_START = 'FETCH_PRODUCTS_LIST_START'
export const FETCH_PRODUCTS_LIST_SUCCESS = 'FETCH_PRODUCTS_LIST_SUCCESS'
export const FETCH_PRODUCTS_LIST_FAIL = 'FETCH_PRODUCTS_LIST_FAIL'

export const FETCH_PRODUCT_START = 'FETCH_PRODUCT_START'
export const FETCH_PRODUCT_SUCCESS = 'FETCH_PRODUCT_SUCCESS'
export const FETCH_PRODUCT_FAIL = 'FETCH_PRODUCT_FAIL'

export const FETCH_PRODUCT_ORDERS_LIST_START = 'FETCH_PRODUCT_ORDERS_LIST_START'
export const FETCH_PRODUCT_ORDERS_LIST_SUCCESS = 'FETCH_PRODUCT_ORDERS_LIST_SUCCESS'
export const FETCH_PRODUCT_ORDERS_LIST_FAIL = 'FETCH_PRODUCT_ORDERS_LIST_FAIL'

export const UPDATE_PRODUCT_ORDER_FORM_PROPERTY = 'UPDATE_PRODUCT_ORDER_FORM_PROPERTY'
export const UPDATE_PRODUCT_ORDER_MODAL_PROPERTY = 'UPDATE_PRODUCT_ORDER_MODAL_PROPERTY'
export const RESET_PRODUCT_ORDER_MODAL_ORDER = 'RESET_PRODUCT_ORDER_MODAL_ORDER'
export const ADD_TO_PRODUCT_ORDER_MODAL_ORDER = 'ADD_TO_PRODUCT_ORDER_MODAL_ORDER'
export const SET_EDITED_ORDER_MODAL_PAYMENT_DETAILS = 'SET_EDITED_ORDER_MODAL_PAYMENT_DETAILS'
export const REMOVE_PRODUCT_ORDER_ROW = 'REMOVE_PRODUCT_ORDER_ROW'

export const FETCH_PRODUCT_ORDER_START = 'FETCH_PRODUCT_ORDER_START'
export const FETCH_PRODUCT_ORDER_SUCCESS = 'FETCH_PRODUCT_ORDER_SUCCESS'
export const FETCH_PRODUCT_ORDER_FAIL = 'FETCH_PRODUCT_ORDER_FAIL'

export const SAVE_PRODUCT_ORDER_START = 'SAVE_PRODUCT_ORDER_START'
export const SAVE_PRODUCT_ORDER_SUCCESS = 'SAVE_PRODUCT_ORDER_SUCCESS'
export const SAVE_PRODUCT_ORDER_FAIL = 'SAVE_PRODUCT_ORDER_FAIL'

export const CHANGE_PRODUCT_ORDER_STATUS_FILTER = 'CHANGE_PRODUCT_ORDER_STATUS_FILTER'

export const GET_COMPANIES_FOR_PRODUCT_START = 'GET_COMPANIES_FOR_PRODUCT_START'
export const GET_COMPANIES_FOR_PRODUCT_SUCCESS = 'GET_COMPANIES_FOR_PRODUCT_SUCCESS'
export const GET_COMPANIES_FOR_PRODUCT_FAIL = 'GET_COMPANIES_FOR_PRODUCT_FAIL'

export const PRODUCT_ORDERS_CHANGE_STATUS_START = 'PRODUCT_ORDERS_CHANGE_STATUS_START'
export const PRODUCT_ORDERS_CHANGE_STATUS_SUCCESS = 'PRODUCT_ORDERS_CHANGE_STATUS_SUCCESS'
export const PRODUCT_ORDERS_CHANGE_STATUS_FAIL = 'PRODUCT_ORDERS_CHANGE_STATUS_FAIL'

export const PRODUCT_ORDERS_CHANGE_NOTIFICATION = 'PRODUCT_ORDERS_CHANGE_NOTIFICATION'

export const PRODUCT_ORDERS_SEARCH_CLICK_START = 'PRODUCT_ORDERS_SEARCH_CLICK_START'
export const PRODUCT_ORDERS_SEARCH_CLICK_SUCCESS = 'PRODUCT_ORDERS_SEARCH_CLICK_SUCCESS'
export const PRODUCT_ORDERS_SEARCH_CLICK_FAIL = 'PRODUCT_ORDERS_SEARCH_CLICK_FAIL'

export const SAVE_PRODUCT_START = 'SAVE_PRODUCT_START'
export const SAVE_PRODUCT_SUCCESS = 'SAVE_PRODUCT_SUCCESS'
export const SAVE_PRODUCT_FAIL = 'SAVE_PRODUCT_FAIL'
export const TOGGLE_PRODUCT_COMPANY_MODAL = 'TOGGLE_PRODUCT_COMPANY_MODAL'

export const REMOVE_PRODUCT_COMPANY = 'REMOVE_PRODUCT_COMPANY'

export const CHANGE_PRODUCT_COMPANY_MODAL_PROPERTY = 'CHANGE_PRODUCT_COMPANY_MODAL_PROPERTY'
export const PRODUCT_COMPANY_LOOKUP_START = 'PRODUCT_COMPANY_LOOKUP_START'
export const PRODUCT_COMPANY_LOOKUP_SUCCESS = 'PRODUCT_COMPANY_LOOKUP_SUCCESS'
export const PRODUCT_COMPANY_LOOKUP_FAIL = 'PRODUCT_COMPANY_LOOKUP_FAIL'

export const PRODUCT_GROUP_LOOKUP_START = 'PRODUCT_GROUP_LOOKUP_START'
export const PRODUCT_GROUP_LOOKUP_SUCCESS = 'PRODUCT_GROUP_LOOKUP_SUCCESS'
export const PRODUCT_GROUP_LOOKUP_FAIL = 'PRODUCT_GROUP_LOOKUP_FAIL'

export const FETCH_PRODUCT_NEW = 'FETCH_PRODUCT_NEW'
export const UPDATE_PRODUCT_PROPERTY = 'UPDATE_PRODUCT_PROPERTY'

export const PRODUCT_SEARCH_CLICK_START = 'PRODUCT_SEARCH_CLICK_START'
export const PRODUCT_SEARCH_CLICK_SUCCESS = 'PRODUCT_SEARCH_CLICK_SUCCESS'
export const PRODUCT_SEARCH_CLICK_FAIL = 'PRODUCT_SEARCH_CLICK_FAIL'

export const FETCH_COMPANIES_LIST_START = 'FETCH_COMPANIES_LIST_START'
export const FETCH_COMPANIES_LIST_SUCCESS = 'FETCH_COMPANIES_LIST_SUCCESS'
export const FETCH_COMPANIES_LIST_FAIL = 'FETCH_COMPANIES_LIST_FAIL'

export const FETCH_COMPANY_START = 'FETCH_COMPANY_START'
export const FETCH_COMPANY_SUCCESS = 'FETCH_COMPANY_SUCCESS'
export const FETCH_COMPANY_FAIL = 'FETCH_COMPANY_FAIL'

export const GET_PRODUCT_TREE_START = 'GET_PRODUCT_TREE_START'
export const GET_PRODUCT_TREE_SUCCESS = 'GET_PRODUCT_TREE_SUCCESS'
export const GET_PRODUCT_TREE_FAIL = 'GET_PRODUCT_TREE_FAIL'

export const SAVE_COMPANY_START = 'SAVE_COMPANY_START'
export const SAVE_COMPANY_SUCCESS = 'SAVE_COMPANY_SUCCESS'
export const SAVE_COMPANY_FAIL = 'SAVE_COMPANY_FAIL'

export const REMOVE_COMPANY_PRODUCT = 'REMOVE_COMPANY_PRODUCT'

export const FETCH_COMPANY_NEW = 'FETCH_COMPANY_NEW'
export const UPDATE_COMPANY_PROPERTY = 'UPDATE_COMPANY_PROPERTY'
export const TOGGLE_COMPANY_PRODUCT_MODAL = 'TOGGLE_COMPANY_PRODUCT_MODAL'
export const CHANGE_COMPANY_PRODUCT_MODAL_PROPERTY = 'CHANGE_COMPANY_PRODUCT_MODAL_PROPERTY'

export const FETCH_CUSTOMER_LIST_START = 'FETCH_CUSTOMER_LIST_START'
export const FETCH_CUSTOMER_LIST_SUCCESS = 'FETCH_CUSTOMER_LIST_SUCCESS'
export const FETCH_CUSTOMER_LIST_FAIL = 'FETCH_CUSTOMER_LIST_FAIL'

export const FETCH_USER_LIST_START = 'FETCH_USER_LIST_START'
export const FETCH_USER_LIST_SUCCESS = 'FETCH_USER_LIST_SUCCESS'
export const FETCH_USER_LIST_FAIL = 'FETCH_USER_LIST_FAIL'

export const UPDATE_USER_ROLE = 'UPDATE_USER_ROLE'
export const UPDATE_USER_PROPERTY = 'UPDATE_USER_PROPERTY'
export const SAVE_USER_START = 'SAVE_USER_START'
export const SAVE_USER_SUCCESS = 'SAVE_USER_SUCCESS'
export const SAVE_USER_FAIL = 'SAVE_USER_FAIL'

export const FETCH_USER_START = 'FETCH_USER_START'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAIL = 'FETCH_USER_FAIL'

export const SAVE_CUSTOMER_START = 'SAVE_CUSTOMER_START'
export const SAVE_CUSTOMER_SUCCESS = 'SAVE_CUSTOMER_SUCCESS'
export const SAVE_CUSTOMER_FAIL = 'SAVE_CUSTOMER_FAIL'

export const FETCH_CUSTOMER_START = 'FETCH_CUSTOMER_START'
export const FETCH_CUSTOMER_SUCCESS = 'FETCH_CUSTOMER_SUCCESS'
export const FETCH_CUSTOMER_FAIL = 'FETCH_CUSTOMER_FAIL'
export const FETCH_CUSTOMER_NEW = 'FETCH_CUSTOMER_NEW'

export const CUSTOMER_ADD_PROJECT = 'CUSTOMER_ADD_PROJECT'
export const CUSTOMER_REMOVE_PROJECT = 'CUSTOMER_REMOVE_PROJECT'

export const UPDATE_CUSTOMER_DATA_PROPERTY = 'UPDATE_CUSTOMER_DATA_PROPERTY'

export const URETIM_SETTING_FETCH_BY_NAME_START = 'URETIM_SETTING_FETCH_BY_NAME_START'
export const URETIM_SETTING_FETCH_BY_NAME_SUCCESS = 'URETIM_SETTING_FETCH_BY_NAME_SUCCESS'
export const URETIM_SETTING_FETCH_BY_NAME_FAIL = 'URETIM_SETTING_FETCH_BY_NAME_FAIL'

export const URETIM_SETTING_V2_FETCH_BY_NAME_SUCCESS = 'URETIM_SETTING_V2_FETCH_BY_NAME_SUCCESS'

export const URETIM_SETTING_V2_FETCH_BY_ID_START = 'URETIM_SETTING_V2_FETCH_BY_ID_START'
export const URETIM_SETTING_V2_FETCH_BY_ID_SUCCESS = 'URETIM_SETTING_V2_FETCH_BY_ID_SUCCESS'
export const URETIM_SETTING_V2_FETCH_BY_ID_FAIL = 'URETIM_SETTING_V2_FETCH_BY_ID_FAIL'

export const URETIM_SETTING_V2_SEARCH_CLICK_START = 'URETIM_SETTING_V2_SEARCH_CLICK_START'
export const URETIM_SETTING_V2_SEARCH_CLICK_SUCCESS = 'URETIM_SETTING_V2_SEARCH_CLICK_SUCCESS'
export const URETIM_SETTING_V2_SEARCH_CLICK_FAIL = 'URETIM_SETTING_V2_SEARCH_CLICK_FAIL'

export const URETIM_SETTING_SEARCH_CLICK_START = 'URETIM_SETTING_SEARCH_CLICK_START'
export const URETIM_SETTING_SEARCH_CLICK_SUCCESS = 'URETIM_SETTING_SEARCH_CLICK_SUCCESS'
export const URETIM_SETTING_SEARCH_CLICK_FAIL = 'URETIM_SETTING_SEARCH_CLICK_FAIL'

export const PRODUCT_GROUP_INPUT_CHANGE = 'PRODUCT_GROUP_INPUT_CHANGE'
export const PRODUCT_GROUP_SEARCH_CLICK_START = 'PRODUCT_GROUP_SEARCH_CLICK_START'
export const PRODUCT_GROUP_SEARCH_CLICK_SUCCESS = 'PRODUCT_GROUP_SEARCH_CLICK_SUCCESS'
export const PRODUCT_GROUP_SEARCH_CLICK_FAIL = 'PRODUCT_GROUP_SEARCH_CLICK_FAIL'

export const SETTING_DOWNLOAD_REPORT = 'SETTING_DOWNLOAD_REPORT'
export const SETTING_DOWNLOAD_REPORT_SUCCESS = 'SETTING_DOWNLOAD_REPORT_SUCCESS'
export const SETTING_DOWNLOAD_REPORT_FAIL = 'SETTING_DOWNLOAD_REPORT_FAIL'

export const SETTING_SELECT_VALUE_CHANGE_IN_ARRAY = 'SETTING_SELECT_VALUE_CHANGE_IN_ARRAY'
export const SETTING_SELECT_VALUE_CHANGE = 'SETTING_SELECT_VALUE_CHANGE'
export const SETTING_PERVAZ_INPUT_CHANGE = 'SETTING_PERVAZ_INPUT_CHANGE'
export const SETTING_INPUT_CHANGE = 'SETTING_INPUT_CHANGE'
export const SETTING_STATE_CHANGE = 'SETTING_STATE_CHANGE'
export const SETTING_DATA_ROW_REMOVED = 'SETTING_DATA_ROW_REMOVED'
export const SETTING_DATA_ROW_ADDED = 'SETTING_DATA_ROW_ADDED'
export const SETTING_DATA_ROW_SAVE_START = 'SETTING_DATA_ROW_SAVE_START'
export const SETTING_DATA_ROW_SAVE_SUCCESS = 'SETTING_DATA_ROW_SAVE_SUCCESS'
export const SETTING_DATA_ROW_SAVE_FAIL = 'SETTING_DATA_ROW_SAVE_FAIL'

export const URETIM_SETTING_SELECTED = 'URETIM_SETTING_SELECTED'

export const URETIM_SETTING_V2_DELETE_START = 'URETIM_SETTING_V2_DELETE_START'
export const URETIM_SETTING_V2_DELETE_SUCCESS = 'URETIM_SETTING_V2_DELETE_SUCCESS'
export const URETIM_SETTING_V2_DELETE_FAIL = 'URETIM_SETTING_V2_DELETE_FAIL'

export const URETIM_SETTING_V2_SAVE_START = 'URETIM_SETTING_V2_SAVE_START'
export const URETIM_SETTING_V2_SAVE_SUCCESS = 'URETIM_SETTING_V2_SAVE_SUCCESS'
export const URETIM_SETTING_V2_SAVE_FAIL = 'URETIM_SETTING_V2_SAVE_FAIL'

export const TOGGLE_EDIT_MODAL = 'TOGGLE_EDIT_MODAL'

export const LOAD_KAPAK_RENK_SUCCESS = 'LOAD_KAPAK_RENK_SUCCESS'
export const LOAD_KAPAK_RENK_FAIL = 'LOAD_KAPAK_RENK_FAIL'
export const LOAD_PARCA_TIPI_SUCCESS = 'LOAD_PARCA_TIPI_SUCCESS'
export const LOAD_PARCA_TIPI_FAIL = 'LOAD_PARCA_TIPI_FAIL'

export const SEARCH_LOOKUP_RESET = 'SEARCH_LOOKUP_RESET'
export const SEARCH_LOOKUP_START = 'SEARCH_LOOKUP_START'
export const SEARCH_LOOKUP_SUCCESS = 'SEARCH_LOOKUP_SUCCESS'
export const SEARCH_LOOKUP_FAIL = 'SEARCH_LOOKUP_FAIL'
export const SEARCH_LOOKUP_SELECTION_SUCCESS = 'SEARCH_LOOKUP_SELECTION_SUCCESS'
export const SEARCH_LOOKUP_SELECTION_START = 'SEARCH_LOOKUP_SELECTION_START'

export const SETTINGS_SEARCH_LOOKUP_RESET = 'SETTINGS_SEARCH_LOOKUP_RESET'
export const SETTINGS_SEARCH_LOOKUP_START = 'SETTINGS_SEARCH_LOOKUP_START'
export const SETTINGS_SEARCH_LOOKUP_SUCCESS = 'SETTINGS_SEARCH_LOOKUP_SUCCESS'
export const SETTINGS_SEARCH_LOOKUP_FAIL = 'SETTINGS_SEARCH_LOOKUP_FAIL'
