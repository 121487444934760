import React from 'react'
import { List } from 'reactstrap'

const toastGolaMessage = ({ golaKulpModeliModulleri }) => (
  <div>
    Gola Kulp degisikliginden dolayi:
    <List type='unstyled'>
      {golaKulpModeliModulleri.map((item) => (
        <li>{item}</li>
      ))}
    </List>
  </div>
)

export default toastGolaMessage
