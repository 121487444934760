import React from 'react'

const IsActiveBadge = (props) => {
  const { value, onChange, name, dataRow } = props
  return (
    <div className='checkbox'>
      <label htmlFor={name || 'isactive'} className={value ? 'btn btn-success' : 'btn btn-danger'}>
        <span id='aktif' className={value ? '' : 'd-none'}>
          {' '}
          Aktif
        </span>
        <span id='pasif' className={value ? 'd-none' : ''}>
          Pasif
        </span>
        <input
          type='checkbox'
          checked={value}
          onChange={onChange}
          placeholder='Aktif/Pasif'
          id={name || 'isactive'}
          data-row={dataRow}
          className='badgebox'
          name={name || 'isactive'}
        />
        <span className='badge'>
          <i className='fas fa-check' />
        </span>
      </label>
    </div>
  )
}

export default IsActiveBadge
