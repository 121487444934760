import React, { useState } from 'react'
import { Col, Label, ListGroup, ListGroupItem, Button, Input } from 'reactstrap'
import { NavLink } from 'react-router-dom'
import { StringInput } from '../../Core'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../../store/actions'

const CustomerForm = (props) => {
  const dispatch = useDispatch()
  const { loading, customer } = useSelector((state) => state.customers)
  const { token } = useSelector((state) => state.auth)
  const [projectName, setProjectName] = useState('')
  const handleAddProject = () => {
    dispatch(actions.addProject(projectName))
    setProjectName('')
  }
  const onCustomerDataPropertyChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
    const { name } = event.currentTarget
    dispatch(actions.updateCustomerDataProperty({ name, value }))
  }
  const handleSave = (e) => {
    e.preventDefault()
    dispatch(actions.saveCustomer(token, customer))
  }
  return (
    <Col sm='12'>
      <h3>{customer.has_projects ? 'Muteahhit' : 'Musteri'}</h3>
      <form onSubmit={handleSave}>
        <div className='row'>
          <div className='form-group col-md-6'>
            <StringInput
              onChange={onCustomerDataPropertyChange}
              value={customer.data.isimSoyisim}
              name='data.isimSoyisim'
              label='Isim Soyisim'
            />
          </div>
        </div>
        <div className='row'>
          <div className='form-group col-md-6'>
            <Label htmlFor='data.telefon'>Telefon</Label>
            <textarea
              className='form-control'
              onChange={onCustomerDataPropertyChange}
              value={customer.data.telefon}
              name='data.telefon'
              id='data.telefon'
              rows='3'
            />
          </div>
          <div className='form-group col-md-6'>
            <Label htmlFor='data.adres'>Adres</Label>
            <textarea
              className='form-control'
              onChange={onCustomerDataPropertyChange}
              value={customer.data.adres}
              id='data.adres'
              name='data.adres'
              rows='3'
            />
          </div>
        </div>

        <input type='hidden' name='has_projects' value={customer.has_projects} />
        {customer.has_projects && (
          <div className='row'>
            <h4>Projeler:</h4>
            <div className='form-inline col-12'>
              <div className='form-group mx-sm-3 mb-2'>
                <label htmlFor='inputPassword2' className='sr-only'>
                  Password
                </label>
                <Input
                  type='text'
                  placeholder='Proje Ismi'
                  className='form-control'
                  name='projectName'
                  value={projectName}
                  onChange={(action) => setProjectName(action.target.value)}
                />
              </div>
              <Button color='primary' onClick={handleAddProject} disabled={projectName === ''}>
                Proje Ekle
              </Button>
            </div>
            <div className='form-group col-12'>
              <ListGroup>
                {customer.projects &&
                  customer.projects.map((p) => <ListGroupItem key={p.project_id}>{p.description}</ListGroupItem>)}
              </ListGroup>
            </div>
          </div>
        )}
        <nav className='navbar fixed-bottom navbar-dark bg-dark'>
          <NavLink className='btn btn-warning  m-1' to='/uretim/musteriler'>
            Vazgec
          </NavLink>
          {!loading && (
            <button type='submit' className='btn btn-primary float-end m-1'>
              <i className='fas fa-save' />
              Kaydet
            </button>
          )}
        </nav>
      </form>
    </Col>
  )
}

export default CustomerForm
