import cloneDeep from 'lodash/cloneDeep'
import { initKitchenModulModalData, initModulModal } from '../../dataModels/ModulModal'
import { initLookup } from '../../dataModels/LookupModals'
import shortid from 'shortid'
import { toast } from 'react-toastify'
import { toastWarnOptions, toastOptions } from '../../../shared'
import clone from 'lodash/clone'
import set from 'lodash/set'
import toastGolaMessage from '../../../components/Common/toastGolaMessage'
import {
  handleGolaKulpModeliParcaUpdate,
  golaDegisikligindeBDModullerdeYanTablaParcalariOrijinalHalineGetir,
  parcaTipiHerhangiBirYanTabla,
  golaTipiGolaSagYadaGolaSolSecildi,
  golaTipiGolaSagSolSecildi
} from './common'
import get from 'lodash/get'
import {kitchenFormFields} from "../../../shared/formFields";

export const removeModulKitchenOrder = (state, action) => {
  const modullerCloned = cloneDeep(state.kitchenForm.moduller)
  modullerCloned.splice(action.payload.index, 1)
  const moduller = modullerCloned.map((m, index) => ({ ...m, siranumarasi: index + 1 }))
  return { ...state, kitchenForm: { ...state.kitchenForm, moduller } }
}
export const toggleKitchenModulModal = (state, action) => {
  switch (action.payload.action) {
    case 'cancel':
      return { ...state, ...{ modulModal: initModulModal('kitchen'), lookup: initLookup() } }
    case 'clone':
      const cloneModul = cloneDeep(state.kitchenForm.moduller[action.payload.index])
      cloneModul.siranumarasi = Math.max(...state.kitchenForm.moduller.map((m) => Number(m.siranumarasi))) + 1
      cloneModul.id = shortid.generate()
      toast.success(`Modul kopyalandi!`, toastOptions)
      return {
        ...state,
        ...{
          modulModal: initModulModal('kitchen'),
          lookup: initLookup(),
          kitchenForm: { ...state.kitchenForm, moduller: [...state.kitchenForm.moduller, cloneModul] }
        }
      }
    case 'save':
      const newModuller = cloneDeep(state.kitchenForm.moduller)
      if (state.modulModal.index === -1) {
        newModuller.push(state.modulModal.data)
      } else {
        newModuller[state.modulModal.index] = state.modulModal.data
      }
      newModuller
        .sort((a, b) => (Number(a.siranumarasi) > Number(b.siranumarasi) ? 1 : -1))
        .forEach((m, index) => {
          if (Number(m.siranumarasi) !== index + 1) {
            m.siranumarasi = index + 1
          }
        })
      return {
        ...state,
        ...{
          modulModal: initModulModal('kitchen'),
          lookup: initLookup(),
          kitchenForm: { ...state.kitchenForm, moduller: newModuller }
        }
      }
    case 'new':
      const siranumarasi =
        state.kitchenForm.moduller.length > 0
          ? parseInt(Math.max(...state.kitchenForm.moduller.map((m) => Number(m.siranumarasi))) + 1)
          : 1
      return {
        ...state,
        ...{
          modulModal: {
            ...state.modulModal,
            show: !state.modulModal.show,
            data: {
              ...initKitchenModulModalData(),
              siranumarasi,
              govdemalzemesi: clone(state.kitchenForm.govdemalzemesi),
              govderengi: clone(state.kitchenForm.govderengi),
              kapakdesenyonu: clone(state.kitchenForm.kapakdesenyonu),
              kapakmalzemesi: clone(state.kitchenForm.kapakmalzemesi),
              kapakmodeli: clone(state.kitchenForm.kapakmodeli),
              kapakrenk: clone(state.kitchenForm.kapakrenk),
              kulpmodeli: clone(state.kitchenForm.kulpmodeli)
            }
          }
        }
      }
    default:
      return {
        ...state,
        ...{
          modulModal: {
            show: !state.modulModal.show,
            data: state.kitchenForm.moduller[action.payload.index],
            index: action.payload.index
          }
        }
      }
  }
}
export const updateModulModalDataProperty = (state, action) => {
  const { value, property } = action.payload
  const modulItem = cloneDeep(state.modulModal.data)
  const currentKulpModeli = get(modulItem, 'kulpmodeli')
  const previousGolatip = get(modulItem, 'golatip')

  set(modulItem, property, value)
  const golaKulpModeliModulleri = []
  if (property === 'golaParcaUzunlugu' || property === 'golaAdet' || property === 'golaTekliCiftli') {
    const clonedAksesuarlar = modulItem.modul.aksesuarlar.filter(aks=>!aks.aciklama.includes('DIKEY GOLA PROFILI'))
    if(modulItem.golaTekliCiftli && modulItem.golaAdet){
      const golaParcaUzunlugu = modulItem.golaParcaUzunlugu || 204
      set(modulItem, 'modul.aksesuarlar', [...clonedAksesuarlar, {
        aciklama:`${modulItem.golaTekliCiftli} DIKEY GOLA PROFILI`,
        tip: 'Montaj',
        fiyat: 0,
        not: `${golaParcaUzunlugu} cm`,
        grup:{
          aciklama: 'Aksesuarlar',
          id: 456,
          isactive: true
        },
        id: Date.now(),
        miktar:Number(modulItem.golaAdet)
      }])
      toast.warn(`Aksesuar ${modulItem.golaTekliCiftli} DIKEY GOLA PROFILI eklendi`, toastWarnOptions)
    }


  }
  else if (property === kitchenFormFields.metalCerceveliKapak) {
    modulItem.kulpYonu = null
    modulItem.camProfili = null
    modulItem.camModeli = null
    modulItem.metalCerceveliKapakAdet = 0
    const clonedAksamlar = modulItem.modul.aksamlar.filter(aks=>aks.kaynak !== kitchenFormFields.metalCerceveliKapak)
    const clonedAksesuarlar = modulItem.modul.aksesuarlar.filter(aks=> aks.kaynak !== kitchenFormFields.metalCerceveliKapak)
    set(modulItem, 'modul.aksamlar', clonedAksamlar)
    set(modulItem, 'modul.aksesuarlar', clonedAksesuarlar)
  }
  else if (property === kitchenFormFields.kulpYonu || property === kitchenFormFields.camProfili || property === kitchenFormFields.camModeli || property === kitchenFormFields.metalCerceveliKapakAdet) {
    if(modulItem.kulpYonu && modulItem.camProfili && modulItem.camModeli && modulItem.metalCerceveliKapakAdet > 0){
      const clonedAksesuarlar = modulItem.modul.aksesuarlar.filter(aks=> aks.kaynak !== kitchenFormFields.metalCerceveliKapak)
      const clonedAksamlar = modulItem.modul.aksamlar.filter(aks=> aks.kaynak !== kitchenFormFields.metalCerceveliKapak)
      const kapakParcalar = modulItem.modul.parcalar.filter(parca => parca.tip.grup ==='Kapaklar')

      const metalCerceveAksamlar = kapakParcalar.map(kapakParca => {
        let aciklama = ''
        let miktar = 0
        if(modulItem.kulpYonu.id === 'kulpsuz')
        {
          miktar = (2*Number(kapakParca.genislik) + 2*Number(kapakParca.uzunluk))*Number(modulItem.adet)*Number(modulItem.metalCerceveliKapakAdet)
          aciklama = `${modulItem.camProfili.aciklama} Kulpsuz`
        }
        else if (modulItem.kulpYonu.id === 'yatay'){
          miktar = (Number(kapakParca.genislik) + 2*Number(kapakParca.uzunluk))*Number(modulItem.adet)*Number(modulItem.metalCerceveliKapakAdet)
          aciklama = `${modulItem.camProfili.aciklama} Kulplu`
        }
        else if (modulItem.kulpYonu.id === 'dikey'){
          miktar = (2*Number(kapakParca.genislik) + Number(kapakParca.uzunluk))*Number(modulItem.adet)*Number(modulItem.metalCerceveliKapakAdet)
          aciklama = `${modulItem.camProfili.aciklama} Kulplu`
        }
        return {
          kaynak:kitchenFormFields.metalCerceveliKapak,
          aciklama,
          tip: 'Imalat',
          fiyat: 0,
          grup:{
            description: "Standart Aksam",
            id: 454,
            isactive: true
          },
          id: `${modulItem.id}-${Date.now()}`,
          miktar // TODO: ask about this
        }
      })
      set(modulItem, 'modul.aksamlar', [...clonedAksamlar, ...metalCerceveAksamlar])

      const metalCerceveAksesuarlar = kapakParcalar.map(kapakParca => {
        const kapakGenislik = Number(kapakParca.genislik)
        const kapakUzunluk = Number(kapakParca.uzunluk)
        const aciklama = `${modulItem.camModeli.aciklama} ${kapakGenislik - Number(modulItem.camProfili.genislikPayi)} ${kapakUzunluk - Number(modulItem.camProfili.uzunlukPayi)}`
        return {
          kaynak:kitchenFormFields.metalCerceveliKapak,
          aciklama,
          tip: 'Montaj',
          fiyat: 0,
          not: '',
          grup:{
            aciklama: 'Aksesuarlar',
            id: 456,
            isactive: true
          },
          id: Date.now(),
          miktar:Number(modulItem.metalCerceveliKapakAdet)
        }
      })
      const camKapakFitiliAksesuarlar = metalCerceveAksesuarlar.map(aks => ({...aks, aciklama: aks.aciklama.replace(modulItem.camModeli.aciklama, 'Cam Kapak Fitili')}))
      set(modulItem, 'modul.aksesuarlar', [...clonedAksesuarlar,...metalCerceveAksesuarlar,...camKapakFitiliAksesuarlar ])

      toast.warn(`Aksamlara ve Aksesuarlara degisiklik yapildi`, toastWarnOptions)
    }
  } else if (property === 'kapakrenk') {
    modulItem.kapakmalzemesi = value
  } else if (property === 'kapakmodeli') {
    modulItem.kapakrenk = null
    modulItem.kapakmalzemesi = null
  }
  else if (property === 'golatip') {
    if (previousGolatip) {
      if (golaTipiGolaSagSolSecildi(previousGolatip) && golaTipiGolaSagYadaGolaSolSecildi(value)) {
        modulItem.modul.parcalar.forEach((parca, index) => {
          if (parcaTipiHerhangiBirYanTabla(parca)) {
            set(modulItem, `modul.parcalar[${index}].genislik`, Number(parca.genislik) + 12)
          }
        })
      } else if (golaTipiGolaSagYadaGolaSolSecildi(previousGolatip) && golaTipiGolaSagSolSecildi(value)) {
        const updatedParcalar = golaDegisikligindeBDModullerdeYanTablaParcalariOrijinalHalineGetir(modulItem)
        modulItem.modul.parcalar = [...updatedParcalar]
      } else if (previousGolatip !== value) {
        modulItem.modul.parcalar.forEach((parca, index) => {
          if (parca.parcaYon) {
            set(modulItem, `modul.parcalar[${index}].parcaYon`, value.replace('gola', ''))
          }
        })
      }
    }
    handleGolaKulpModeliParcaUpdate({
      newKulpModeli: currentKulpModeli,
      currentKulpModeli: null,
      modulItem,
      golaKulpModeliModulleri
    })
  }
  else if (property === 'modul') {
    handleGolaKulpModeliParcaUpdate({
      newKulpModeli: currentKulpModeli,
      currentKulpModeli: null,
      modulItem,
      golaKulpModeliModulleri
    })
  }
  else if (property === 'kulpmodeli') {
    handleGolaKulpModeliParcaUpdate({
      newKulpModeli: value,
      currentKulpModeli,
      modulItem,
      golaKulpModeliModulleri
    })
  }
  if (golaKulpModeliModulleri.length > 0) {
    toast.warn(toastGolaMessage({ golaKulpModeliModulleri }), toastWarnOptions)
  }
  return {
    ...state,
    modulModal: {
      ...state.modulModal,
      data: modulItem
    }
  }
}
