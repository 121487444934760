import React from 'react'
import Select from '../../Core/Select'
import { Button, CardHeader, Card, CardBody, Input, Label, Alert } from 'reactstrap'
import { Modal } from '../../Uretim/Orders/Kitchen'
import NumberInput from '../../Core/NumberInput'

const ModulParcaForm = (props) => {
  const whenPopulated = () => {
    return (
      props.parcaModal.new.parca &&
      Number(props.parcaModal.new.uzunluk) > 0 &&
      Number(props.parcaModal.new.genislik) > 0 &&
      Number(props.parcaModal.new.miktar) > 0 &&
      Number(props.parcaModal.new.boyut) > 0
    )
  }
  const handleChange = (action) => {
    action.preventDefault()
    action.stopPropagation()
    props.newOnChange(action.target.name, action.target.value)
  }
  const handleSave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    props.save()
  }
  return (
    <Modal modal={props.parcaModal.show} index={props.parcaModal.index} size='xl' title='Parca Ekle/Degistir'>
      <div className='row'>
        <div className='form-group col-sm-2'>
          <NumberInput
            step='1'
            label='Miktar'
            name='miktar'
            value={props.parcaModal.new.miktar}
            onChange={handleChange}
          />
        </div>
        <div className='form-group col-sm-2'>
          <NumberInput step='1' label='Boyut' name='boyut' value={props.parcaModal.new.boyut} onChange={handleChange} />
        </div>
        <div className='form-group col-sm-2'>
          <NumberInput
            step='1'
            label='Uzunluk'
            name='uzunluk'
            value={props.parcaModal.new.uzunluk}
            onChange={handleChange}
          />
        </div>
        <div className='form-group col-sm-2'>
          <NumberInput
            step='1'
            label='Genislik'
            name='genislik'
            value={props.parcaModal.new.genislik}
            onChange={handleChange}
          />
        </div>
        <div className='form-group col-sm-4'>
          <Select
            name='parca'
            value={props.parcaModal.new.parca}
            onChange={props.newOnChange}
            text='Parca'
            options={props.lookup.result}
            onInputChange={(e) => props.lookupSetting(e, 'parca')}
            onSelectMenuClose={props.onSelectMenuClose}
            isLoading={props.lookup.isLoading}
          />
        </div>
        <div className='form-group col-sm-1'>
          {whenPopulated() && (
            <Button color='success' onClick={props.onAdd}>
              Ekle
            </Button>
          )}
        </div>
      </div>
      <h5>Mevcut Parcalar:</h5>
      {props.enableEditParcaBandlar && (
        <Alert color='secondary'>
          <div className='form-check form-switch'>
            <Input
              className='form-check-input'
              type='checkbox'
              role='switch'
              id='toggleParcaBandlar'
              onChange={props.onToggleBandlar}
              value='true'
              checked={props.parcaModal.showBand ? 'checked' : ''}
            />
            <Label className='form-check-label' htmlFor='toggleParcaBandlar'>
              Bantlari goster
            </Label>
          </div>
        </Alert>
      )}
      <form onSubmit={handleSave}>
        {props.parcaModal.data.map((item, index) => {
          return (
            <Card key={`${item.id}_${index}`}>
              <CardHeader>
                {item.aciklama}
                <small className='text-muted'>
                  ({item.tip.aciklama} - {item.tip.grup})
                </small>
                <Button
                  className='float-end'
                  color='danger'
                  id={`delete-${index}`}
                  onClick={() => props.onRemove({ action: 'delete', index })}
                >
                  Sil
                </Button>
              </CardHeader>
              <CardBody>
                <div className='row'>
                  <div className='form-group col-sm-2'>
                    <NumberInput
                      step='1'
                      label='Miktar'
                      name={`miktar`}
                      value={item.miktar}
                      onChange={(event) => props.onChange(index)(event.target.name, event.target.value)}
                    />
                  </div>
                  <div className='form-group col-sm-2'>
                    <NumberInput
                      step='1'
                      label='Boyut'
                      name={`boyut`}
                      value={item.boyut}
                      onChange={(event) => props.onChange(index)(event.target.name, event.target.value)}
                    />
                  </div>
                  <div className='form-group col-sm-2'>
                    <NumberInput
                      step='1'
                      label='Uzunluk'
                      name={`uzunluk`}
                      value={item.uzunluk}
                      onChange={(event) => props.onChange(index)(event.target.name, event.target.value)}
                    />
                  </div>
                  <div className='form-group col-sm-2'>
                    <NumberInput
                      step='1'
                      label='Genislik'
                      name={`genislik`}
                      value={item.genislik}
                      dataRow={index}
                      onChange={(event) => props.onChange(index)(event.target.name, event.target.value)}
                    />
                  </div>
                  {props.showRenk && (<><div className='form-group col-sm-3'>
                    <Select
                      name='renk'
                      value={item.renk || null}
                      onChange={(name, value) => props.onChange(index)(name, value)}
                      text='Parca Rengi'
                      defaultValue={props.renk}
                      options={props.lookup.result}
                      onInputChange={(e) => props.lookupSetting(e, 'govdeRengi,kapakRenk')}
                      onSelectMenuClose={props.onSelectMenuClose}
                      isLoading={props.lookup.isLoading}
                    />
                  </div>
                    <div className='form-group col-sm-2'>
                      <Select
                        name='kapakVeyaGovdeMalzemesi'
                        value={item.kapakVeyaGovdeMalzemesi || null}
                        onChange={(name, value) => props.onChange(index)(name, value)}
                        text='Kapak Model veya Govde Malzemesi'
                        defaultValue={props.kapakVeyaGovdeMalzemesi}
                        options={props.lookup.result}
                        onInputChange={(e) => props.lookupSetting(e, 'kapakModel,govdeMalzemesi')}
                        onSelectMenuClose={props.onSelectMenuClose}
                        isLoading={props.lookup.isLoading}
                      />
                    </div>
                  </>)}
                </div>

                {props.parcaModal.showBand && props.enableEditParcaBandlar && (
                  <div className='row'>
                    {item.tip.bandTipi ? (
                      <>
                        <div className='col-sm-3'>
                          <Select
                            name={`tip.bandTipi.uzunUst`}
                            value={item.tip.bandTipi.uzunUst}
                            onChange={(name, value) => props.onChange(index)(name, value)}
                            text='Band Tipi Uzun'
                            options={props.lookup.result}
                            onInputChange={(e) => props.lookupSetting(e, 'bandTipi')}
                            onSelectMenuClose={props.onSelectMenuClose}
                            disableRequired={true}
                            isLoading={props.lookup.isLoading}
                          />
                        </div>
                        <div className='col-sm-3'>
                          <Select
                            name={`tip.bandTipi.uzunAlt`}
                            value={item.tip.bandTipi.uzunAlt}
                            onChange={(name, value) => props.onChange(index)(name, value)}
                            text='Band Tipi Uzun'
                            options={props.lookup.result}
                            onInputChange={(e) => props.lookupSetting(e, 'bandTipi')}
                            onSelectMenuClose={props.onSelectMenuClose}
                            disableRequired={true}
                            isLoading={props.lookup.isLoading}
                          />
                        </div>
                        <div className='col-sm-3'>
                          <Select
                            name={`tip.bandTipi.kisaSag`}
                            value={item.tip.bandTipi.kisaSag}
                            onChange={(name, value) => props.onChange(index)(name, value)}
                            text='Band Tipi Kisa'
                            options={props.lookup.result}
                            onInputChange={(e) => props.lookupSetting(e, 'bandTipi')}
                            onSelectMenuClose={props.onSelectMenuClose}
                            disableRequired={true}
                            isLoading={props.lookup.isLoading}
                          />
                        </div>
                        <div className='col-sm-3'>
                          <Select
                            name={`tip.bandTipi.kisaSol`}
                            value={item.tip.bandTipi.kisaSol}
                            onChange={(name, value) => props.onChange(index)(name, value)}
                            text='Band Tipi Kisa'
                            options={props.lookup.result}
                            onInputChange={(e) => props.lookupSetting(e, 'bandTipi')}
                            onSelectMenuClose={props.onSelectMenuClose}
                            disableRequired={true}
                            isLoading={props.lookup.isLoading}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <Alert color='danger'>
                          Parca bandlari mevcut degil. Eger bandlari olmasi gerekiyorsa, bu parcayi silip tekrar
                          ekleyin!
                        </Alert>
                      </>
                    )}
                  </div>
                )}
              </CardBody>
            </Card>
          )
        })}
        <Button color='secondary' className='float-start' onClick={props.cancel}>
          Vazgec
        </Button>
        <button type='submit' className='btn btn-primary'>
          <i className='fas fa-save' />
          Kaydet
        </button>
      </form>
    </Modal>
  )
}

export default ModulParcaForm
