import React from 'react'
import Select from '../../../Core/Select'
import { Card, CardBody, CardHeader, Label, Input } from 'reactstrap'
const DolapSiparisFields = (props) => {
  return (
    <Card className='shadow mb-2'>
      <CardHeader>Siparis Ozellikleri</CardHeader>
      <CardBody>
        <div className='form-group row'>
          {props.tip === 'acilan' && (
            <div className='form-group col-md-6 col-lg-4'>
              <Select
                name='kulpmodeli'
                value={props.kulpmodeli}
                onChange={props.updateSiparisOzellikleri}
                text='Kulp Modeli'
                options={props.lookup.result}
                onInputChange={(e) => props.lookupSetting(e, 'kulpModeli')}
                onSelectMenuClose={props.onSelectMenuClose}
                isLoading={props.lookup.isLoading}
              />
            </div>
          )}
          <div className='form-group col-md-6 col-lg-4'>
            <Select
              name='kapakmodeli'
              onChange={props.updateSiparisOzellikleri}
              value={props.kapakmodeli}
              text='Kapak Modeli'
              options={props.lookup.result}
              onInputChange={(e) => props.lookupSetting(e, 'kapakModel')}
              onSelectMenuClose={props.onSelectMenuClose}
              isLoading={props.lookup.isLoading}
            />
          </div>
          <div className='form-group col-md-6 col-lg-4'>
            <Select
              name='kapakrenk'
              onChange={props.updateSiparisOzellikleri}
              value={props.kapakrenk}
              text='Kapak Renk'
              options={props.kapakmodeli && props.kapakmodeli.kapakRenk ? props.kapakmodeli.kapakRenk : []}
            />
          </div>
          <div className='form-group col-md-6 col-lg-4'>
            <Select
              name='kapakmalzemesi'
              value={props.kapakmalzemesi}
              onChange={props.updateSiparisOzellikleri}
              text='Kapak Malzemesi'
              options={props.kapakmodeli && props.kapakmodeli.kapakRenk ? props.kapakmodeli.kapakRenk : []}
            />
          </div>
          <div className='form-group col-md-6 col-lg-4'>
            <Select
              name='kapakdesenyonu'
              label='Kapak Desen Yonu'
              value={props.kapakdesenyonu}
              onChange={props.updateSelect}
              text='Kapak Desen Yonu'
              options={props.lookup.result}
              onInputChange={(e) => props.lookupSetting(e, 'kapakDesenYonu')}
              onSelectMenuClose={props.onSelectMenuClose}
              isLoading={props.lookup.isLoading}
            />
          </div>
          <div className='form-group col-md-6 col-lg-4'>
            <Select
              name='govderengi'
              onChange={props.updateSelect}
              value={props.govderengi}
              text='Govde Rengi'
              options={props.lookup.result}
              onInputChange={(e) => props.lookupSetting(e, 'govdeRengi')}
              onSelectMenuClose={props.onSelectMenuClose}
              isLoading={props.lookup.isLoading}
            />
          </div>
          <div className='form-group col-md-6 col-lg-4'>
            <Select
              name='govdemalzemesi'
              value={props.govdemalzemesi}
              onChange={props.updateSelect}
              text='Govde Malzemesi'
              options={props.lookup.result}
              onInputChange={(e) => props.lookupSetting(e, 'govdeMalzemesi')}
              onSelectMenuClose={props.onSelectMenuClose}
              isLoading={props.lookup.isLoading}
            />
          </div>
          <div className='form-group col-md-6 col-lg-4'>
            <Select
              name='cekmecesistemi'
              label='Cekmece Sistemi'
              value={props.cekmecesistemi}
              onChange={props.updateSelect}
              text='Cekmece Sistemi'
              options={props.lookup.result}
              onInputChange={(e) => props.lookupSetting(e, 'cekmeceSistemi')}
              onSelectMenuClose={props.onSelectMenuClose}
              isLoading={props.lookup.isLoading}
            />
          </div>

          <div className='form-group col-md-6 col-lg-4'>
            <Select
              name='pervazrenk'
              onChange={props.updateSelect}
              value={props.pervazrenk}
              text='Pervaz Renk'
              options={props.kapakmodeli && props.kapakmodeli.kapakRenk ? props.kapakmodeli.kapakRenk : []}
            />
          </div>
        </div>
        {props.tip === 'surme' && (
          <div className='form-group row'>
            <div className='form-group col-md-6 col-lg-4'>
              <Select
                name='surmeKulpVeMekanizma'
                value={props.surmeKulpVeMekanizma}
                onChange={props.updateSelect}
                text='Surme Kulp Ve Mekanizma'
                options={props.lookup.result}
                onInputChange={(e) => props.lookupSetting(e, 'surmeKulpVeMekanizma')}
                onSelectMenuClose={props.onSelectMenuClose}
                isLoading={props.lookup.isLoading}
              />
            </div>
            <div className='form-group col-md-6 col-lg-3'>
              <Label htmlFor='mekanizma'>Mekanizma</Label>
              <Input
                name='mekanizma'
                disabled
                value={props.surmeKulpVeMekanizma ? props.surmeKulpVeMekanizma.mekanizma : ''}
              />
            </div>
            <div className='form-group col-md-6 col-lg-3'>
              <Label htmlFor='surmeKulp'>Surme Kulp</Label>
              <Input
                name='surmeKulp'
                disabled
                value={props.surmeKulpVeMekanizma ? props.surmeKulpVeMekanizma.surmeKulp : ''}
              />
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  )
}
export default DolapSiparisFields
