import React, { useEffect } from 'react'
import * as actions from '../../../store/actions'
import { useDispatch, useSelector } from 'react-redux'
import WeeklyForm from './WeeklyForm'
import { Alert, Col, Row } from 'reactstrap'
import Loading from '../../Common/Loading'

function WeeklyEditContainer(props) {
  const dispatch = useDispatch()
  const { groupForm } = useSelector((state) => state.groups)
  const { token } = useSelector((state) => state.auth)

  useEffect(() => {
    props.match.params.id
      ? dispatch(actions.fetchOrderGroup(token, props.match.params.id))
      : dispatch(actions.newOrderGroup())
  }, [props.match.params.id, token, dispatch])

  return (
    <Row>
      {groupForm.error && (
        <Col sm={12}>
          <Alert color='danger'>Haftalik yuklerken hata oluştu — {groupForm.error}</Alert>
        </Col>
      )}
      <Col sm='12'>
        <h3>Haftalik</h3>
        {groupForm.loading ? <Loading /> : <WeeklyForm />}
      </Col>
    </Row>
  )
}

export default WeeklyEditContainer
