import React from 'react'
import { Button, Card, CardBody, CardFooter, CardHeader } from 'reactstrap'
import { ModulAksamForm, ModulAksesuarForm, ModulParcaForm } from '../../Kitchen'
import Dekorasyon from '../../../../Common/Dekorasyon'
import MusteriBilgiFormu from '../../../../Common/MusteriBilgiFormu'
import WardrobeModulForm from '../WardrobeModulForm'
import Modal from '../../../../Common/Modal'
import ModulList from '../../../../Common/Modul/ModulList'
import DolapOlculer from '../DolapOlculer'
import DolapTipi from '../DolapTipi'
import DolapSiparisFields from '../DolapSiparisFields'
import WardrobeProperties from '../WardrobeProperties'
import UretimSiparisFormFooter from '../../../../Common/UretimSiparisFormFooter'

const AcilanForm = ({
  onModulParcaClick,
  onModulAksesuarClick,
  onModulAksamClick,
  aksamModalOnChange,
  parcaModalOnChange,
  aksesuarModalOnChange,
  updateWardrobeState,
  onDownloadAcilanReport,
  updateDekorasyonState,
  onPervazInputChange,
  onSearchMusteri,
  onSearchMuteahhit,
  onLookupSetting,
  onSelectSetting,
  onSelectMenuClose,
  updateSelect,
  updateSiparisOzellikleri,
  onDekorasyonAdd,
  onDekorasyonRemove,
  onSaveOrder,
  wardrobeForm,
  lookup,
  addNewModulModal,
  modulModal,
  parcaModal,
  parcaModalNewOnChange,
  onModulModalClick,
  onModulRemoveClick,
  aksesuarModalNewOnChange,
  aksesuarModal,
  aksamModal,
  aksamModalNewOnChange,
  downloading,
  saving,
  cancelModulModal,
  saveModulModal,
  onModulModalStateChange
}) => {
  const handleCancelParca = (e) => {
    onModulParcaClick({ action: 'cancel' })
  }
  const handleAksamModalOnChange = (event) => {
    aksamModalOnChange({ name: event.target.name, value: event.target.value, row: event.target.dataset.row })
  }
  const handleParcaModalOnChange = (index) => (name, value) => {
    parcaModalOnChange({ name, value, index: Number(index) })
  }
  const handleAksesuarModalOnChange = (event) => {
    aksesuarModalOnChange({ name: event.target.name, value: event.target.value, row: event.target.dataset.row })
  }
  const handleUpdateWardrobeState = (event) => {
    const field = event.target.name
    const value = event.target.value
    updateWardrobeState({ field, value })
  }
  const handleParcaAction = (action) => (e) => {
    onModulParcaClick({ action })
  }
  const handleAksesuarAction = (action) => (e) => {
    onModulAksesuarClick({ action })
  }
  const handleAksamAction = (action) => (e) => {
    onModulAksamClick({ action })
  }
  const handleDownload = () => {
    onDownloadAcilanReport(
      `${wardrobeForm.musteri.isimsoyisim}-acilan-${wardrobeForm.konutno}-${wardrobeForm.dolapTakilacakOda}`,
      wardrobeForm.id
    )
  }
  const handleSelectChangeDekorasyonState = (nameAndId, selected, event) => {
    const [name, id] = nameAndId.split('.')
    updateDekorasyonState(id, name, selected)
  }
  const handleInputChangeDekorasyonState = (event) => {
    const [name, id] = event.currentTarget.name.split('.')
    const value = event.currentTarget.value
    updateDekorasyonState(id, name, value)
  }
  const handlePervazInputChange = (event, pervazName) => {
    const property = event.target.name
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
    onPervazInputChange(pervazName, property, value)
  }
  const handleSearchMusteri = (e) => {
    if (e !== '') {
      onSearchMusteri(e)
    }
  }
  const handleSearchMuteahhit = (e) => {
    if (e !== '') {
      onSearchMuteahhit(e)
    }
  }
  const handleLookupSetting = (e, name) => {
    if (e !== '') {
      onLookupSetting(name, e)
    }
  }
  const handleSelectSetting = (e, name) => {
    if (e !== '') {
      onSelectSetting(name, e)
    }
  }
  const handleSave = (e) => {
    e.preventDefault()
    onSaveOrder(wardrobeForm)
  }
  return (
    <form onSubmit={handleSave}>
      <MusteriBilgiFormu
        searchMusteri={handleSearchMusteri}
        searchMuteahhit={handleSearchMuteahhit}
        lookup={lookup}
        onSelectMenuClose={onSelectMenuClose}
        musteri={wardrobeForm.musteri}
        muteahhit={wardrobeForm.muteahhit}
        teslimtarihi={wardrobeForm.teslimtarihi}
        sicilno={wardrobeForm.sicilno}
        konutno={wardrobeForm.konutno}
        onSelectChange={updateSelect}
        onChange={handleUpdateWardrobeState}
      />
      <hr />
      <WardrobeProperties
        onChange={handleUpdateWardrobeState}
        dolapAdet={wardrobeForm.dolapAdet}
        dolapTakilacakOda={wardrobeForm.dolapTakilacakOda}
      />
      <DolapSiparisFields
        tip='acilan'
        kulpmodeli={wardrobeForm.kulpmodeli}
        cekmecesistemi={wardrobeForm.cekmecesistemi}
        kapakmalzemesi={wardrobeForm.kapakmalzemesi}
        kapakdesenyonu={wardrobeForm.kapakdesenyonu}
        govdemalzemesi={wardrobeForm.govdemalzemesi}
        pervazrenk={wardrobeForm.pervazrenk}
        kapakrenk={wardrobeForm.kapakrenk}
        kapakmodeli={wardrobeForm.kapakmodeli}
        govderengi={wardrobeForm.govderengi}
        updateSelect={updateSelect}
        updateSiparisOzellikleri={updateSiparisOzellikleri}
        lookupSetting={handleLookupSetting}
        lookup={lookup}
        onSelectMenuClose={onSelectMenuClose}
      />
      <DolapOlculer
        tip='acilan'
        boslukOlcusu={wardrobeForm.boslukOlcusu}
        yapilacakOlan={wardrobeForm.yapilacakOlan}
        updateWardrobeState={handleUpdateWardrobeState}
      />
      <DolapTipi
        tipi={wardrobeForm.tipi}
        handlePervazInputChange={handlePervazInputChange}
        updateSelect={updateSelect}
        lookupSetting={handleLookupSetting}
        lookup={lookup}
        onSelectMenuClose={onSelectMenuClose}
      />
      <Dekorasyon
        lookupSetting={handleLookupSetting}
        onSelectMenuClose={onSelectMenuClose}
        lookup={lookup}
        dekorasyonlar={wardrobeForm.dekorasyonlar}
        onSelectChange={handleSelectChangeDekorasyonState}
        onInputChange={handleInputChangeDekorasyonState}
        onDekorasyonAddClick={onDekorasyonAdd}
        onDekorasyonRemoveClick={onDekorasyonRemove}
      />

      <Card className='shadow mb-2'>
        <CardHeader>Ozel istekler</CardHeader>
        <CardBody>
          <textarea
            className='form-control'
            value={wardrobeForm.ozelistekler}
            onChange={handleUpdateWardrobeState}
            name='ozelistekler'
            rows='3'
          />
        </CardBody>
      </Card>

      <Card className='shadow mb-2'>
        <CardHeader>
          Moduller
          <Button className='float-end' outline color='primary' onClick={addNewModulModal}>
            <i className='fas fa-plus' />
            Modul Ekle
          </Button>
        </CardHeader>
        <CardBody>
          <div className='form-group row'>
            <div className='col-12'>
              <Modal modal={modulModal.show} index={modulModal.index} size='xl' cancel={cancelModulModal}>
                <WardrobeModulForm
                  tipi='acilan'
                  onChange={onModulModalStateChange}
                  wardrobeOrder={wardrobeForm}
                  cancel={cancelModulModal}
                  save={saveModulModal}
                  modulModal={modulModal}
                  lookupSetting={handleLookupSetting}
                  selectSetting={handleSelectSetting}
                  lookup={lookup}
                  onSelectMenuClose={onSelectMenuClose}
                />
              </Modal>

              <ModulParcaForm
                parcaModal={parcaModal}
                onChange={handleParcaModalOnChange}
                onRemove={onModulParcaClick}
                onAdd={handleParcaAction('add')}
                onToggleBandlar={handleParcaAction('toggleBandlar')}
                save={handleParcaAction('save')}
                cancel={handleCancelParca}
                lookupSetting={handleLookupSetting}
                lookup={lookup}
                onSelectMenuClose={onSelectMenuClose}
                newOnChange={parcaModalNewOnChange}
                enableEditParcaBandlar={true}
              />
              <ModulAksamForm
                aksamModal={aksamModal}
                onChange={handleAksamModalOnChange}
                onRemove={onModulAksamClick}
                onAdd={handleAksamAction('add')}
                save={handleAksamAction('save')}
                cancel={handleAksamAction('cancel')}
                lookupSetting={handleLookupSetting}
                lookup={lookup}
                onSelectMenuClose={onSelectMenuClose}
                newOnChange={aksamModalNewOnChange}
              />
              <ModulAksesuarForm
                aksesuarModal={aksesuarModal}
                onChange={handleAksesuarModalOnChange}
                onRemove={onModulAksesuarClick}
                onAdd={handleAksesuarAction('add')}
                save={handleAksesuarAction('save')}
                cancel={handleAksesuarAction('cancel')}
                lookupSetting={handleLookupSetting}
                lookup={lookup}
                onSelectMenuClose={onSelectMenuClose}
                newOnChange={aksesuarModalNewOnChange}
              />
              <ModulList
                moduller={wardrobeForm.moduller}
                toggle={wardrobeForm.toggle}
                onModulRemoveClick={onModulRemoveClick}
                onModulEditClick={onModulModalClick}
                onModulAksamClick={onModulAksamClick}
                onModulParcaClick={onModulParcaClick}
                onModulAksesuarClick={onModulAksesuarClick}
                type='acilan-wardrobe'
              />
            </div>
          </div>
        </CardBody>
        <CardFooter>
          <Button className='float-end' outline color='primary' onClick={addNewModulModal}>
            <i className='fas fa-plus' />
            Modul Ekle
          </Button>
        </CardFooter>
      </Card>
      <UretimSiparisFormFooter
        downloading={downloading}
        saving={saving}
        handleDownload={handleDownload}
        id={wardrobeForm.id}
      />
    </form>
  )
}

export default AcilanForm
