import shortid from "shortid";

export const initDekorasyon = () => ({
  id:shortid.generate(),
  dekorasyon:null,
  kapakRenk:null,
  adet:0,
  en:0,
  boy:0,
  kalinlik:0,
  not:''
})
