import React, { useEffect, useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import AsyncSelect from 'react-select/async'
import {NavLink} from "react-router-dom"
import set from 'lodash/set'
import cloneDeep from 'lodash/cloneDeep'
import { toast } from 'react-toastify'
import {Button, CardHeader, Card, CardBody, Label} from "reactstrap"
import {instance as axios} from "../../../../axios-api"
import {
  apiPath,
  toastOptions,
  toastErrorOptions
} from "../../../../shared";
import Loading from "../../../Common/Loading";
import NumberInput from "../../../Core/NumberInput";
import ModulNavigation from "./ModulNavigation";

const initialNewParca = {parca: {}, miktar: 0}
const ModulParcalarForm = (props) => {
  const { token } = useSelector((state) => state.auth)
  const [state, setState] = useState({error:null, new: { ...initialNewParca }, loading: false, modul: {}})
  const modulId = props.match.params.id
  const entity = props.match.params.entity
  const selectInputRef = useRef();
  const onClear = () => {
    selectInputRef.current.clearValue();
  };
  const handleParcaLookup = async (inputValue) =>{
    if (inputValue === '') {
      return []
    }
    const response = await axios(token).get(apiPath.uretimSettingsLookup('parca', inputValue))
    return response.data
  }
  const handleParcaSelected = (parca) => {
    setState(prevState=> ({...prevState, new: { ...prevState.new, parca}}))
  }
  const handleRenkSelected = (renk,parcaId) => {
    const clonedParcalar = cloneDeep(state.modul.parcalar)
    const parca = clonedParcalar.find(p=>Number(p.id) === Number(parcaId))
    set(parca,'renk',renk)
    setState(prevState=> ({...prevState, modul: { ...prevState.modul, parcalar: clonedParcalar}}))
  }
  const handleKapakVeyaGovdeMalzemesiSelected = (kapakVeyaGovdeMalzemesi,parcaId) => {
    const clonedParcalar = cloneDeep(state.modul.parcalar)
    const parca = clonedParcalar.find(p=>Number(p.id) === Number(parcaId))
    set(parca,'kapakVeyaGovdeMalzemesi',kapakVeyaGovdeMalzemesi)
    setState(prevState=> ({...prevState, modul: { ...prevState.modul, parcalar: clonedParcalar}}))
  }
  const handleRenkLookup = async (inputValue) =>{
    if (inputValue === '') {
      return []
    }
    const response = await axios(token).get(apiPath.uretimSettingsLookup('govdeRengi,kapakRenk', inputValue))
    return response.data
  }
  const handleKapakVeyaGovdeMalzemesiLookup = async (inputValue) =>{
    if (inputValue === '') {
      return []
    }
    const response = await axios(token).get(apiPath.uretimSettingsLookup('kapakModel,govdeMalzemesi', inputValue))
    return response.data
  }

  const handleChangeExistingParcaProperties = (event) => {
    const {name,value} = event.target
    const clonedParcalar = cloneDeep(state.modul.parcalar)
    set(clonedParcalar,name,value)

    setState(prevState=> ({...prevState, modul: { ...prevState.modul, parcalar: [...clonedParcalar]}}))
  }
  const handleAddParca = () => {
    if (state.modul.parcalar.length >0 &&  state.modul.parcalar.some(parca => Number(parca.id) === Number(state.new.parca.id)))
    {
      toast.warn(`Parca listede mevcut!`, toastErrorOptions)
      return
    }
    const clonedParcalar = cloneDeep(state.modul.parcalar)
    clonedParcalar.push({ ...state.new.parca, miktar: state.new.miktar})
    setState(prevState=> ({...prevState, new: {...initialNewParca}, modul: { ...prevState.modul, parcalar: [...clonedParcalar]}}))
    // onClear()
  }

  const handleChangeNewParcaProperties = (event) => {
    const {name,value} = event.target
    const clonedNew = cloneDeep(state.new)
    set(clonedNew,name,value)

    setState(prevState=> ({...prevState, new: clonedNew }))
  }
  const whenPopulated = () => {
    return (
      state.new.parca &&
      Number(state.new.parca.uzunluk) > 0 &&
      Number(state.new.parca.genislik) > 0 &&
      Number(state.new.miktar) > 0 &&
      Number(state.new.parca.boyut) > 0
    )
  }
  const handleRemove = (id) => {
    const parcalar = state.modul.parcalar.filter(parca => parca.id !== id)
    setState(prevState=> ({...prevState, new: {...initialNewParca}, modul: { ...prevState.modul, parcalar}}))
  }
  const handleFetch = async (id) => {
    const response = await axios(token).get(apiPath.uretimSettingsGetById(entity, id))
    return response.data
  }
  const handleSave = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    const options = {
      method: 'PUT',
      url: apiPath.uretimSettingsDataIdProp(entity, modulId, 'parca'),
      data: state.modul.parcalar
    }
    await axios(token)(options)
    toast.success(`Parcalar kaydedildi!`, toastOptions)
  }
  useEffect(() => {
    setState(prevState=> ({...prevState,loading: true}))
    handleFetch(modulId)
      .then(modul => setState(prevState=> ({...prevState, loading: false, modul, error:null})))
      .catch(error => setState(prevState=> ({...prevState, loading: false, error})))
  }, [])
  return (<>
    {state.loading && <Loading/>}
    {!state.loading &&  <>
      <h1>{state.modul.aciklama}</h1>
      <ModulNavigation entity={entity} modulId={modulId}/>
      <div className='row'>
        <h5>Yeni Parca:</h5>
        <div className='form-group col-sm-2'>
          <NumberInput
            step='1'
            label='Miktar'
            name='miktar'
            value={state.new.miktar}
            onChange={handleChangeNewParcaProperties}
          />
        </div>
        <div className='form-group col-sm-4'>
          <Label htmlFor='new-parca-select'>Parca</Label>
          <AsyncSelect
            ref={selectInputRef}
            name='new-parca-select'
            cacheOptions={false}
            loadOptions={handleParcaLookup}
            onChange={handleParcaSelected}
            getOptionValue={(option) => option['id']}
            getOptionLabel={(option) => option['aciklama']}
          />
        </div>
        <div className='form-group col-sm-1'>
          <Button className='mt-4' name='new-parca-button' disabled={!whenPopulated()} color='success' onClick={handleAddParca}>
            Ekle
          </Button>
        </div>
      </div>
      <h5>Mevcut Parcalar:</h5>
        <form onSubmit={handleSave}>
          {state.modul.parcalar && state.modul.parcalar.map((item, index) => {
            return (<Card key={`${item.id}`}>
              <CardHeader>
                {item.aciklama}
                <small className='text-muted'>
                  ({item.tip.aciklama} - {item.tip.grup})
                </small>
                <Button
                  className='float-end'
                  color='danger'
                  id={`delete-${index}`}
                  onClick={() => handleRemove(item.id)}
                >
                  Sil
                </Button>
              </CardHeader>
              <CardBody>
                <div className='row'>
                  <div className='form-group col-md-1'>
                    <NumberInput
                      step='1'
                      label='Miktar'
                      name={`${index}.miktar`}
                      value={item.miktar}
                      onChange={handleChangeExistingParcaProperties}
                    />
                  </div>
                  <div className='form-group col-md-2'>
                    <NumberInput
                      step='1'
                      disabled={true}
                      label='Boyut'
                      name={`${index}.boyut`}
                      value={item.boyut}
                      onChange={handleChangeExistingParcaProperties}
                    />
                  </div>
                  <div className='form-group col-md-2'>
                    <NumberInput
                      step='1'
                      disabled={true}
                      label='Uzunluk'
                      name={`${index}.uzunluk`}
                      value={item.uzunluk}
                    />
                  </div>
                  <div className='form-group col-md-2'>
                    <NumberInput
                      step='1'
                      disabled={true}
                      label='Genislik'
                      name={`${index}.genislik`}
                      value={item.genislik}
                    />
                  </div>
                  {entity === 'mobilya' && (<><div className='form-group col-md-3'>
                    <Label htmlFor='new-parca-renk-select'>Renk</Label>
                    <AsyncSelect
                      ref={selectInputRef}
                      value={item.renk}
                      name='new-parca-renk-select'
                      cacheOptions={false}
                      loadOptions={handleRenkLookup}
                      onChange={(renk) => handleRenkSelected(renk,item.id)}
                      getOptionValue={(option) => option['id']}
                      getOptionLabel={(option) => option['aciklama']}
                    />
                  </div>
                    <div className='form-group col-md-2'>
                      <Label htmlFor='kapakVeyaGovdeMalzemesi-select'>Kapak veya Govde Malzemesi</Label>
                      <AsyncSelect
                        value={item.kapakVeyaGovdeMalzemesi}
                        name='kapakVeyaGovdeMalzemesi'
                        cacheOptions={false}
                        loadOptions={handleKapakVeyaGovdeMalzemesiLookup}
                        onChange={(model) =>handleKapakVeyaGovdeMalzemesiSelected(model,item.id)}
                        getOptionValue={(option) => option['id']}
                        getOptionLabel={(option) => option['aciklama']}
                      />
                    </div></>)
                  }
                </div>
              </CardBody>
            </Card>)
          })}
          <div className='form-group row'>
            <div className='form-group col-md-12'>
              <NavLink className='btn btn-secondary float-start' to={`/uretim/ayarlar/${entity}`}>
                Vazgec
              </NavLink>
              <button type='submit' className='btn btn-primary float-end'>
                <i className='fas fa-save' />
                Kaydet
              </button>
            </div>
          </div>
        </form>
      </>
    }
    </>
  )
}
export default ModulParcalarForm
